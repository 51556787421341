import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { Button, Icon, Modal, Table, Header, Label, Segment } from 'semantic-ui-react';
import { log, post } from '../../util/api';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

class HistoryTable extends Component {
	state = {
		targets: [],
		loading: true,
		eyeLoading: false,
	};
	componentDidMount() {
		this.getLogs();
	}

	async getLogs() {
		let { product } = this.props;
		let pid = product.id;
		await post('/dahi/tag/list', {}).then((data) => {
			//console.log(data);
			this.setState({ tagList: data.data.result.result });
		});
		await post('/broadcast/listBroadcastUser', { pid, state: 'all' }).then((data) => {
			let list = data.data.result;
			this.setState({
				list,
				filtered_list: list,
				selectedUserId: '',
				loading: false,
				isAddUserModalOpen: false,
				openDeleteModal: false,
				loadingBtn: false,
				openEditModal: false,
				name: '',
				phone: '',
				selectedUser: '',
				selectedUserId: '',
			});
		});
		await log('/live/groupMessageLog/all', { pid }).then((data) => {
			let response = data.data && data.data.message;
			let response_order = _.orderBy(response, ['date'], ['desc']);
			let today = moment();
			let first_five_array = response_order && response_order.length > 4 ? response_order.slice(0, 5) : response_order;
			let same_month_array = _.filter(response_order, (o) => moment(o.date).month() === today.month());
			let first_show_array = same_month_array > first_five_array ? same_month_array : first_five_array;
			this.setState({ logData: _.orderBy(response, ['date'], ['desc']), first_show_array });
		});
		let { first_show_array, tagList, logData } = this.state;
		let merged_broadcast_list = product.settings.merged_broadcast_list;
		if (merged_broadcast_list)
			for (let index = 0; index < first_show_array.length; index++) {
				const element = first_show_array[index];
				let { log, date } = element;
				let groups = [];
				_.map(log.groups, (it) => groups.push(it));
				let new_log = {};
				if (index > 0) {
					new_log = first_show_array && first_show_array[index - 1];
				}
				let tag =
					log && log.extra && log.extra.targetlist
						? _.find(tagList, { _id: log.extra.targetlist }) && _.find(tagList, { _id: log.extra.targetlist }).title
						: log && log.extra && log.extra.targetlist;
				let detail = await this.getDetail(log, groups, date, new_log, tag, true);

				let logData_element = _.findIndex(logData, (o) => o.id === element.id);
				element.detail = detail;
				logData[logData_element].detail = detail;
				this.setState({ first_show_array, logData });
			}
		this.setState({ loading: false });
	}
	closeModal = () => {
		this.setState({ openModal: false, startedSend: false });
	};
	closeModalSent = () => {
		this.setState({ openModalSend: false, startedSend: false });
	};
	exportExcel = async (messageTypes) => {
		this.setState({ buttonLoading: true });
		let { product, t } = this.props;
		let pid = product.id;
		let { details, selected, selectedDate, selectedLog, cancel_trigger_count, total_message_count, messages_from_users, selectedTag } = this.state;
		console.log('selected', selected);
		let sent_list = _.filter(details, { status: 'sent' });
		let delivered_list = _.filter(details, { status: 'delivered' });
		let read_list = _.filter(details, { status: 'read' });
		let error_list = _.filter(details, { status: 'failed' });
		let excel_data = _.map(selected, (o) => {
			let phone_number = /@/.test(o.id) ? o.id.replace(/@.*/, '') : o.id;
			let rgx = new RegExp(phone_number);

			let isRead = _.find(read_list, (it) => rgx.test(it.phone_id));
			let isDelivered = isRead || _.find(delivered_list, (it) => rgx.test(it.phone_id));
			let isSent = isDelivered || _.find(sent_list, (it) => rgx.test(it.phone_id));
			let isError = _.find(error_list, (it) => rgx.test(it.phone_id));
			return {
				İsim: o.name,
				Telefon: phone_number,
				'Gönderen Telefon': o.phone,
				Hatalı: '' + !isSent,
				Gönderildi: '' + !!isSent,
				Ulaştı: '' + !!isDelivered,
				Okundu: '' + !!isRead,
			};
		});

		let sheets = [
			{
				name: 'message',
				x: ['Mesaj', 'Tip'],
				datasets: [
					{
						Mesaj: selectedLog.message + (selectedLog.text_message ? ' - ' + selectedLog.text_message : ''),
						Tip: _.find(messageTypes, { value: selectedLog.message_type }).text,
					},
				],
			},
			{
				name: 'message_analytic',
				x: ['İsim', 'Telefon', 'Gönderen Telefon', 'Hatalı', 'Gönderildi', 'Ulaştı', 'Okundu'],
				datasets: excel_data,
			},
		];
		//console.log(selectedLog, excel_data, selected, selectedDate);
		if (product.settings.merged_broadcast_list) {
			let data = [
				{
					[t('date')]: moment(selectedDate).format('DD-MM-YYYY HH:mm'),
					[t('message')]: selectedLog.message + (selectedLog.text_message ? ' - ' + selectedLog.text_message : ''),
					message_name: selectedLog.extra && selectedLog.extra.message_name,
					[t('label')]: selectedLog.extra.targetlist,
					[t('total')]: selectedLog.groups.length,
					[t('errored')]: _.filter(excel_data, (it) => it['Hatalı'] === 'true').length,
					[t('sent')]: _.filter(excel_data, (it) => it['Gönderildi'] === 'true').length,
					[t('delivered')]: _.filter(excel_data, (it) => it['Ulaştı'] === 'true').length,
					[t('read_mee')]: _.filter(excel_data, (it) => it['Okundu'] === 'true').length,
					Unsub: cancel_trigger_count,
					Replies: total_message_count,
				},
			];
			let message_data = _.map(messages_from_users, (it) => {
				let phone = it.recipient && it.recipient.replace(/-.*/, '');
				let name = phone && _.find(excel_data, { Telefon: phone });
				return {
					Date: moment(it.sendDate).format('DD-MM-YYYY'),
					Time: moment(it.sendDate).format('HH:mm'),
					Name: (name && name.İsim) || phone,
					Phone: phone,
					Label: selectedTag,
					Message: selectedLog.message + (selectedLog.text_message ? ' - ' + selectedLog.text_message : ''),
					message_name: selectedLog.extra && selectedLog.extra.message_name,
					Reply:
						it &&
						it.message &&
						(it.message.imageUrl ? (it.message.text ? it.message.imageUrl + ' - ' + it.message.text : it.message.imageUrl) : it.message.text),
				};
			});

			sheets = [
				{
					name: 'general_analytic',
					x: [
						t('date'),
						'message_name',
						t('message'),
						t('label'),
						t('total'),
						t('errored'),
						t('sent'),
						t('delivered'),
						t('read_mee'),
						'Unsub',
						'Replies',
					],
					datasets: data,
				},
				{
					name: 'recieve_analytic',
					x: ['Date', 'Time', 'Name', 'Phone', 'Label', 'message_name', 'Message', 'Reply'],
					datasets: message_data,
				},
				{
					name: 'send_analytic',
					x: ['İsim', 'Telefon', 'Gönderen Telefon', 'Hatalı', 'Gönderildi', 'Ulaştı', 'Okundu'],
					datasets: excel_data,
				},
			];
		}
		await fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: moment(selectedDate).format('DD_MM_YYYY_HH_mm_ss') + '_broadcast_message_' + pid,
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
				this.setState({ buttonLoading: false });
			});
	};
	exportRecieve = async (messages) => {
		let { product } = this.props;
		let pid = product.id;
		let messages_from_users = _.flatten(
			_.map(messages, (it) => {
				let message_name = it.log.extra.message_name,
					tag = it.detail.selectedTag,
					message = it.log.extra.content.text;

				let messages_from_users = it.detail && it.detail.messages_from_users;
				if (messages_from_users) {
					_.map(messages_from_users, (o) => {
						o.tag = tag;
						o.message_name = message_name;
						o.content_message = message;
					});
					console.log('messages_from_users', messages_from_users);
					return messages_from_users;
				}
			})
		);
		let message_data = _.map(messages_from_users, (it) => {
			let phone = it.recipient && it.recipient.replace(/-.*/, '');
			let name = it.recipientName || phone;
			return {
				Date: moment(it.sendDate).format('DD-MM-YYYY'),
				Time: moment(it.sendDate).format('HH:mm'),
				Name: name || phone,
				Phone: phone,
				Label: it.tag,
				Message: it.content_message,
				message_name: it.message_name,
				Reply:
					it &&
					it.message &&
					(it.message.imageUrl ? (it.message.text ? it.message.imageUrl + ' - ' + it.message.text : it.message.imageUrl) : it.message.text),
			};
		});
		let sheets = [
			{
				name: 'recieve_analytic_all',
				x: ['Date', 'Time', 'Name', 'Phone', 'Label', 'message_name', 'Message', 'Reply'],
				datasets: message_data,
			},
		];
		await fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: 'reply_message_' + pid + '_' + Date.now(),
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
				this.setState({ buttonLoading: false });
			});
	};
	async getDetail(log, groups, date, new_log, tag, noOpenModal = false) {
		let { filtered_list } = this.state;
		this.setState({ btnRefresh: true, eyeLoading: true });
		let { product } = this.props;
		//let pid = product.id;
		let live_pid = product.settings.live.token;
		let live_id = product.settings.live_id;
		let hash = log && log.extra && log.extra.hash;
		let start_send_time = moment(date).add(log.extra.start_time, 's');
		let _cancel_trigger_count, _total_message_count, _messages_from_users, _details, _detailList;
		await fetch('https://live.dahi.ai/analytics/dialogtab/broadcastResult ', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				template_name: log.template_name || log.message,
				list: 'uid',
				pid: live_pid,
				start_date: moment(start_send_time),
				end_date: moment(start_send_time).add('20', 'd'),
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let list = [];
				_.map(data.data, (it) =>
					_.map(it.list, (o) => {
						o.status = it._id;
						list.push(o);
					})
				);
				_details = list;
				_detailList = data.data;
				this.setState({
					details: list,
					detailList: data.data,
				});
			});
		if (product && product.settings && product.settings.merged_broadcast_list) {
			await fetch('https://live.dahi.ai/analytics/dialogtab/broadcastAfterMessageCount ', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					template_name: log.template_name || log.message,
					hash,
					new_log,
					live_id,
					list: 'uid',
					pid: live_pid,
					cancel_trigger_word: product.settings.cancel_trigger_word,
					start_date: moment(start_send_time).add('-3', 'h'),
					end_date: moment(start_send_time).add('32', 'h'),
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					let result = data && data.data;
					console.log('rrrr', result);
					let { cancel_trigger_count, total_message_count, messages_from_users } = result;
					if (tag && tag !== 'all') {
						messages_from_users = _.filter(messages_from_users, (it) => {
							let phone = it.recipient.replace(/-.*/, '');
							let user = _.find(filtered_list, (o) => o.phone === phone);
							let user_tag = user && user.data && user.data.tag && _.includes(user.data.tag, tag);
							if (user_tag) return true;
						});
					}
					total_message_count = messages_from_users.length;
					_cancel_trigger_count = cancel_trigger_count;
					_total_message_count = total_message_count;
					_messages_from_users = messages_from_users;
					this.setState({ cancel_trigger_count, total_message_count, messages_from_users });
				});
		}

		/*  */
		if (!noOpenModal) {
			this.setState({ openModal: true, selected: groups, selectedLog: log, selectedDate: date, btnRefresh: false, selectedTag: tag, eyeLoading: false });
		} else {
			return {
				selected: groups,
				selectedLog: log,
				selectedDate: date,
				selectedTag: tag,
				cancel_trigger_count: _cancel_trigger_count,
				total_message_count: _total_message_count,
				messages_from_users: _messages_from_users,
				details: _details,
				detailList: _detailList,
				btnRefresh: false,
				eyeLoading: false,
			};
		}
	}
	async sendMessage() {
		let { selectedLog, targets } = this.state;
		let { product } = this.props;
		let pid = product.id;
		let extra = selectedLog.extra;
		//{id: '120363022784153087@g.us', name: 'Base', phone_id: 13628}
		extra.numbers = _.map(targets, (o) => ({ id: o.id, name: o.name, phone_id: o.number }));
		await log('/api/addSchedule', {
			category: 'schedule',
			label: 'broadcast_message_event',
			extra,
			pid,
		}).then(() => this.setState({ startedSend: true, disabledSend: true }));
	}
	render() {
		let {
			logData,
			loading,
			openModal,
			selected,
			details,
			selectedLog,
			selectedDate,
			btnRefresh,
			targets,
			tagList,
			startedSend,
			disabledSend,
			total_message_count,
			cancel_trigger_count,
			selectedTag,
			first_show_array,
			filtered_list,
		} = this.state;
		let { t, product } = this.props;
		if (loading) return <Page.Loader></Page.Loader>;

		let excel_data = _.filter(logData, (it) => it.detail);
		let messageTypes = [
			{
				value: 'text',
				text: t('Yazı'),
			},
			{
				value: 'media',
				text: t('Medya'),
			},
			{
				value: 'link',
				text: t('Link'),
			},
			{
				value: 'document',
				text: t('document'),
			},
			{
				value: 'template',
				text: 'Template',
			},
			{
				value: 'audio',
				text: t('audio'),
			},
		];
		let sent_list = _.uniqBy(
			_.filter(details, (it) => it.status === 'delivered' || it.status === 'read' || it.status === 'sent'),
			'cid'
		);
		let delivered_list = _.uniqBy(
			_.filter(details, (it) => it.status === 'delivered' || it.status === 'read'),
			'cid'
		);
		console.log('logData', selected, selectedLog && selectedLog.groups);
		let read_list = _.filter(details, { status: 'read' });
		let error_listed = _.filter(selected, (it) => {
			let rex = /@/.test(it.id) ? new RegExp('' + it.id.replace(/@.*/, '')) : new RegExp(it.id);
			let is_sent = _.findIndex(sent_list, (o) => rex.test(o.phone_id));
			if (product && product.settings && product.settings.merged_broadcast_list) {
				let is_active = _.findIndex(filtered_list, (o) => rex.test(o.phone) && o.state === 'active');
				return is_sent === -1 && is_active >= 0;
			} else {
				return is_sent === -1;
			}
		});
		let error_listed2 = _.uniqBy(
			_.filter(details, (it) => it.status === 'failed'),
			'cid'
		);
		if (product && product.settings && product.settings.merged_broadcast_list) {
			return (
				<Table className="history-table" attached>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={1}>#</Table.HeaderCell>
							<Table.HeaderCell width={2}>{t('date')}</Table.HeaderCell>
							<Table.HeaderCell width={2}>Mesaj İsmi</Table.HeaderCell>
							<Table.HeaderCell>{t('message')}</Table.HeaderCell>
							<Table.HeaderCell width={2}>{t('label')}</Table.HeaderCell>
							<Table.HeaderCell width={2} textAlign="right">
								{t('count')}
							</Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="right">
								<Button onClick={() => this.exportRecieve(excel_data)} color="green" basic>
									Download
								</Button>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(logData, ({ log, date, detail, id }, i) => {
							let groups = [];
							_.map(log.groups, (it) => groups.push(it));
							let new_log = {};
							if (i > 0) {
								new_log = logData && logData[i - 1];
							}
							let tag =
								log && log.extra && log.extra.targetlist
									? _.find(tagList, { _id: log.extra.targetlist }) && _.find(tagList, { _id: log.extra.targetlist }).title
									: log && log.extra && log.extra.targetlist;

							let sent_list = _.uniqBy(
								_.filter(detail && detail.details, (it) => it.status === 'delivered' || it.status === 'read' || it.status === 'sent'),
								'cid'
							);
							let delivered_list = _.uniqBy(
								_.filter(detail && detail.details, (it) => it.status === 'delivered' || it.status === 'read'),
								'cid'
							);
							console.log('etail.details', detail);
							let read_list = _.filter(detail && detail.details, { status: 'read' });
							let error_listed = _.filter(log.groups, (it) => {
								let rex = /@/.test(it.id) ? new RegExp('' + it.id.replace(/@.*/, '')) : new RegExp(it.id);
								let is_sent = _.findIndex(sent_list, (o) => rex.test(o.phone_id));
								let is_active = _.findIndex(filtered_list, (o) => rex.test(o.phone) && o.state === 'active');
								return is_sent === -1 && is_active >= 0;
							});
							if (error_listed) console.log('error_listed', error_listed, error_listed2);
							return (
								<>
									<Table.Row key={i} className={i % 2 === 0 ? 'even' : 'odd'}>
										<Table.Cell>{i + 1}</Table.Cell>
										<Table.Cell>{moment(date).format('DD-MM-YYYY HH:mm')}</Table.Cell>
										<Table.Cell>{log && log.extra && log.extra.message_name}</Table.Cell>
										<Table.Cell>
											{log.message} {log.text_message ? ' - ' + log.text_message : ''}
										</Table.Cell>
										<Table.Cell>{tag}</Table.Cell>
										<Table.Cell textAlign="right">
											{groups.length}
											<Button
												icon
												size="mini"
												style={{ marginLeft: '20px' }}
												onClick={() => this.getDetail(log, groups, date, new_log, tag)}
											>
												<Icon name="eye"></Icon>
											</Button>
										</Table.Cell>
										<Table.Cell></Table.Cell>
									</Table.Row>
									{detail ? (
										<Table.Row>
											<Table.Cell colSpan="6" style={{ padding: '0px 0px 0px 30px', backgroundColor: 'cornflowerblue' }}>
												<div>
													<Table attached>
														<Table.Header>
															<Table.Row>
																<Table.HeaderCell>{t('date')}</Table.HeaderCell>
																<Table.HeaderCell>Mesaj İsmi</Table.HeaderCell>
																<Table.HeaderCell width={4}>{t('message')}</Table.HeaderCell>
																<Table.HeaderCell>{t('label')}</Table.HeaderCell>
																<Table.HeaderCell>{t('total')}</Table.HeaderCell>
																<Table.HeaderCell>{t('errored')}</Table.HeaderCell>
																<Table.HeaderCell>{t('sent')}</Table.HeaderCell>
																<Table.HeaderCell>{t('delivered')}</Table.HeaderCell>
																<Table.HeaderCell>{t('read_mee')}</Table.HeaderCell>
																<Table.HeaderCell>{'Unsub'}</Table.HeaderCell>
																<Table.HeaderCell>{'Replies'}</Table.HeaderCell>
															</Table.Row>
														</Table.Header>
														<Table.Body>
															<Table.Row key={id}>
																<Table.Cell>{moment(date).format('DD-MM-YYYY HH:mm')}</Table.Cell>
																<Table.Cell>{log && log.extra && log.extra.message_name}</Table.Cell>
																<Table.Cell>
																	{log.message} {log.text_message ? ' - ' + log.text_message : ''}
																</Table.Cell>
																<Table.Cell>{detail && detail.selectedTag}</Table.Cell>
																<Table.Cell>{log.groups.length}</Table.Cell>
																<Table.Cell>{error_listed.length}</Table.Cell>
																<Table.Cell>{log.groups.length + error_listed.length}</Table.Cell>
																<Table.Cell>{delivered_list.length}</Table.Cell>
																<Table.Cell>{read_list.length}</Table.Cell>
																<Table.Cell>{detail.cancel_trigger_count}</Table.Cell>
																<Table.Cell>{detail.total_message_count}</Table.Cell>
															</Table.Row>
														</Table.Body>
													</Table>
												</div>
											</Table.Cell>
										</Table.Row>
									) : (
										''
									)}
								</>
							);
						})}
					</Table.Body>
					{selectedLog ? (
						<Modal open={openModal} onClose={this.closeModal} style={{ width: '900px' }}>
							<Header icon="whatsapp" content={t('Geçmiş')} />
							<Modal.Content>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('date')}</Table.HeaderCell>
											<Table.HeaderCell width={1}>Mesaj İsmi</Table.HeaderCell>
											<Table.HeaderCell width={4}>{t('message')}</Table.HeaderCell>
											<Table.HeaderCell>{t('label')}</Table.HeaderCell>
											<Table.HeaderCell>{t('total')}</Table.HeaderCell>
											<Table.HeaderCell>{t('errored')}</Table.HeaderCell>
											<Table.HeaderCell>{t('sent')}</Table.HeaderCell>
											<Table.HeaderCell>{t('delivered')}</Table.HeaderCell>
											<Table.HeaderCell>{t('read_mee')}</Table.HeaderCell>
											<Table.HeaderCell>{'Unsub'}</Table.HeaderCell>
											<Table.HeaderCell>{'Replies'}</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										<Table.Row key={selectedLog.date}>
											<Table.Cell>{moment(selectedLog.date).format('DD-MM-YYYY HH:mm')}</Table.Cell>
											<Table.Cell style={{ lineBreak: 'anywhere' }}>
												{selectedLog && selectedLog.extra && selectedLog.extra.message_name}
											</Table.Cell>
											<Table.Cell style={{ lineBreak: 'anywhere' }}>
												{`${(selectedLog.message + (selectedLog.text_message ? ' - ' + selectedLog.text_message : '')).slice(0, 47)}${
													(selectedLog.message + (selectedLog.text_message ? ' - ' + selectedLog.text_message : '')).length > 47
														? '...'
														: ''
												}`}
											</Table.Cell>
											<Table.Cell>{selectedTag}</Table.Cell>
											<Table.Cell>{selected.length}</Table.Cell>
											<Table.Cell>{error_listed.length}</Table.Cell>
											<Table.Cell>{sent_list.length}</Table.Cell>
											<Table.Cell>{delivered_list.length}</Table.Cell>
											<Table.Cell>{read_list.length}</Table.Cell>
											<Table.Cell>{cancel_trigger_count}</Table.Cell>
											<Table.Cell>{total_message_count}</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
								<div style={{ display: 'flex', marginBottom: '10px' }}>
									<Button
										icon
										positive
										onClick={() => this.exportExcel(messageTypes)}
										loading={this.state.buttonLoading}
										disabled={this.state.buttonLoading}
									>
										{t('DownloadExcel')}
									</Button>
									{error_listed.length && selectedLog.extra ? (
										<Button
											disabled={disabledSend}
											icon
											primary
											onClick={() => this.setState({ openModalSend: true, targets: error_listed })}
										>
											{t('sentToError')}
										</Button>
									) : (
										''
									)}
									{console.log('selectedLog', selectedLog)}
									<div style={{ textAlign: 'end', marginLeft: 'auto' }}>
										<Button
											loading={btnRefresh}
											icon
											basic
											color="green"
											size="mini"
											onClick={() => this.getDetail(selectedLog, selected, selectedDate)}
										>
											<Icon name="redo"></Icon>
										</Button>
										<Label color="red" image>
											{t('errored')}
											<Label.Detail>{error_listed.length}</Label.Detail>
										</Label>
										<Label color="orange" image>
											{t('sent')}
											<Label.Detail>{sent_list.length}</Label.Detail>
										</Label>
										<Label color="yellow" image>
											{t('delivered')}
											<Label.Detail>{delivered_list.length}</Label.Detail>
										</Label>
										<Label color="green" image>
											{t('read_mee')}
											<Label.Detail>{read_list.length}</Label.Detail>
										</Label>
									</div>
								</div>
								<div style={{ display: 'flex', maxHeight: '500px', overflowY: 'scroll' }}>
									<Table>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>{t('name')}</Table.HeaderCell>
												<Table.HeaderCell>{t('phone')}</Table.HeaderCell>
												<Table.HeaderCell>{t('sentPhone')}</Table.HeaderCell>
												<Table.HeaderCell width={5} textAlign="center">
													{t('status')}
												</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{_.map(selected, (it, i) => {
												let phone_number = /@/.test(it.id) ? it.id.replace(/@.*/, '') : it.id;
												let rgx = new RegExp(phone_number);

												let isRead = _.find(read_list, (o) => rgx.test(o.phone_id));
												let isDelivered = isRead || _.find(delivered_list, (o) => rgx.test(o.phone_id));
												let isSent = isDelivered || _.find(sent_list, (o) => rgx.test(o.phone_id));
												let isError = _.find(error_listed, (o) => rgx.test(o.phone_id));
												return (
													<Table.Row key={i}>
														<Table.Cell>{it.name || it.id}</Table.Cell>
														<Table.Cell>{phone_number}</Table.Cell>
														<Table.Cell>{it.phone}</Table.Cell>
														<Table.Cell style={{ textAlign: 'end' }}>
															{!isSent ? <Label color="red">{t('errored')}</Label> : null}
															{isSent ? <Label color="orange">{t('sent')}</Label> : null}
															{isDelivered ? <Label color="yellow">{t('delivered')}</Label> : null}
															{isRead ? <Label color="green">{t('read_mee')}</Label> : null}
														</Table.Cell>
													</Table.Row>
												);
											})}
										</Table.Body>
									</Table>
								</div>
							</Modal.Content>
						</Modal>
					) : (
						''
					)}
					<Modal open={this.state.openModalSend} onClose={this.closeModalSent} style={{ width: '900px', maxHeight: '600px' }}>
						<Header icon="whatsapp" content={`${t('sendMessage')} (${targets.length})`} />
						{startedSend ? (
							<Modal.Content>
								<p>{t('statedErrorNumbers')}</p>
							</Modal.Content>
						) : (
							<Modal.Content>
								<div style={{ maxHeight: '129px', overflowY: 'auto' }}>
									<Table>
										<Table.Body>
											{_.map(targets, (it, i) => (
												<Table.Row key={i}>
													<Table.Cell>{it.name || it.id}</Table.Cell>
													<Table.Cell>{it.id}</Table.Cell>
													<Table.Cell>
														{it.tags && (
															<div className="cl-labels tags-line">
																{_.map(it.tags, function (ot) {
																	let _tag = _.find(tagList, { _id: ot });
																	if (_tag)
																		return (
																			<label className="label-tags" style={{ color: _tag.color }}>
																				{_tag.title}
																			</label>
																		);
																})}
															</div>
														)}
													</Table.Cell>
													<Table.Cell>
														{it.phone === 'waba' ? 'Kişi' : it.type === 'chat' || /@c\.us/.test(it.id) ? t('Kişi') : t('Grup')}
													</Table.Cell>
												</Table.Row>
											))}
										</Table.Body>
									</Table>
								</div>
							</Modal.Content>
						)}
						{startedSend ? (
							''
						) : (
							<Modal.Actions>
								<div style={{ display: 'flex' }}>
									<div style={{ textAlign: 'left' }}>
										<p style={{ display: 'flex', alignItems: 'center' }}>{t('sendErrorGroupsSure')}</p>
									</div>

									<div style={{ marginLeft: 'auto' }}>
										<Button color="red" onClick={this.closeModalSent}>
											<Icon name="remove" /> {t('no')}
										</Button>
										<Button color="green" onClick={() => this.sendMessage(false)}>
											<Icon name="checkmark" /> {t('yes')}
										</Button>
									</div>
								</div>
							</Modal.Actions>
						)}
					</Modal>
				</Table>
			);
		}
		return (
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>{t('message')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('label')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('type')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('date')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('Gruplar')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(logData, ({ log, date }) => {
						let groups = [];
						_.map(log.groups, (it) => groups.push(it));
						return (
							<Table.Row key={date}>
								<Table.Cell>
									{log.message} {log.text_message ? ' - ' + log.text_message : ''}
								</Table.Cell>
								<Table.Cell>{log && log.extra && log.extra.targetlist}</Table.Cell>
								<Table.Cell>
									{(_.find(messageTypes, { value: log.message_type }) && _.find(messageTypes, { value: log.message_type }).text) || 'other'}
								</Table.Cell>
								<Table.Cell>{moment(date).format('DD-MM-YYYY HH:mm')}</Table.Cell>
								<Table.Cell>
									{groups.length}
									<Button
										loading={this.state.eyeLoading}
										icon
										size="mini"
										style={{ marginLeft: '20px' }}
										onClick={() => this.getDetail(log, groups, date)}
									>
										<Icon name="eye"></Icon>
									</Button>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
				{selectedLog ? (
					<Modal open={openModal} onClose={this.closeModal} style={{ width: '900px' }}>
						<Header icon="whatsapp" content={t('Geçmiş')} />
						<Modal.Content>
							<Segment className="message">
								{
									<div>
										<p>
											{t('message')}: {selectedLog.message} {selectedLog.text_message ? ' - ' + selectedLog.text_message : ''}
										</p>
										<p>
											{t('type')}: {_.find(messageTypes, { value: selectedLog.message_type }).text}
										</p>
									</div>
								}
							</Segment>
							<div style={{ display: 'flex', marginBottom: '10px' }}>
								<Button
									icon
									positive
									onClick={() => this.exportExcel(messageTypes)}
									loading={this.state.buttonLoading}
									disabled={this.state.buttonLoading}
								>
									{t('DownloadExcel')}
								</Button>
								{error_listed.length && selectedLog.extra ? (
									<Button disabled={disabledSend} icon primary onClick={() => this.setState({ openModalSend: true, targets: error_listed })}>
										{t('sentToError')}
									</Button>
								) : (
									''
								)}

								<div style={{ textAlign: 'end', marginLeft: 'auto' }}>
									<Button
										loading={btnRefresh}
										icon
										basic
										color="green"
										size="mini"
										onClick={() => this.getDetail(selectedLog, selected, selectedDate)}
									>
										<Icon name="redo"></Icon>
									</Button>
									<Label color="red" image>
										{t('errored')}
										<Label.Detail>{error_listed.length}</Label.Detail>
									</Label>
									<Label color="orange" image>
										{t('sent')}
										<Label.Detail>{sent_list.length}</Label.Detail>
									</Label>
									<Label color="yellow" image>
										{t('delivered')}
										<Label.Detail>{delivered_list.length}</Label.Detail>
									</Label>
									<Label color="green" image>
										{t('read_mee')}
										<Label.Detail>{read_list.length}</Label.Detail>
									</Label>
								</div>
							</div>
							<div style={{ display: 'flex', maxHeight: '500px', overflowY: 'scroll' }}>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('name')}</Table.HeaderCell>
											<Table.HeaderCell>{t('phone')}</Table.HeaderCell>
											<Table.HeaderCell>{t('sentPhone')}</Table.HeaderCell>
											<Table.HeaderCell width={5} textAlign="center">
												{t('status')}
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(selected, (it, i) => {
											let phone_number = /@/.test(it.id) ? it.id.replace(/@.*/, '') : it.id;
											let rgx = new RegExp(phone_number);

											let isRead = _.find(read_list, (o) => rgx.test(o.phone_id));
											let isDelivered = isRead || _.find(delivered_list, (o) => rgx.test(o.phone_id));
											let isSent = isDelivered || _.find(sent_list, (o) => rgx.test(o.phone_id));

											let isError = _.find(error_listed, (o) => rgx.test(o.phone_id));
											return (
												<Table.Row key={i}>
													<Table.Cell>{it.name || it.id}</Table.Cell>
													<Table.Cell>{phone_number}</Table.Cell>
													<Table.Cell>{it.phone}</Table.Cell>
													<Table.Cell style={{ textAlign: 'end' }}>
														{!isSent ? <Label color="red">{t('errored')}</Label> : null}
														{isSent ? <Label color="orange">{t('sent')}</Label> : null}
														{isDelivered ? <Label color="yellow">{t('delivered')}</Label> : null}
														{isRead ? <Label color="green">{t('read_mee')}</Label> : null}
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</div>
						</Modal.Content>
					</Modal>
				) : (
					''
				)}
				<Modal open={this.state.openModalSend} onClose={this.closeModalSent} style={{ width: '900px', maxHeight: '600px' }}>
					<Header icon="whatsapp" content={`${t('sendMessage')} (${targets.length})`} />
					{startedSend ? (
						<Modal.Content>
							<p>{t('statedErrorNumbers')}</p>
						</Modal.Content>
					) : (
						<Modal.Content>
							<div style={{ maxHeight: '129px', overflowY: 'auto' }}>
								<Table>
									<Table.Body>
										{_.map(targets, (it, i) => (
											<Table.Row key={i}>
												<Table.Cell>{it.name || it.id}</Table.Cell>
												<Table.Cell>{it.id}</Table.Cell>
												<Table.Cell>
													{it.tags && (
														<div className="cl-labels tags-line">
															{_.map(it.tags, function (ot) {
																let _tag = _.find(tagList, { _id: ot });
																if (_tag)
																	return (
																		<label className="label-tags" style={{ color: _tag.color }}>
																			{_tag.title}
																		</label>
																	);
															})}
														</div>
													)}
												</Table.Cell>
												<Table.Cell>
													{it.phone === 'waba' ? 'Kişi' : it.type === 'chat' || /@c\.us/.test(it.id) ? t('Kişi') : t('Grup')}
												</Table.Cell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							</div>
						</Modal.Content>
					)}
					{startedSend ? (
						''
					) : (
						<Modal.Actions>
							<div style={{ display: 'flex' }}>
								<div style={{ textAlign: 'left' }}>
									<p style={{ display: 'flex', alignItems: 'center' }}>{t('sendErrorGroupsSure')}</p>
								</div>

								<div style={{ marginLeft: 'auto' }}>
									<Button color="red" onClick={this.closeModalSent}>
										<Icon name="remove" /> {t('no')}
									</Button>
									<Button color="green" onClick={() => this.sendMessage(false)}>
										<Icon name="checkmark" /> {t('yes')}
									</Button>
								</div>
							</div>
						</Modal.Actions>
					)}
				</Modal>
			</Table>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(HistoryTable));
