import React, { useState } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Table, Tab, Button, Modal, Header, Message, Container } from 'semantic-ui-react';
import { Link, Prompt, Route, matchPath, NavLink } from 'react-router-dom';
import useFetch from 'use-http';
import { Formik, FastField } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { TextInput, TableLine, BooleanInput } from '../inputs';
import AccountProgress from '../../../components/AccountProgress';
import { useTranslation } from 'react-i18next';
const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

function SubConfigs() {
	const { t } = useTranslation();
	return (
		<FastField name="active">
			{({ field }) =>
				field.value ? (
					<Table definition>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell width={2} />
								<Table.HeaderCell>{t('value')}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							<TableLine title={t('productSearch')} description={t('productSearchDesc')}>
								<BooleanInput name="_search" />
							</TableLine>
							<TableLine title={t('productDetails')} description={t('productDetailsDesc')}>
								<BooleanInput name="_detail" />
							</TableLine>
						</Table.Body>
					</Table>
				) : null
			}
		</FastField>
	);
}

function GeneralConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2} />
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title="Url">
						<TextInput name="host" placeholder={t('yourWebsiteUrl')} />
					</TableLine>

					<TableLine title="Token">
						<TextInput name="token" placeholder="Api Token" />
					</TableLine>
				</Table.Body>
			</Table>
			<SubConfigs />
		</Tab.Pane>
	);
}
function TicimaxLogs() {
	const { t } = useTranslation();
	const { loading, response } = useFetch('/api/dahi/channel/ticimax_logs', fetch_config, []);
	const result = response && response.data && response.data.data;
	if (loading) return <Page.Loader></Page.Loader>;
	return (
		<Table>
			<Table.Header>
				<Table.HeaderCell>{t('name')}</Table.HeaderCell>
				<Table.HeaderCell>{'body'}</Table.HeaderCell>
				<Table.HeaderCell>{t('date')}</Table.HeaderCell>
				<Table.HeaderCell>{t('status')}</Table.HeaderCell>
				<Table.HeaderCell>{t('sec')}</Table.HeaderCell>
			</Table.Header>
			<Table.Body>
				{result &&
					_.map(result, (it, i) => (
						<Table.Row key={i}>
							<Table.Cell>{it.name}</Table.Cell>
							<Table.Cell>
								<pre>{JSON.stringify(it.body, null, 2)}</pre>
							</Table.Cell>
							<Table.Cell>{moment(it.date).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
							<Table.Cell>{it.success ? 'true' : 'false'}</Table.Cell>
							<Table.Cell>{it.t.toFixed(0)}</Table.Cell>
						</Table.Row>
					))}
			</Table.Body>
		</Table>
	);
}

function Configs() {
	const { t } = useTranslation();
	const panes = [
		{
			menuItem: {
				as: NavLink,
				content: 'Logs',
				to: '/ticimax_logs',
				key: 'ticimax_logs',
			},
			render: () => <Route path="/ticimax_logs" component={TicimaxLogs} />,
		},
	];
	// Required to get a correct tab opened by default
	const defaultActiveIndex = panes.findIndex((pane) => {
		return !!matchPath(window.location.pathname, {
			path: pane.menuItem.to,
			exact: true,
		});
	});
	return (
		<Page.Wrap>
			<Tab menu={{ secondary: true, pointing: true }} defaultActiveIndex={defaultActiveIndex} panes={panes} />
		</Page.Wrap>
	);
}

export default function ClientSettings() {
	const { t } = useTranslation();
	const { loading, response } = useFetch('/api/dahi/channel/get/ticimax', fetch_config, []);
	const result = response && response.data;
	const [isShowing, setIsShowing] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [message, setMessage] = useState('');
	const [messageType, setMessageType] = useState('');
	const [showMessage, setShowMessage] = useState(true);
	const initialValues = React.useMemo(() => {
		if (!result) return;
		const { config } = (result && result.data) || {};
		//const agent = _.pick(_.assignIn(agentOptions, staticOptions), ['emoji']);
		return _.merge({ host: '', username: '', password: '' }, config);
	}, [result]);
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<Container activeItem="ticimax">
					<Page.Loader />
				</Container>
			</Page>
		);

	function openModal() {
		setIsShowing(true);
	}
	function closeModal() {
		setIsShowing(false);
	}
	function removeMaytapi() {
		setDeleting(true);
		fetch('/api/dahi/channel/save', {
			method: 'POST',
			body: JSON.stringify({
				channel: 'ticimax',
				data: { remove: true },
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((json) => {
				if (json.success) {
					setShowMessage(false);
					setIsShowing(false);
					setMessageType('positive');
					setMessage('Your ticimax account deleted.');
					setDeleting(false);
					window.location.reload();
				} else {
					setShowMessage(false);
					setIsShowing(false);
					setMessageType('negative');
					setMessage('Error');
					setDeleting(false);
				}
			});
	}
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				if (values.client) values.client.name = values.name;
				fetch('/api/dahi/channel/save', {
					method: 'POST',
					body: JSON.stringify({
						channel: 'ticimax',
						data: values,
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						return response.json();
					})
					.then((json) => {
						if (json.success) {
							setShowMessage(false);
							setMessageType('positive');
							setMessage('Your ticimax account connected.');
							values.active = true;
							actions.resetForm(values);
						} else {
							setShowMessage(false);
							setMessageType('negative');
							setMessage('Error');
						}
						actions.setSubmitting(false);
					})
					.catch((e) => {
						console.error(e);
						setShowMessage(false);
						setMessageType('negative');
						setMessage('Error');
						actions.setSubmitting(false);
					});
			}}
		>
			{(props) => {
				return (
					<Page
						header={
							<Breadcrumb size="big">
								<Breadcrumb.Section>Ticimax {t('settings')}</Breadcrumb.Section>
							</Breadcrumb>
						}
						flex
					>
						<Container activeItem="ticimax">
							<form onSubmit={props.handleSubmit} autoComplete="off">
								<Prompt
									when={props.dirty}
									message={(location) =>
										location.pathname.startsWith('/settings/integrations/website-chat') ? true : t('questionWithoutSaving')
									}
								/>

								<Configs />
								<div style={{ textAlign: 'center' }}>
									<Message compact hidden={showMessage} positive={messageType === 'positive'} negative={messageType === 'negative'}>
										{message}
									</Message>
								</div>
							</form>
						</Container>
						{/*delete modal*/}
						<Modal open={isShowing} onClose={() => closeModal()} style={{ width: '500px' }}>
							<Header icon="trash" content={t('removeTsoftConnection', { title: 'Ticimax' })} />
							<Modal.Content>
								<p>{t('tsoftRemove', { title: 'Ticimax' })}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={() => closeModal()}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" loading={deleting} onClick={() => removeMaytapi(props.values)}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</Modal.Actions>
						</Modal>
					</Page>
				);
			}}
		</Formik>
	);
}
