import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { Menu, Icon, Dropdown, Header, Message, Segment, Dimmer, Loader } from 'semantic-ui-react';
import Page from '../../components/Page';
import IceBreakers from './icebreaker';
import PersistantMenu from './persistentMenu';
import { post } from '../../util/api';
import _ from 'lodash';
class InstagramIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageListOptions: [],
			selectedAccound: '',
			haveaccound: true,
			loading: true,
		};
	}
	componentDidMount() {
		let { pageListOptions } = this.state;
		let pageList = {};
		post('/messenger/register/insta/list', {}).then((res) => {
			pageList = res && res.data && res.data.result;
			_.map(pageList, (it, i) => {
				if (it && it.accessToken !== null) {
					let result = { key: it.shop, text: (it && it.data && it.data.connected_instagram_account_name) || it.shop, value: it.shop };
					pageListOptions.push(result);
				}
			});
			this.setState({ pageListOptions, loading: false });
			if (window.location.pathname.split('/icebreakers/')[1] !== '' || window.location.pathname.split('/persistentMenu/')[1] !== '') {
				this.setState({ selectedAccound: window.location.pathname.split('/icebreakers/')[1] || window.location.pathname.split('/persistentMenu/')[1] });
			}
			if (pageList.length === 0) {
				this.setState({ haveaccound: false });
			}
		});
	}
	handleChange = (e, { name, value }) => {
		if (name === 'selectAccount') {
			let mainPathName = '/settings/instagramsettings';

			if (window.location.pathname.includes('persistentMenu')) {
				let newPathName = mainPathName + '/persistentMenu/' + value;
				this.props.history.replace(newPathName);
				this.setState({ selectedAccound: value });
			} else {
				let newPathName = mainPathName + '/icebreakers/' + value;
				this.props.history.replace(newPathName);
				this.setState({ selectedAccound: value });
			}
		}
	};

	render() {
		let { pageListOptions, selectedAccound, haveaccound, loading } = this.state;
		function GoInstagramMessenger() {
			return <Redirect to={'/settings/integrations/instagram-messenger'} />;
		}
		return loading === true ? (
			<Page title={'instagram'} icon="instagram">
				<Page.Wrap>
					<Dimmer active inverted center>
						<Loader inverted></Loader>
					</Dimmer>
				</Page.Wrap>
			</Page>
		) : haveaccound === false ? (
			<GoInstagramMessenger />
		) : (
			<Page title={'instagram'} icon="instagram">
				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '12px', paddingTop: '12px' }}>
					<div style={{ marginLeft: '15px' }}>
						<Header as={'h3'} content={'id: '} />
					</div>
					<div>
						<Dropdown
							placeholder="chose your account"
							name={'selectAccount'}
							fluid
							id={'selectAccount'}
							selection
							style={{ marginLeft: '10px' }}
							options={pageListOptions}
							onChange={this.handleChange}
							value={selectedAccound}
						/>
					</div>
				</div>
				{selectedAccound && selectedAccound.length > 0 && (
					<>
						<Page.Wrap>
							<Menu className="settings" pointing secondary>
								<Menu.Item as={NavLink} to={`/settings/instagramsettings/icebreakers/${selectedAccound}`} exact>
									<Icon name="pencil alternate" />
									IceBreakers
								</Menu.Item>
								<Menu.Item as={NavLink} to={`/settings/instagramsettings/persistentMenu/${selectedAccound}`}>
									<Icon name="list" />
									ThePersisantMenu
								</Menu.Item>
							</Menu>
							<Switch>
								<Route path={`/settings/instagramsettings/icebreakers/${selectedAccound}`} component={IceBreakers} />
								<Route path={`/settings/instagramsettings/persistentMenu/${selectedAccound}`} component={PersistantMenu} />
							</Switch>
						</Page.Wrap>
					</>
				)}
			</Page>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(withTranslation()(InstagramIndex));
