import React from 'react';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Page from '../../components/Page';
import { Menu } from 'semantic-ui-react';

import MainTable from './table';
import SubTable from './subtable';
import AnalyticTable from './analytictable';
import Partners from './partners';
import GenerateLink from './generatelink';
import ProductAnalytic from './productanalytic';
import InstagramUsers from './instagramusers';
import AppsTable from './appTable';
import Extension from './extension';
import ActiveAccounts from './activeaccounts';

export default (props) => {
	let user = props && props.access && props.access.user && props.access.user.loggedUserObj;
	let limitedAdmin = user && user.extra.limitedAdmin;
	return (
		<Page title="admin" icon="setting">
			<Menu className="settings" attached="top" tabular>
				<Menu.Item as={NavLink} to="/yta" exact>
					Table
				</Menu.Item>
				<Menu.Item as={NavLink} to="/yta/analytictable">
					Analytic Table
				</Menu.Item>
				{!limitedAdmin ? (
					<>
						<Menu.Item as={NavLink} to="/yta/subtable">
							Subs Table
						</Menu.Item>
						<Menu.Item as={NavLink} to="/yta/instagramusers">
							Instagram Users
						</Menu.Item>
						<Menu.Item as={NavLink} to="/yta/partners">
							Partners
						</Menu.Item>
						<Menu.Item as={NavLink} to="/yta/appstable">
							Apps Table
						</Menu.Item>
					</>
				) : null}
				<Menu.Item as={NavLink} to="/yta/generatelink">
					Generate Link
				</Menu.Item>
				<Menu.Item as={NavLink} to="/yta/productsanalytic">
					Products Analytic
				</Menu.Item>
				<Menu.Item as={NavLink} to="/yta/extension">
					Extension Log
				</Menu.Item>
				<Menu.Item as={NavLink} to="/yta/activeaccounts">
					Active Accounts
				</Menu.Item>
			</Menu>
			<Switch>
				<Route path="/yta" exact component={MainTable} />
				<Route path="/yta/productsanalytic" component={ProductAnalytic} />
				<Route path="/yta/analytictable" component={AnalyticTable} />
				{!limitedAdmin ? (
					<>
						<Route path="/yta/subtable" component={SubTable} />
						<Route path="/yta/instagramusers" component={InstagramUsers} />
						<Route path="/yta/generatelink" component={GenerateLink} />
						<Route path="/yta/partners" component={Partners} />
						<Route path="/yta/appstable" component={AppsTable} />
						<Route path="/yta/extension" component={Extension} />
						<Route path="/yta/activeaccounts" component={ActiveAccounts} />
					</>
				) : null}
				<Route path="/yta/generatelink" component={GenerateLink} />

				<Redirect to="/yta" />
			</Switch>
		</Page>
	);
};
