import React from 'react';
import { Input, Icon, Checkbox, Dropdown, Table, Popup, Button, TextArea } from 'semantic-ui-react';
import { FastField, Field } from 'formik';
import cs from 'classnames';
import { ChromePicker } from 'react-color';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line
import styled from 'styled-components/macro';

export class MaterialInput extends React.PureComponent {
	render() {
		let { className, icon, color, min, max, readonly, ...rest } = this.props;
		return (
			<Input transparent icon={this.props.iconPosition !== 'left' && !!icon} {...rest} className={cs(className, 'yt-input', color)}>
				{icon && <Icon name={icon} />}
				<input min={min} max={max} readOnly={readonly} />
				<span />
			</Input>
		);
	}
}

export class MaterialTextArea extends React.PureComponent {
	render() {
		let { className, icon, color, min, max, readonly, ...rest } = this.props;
		return (
			<TextArea
				style={{ minHeight: 100 }}
				transparent
				icon={this.props.iconPosition !== 'left' && !!icon}
				{...rest}
				className={cs(className, 'yt-input', color)}
			></TextArea>
		);
	}
}
export const FormDropdown = styled(Dropdown)`
	min-width: ${(props) => props.size || 200}px;
	//text-align: center !important;
	height: 22px;

	.text {
		font-weight: 100 !important;
	}
	.dropdown.icon {
		float: right;
	}
`;

export function BooleanInput(props) {
	return (
		<FastField name={props.name}>
			{({ field, form }) => (
				<Checkbox
					toggle
					onChange={(e, { checked }) => {
						form.setFieldValue(field.name, checked);
					}}
					checked={field.value}
				/>
			)}
		</FastField>
	);
}

export function DropdownInput(props) {
	const { t } = useTranslation();
	return (
		<FastField name={props.name}>
			{({ field, form }) => (
				<FormDropdown
					className="yt-line"
					placeholder={props.placeholder || t('select')}
					inline
					value={field.value}
					onChange={(e, { value }) => {
						form.setFieldValue(props.name, value);
					}}
					scrolling
					compact
					options={props.list}
				/>
			)}
		</FastField>
	);
}

export function TextInput(props) {
	return (
		<FastField name={props.name}>
			{({ field }) => (
				<MaterialInput
					css={`
						max-width: 100%;
						height: 22px;
					`}
					readonly={props.readonly}
					type={props.type || 'text'}
					name={field.name}
					placeholder={props.placeholder || '...'}
					value={field.value}
					onChange={field.onChange}
				/>
			)}
		</FastField>
	);
}
export function TextInputField(props) {
	return (
		<Field name={props.name}>
			{({ field }) => (
				<MaterialInput
					css={`
						max-width: 100%;
						height: 22px;
					`}
					readonly={props.readonly}
					type={props.type || 'text'}
					name={field.name}
					placeholder={props.placeholder || '...'}
					value={field.value}
					onChange={field.onChange}
				/>
			)}
		</Field>
	);
}

export function UploadTextInput(props) {
	let { t } = props;
	function fileUpload(file, form, name) {
		const url = 'https://uploads.yapaytech.com/flow/uploadPermImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				handleChange(form, name, image_url);
			});
	}
	const handleFileInput = (e, form, name) => {
		let file = e.target.files[0];
		fileUpload(file, form, name);
	};
	const handleChange = (form, name, value) => {
		form.setFieldValue(name, value);
	};
	return (
		<FastField name={props.name}>
			{({ field, form }) => (
				<>
					<MaterialInput
						css={`
							max-width: 95%;
							height: 22px;
						`}
						type={props.type || 'text'}
						name={field.name}
						placeholder={props.placeholder || '...'}
						value={field.value}
						onChange={(e, { value }) => {
							handleChange(form, field.name, value);
						}}
					/>
					<Popup
						content={t('UploadImage')}
						trigger={
							<div class="image-upload" style={{ marginLeft: 'auto' }}>
								<label for="file-input">
									<Icon name="upload" color="blue" size="large" style={{ cursor: 'pointer' }}></Icon>
								</label>
								<input
									id="file-input"
									type="file"
									accept="image/*"
									style={{ display: 'none' }}
									onChange={(e) => {
										handleFileInput(e, form, field.name);
									}}
								/>
							</div>
						}
					/>
				</>
			)}
		</FastField>
	);
}

export function TableLine(props) {
	if (_.has(props, 'single'))
		return (
			<Table.Row warning={props.warning}>
				<Table.Cell>{props.title || ''}</Table.Cell>
				<Table.Cell>{props.children}</Table.Cell>
			</Table.Row>
		);
	return (
		<Table.Row warning={props.warning}>
			<Table.Cell>{props.title || ''}</Table.Cell>
			<Table.Cell style={{ display: 'flex' }}>
				{props.children}
				<p style={{ marginLeft: '10px' }}>{props.description || ''}</p>
			</Table.Cell>
		</Table.Row>
	);
}

const POPUP_STYLE = {
	borderRadius: 0,
	padding: 0,
};
export function ColorInput(props) {
	const { t } = useTranslation();
	return (
		<FastField name={props.name}>
			{({ field, form }) => (
				<div
					css={`
						display: flex;
						align-items: center;
						.color-wrap {
							width: 50px;
							height: 30px;
							padding: 2px;
							border: 1px solid rgba(0, 0, 0, 0.2);
							border-radius: 3px;
							margin-left: 5px;
						}
						.color-display {
							width: 100%;
							height: 100%;
							cursor: pointer;
						}
						.button {
							margin-left: 5px;
						}
					`}
				>
					<MaterialInput
						css={`
							max-width: 200px;
							height: 22px;
						`}
						type={'text'}
						name={field.name}
						placeholder={props.placeholder || '...'}
						value={field.value || props.defaultValue}
						onChange={field.onChange}
					/>

					<Popup
						trigger={
							<div className="color-wrap">
								<div className="color-display" style={{ backgroundColor: field.value || props.defaultValue }} />
							</div>
						}
						on="click"
						content={
							<ChromePicker
								style={{ zIndex: '14' }}
								color={field.value || props.defaultValue}
								onChange={({ hex }) => form.setFieldValue(props.name, hex)}
							/>
						}
						style={POPUP_STYLE}
					/>

					{props.defaultValue && (
						<Button size="mini" type="button" onClick={() => form.setFieldValue(props.name, props.defaultValue)}>
							{t('reset')}
						</Button>
					)}
				</div>
			)}
		</FastField>
	);
}
