import React, { Component } from 'react';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Segment, Icon, Table, Button, Message, Breadcrumb, Popup } from 'semantic-ui-react';
import { getWG, postWg, toWapi } from '../../util/api';
import _ from 'lodash';
import moment from 'moment';

function bytesToMegaBytes(bytes) {
	let mb = (bytes / (1024 * 1024)).toFixed(0);
	if (mb === '0') {
		mb = (bytes / (1024 * 1024)).toFixed(3);
	}
	return mb;
}
class WTable extends Component {
	state = {
		loading: true,
		column: 'msCreate',
		direction: 'asc',
	};
	async componentDidMount() {
		let day = window.location.pathname.replace('/wgroup/', '');

		await getWG('/list/' + day).then((data) => {
			this.setState({ list: [data.result.folders] });
		});
		await getWG('/numberList').then((data) => {
			this.setState({ groupList: data.result });
		});
		await getWG('/log').then((data) => {
			let filter_data = _.filter(data.result, (it) => {
				let diff = moment().diff(moment(it.update_date), 'd');
				if (diff <= 360) return it;
			});
			this.setState({ log: filter_data });
		});
		await this.getListPhone();
		this.setState({ allDisable: false });
	}
	async getListPhone() {
		let { product } = this.props;
		let pid = product.id;
		toWapi(`/api/${pid}/listPhones`, product.settings.wapi_token)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					}),
					'id'
				);
				let activePhones = _.filter(data, (it) => it.status === 'active');
				let warningPhones = _.filter(data, (it) => it.status !== 'active');
				this.setState({ phones: data, activePhones, warningPhones, loading: false, btnLoading: false });
			})
			.catch((err) => {
				console.error(err);
			});
	}

	handleSubmit = async (main_folder, sub_folder) => {
		this.setState({ allDisable: true });
		await postWg('/send', {
			main_folder,
			sub_folder,
		}).then((data) => {
			if (data.data.success) {
				this.setState({
					cardType: 'green',
					cardMessage: 'Seçmiş olduğunuz klasör atılmaya başlamıştır.',
				});
				this.componentDidMount();
			} else {
				this.setState({
					cardType: 'red',
					cardMessage: 'Bu klasör daha önce atılmıştır.',
					allDisable: false,
				});
			}
		});
	};
	handleConvert = async (main_folder, sub_folder) => {
		this.setState({
			cardType: 'green',
			cardMessage: 'Seçmiş olduğunuz klasördeki videolar dönüştürülmeye başiamıştır.',
		});
		await postWg('/convert', {
			main_folder,
			sub_folder,
		}).then((data) => {
			if (data.data.success) {
				this.setState({
					cardType: 'green',
					cardMessage: 'Seçmiş olduğunuz klasördeki videolar dönüştürülmeye başiamıştır.',
				});
				this.componentDidMount();
			} else {
				this.setState({
					cardType: 'red',
					cardMessage: 'Hata',
				});
			}
		});
	};
	handleSort = (column) => () => {
		let { direction } = this.state;
		this.setState({
			column,
			direction: direction === 'asc' ? 'desc' : 'asc',
		});
	};
	exportExcel = async () => {
		this.setState({ excelloading: true });
		let { t } = this.props;
		let { list, log } = this.state;
		let newList = _.map(list[0].files, function (it) {
			let imgCount = _.filter(it.images, (ofile) => /(png|jpg|jpeg)$/.test(ofile.name.toLowerCase()));
			let videoCount = _.filter(it.images, (ofile) => /(mp4|mov)$/.test(ofile.name.toLowerCase()));
			let imgSize = _.sumBy(imgCount, function (it) {
				return it.stats.size;
			});
			let videoSize = _.sumBy(videoCount, function (it) {
				return it.stats.size;
			});
			let logData = _.find(log, { main_folder: list[0].name, sub_folder: it.name });
			return {
				Dosya: it.name,
				Resim: imgCount.length,
				'Resim Boyut': imgCount.length === 0 ? 0 : parseFloat(bytesToMegaBytes(imgSize)),
				Video: videoCount.length,
				'Video Boyut': videoCount.length === 0 ? 0 : parseFloat(bytesToMegaBytes(videoSize)),
				'Oluşturma Tarihi': moment(it.msCreate).format('DD-MM-YYYY HH:mm'),
				Durum:
					(logData &&
						logData.status &&
						t(logData.status) + (logData.update_date ? ' (' + moment(logData.update_date).format('DD/MM - HH:mm') + ')' : '')) ||
					t('notSend'),
			};
		});
		let sheets = [
			{
				name: list[0].name + ' Dosyalar',
				x: ['Dosya', 'Resim', 'Resim Boyut', 'Video', 'Video Boyut', 'Oluşturma Tarihi', 'Durum'],
				datasets: newList,
			},
		];
		await fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: list[0].name,
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
				this.setState({ excelloading: false });
			});
	};
	render() {
		let { loading, list, log, cardType, cardMessage, direction, column, phones, activePhones, warningPhones } = this.state;
		let { t } = this.props;
		if (loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/wgroup`}>
								<Icon name="arrow left"></Icon>
								{t('WhatsApp Group')}
							</Breadcrumb.Section>
						</Breadcrumb>
					}
				>
					<Page.Loader></Page.Loader>
				</Page>
			);
		}
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/wgroup`}>
							<Icon name="arrow left"></Icon>
							{t('WhatsApp Group')}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
			>
				<Page.Wrap>
					<div className="wapi-state" style={{ display: 'flex' }}>
						<Button.Group className="but" style={{ marginRight: '10px' }}>
							<Button
								style={{ margin: '0', minWidth: '35px', minHeight: '38px', padding: '5px' }}
								color="green"
								icon
								onClick={() => this.exportExcel()}
							>
								{!this.state.excelloading ? (
									<img src="/icons/excel-white.png" alt="" width="25px" />
								) : (
									<Icon width="25px" loading name="spinner" />
								)}
							</Button>

							<Button
								icon
								color="green"
								basic
								onClick={() => {
									this.setState({ btnLoading: true });
									this.componentDidMount();
								}}
							>
								<Icon name="refresh" loading={this.state.btnLoading} color="white"></Icon>
							</Button>
						</Button.Group>
						<div className="logo">
							<div class="wapi-logo" style={{ background: '#25D366' }}>
								<img src="/images/whatsapp.svg" alt="/images/whatsapp.svg" style={{ height: '38px', display: 'flex', padding: '6px' }}></img>
							</div>
						</div>
						<div className="info" style={{ width: '100%', display: 'flex' }}>
							<div className="active-part" style={{ display: 'flex', background: '#d4edda', lineHeight: '36px', color: '#155724' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="check" color="green" /> Aktif {(activePhones && activePhones.length) + ' / ' + (phones && phones.length)}
								</div>
							</div>
							{warningPhones && warningPhones.length ? (
								<div className="warning-part" style={{ display: 'flex', background: '#fff3cd', lineHeight: '36px', color: '#856404' }}>
									<div style={{ marginLeft: '10px', marginRight: '10px' }}>
										<Icon name="warning" color="brown" />{' '}
										{_.map(warningPhones, (it, i) => (
											<span>{it.number + '(' + it.id + '): ' + it.status + (i !== warningPhones.length - 1 ? ', ' : ' ')}</span>
										))}
									</div>
								</div>
							) : (
								''
							)}
							{warningPhones && warningPhones.length ? (
								<div className="goto" style={{ display: 'flex', lineHeight: '36px', background: 'white', color: 'black' }}>
									<div style={{ marginLeft: '10px', marginRight: '10px' }}>
										<Link to="/settings/integrations/whatsapp-web-api" style={{ color: 'black' }}>
											WhatsApp Ayarlarına Git
										</Link>
									</div>
								</div>
							) : (
								''
							)}
							<div className="phonestat" style={{ display: 'flex', lineHeight: '36px', background: '#9696f378', color: 'darkblue' }}>
								<div style={{ marginLeft: '10px', marginRight: '10px' }}>
									<Icon name="mobile alternate" color="darkblue" />{' '}
									<Link to="/wgroup/phones_status" style={{ color: 'darkblue' }}>
										Kuyruk Durumuna Git
									</Link>
								</div>
							</div>
						</div>
					</div>
					<Segment>
						{/* <h3>{'Ana Klasör'}</h3> */}
						{cardType && <Message color={cardType} content={cardMessage}></Message>}
						{this.state.btnLoading ? (
							<Page.Loader></Page.Loader>
						) : (
							<Table celled sortable>
								<Table.Header>
									{/* <Table.HeaderCell>Ana Klasör</Table.HeaderCell> */}
									<Table.HeaderCell
										sorted={column === 'name' ? (direction === 'asc' ? 'ascending' : 'descending') : null}
										onClick={this.handleSort('name')}
									>
										Alt Klasör
									</Table.HeaderCell>
									<Table.HeaderCell>Dosya Sayısı</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'msCreate' ? (direction === 'asc' ? 'ascending' : 'descending') : null}
										onClick={this.handleSort('msCreate')}
									>
										Oluşturma Tarihi
									</Table.HeaderCell>
									<Table.HeaderCell>Durum</Table.HeaderCell>
									<Table.HeaderCell>Gönder</Table.HeaderCell>
								</Table.Header>
								<Table.Body>
									{_.map(_.orderBy(list[0].files, [column], [direction]), (it, i) => {
										let imgCount = _.filter(it.images, (ofile) => /(png|jpg|jpeg)$/.test(ofile.name.toLowerCase()));
										let videoCount = _.filter(it.images, (ofile) => /mov$/.test(ofile.name.toLowerCase()));
										let videoCount_mp4 = _.filter(it.images, (ofile) => /mp4$/.test(ofile.name.toLowerCase()));
										let otherVideoCount = _.filter(it.images, (ofile) =>
											/webm|flv|vob|ogv|ogg|gif|avi|wmv$/.test(ofile.name.toLowerCase())
										);
										let fileinfo = '';
										let imgSize = _.sumBy(imgCount, function (it) {
											return it.stats.size;
										});
										let videoSize = _.sumBy(videoCount, function (it) {
											return it.stats.size;
										});
										let videoSize_mp4 = _.sumBy(videoCount_mp4, function (it) {
											return it.stats.size;
										});
										if (imgCount.length && (videoCount.length || videoCount_mp4.length)) {
											fileinfo =
												' (' +
												imgCount.length +
												' Resim - ' +
												bytesToMegaBytes(imgSize) +
												' MB, ' +
												videoCount.length +
												' Video Mov - ' +
												bytesToMegaBytes(videoSize) +
												' MB, ' +
												videoCount_mp4.length +
												' Video Mp4 - ' +
												bytesToMegaBytes(videoSize_mp4) +
												' MB )';
										} else if (!imgCount.length && (videoCount.length || videoCount_mp4.length)) {
											fileinfo =
												' (' +
												videoCount.length +
												' Video Mov - ' +
												bytesToMegaBytes(videoSize) +
												' MB, ' +
												videoCount_mp4.length +
												' Video Mp4 - ' +
												bytesToMegaBytes(videoSize_mp4) +
												'MB)';
										} else {
											fileinfo = ' (' + imgCount.length + ' Resim - ' + bytesToMegaBytes(imgSize) + ' MB )';
										}
										let logData = _.find(log, { main_folder: list[0].name, sub_folder: it.name });
										//console.log('sss', logData);
										return (
											<Table.Row>
												<Table.Cell>{it.name}</Table.Cell>
												<Table.Cell style={{ display: 'flex' }}>
													{it.images && it.images.length}
													{fileinfo}
													{videoCount > videoCount_mp4 ? (
														<Button
															style={{ marginLeft: 'auto' }}
															disabled={videoCount <= videoCount_mp4}
															size="mini"
															color="orange"
															onClick={() => this.handleConvert(list[0].name, it.name)}
														>
															Convert
														</Button>
													) : (
														''
													)}
													{otherVideoCount && otherVideoCount.length ? (
														<Popup
															position="top right"
															content={
																'Bu klasör mov dışında farklı uzantılı video vardır. Bu videolara dönüştürme işlemi yapılamaz.'
															}
															trigger={<Icon name="warning sign" color="red" style={{ lineHeight: '28px' }}></Icon>}
														/>
													) : (
														''
													)}
												</Table.Cell>
												<Table.Cell>{moment(it.msCreate).format('DD-MM-YYYY HH:mm')}</Table.Cell>
												<Table.Cell>
													{(logData &&
														logData.status &&
														t(logData.status) +
															(logData.update_date ? ' (' + moment(logData.update_date).format('DD/MM - HH:mm') + ')' : '')) ||
														t('notSend')}
												</Table.Cell>
												<Table.Cell>
													{/* <Button
														disabled={videoCount <= videoCount_mp4}
														size="mini"
														color="orange"
														onClick={() => this.handleConvert(list[0].name, it.name)}
													>
														Convert
													</Button> */}
													<Button
														disabled={(logData && logData.status) || this.state.allDisable}
														size="mini"
														positive
														onClick={() => this.handleSubmit(list[0].name, it.name)}
													>
														Gönder
													</Button>

													{videoCount.length > 3 && !(logData && logData.status) && (
														<Popup
															position="top right"
															content={
																"Bu klasörde 3'ten fazla video bulunmaktadır. Dosya yollama sırasında sorunlar yaşanabilir. "
															}
															trigger={<Icon name="warning sign" color="yellow" style={{ lineHeight: '28px' }}></Icon>}
														/>
													)}
												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						)}
					</Segment>
				</Page.Wrap>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(WTable));
