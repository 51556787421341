import React, { Component } from 'react';
import { Form, Segment, Header, Button, Icon, Input } from 'semantic-ui-react';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { updateProfile } from '../../actions/access.actions';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class UserPage extends Component {
	state = {
		shopname: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.shopname) || '',
		description: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.description) || '',
		phone: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.phone) || '',
		logo: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.logo) || '',
		mindelivery: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.mindelivery) || '',
		minfreedelivery: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.minfreedelivery) || '',
		deliveryfee: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.deliveryfee) || '',
		currency: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.currency) || '',
		discount: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.discount) || '',
		tax: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.tax) || '',
		paymentMethods: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.paymentMethods) || [],
		whatsappButton: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.whatsappButton) || '',
		productDisplay: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.productDisplay) || '',
		priceDisplay: (this.props.user.extra && this.props.user.extra.wcart && this.props.user.extra.wcart.priceDisplay) || '',
	};
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	postData() {
		let {
			shopname,
			description,
			phone,
			logo,
			mindelivery,
			minfreedelivery,
			deliveryfee,
			currency,
			tax,
			discount,
			paymentMethods,
			whatsappButton,
			productDisplay,
			priceDisplay,
		} = this.state;
		this.setState({ btnLoading: true });
		let extra = _.merge(this.props.user.extra, {
			wcart: {
				shopname,
				description,
				phone,
				logo,
				mindelivery,
				minfreedelivery,
				deliveryfee,
				currency,
				discount,
				tax,
				paymentMethods,
				whatsappButton,
				productDisplay,
				priceDisplay,
			},
		});
		post('/auth/updateInfo', {
			extra,
		}).then(() => this.setState({ btnLoading: false }));
	}
	handleAddImages = (name, ob) => {
		let paymentMethods = this.state.paymentMethods;
		paymentMethods.push({ name: '', price: '' });
		this.setState({ paymentMethods });
	};
	handleRemoveImage = (index, ind, ob) => {
		let paymentMethods = this.state.paymentMethods;
		_.pullAt(paymentMethods, [index]);
		this.setState({ paymentMethods });
	};
	handleArrayChange = (e, { name, ind, value }) => {
		let paymentMethods = this.state.paymentMethods;
		let payment = paymentMethods[ind];
		payment[name] = value;
		this.setState({ paymentMethods });
	};
	fileUpload(file, name) {
		const url = 'https://uploads.yapaytech.com/flow/uploadPermImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				this.setState({ [name]: image_url });
			});
	}
	handleFileInput = (e) => {
		let file = e.target.files[0];
		let name = e.target.name;
		this.fileUpload(file, name);
	};
	render() {
		let {
			shopname,
			description,
			phone,
			logo,
			mindelivery,
			minfreedelivery,
			deliveryfee,
			currency,
			btnLoading,
			discount,
			paymentMethods,
			whatsappButton,
			productDisplay,
			priceDisplay,
		} = this.state;
		const { t, product } = this.props;
		let orderButton = [
			{ text: 'Numaraya Gönder', value: 'send' },
			{ text: 'Paylaş', value: 'share' },
		];
		let productDisplayOptions = [
			{ text: 'Liste', value: '1' },
			{ text: 'İkili', value: '2' },
			{ text: 'Üçlü', value: '3' },
		];
		let priceDisplayOptions = [
			{ text: 'Göster', value: 'show' },
			{ text: 'Gösterme', value: 'noShow' },
		];
		let urlList = {
			'20cec87a-3163-4ae4-be35-f67c0b1fe2a0': 'enmodaenuygun',
			'f860fc1e-e975-4bdf-94d6-5e88f62a3963': 'terlikayakkabicanta',
			'6c3f19bf-71c2-4168-8918-8586a8b13aa7': 'toptanterlikalsat',
		};
		let pid = product.id;
		return (
			<Segment>
				<Header>{t('GeneralSettings')}</Header>
				<br />
				<Form>
					<Form.Group widths="equal">
						<Form.Input
							label={t('shopName')}
							name="shopname"
							value={shopname}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('shopname') })}
						/>
						<Form.Input
							label={t('description')}
							name="description"
							value={description}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('description') })}
						/>
						{urlList[pid] ? (
							<Form.Input
								label={'Url'}
								name="description"
								readOnly
								value={'https://whatsapp-shop.com/' + urlList[pid]}
								onChange={this.handleChange}
								placeholder={t('enterPlaceholder', { query: t('description') })}
							/>
						) : (
							''
						)}
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Input
							label={t('phone')}
							name="phone"
							value={phone}
							type="phone"
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('phone') })}
						/>
						<Form.Dropdown
							label={t('orderButton')}
							name="whatsappButton"
							selection
							options={orderButton}
							value={whatsappButton}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('orderButton') })}
						/>
						<Form.Dropdown
							label={t('productDisplay')}
							name="productDisplay"
							selection
							options={productDisplayOptions}
							value={productDisplay}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('productDisplay') })}
						/>
						<Form.Dropdown
							label={t('priceDisplay')}
							name="priceDisplay"
							selection
							options={priceDisplayOptions}
							value={priceDisplay}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('priceDisplay') })}
						/>
						<Form.Input
							label={t('logo')}
							name="logo"
							value={logo}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('logo') })}
						>
							<input />
							<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
								<label htmlFor="file-input_image">
									<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
								</label>
								<Input
									id="file-input_image"
									type="file"
									name={'logo'}
									accept="image/*"
									style={{ display: 'none' }}
									onChange={(e) => this.handleFileInput(e)}
								/>
							</div>
						</Form.Input>
					</Form.Group>
				</Form>

				<Header>{t('OrderSettings')}</Header>
				<br />
				<Form>
					<Form.Group widths="equal">
						<Form.Input
							label={t('minimumOrderDelivery')}
							name="mindelivery"
							type="number"
							value={mindelivery}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('minimumOrderDelivery') })}
						/>
						<Form.Input
							label={t('minimumOrderFreeDelivery')}
							name="minfreedelivery"
							type="number"
							value={minfreedelivery}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('minimumOrderFreeDelivery') })}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Input
							label={t('deliveryFee')}
							name="deliveryfee"
							type="number"
							value={deliveryfee}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('deliveryFee') })}
						/>
						<Form.Input
							label={t('currency')}
							name="currency"
							value={currency}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('currency') })}
						/>
						{/*<Form.Dropdown
							label={t('currency')}
							name="currency"
							selection
							value={currency}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('currency') })}
						/> */}
						{/* <Form.Input
							label={t('tax')}
							name="tax"
							type="number"
							value={tax}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('tax') })}
						/> */}
						<Form.Input
							label={t('discount')}
							name="discount"
							type="number"
							value={discount}
							onChange={this.handleChange}
							placeholder={t('enterPlaceholder', { query: t('discount') })}
						/>
					</Form.Group>
				</Form>
				<Header>{t('PaymentSettings')}</Header>
				<br />
				<div>
					<Form>
						{_.map(paymentMethods, (im, im_i) => (
							<Form.Group>
								<Form.Input label={im_i === 0 ? t('name') : ''} value={im.name} ind={im_i} name={'name'} onChange={this.handleArrayChange}>
									<input />
								</Form.Input>
								<Form.Input label={im_i === 0 ? t('price') : ''} value={im.price} ind={im_i} name={'price'} onChange={this.handleArrayChange}>
									<input />
								</Form.Input>
								<Form.Input
									label={im_i === 0 ? t('extraPrice') : ''}
									value={im.extraPrice}
									ind={im_i}
									name={'extraPrice'}
									onChange={this.handleArrayChange}
								>
									<input />
								</Form.Input>
								<Form.Button
									style={{ marginTop: '26px' }}
									icon
									type="button"
									size="tiny"
									basic
									color="red"
									onClick={() => this.handleRemoveImage(im_i)}
								>
									<Icon name="trash"></Icon>
								</Form.Button>
							</Form.Group>
						))}
						<Button
							icon
							basic
							color="green"
							size="mini"
							type="button"
							style={{ marginTop: '5px' }}
							onClick={() => {
								this.handleAddImages();
							}}
						>
							<Icon name="plus"></Icon>
						</Button>
					</Form>
				</div>
				<Button positive onClick={() => this.postData()} loading={btnLoading} style={{ marginTop: '20px' }}>
					{t('save')}
				</Button>
			</Segment>
		);
	}
}

export default connect(
	(state) => ({ user: state.access.user.loggedUserObj, product: state.product.live }),
	(dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(withTranslation()(UserPage));
