import React, { Component } from 'react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import { Grid, Header, Icon, Segment, Popup, Table, Checkbox, Dropdown, Container, Radio } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter6.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import { colors2 } from '../../constants/colors';
import MockAgents from './mock.json';

const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content style={{ width: '600px' }}>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		pid: /* 'e2fc00fc941c227b723d8a5f63caff08' || 'e2fc00fc941c227b723d8a5f63caff08' || */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		column: 'agentFirstResponseTimeAfterActivate',
		botId: '',
		direction: 'descending',
		dataloading: true,
		startTime: 0,
		endTime: 23,
		selectedBarStyle: 'line',
		loadingGraph: true,
		selectedPlatform: 'all',
		selectedWatsonsGroup: 'all',
	};
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'descending',
			});

			return;
		}
		this.setState({
			direction: direction === 'asc' ? 'descending' : 'asc',
		});
	};
	TableHead = (data, keys, head, column, direction, t) => (
		<Table.Header>
			<Table.HeaderCell width={3}>{t('agent')}</Table.HeaderCell>
			{_.map(head, (val, index) => (
				//<Table.HeaderCell style={{ color: TypeList[val].color }}>{_.capitalize(val)}</Table.HeaderCell>
				<Table.HeaderCell key={index} sorted={column === val ? direction : null} onClick={this.handleSort(val)}>
					{t(_.find(TypeList, { type: val }).title) + ' (' + t('minutes') + ')'}
				</Table.HeaderCell>
			))}
		</Table.Header>
	);
	TableHeadSecond = (data, keys, head, column, direction, t) => (
		<Table.Header>
			<Table.HeaderCell>Agent</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'avg' ? direction : null} onClick={this.handleSort('avg')}>
				{t('afterSendFirstResponse')}
			</Table.HeaderCell>
		</Table.Header>
	);
	TableLine = (data, keys, head, st, agentList, productAgentList, productAgentList2, selectedAgent, column, direction, alldata, t, product) => (
		<Table.Body>
			{selectedAgent === 'all' ? (
				_.map(
					_.orderBy(
						_.filter(productAgentList2, function (it) {
							return it.id !== '5e6f519560b2b4d07559fcf8';
						}),
						[column],
						[direction]
					),
					(val, index) => {
						let oldAgent = _.find(this.state.productOldAgentList, { id: val.key });
						return (
							<Table.Row key={index}>
								{console.log('val', val)}
								<Table.Cell>
									{(val && val[this.props.displayName]) ||
										(oldAgent && oldAgent[this.props.displayName] && 'Eski-' + oldAgent[this.props.displayName]) ||
										t('oldAgent')}
								</Table.Cell>
								{_.map(head, (d, i) => (
									<Table.Cell key={i}>{(val && val[d] && formatMinutesToTime(val[d], product)) || 0}</Table.Cell>
								))}
							</Table.Row>
						);
					}
				)
			) : /bot/.test(selectedAgent) ? (
				<Table.Row key="1">
					<Table.Cell>Bot</Table.Cell>
					{_.map(head, (d, i) => (
						<Table.Cell>
							{(_.find(productAgentList2, { key: selectedAgent }) &&
								formatMinutesToTime(_.find(productAgentList2, { key: selectedAgent })[d], product)) ||
								'0'}
						</Table.Cell>
					))}
				</Table.Row>
			) : (
				<Table.Row key="1">
					<Table.Cell>
						{(_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).developer) || '-'}
					</Table.Cell>
					{_.map(head, (d, i) => (
						<Table.Cell>
							{(_.find(productAgentList2, { id: selectedAgent }) &&
								formatMinutesToTime(_.find(productAgentList2, { id: selectedAgent })[d], product)) ||
								'0'}
						</Table.Cell>
					))}
				</Table.Row>
			)}
			{selectedAgent === 'all' ? (
				<Table.Row key={'all'}>
					<Table.Cell>{t('all')}</Table.Cell>
					{_.map(head, (d, i) => (
						<Table.Cell key={i}>{(_.find(alldata, { type: d }) && formatTime(_.find(alldata, { type: d }).avg, product)) || 0}</Table.Cell>
					))}
				</Table.Row>
			) : (
				''
			)}
		</Table.Body>
	);
	TableLine2 = (data, selectedAgent, column, direction, t, product) => (
		<Table.Body>
			{_.map(_.orderBy(_.filter(data, 'email'), [column], [direction]), (val, index) => (
				<Table.Row key={index}>
					<Table.Cell>{this.props.displayName === 'name' ? val.name || val.email : val.email}</Table.Cell>
					<Table.Cell>{formatTime(val.avg, product) || 0}</Table.Cell>
				</Table.Row>
			))}
			{selectedAgent === 'all' ? (
				<Table.Row key={'all'}>
					<Table.Cell>{t('all')}</Table.Cell>
					<Table.Cell>{_.sumBy(data, 'count') ? formatTime(_.sumBy(data, 'sum') / _.sumBy(data, 'count'), product) : 0}</Table.Cell>
				</Table.Row>
			) : (
				''
			)}
		</Table.Body>
	);
	componentDidMount() {
		let typeQuery = [];
		let pairs = {};
		let group = [];
		let select = [];
		let { start_date, end_date, agent_id, agent_role, selectedPlatform } = this.state;
		let valueArr = this.state.value.length ? this.state.value : TypeList;
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
			if (it.pairs) pairs = _.assignIn(it.pairs);
		});

		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		typeQuery = _.uniq(typeQuery);
		let filter = {},
			filter2 = {};
		if (agent_role === 'agent') {
			filter = { type: typeQuery, pid: this.state.pid, agent: agent_id };
			filter2 = {
				type: ['agentFirstResponseTimeAfterActivate'],
				pid: this.state.pid,
				agent: agent_id,
			};
			this.setState({ selectedAgent: agent_id });
		} else {
			filter = { type: typeQuery, pid: this.state.pid };
			filter2 = {
				type: ['agentFirstResponseTimeAfterActivate'],
				pid: this.state.pid,
			};
		}
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		pairs.platform = 'platform';
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: group,
				pairs: { agent: 'select1', topic: 'select2', platform: 'platform' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent, id: it.agent };
				});
				this.setState({ head: typeQuery });
				agentList = _.uniqBy(agentList, 'key');
				this.setState({ gettingData: _.sortBy(data.result, ['agent', 'asc']), agentList: agentList });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?hist', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter2,
				select: [],
				group: ['type'],
				pairs: {
					agent: 'select1',
					platform: 'platform',
				},
				fn: 'generateHistogramQuery',
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ histogramData: _.sortBy(data.result, ['agent', 'asc']), tableData: _.groupBy(data.result, 'agent') });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type'],
				pairs: { agent: 'select1', topic: 'select2', platform: 'platform' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ alldata: data.result });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'day'],
				pairs,
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				this.setState({ gettingDataDate: _.orderBy(data.result, ['day', 'asc']) });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?hourly', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'day', 'hour'],
				pairs,
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				this.setState({ loadingGraph: false, gettingDataHour: _.orderBy(data.result, ['day', 'asc']) });
			});
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					productOldAgentList: data.result && data.result.old_agents,
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					chat_groups: data.result && data.result.staticOptions && data.result.staticOptions.groups,
					loading: false,
				});
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	toggle = () => this.setState((prevState) => ({ selectedDate: prevState.selectedDate === 'daily' ? 'hourly' : 'daily' }));

	handleFilterCheck(value) {
		let array = this.state.value || [];
		let exists = _.find(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ value: valueArr });
	}
	handleGroupChange = (e, { text, value }) => this.setState({ group: value });
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	componentDidUpdate(oldProps, oldState) {
		const check_list = [
			/* 'start_date', 'end_date', */ 'selectedAgent',
			'value',
			'selectedDate',
			'selectedPlatform',
			'selectedWatsonsGroup',
			'newDataToggle',
		];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date' || key === 'newDataToggle') {
				if (this.props[key] !== oldProps[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
			if (!_.isArray(this.state[key])) {
				if (this.state[key] !== oldState[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			} else if (!_.isEqual(this.state[key], oldState[key])) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}
	async getData() {
		this.setState({ loading: true });
		let typeQuery = [];
		let pairs = {};
		let group = [];
		let dayGroup = ['type', 'day'];
		let select = [];
		let { selectedAgent, selectedDate, selectedPlatform, selectedWatsonsGroup, productAgentList } = this.state;
		let { start_date, end_date } = this.props;
		let showingproductAgentList2;
		if (selectedWatsonsGroup !== 'all') {
			showingproductAgentList2 = _.filter(productAgentList, (it) => it.group === selectedWatsonsGroup);
		} else {
			showingproductAgentList2 = productAgentList;
		}
		let group_agents = _.map(showingproductAgentList2, (it) => it.id);
		_.each(this.state.value, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
			if (it.pairs) pairs = _.assignIn(it.pairs);
		});

		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		typeQuery = _.uniq(typeQuery);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		let filter = {},
			filterHistogram = {};
		if (selectedAgent === 'all') {
			if (selectedWatsonsGroup !== 'all') {
				filter = { type: typeQuery, pid: this.state.pid, agent: group_agents };
				filterHistogram = { type: ['agentFirstResponseTimeAfterActivate'], pid: this.state.pid, agent: group_agents };
			} else {
				filter = { type: typeQuery, pid: this.state.pid };
				filterHistogram = { type: ['agentFirstResponseTimeAfterActivate'], pid: this.state.pid };
			}
		} else {
			filter = { type: typeQuery, pid: this.state.pid, agent: selectedAgent };
			filterHistogram = { type: ['agentFirstResponseTimeAfterActivate'], pid: this.state.pid, agent: selectedAgent };
		}
		if (!(selectedDate === 'daily')) {
			//filter.day = selectedDate;
			dayGroup = ['type', 'hour'];
		}
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		pairs.platform = 'platform';
		pairs.select1 = 'select1';
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'agent'],
				pairs: { agent: 'select1', select1: 'select1', topic: 'select2', platform: 'platform' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent };
				});
				this.setState({ head: typeQuery });
				agentList = _.uniqBy(agentList, 'key');
				this.setState({ gettingData: _.sortBy(data.result, ['agent', 'asc']), agentList: agentList });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?hist2', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				filter: filterHistogram,
				select: [],
				group: ['type'],
				pairs: {
					agent: 'select1',
					select1: 'select1',
					platform: 'platform',
				},
				fn: 'generateHistogramQuery',
			}),
		})
			.then((response) => {
				return response.json();
			})

			.then((data) => {
				/* _.each(data.result, function (it) {
					return (it.hour = (it.hour + 2) % 24);
				}); */
				this.setState({ histogramData: _.sortBy(data.result, ['agent', 'asc']), tableData: _.groupBy(data.result, 'agent') });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type'],
				pairs: { agent: 'select1', select1: 'select1', topic: 'select2', platform: 'platform' },
				options: {
					sum: 1,
					avg: 1,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ alldata: data.result });
			});
		if (typeQuery.length) {
			fetch('https://log.yapaytech.com/live/test/getInfo', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					include_hour: true,
					filter,
					select,
					group: dayGroup,
					pairs,
					options: {
						sum: 1,
						avg: 1,
					},
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (!(selectedDate === 'daily')) {
						/* _.each(data.result, function (it) {
							return (it.hour = (it.hour + 2) % 24);
						}); */
					} else {
						_.each(data.result, function (it) {
							return (it.dayNumber = moment(it.day).dayOfYear());
						});
					}
					this.setState({ gettingDataDate: _.orderBy(data.result, ['day', 'asc']), loading: false });
				});
			fetch('https://log.yapaytech.com/live/test/getInfo', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					include_hour: true,
					filter,
					select,
					group: ['type', 'day', 'hour'],
					pairs,
					options: {
						sum: 1,
						avg: 1,
					},
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					/* if (!(selectedDate === 'daily')) {
						_.each(data.result, function (it) {
							return (it.hour = it.hour + 2);
						});
					} else {
						_.each(data.result, function (it) {
							return (it.dayNumber = moment(it.day).dayOfYear());
						});
					} */
					_.each(data.result, function (it) {
						return (it.dayNumber = moment(it.day).dayOfYear());
					});
					this.setState({ loadingGraph: false, gettingDataHour: _.orderBy(data.result, ['day', 'asc']), loading: false });
				});
		} else {
			this.setState({ gettingDataDate: [], loading: false });
		}
	}

	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};

	render() {
		const { alldata, selectedBarStyle, gettingDataHour, loadingGraph, selectedPlatform, productOldAgentList, selectedWatsonsGroup, chat_groups } =
			this.state;
		let { start_date, end_date, t, i18n, displayName, product, platformList } = this.props;
		let watsons_groups = [
			{
				text: t('allGroups'),
				value: 'all',
				id: 'all',
				key: 'all',
			},
		];
		if (chat_groups && chat_groups.length) {
			_.each(chat_groups, (it) =>
				watsons_groups.push({
					text: it.label,
					value: it.id,
					id: it.id,
					key: it.id,
				})
			);
		}
		let isMock = product.live && product.live.settings.mock_analytic;
		let gettingData = isMock ? MockAgents.times : this.state.gettingData;
		let { agent_role } = this.state;
		let gGettingData = _.groupBy(gettingData, 'agent');
		let histogramData = this.state.histogramData;
		let ghistogramData = _.groupBy(histogramData, 'agent');
		let agentList = this.state.agentList;

		if (isMock) {
			agentList = _.map(gettingData, function (it) {
				return { key: it.agent, text: it.agent, value: it.agent };
			});
		}
		let newList = this.state.newList;
		let user = this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj;
		let agentList2 = _.concat(user, newList);
		let productAgentList2 = isMock ? MockAgents.agentList : this.state.productAgentList;
		let head = this.state.head;
		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		let dataloading = this.state.dataloading;
		_.map(productAgentList2, function (it) {
			return _.map(head, function (d) {
				return (it[d] = 0);
			});
		});
		_.map(gGettingData, function (it) {
			return _.map(it, function (d, i) {
				var e = _.findIndex(agentList, function (o) {
					return o.key === d.agent;
				});
				return (agentList[e][d.type] = d.avg || 0);
			});
		});
		let gettingDataDate = this.state.gettingDataDate;
		let typeList = _.map(TypeList, function (it) {
			return { text: t(it.title), key: it.type, value: it, desc: it.desc };
		});
		function showingBar(data, barStyle) {
			switch (barStyle) {
				case 'line':
					return <Line data={data} options={barOptions} />;
				case 'bar':
					return <Bar data={data} options={barOptions} />;
				case 'stackbar':
					return <Bar data={data} options={barOptionsStack} />;
				case 'radar':
					return <Radar data={data} />;
				default:
					return <Line data={data} options={barOptions} />;
			}
		}
		let showingproductAgentList2;
		if (selectedWatsonsGroup !== 'all') {
			showingproductAgentList2 = _.filter(productAgentList2, (it) => it.group === selectedWatsonsGroup);
		} else {
			showingproductAgentList2 = productAgentList2;
		}
		let barStyleDropdown = [
			{
				text: t('Line'),
				value: 'line',
			},
			{
				text: t('Bar'),
				value: 'bar',
			},
			{
				text: t('StackBar'),
				value: 'stackbar',
			},
			{
				text: t('Radar'),
				value: 'radar',
			},
		];
		const barOptions = {
			scales: {
				xAxes: [{ stacked: false }],
				yAxes: [
					{
						stacked: false,
						ticks: { min: 0 },
						scaleLabel: {
							display: true,
							labelString: t('minutes'),
						},
					},
				],
			},
			tooltips: {
				mode: 'index',
				intersect: false,
				filter: (a) => a.yLabel,
			},
			hover: {
				mode: 'nearest',
				intersect: true,
			},
			legend: {
				display: true,
				labels: { fill: false },
			},
		};
		const barOptionsStack = {
			scales: {
				xAxes: [{ stacked: true }],
				yAxes: [
					{
						stacked: true,
						ticks: { min: 0 },
						scaleLabel: {
							display: true,
							labelString: t('minutes'),
						},
					},
				],
			},
			tooltips: {
				mode: 'index',
				intersect: false,
				filter: (a) => a.yLabel,
			},
			hover: {
				mode: 'nearest',
				intersect: true,
			},
			legend: {
				display: true,
				labels: { fill: false },
			},
		};
		let data = chartData(gettingDataDate, start_date, end_date, this.state.selectedDate, t, i18n);
		let data_hourly = chartDataHourly(gettingDataHour, start_date, end_date, t);
		let dataHistogram = HistogramChartData(ghistogramData, agentList2, displayName, this.state.startTime, this.state.endTime);
		let productAgentList = this.state.productAgentList;
		productAgentList = _.filter(productAgentList, function (it) {
			return it.id !== '5e6f519560b2b4d07559fcf8';
		});
		let dropAgentList = _.map(productAgentList, function (it) {
			return { text: it.developer, key: it.id, value: it.id };
		});
		dropAgentList = _.concat({ text: t('allAgent'), key: 'all', value: 'all' }, dropAgentList);
		_.map(agentList, function (it) {
			let n = _.findIndex(productAgentList2, { id: it.key });
			if (n >= 0) {
				return _.assign(productAgentList2[n], it);
			} else {
				return (productAgentList2 = _.concat(productAgentList2, it));
			}
		});
		_.map(productAgentList2, function (it) {
			return _.map(head, function (d) {
				if (it && !it[d]) {
					return (it[d] = 0);
				} else {
					if (it && it[d]) {
						return (it[d] = miliToSec(it[d]));
					}
				}
			});
		});
		if (agentList) {
			dataloading = false;
		}
		if (this.state.loading || dataloading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		let totalTableData = _.map(this.state.tableData, function (it) {
			let agent = _.find(agentList2, function (o) {
				return o && o.extra && o.extra.user_id === it[0].agent;
			});
			return {
				agent: it[0].agent,
				name: agent && agent.name,
				email: agent && agent.email,
				count: _.sumBy(it, (o) => Number(o.count)),
				sum: _.sumBy(it, 'sum'),
				avg: _.sumBy(it, (o) => Number(o.count)) > 0 ? _.sumBy(it, 'sum') / _.sumBy(it, (o) => Number(o.count)) : 0,
			};
		});
		if (agent_role !== 'agent') {
			_.map(productAgentList2, function (it, i) {
				let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;
				if (i === 0) {
					return (it.name = ownerName || it.developer);
				}
				return (it.name = name ? name : it.developer);
			});
		}
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<Segment attached="bottom">
						{
							<div style={{ display: 'flex' }}>
								<Dropdown
									selection
									options={platformList}
									value={selectedPlatform}
									floating
									labeled
									button
									icon="filter"
									className="icon"
									name="selectedPlatform"
									onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
								/>
								{product &&
								product.live &&
								product.live.settings &&
								product.live.settings.analyticView &&
								product.live.settings.analyticView.watsonsgroups ? (
									<Dropdown
										selection
										options={watsons_groups}
										value={selectedWatsonsGroup}
										floating
										labeled
										button
										icon="filter"
										className="icon"
										name="selectedWatsonsGroup"
										onChange={(e, { name, value }) => this.setState({ selectedWatsonsGroup: value })}
									/>
								) : (
									''
								)}
								{agent_role !== 'agent' ? (
									<div style={{ display: 'flex' }}>
										<Dropdown
											options={dropAgentList}
											selection
											defaultValue={dropAgentList[0].value}
											icon="filter"
											floating
											labeled
											button
											className="icon"
											onChange={this.handleFilterChange}
										></Dropdown>
									</div>
								) : (
									''
								)}
							</div>
						}

						<div style={{ display: 'flex' }}>
							{_.map(typeList, (it, i) => (
								<div>
									<Checkbox
										style={{ margin: '10px' }}
										label={it.text}
										key={i}
										onChange={() => this.handleFilterCheck(it.value)}
										defaultChecked={true}
									></Checkbox>
									<Popup trigger={<Icon name="question circle outline" />} content={t(it.desc)} size="mini" />
								</div>
							))}
						</div>
					</Segment>
					<Table sortable fixed width={16} style={{ padding: '0px' }}>
						{this.TableHead(gGettingData, [], this.state.head, this.state.column, this.state.direction, t)}
						{this.TableLine(
							gGettingData,
							[],
							this.state.head,
							this.state,
							this.state.agentList,
							productAgentList,
							showingproductAgentList2,
							this.state.selectedAgent,
							this.state.column,
							this.state.direction,
							alldata,
							t,
							this.props.product
						)}
					</Table>

					<ChartWrap
						width={8}
						title={t('graph')}
						agentList={this.state.agentList}
						content={
							<div style={{ marginLeft: 'auto' }}>
								<Dropdown
									options={barStyleDropdown}
									value={selectedBarStyle}
									name="selectedBarStyle"
									onChange={this.handleChange}
									style={{ marginRight: '30px' }}
								></Dropdown>
								<Radio style={{ marginLeft: 'auto' }} toggle label={t('24HourDist')} onChange={this.toggle}></Radio>
							</div>
						}
					>
						{showingBar(data, selectedBarStyle)}
					</ChartWrap>
					<ChartWrap
						width={16}
						title={t('graphHourly')}
						content={
							<div style={{ marginLeft: 'auto', display: loadingGraph ? 'none' : 'block' }}>
								<Dropdown
									options={barStyleDropdown}
									value={selectedBarStyle}
									name="selectedBarStyle"
									onChange={this.handleChange}
									style={{ marginRight: '30px' }}
								/>
							</div>
						}
					>
						{loadingGraph ? <Page.Loader /> : showingBar(data_hourly, selectedBarStyle)}
					</ChartWrap>
					<Table sortable fixed width={16} style={{ padding: '0px' }}>
						{this.TableHeadSecond(gGettingData, [], this.state.head, this.state.column, this.state.direction, t)}
						{this.TableLine2(totalTableData, this.state.selectedAgent, this.state.column, this.state.direction, t, this.props.product)}
					</Table>
					<ChartWrap
						width={8}
						title={t('timeGraphDesc')}
						agentList={this.state.agentList}
						content={
							<div style={{ marginLeft: 'auto' }}>
								<span>{t('start')}:</span>{' '}
								<Dropdown
									options={HourArrayDropdownStart(this.state.endTime)}
									name="startTime"
									onChange={this.handleChange}
									value={this.state.startTime}
									defaultValue={0}
									scrolling
									style={{ marginRight: '10px' }}
								></Dropdown>
								<span>{t('end')}:</span>{' '}
								<Dropdown
									options={HourArrayDropdownEnd(this.state.startTime)}
									name="endTime"
									onChange={this.handleChange}
									value={this.state.endTime}
									defaultValue={23}
									scrolling
								></Dropdown>
							</div>
						}
					>
						<Bar data={dataHistogram} options={barOptions} />
					</ChartWrap>
				</Segment>
			</Container>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(moment(start).startOf('d'));
	while (dt <= new Date(moment(end).startOf('d'))) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}
	return arr;
};
var getHourArray = function (start, end) {
	var arr = [],
		dt = start;

	while (dt < end + 1) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
var getHourArray2 = function () {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
var HourArrayDropdownStart = function (end) {
	var arr = [],
		dt = 0;

	while (dt < end) {
		arr.push({ text: dt, value: dt });
		dt++;
	}
	return arr;
};
var HourArrayDropdownEnd = function (start) {
	var arr = [],
		dt = start + 1;

	while (dt < 24) {
		arr.push({ text: dt, value: dt });
		dt++;
	}
	return arr;
};

function miliToSec(value) {
	return (value / 1000 / 60).toFixed(2);
}
function formatTime(value, product) {
	console.log('product.live.settings.showformat', product && !product.live.settings.showformat, value);
	if (!product.live.settings.showformat) return miliToSec(value);

	const totalSeconds = Math.floor(value / 1000); // Milisaniyeyi saniyeye çevirme
	const hours = Math.floor(totalSeconds / 3600); // Saat hesaplama
	const remainingSeconds = totalSeconds % 3600; // Saat hesabından kalan saniyeler
	const minutes = Math.floor(remainingSeconds / 60); // Dakika hesaplama
	const seconds = remainingSeconds % 60; // Dakika hesabından kalan saniyeler

	return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

	let dur = moment.duration(value);
	return (
		dur._data.years +
		dur._data.months +
		dur._data.days * 24 +
		dur._data.hours +
		':' +
		moment(dur._data.minutes, 'm').format('mm') +
		':' +
		moment(dur._data.seconds, 's').format('ss')
	);
}
function formatMinutesToTime(minutes, product) {
	console.log('formatMinutesToTime', product);
	if (!product.live.settings.showformat) return minutes;
	const totalSeconds = Math.round(minutes * 60);

	const hours = Math.floor(totalSeconds / 3600);
	const remainingSeconds = totalSeconds % 3600;
	const minutesPart = Math.floor(remainingSeconds / 60);
	const secondsPart = remainingSeconds % 60;

	const formattedHours = String(hours).padStart(2, '0');
	const formattedMinutes = String(minutesPart).padStart(2, '0');
	const formattedSeconds = String(secondsPart).padStart(2, '0');

	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
function chartData(data, start_date, end_date, selectedDate, t, i18n) {
	moment.locale(i18n.language);
	/* let dataset = _.each(data, function(it) {
		return { label: it.type, sum: it.sum };
	}); */
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray2();
	let gdata = _.groupBy(data, 'type');
	if (selectedDate === 'daily') {
		_.map(gdata, function (it, index) {
			return _.each(dateArr, function (d, i) {
				if (_.findIndex(it, { dayNumber: moment(d).dayOfYear() }) === -1) {
					it.push({ type: it[0].type, day: moment(d).format(), dayNumber: moment(d).dayOfYear(), avg: 0 });
				}
			});
		});
	} else {
		_.map(gdata, function (it, index) {
			return _.each(hourArr, function (d, i) {
				if (_.findIndex(it, { hour: d }) === -1) {
					it.push({ type: it[0].type, day: it[0].day, hour: d, dayNumber: it[0].dayNumber, avg: 0 });
				}
			});
		});
	}
	if (selectedDate === 'daily') {
		labels = _.map(dateArr, (it) => moment(it).format('DD-MMM'));
	} else {
		labels = hourArr;
	}
	let datasets = _.map(gdata, function (it) {
		if (selectedDate === 'daily') {
			it = _.sortBy(it, ['day', 'asc']);
		} else {
			it = _.sortBy(it, ['hour', 'asc']);
		}
		return {
			label: t(_.find(TypeList, { type: it[0].type }).title),
			day: _.map(it, (item) => moment(item.day, 'DD-MM').format()),
			data: _.map(it, (item) => miliToSec(item.avg)),
			borderColor: TypeList[it[0].type].color,
			backgroundColor: TypeList[it[0].type].color,
			fill: false,
		};
	});
	return {
		labels,
		datasets,
	};
}
function chartDataHourly(data, start_date, end_date, t) {
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray2();
	let gdata = _.groupBy(data, 'type');
	_.map(dateArr, (it) => {
		return _.map(hourArr, (o) => {
			labels.push(moment(it).format('DD/MM') + ' - ' + moment(o, 'H').format('HH'));
		});
	});
	let datasets = [];
	//let i = 0;
	datasets = _.map(gdata, (o) => {
		//let color = colors2[i++ % colors2.length];
		let hobj;
		let data_arr = [];
		_.map(dateArr, (ditem) => {
			return _.map(hourArr, (it) => {
				/* if (it >= 2) {
					hobj = _.find(o, {
						dayNumber: moment(ditem).dayOfYear(),
						hour: it - 2,
					});
				} else if (it === 0) {
					hobj = _.find(o, {
						dayNumber: moment(ditem).dayOfYear() - 1,
						hour: it + 22,
					});
				} else {
					hobj = _.find(o, {
						dayNumber: moment(ditem).dayOfYear(),
						hour: it + 22,
					});
				} */
				hobj = _.find(o, {
					dayNumber: moment(ditem).dayOfYear(),
					hour: it,
				});
				return data_arr.push((hobj && miliToSec(hobj.avg)) || 0);
			});
		});
		return {
			label: t(_.find(TypeList, { type: o[0].type }).title),
			data: data_arr,
			borderColor: TypeList[o[0].type].color,
			backgroundColor: TypeList[o[0].type].color,
			fill: false,
		};
	});
	return {
		labels,
		datasets,
	};
}
function HistogramChartData(data, agentList, displayName, startTime, endTime) {
	let labels = [];
	var hourArrLabel = getHourArray(startTime, endTime);
	var hourArr = getHourArray2();
	let display = displayName === 'developer' ? 'email' : 'name';
	let gdata = _.groupBy(data, 'type');
	_.map(gdata, function (it, index) {
		_.map(it, function (a) {
			return _.each(hourArr, function (d, i) {
				if (_.findIndex(a, { hour: d }) === -1) {
					a.push({ agent: a[0].agent, type: a[0].type, day: a[0].day, hour: d, dayNumber: a[0].dayNumber, avg: 0 });
				}
			});
		});
	});
	labels = hourArrLabel;
	let datasets = _.map(gdata.undefined, function (it, i) {
		it = _.sortBy(it, ['hour', 'asc']);
		it = _.filter(it, function (a) {
			return a.hour >= startTime && a.hour <= endTime;
		});
		let name =
			(_.find(agentList, function (o) {
				return o && o.extra && o.extra.user_id === it[0].agent;
			}) &&
				_.find(agentList, function (o) {
					return o && o.extra && o.extra.user_id === it[0].agent;
				})[display]) ||
			(_.find(agentList, function (o) {
				return o && o.extra && o.extra.user_id === it[0].agent;
			}) &&
				_.find(agentList, function (o) {
					return o && o.extra && o.extra.user_id === it[0].agent;
				}).email) ||
			'no';

		let color = colors2[i++ % colors2.length];

		return {
			label: name,
			day: _.map(it, (item) => moment(item.day, 'DD-MM').format()),
			data: _.map(it, (item) => miliToSec(item.avg)),
			borderColor: color,
			backgroundColor: color,
			fill: false,
			lineTension: 0,
		};
	});
	return {
		labels,
		datasets: _.filter(datasets, function (it) {
			return it.label !== 'no';
		}),
	};
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
