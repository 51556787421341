import React, { Component } from 'react';
import { Icon, Segment, Table, Dropdown, Container, Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: [5, 4, 3, 2, 1],
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		pid: /* '02cc63330cd0808f87c54ad9dd94b74a' ||  */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		groupBy: 'agent',
		selectedTopic: 'all',
		column: 'rate',
		botId: '',
		direction: 'descending',
		dataloading: true,
		displayName: 'developer',
		selectedBarStyle: 'line',
		loadingGraph: true,
		rate: 'all',
		selectedPlatform: 'all',
		selectedWatsonsGroup: 'all',
	};
	componentDidMount() {
		this.getData();
	}
	componentDidUpdate(oldProps, oldState) {
		const check_list = [/* 'start_date', 'end_date', */ 'selectedAgent', 'value', 'newDataToggle'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			if (/* key === 'start_date' || key === 'end_date' */ key === 'newDataToggle') {
				if (this.props[key] !== oldProps[key]) {
					this.getData();
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				this.getData();
				break;
			}
		}
	}
	async getData() {
		this.setState({ loading: true });
		let { pid, selectedAgent, selectedPlatform } = this.state;
		let { start_date, end_date } = this.props;
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let filter = {};
		if (selectedAgent === 'all') {
			filter = { type: ['contacted'], pid };
		} else {
			filter = { type: ['closed'], pid, agent: selectedAgent };
		}
		if (selectedPlatform !== 'all') {
			if (pid === '8246c490-2e1c-11eb-ad5c-b51ab8880cfc' && selectedPlatform === 'web') {
				_.set(filter, 'platform', ['web', 'flo']);
			} else {
				_.set(filter, 'platform', selectedPlatform);
			}
		}
		await fetch('https://log.yapaytech.com/live/test/getInfo?contacted', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['contacted'],
					pid: this.state.pid,
				},
				select: [],
				group: ['type', 'cid', 'platform'],
				pairs: { agent: 'type1', cid: 'select2', platform: 'platform' },
				options: {
					cids: 'count(distinct select2)',
				},
				from: `(select lr.* from (select cid from live_event le where pid = '${this.state.pid}' and created_at between '${moment(start_date).format(
					'YYYY-MM-DD'
				)}' and '${moment(end_date).add('1', 'd').format('YYYY-MM-DD')}' and label='contacted' intersect select cid from live_event le2 where pid = '${
					this.state.pid
				}' and created_at between '${moment(start_date).format('YYYY-MM-DD')}' and '${moment(end_date)
					.add('1', 'd')
					.format('YYYY-MM-DD')}' and label='activateAgent') le left join live_refined lr on lr.select2 =le.cid) lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingcontactedData: false,
					contactedData: data && data.result,
				});
			});
		await fetch('https://log.yapaytech.com/live/test/getInfo?closed', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: {
					type: ['closed'],
					pid: this.state.pid,
				},
				select: [],
				group: ['type', 'cid', 'platform', 'topic'],
				pairs: { agent: 'type1', cid: 'select4', platform: 'platform', topic: 'select2' },
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingclosedData: false,
					closedData: data && data.result && _.filter(data.result, { topic: 'auto_close' }),
				});
			});
		await fetch('https://log.yapaytech.com/live/getWaitingInfo1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
				select: '*',
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data && data.result && data.result.result;
				let ne_result = _.map(result, (it) => {
					let selected = _.find(it, { label: 'activateAgent' }) || it[0];
					return {
						cid: selected.cid,
						count: null,
						created_at: selected.created_at,
						label: 'activateAgent',
						platform: selected.platform,
					};
				});
				this.setState({ waitingData: ne_result, loadingwaitingTotalData: false, loading: false });
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	handleFilterChangeRate = (e, { text, value }) => this.setState({ rate: value });
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleFilterCheck(value) {
		let array = this.state.value || [];
		let exists = _.includes(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ value: valueArr });
	}
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'descending',
			});

			return;
		}
		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	render() {
		let { t, platformList } = this.props;
		let { loading, column, direction, selectedPlatform, pid, waitingData, contactedData, closedData } = this.state;

		if (loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);

		let missing_list = _.differenceBy(waitingData, contactedData, 'cid');
		if (selectedPlatform !== 'all') {
			if (pid === '8246c490-2e1c-11eb-ad5c-b51ab8880cfc' && selectedPlatform === 'web') {
				missing_list = _.filter(missing_list, (it) => it.platform === 'web' || it.platform === 'flo');
			} else {
				missing_list = _.filter(missing_list, (it) => it.platform === selectedPlatform);
			}
		}
		let indexTable = 0;
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<div style={{ display: 'flex' }}>
						<Dropdown
							selection
							options={platformList}
							value={selectedPlatform}
							floating
							labeled
							button
							icon="filter"
							className="icon"
							name="selectedPlatform"
							onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
						/>
					</div>
					<Table sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>#</Table.HeaderCell>
								<Table.HeaderCell>{t('conversation')}</Table.HeaderCell>
								<Table.HeaderCell width={4}>{t('date')}</Table.HeaderCell>
								<Table.HeaderCell>{t('platform')}</Table.HeaderCell>
								<Table.HeaderCell width="1"></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(missing_list, function (it, i) {
								let is_miss = _.find(closedData, { cid: it.cid });
								if (is_miss) {
									indexTable++;
									return (
										<Table.Row key={i}>
											<Table.Cell>{indexTable}</Table.Cell>
											<Table.Cell>{it.cid}</Table.Cell>
											<Table.Cell>{moment(it.created_at).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
											<Table.Cell>
												{pid === '8246c490-2e1c-11eb-ad5c-b51ab8880cfc' && it.platform === 'web' ? 'flo' : it.platform}
											</Table.Cell>
											<Table.Cell>
												<Button
													icon
													primary
													onClick={() => {
														const win = window.open('/#' + it.cid, '_blank');
														win.focus();
													}}
												>
													<Icon name="chat"></Icon>
												</Button>
											</Table.Cell>
										</Table.Row>
									);
								}
							})}
						</Table.Body>
					</Table>
				</Segment>
			</Container>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });
export default connect(stateToProps)(withTranslation()(Analytic));
