import React, { Component } from 'react';
import { Segment, Table, Container, Grid, Header, Icon, Dropdown } from 'semantic-ui-react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		pid: this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		selectedDate: 'daily',
		botId: '',
		tableStart: 0,
		tableEnd: 20,
		direction: null,
		column: null,
		selectedBarStyle: 'line',
	};
	_i = 0;
	componentDidMount() {
		let typeQuery = [];
		let group = [];
		let select = [];
		let { start_date, end_date, agent_role, agent_id } = this.state;
		let valueArr = this.state.value.length ? this.state.value : TypeList;
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		this.setState({ head: typeQuery });
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let body = {};
		if (agent_role === 'agent') {
			body = JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
				agent_id,
			});
		} else {
			body = JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			});
		}
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					productOldAgentList: data.result && data.result.old_agents,
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
				});
			});
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		fetch('https://live.dahi.ai/analytics/sharedProductCount', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body,
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					agent_data: data.data && data.data.byAgent,
					date_date: data.data && data.data.byDate,
					loading: false,
				});
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });

	handleFilterCheck = (value, array) => () => {
		let valueArr = array;
		if (!_.find(valueArr, value)) {
			valueArr.push(value);
		} else {
			_.pullAllWith(valueArr, [value], _.isEqual);
		}
		this.setState({ value: valueArr });
	};
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = [/* 'start_date', 'end_date',  */ 'selectedAgent', 'newDataToggle'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date' || key === 'newDataToggle') {
				if (!_.isEqual(this.props[key], oldProps[key])) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}

	async getData() {
		this.setState({ loading: true });
		let typeQuery = [];
		let group = [];
		let select = [];
		let { start_date, end_date } = this.props;
		_.each(this.state.value, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);

		start_date = moment(start_date).add('3', 'hours').format();
		end_date = moment(end_date).add('3', 'hours').format();
		fetch('https://live.dahi.ai/analytics/sharedProductCount', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					agent_data: data.data && data.data.byAgent,
					date_date: data.data && data.data.byDate,
					loading: false,
				});
			});
	}
	handlePaginationChange = (e, { activePage }) => this.setState({});
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'descending',
			});

			return;
		}
		this.setState({
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	TableHead = (column, direction, t) => (
		<Table.Header>
			<Table.HeaderCell>{t('agent')}</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'total' ? direction : null} onClick={this.handleSort('total')}>
				{t('totalSendingProduct')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'c_total' ? direction : null} onClick={this.handleSort('c_total')}>
				{t('uniqueConversation')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'u_total' ? direction : null} onClick={this.handleSort('u_total')}>
				{t('uniqueSendingProduct')}
			</Table.HeaderCell>
			<Table.HeaderCell sorted={column === 'rate' ? direction : null} onClick={this.handleSort('rate')}>
				{t('productsPerConversation')}
			</Table.HeaderCell>
		</Table.Header>
	);
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	TableLine = (data, column, direction, t, agent_role) => (
		<Table.Body>
			{_.map(_.orderBy(data, [column], [direction === 'descending' ? 'desc' : 'asc']), (val, index) => (
				<Table.Row key={index}>
					<Table.Cell>{this.props.displayName === 'name' ? val.name : val.email}</Table.Cell>
					<Table.Cell>{val.total}</Table.Cell>
					<Table.Cell>{val.c_total}</Table.Cell>
					<Table.Cell>{val.u_total}</Table.Cell>
					<Table.Cell>{val.rate && val.rate.toFixed(2)}</Table.Cell>
				</Table.Row>
			))}
			{agent_role !== 'agent' ? (
				<Table.Row key={'all'}>
					<Table.Cell>{t('all')}</Table.Cell>
					<Table.Cell>{_.sumBy(data, 'total')}</Table.Cell>
					<Table.Cell>{_.sumBy(data, 'c_total')}</Table.Cell>
					<Table.Cell>{_.sumBy(data, 'u_total')}</Table.Cell>
					<Table.Cell>{_.sumBy(data, 'c_total') && (_.sumBy(data, 'total') / _.sumBy(data, 'c_total')).toFixed(2)}</Table.Cell>
				</Table.Row>
			) : (
				''
			)}
		</Table.Body>
	);
	render() {
		const { column, direction, agent_id, agent_role, selectedBarStyle, productOldAgentList } = this.state;
		let { start_date, end_date, t, i18n } = this.props;
		let gettingAgentData = this.state.agent_data;
		if (agent_role === 'agent') {
			gettingAgentData = _.filter(gettingAgentData, function (it) {
				return it._id === agent_id;
			});
		}
		let newList = this.state.newList;
		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		let ownerMail =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.email;
		let gettingDateData = this.state.date_date;
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		_.map(gettingAgentData, function (it, i) {
			return (gettingAgentData[i].rate = (it.c_total && parseFloat(it.total / it.c_total)) || 0);
		});
		_.map(gettingAgentData, function (it) {
			let name = _.find(newList, { email: it.email }) && _.find(newList, { email: it.email }).name;
			if (it.email === ownerMail) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.email);
		});
		console.log('gettingAgentData', gettingAgentData);
		const barOptions = {
			scales: {
				xAxes: [{ stacked: false }],
				yAxes: [
					{
						id: 'A',
						stacked: false,
						position: 'left',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelCount'),
						},
					},
					{
						id: 'B',
						stacked: false,
						position: 'right',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelAverage'),
						},
					},
				],
				//yAxes: [{ stacked: false, ticks: { min: 0 } }],
			},
			tooltips: {
				mode: 'index',
				intersect: false,
				filter: (a) => a.yLabel,
			},
			hover: {
				mode: 'nearest',
				intersect: true,
			},
			legend: {
				display: true,
				labels: { fill: true, reverse: true },
			},
		};
		const barOptionsStack = {
			scales: {
				xAxes: [{ stacked: false }],
				yAxes: [
					{
						id: 'A',
						stacked: true,
						position: 'left',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelCount'),
						},
					},
					{
						id: 'B',
						stacked: true,
						position: 'right',
						ticks: { min: 0 },
						gridLines: {
							display: false,
						},
						scaleLabel: {
							display: true,
							labelString: t('labelAverage'),
						},
					},
				],
				//yAxes: [{ stacked: false, ticks: { min: 0 } }],
			},
			tooltips: {
				mode: 'index',
				intersect: false,
				filter: (a) => a.yLabel,
			},
			hover: {
				mode: 'nearest',
				intersect: true,
			},
			legend: {
				display: true,
				labels: { fill: true, reverse: true },
			},
		};
		function showingBar(data, barStyle) {
			switch (barStyle) {
				case 'line':
					return <Line data={data} options={barOptions} />;
				case 'bar':
					return <Bar data={data} options={barOptions} />;
				case 'stackbar':
					return <Bar data={data} options={barOptionsStack} />;
				case 'radar':
					return <Radar data={data} />;
				default:
					return <Line data={data} options={barOptions} />;
			}
		}
		let barStyleDropdown = [
			{
				text: t('Line'),
				value: 'line',
			},
			{
				text: t('Bar'),
				value: 'bar',
			},
		];
		let data = chartData(gettingDateData, start_date, end_date, t, i18n);
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<Table width={16} sortable fixed style={{ padding: '0px' }}>
						{this.TableHead(column, direction, t)}
						{this.TableLine(gettingAgentData, column, direction, t, agent_role)}
					</Table>
					<ChartWrap
						width={8}
						title={t('graph')}
						agentList={this.state.agentList}
						content={
							<div style={{ marginLeft: 'auto' }}>
								<Dropdown options={barStyleDropdown} value={selectedBarStyle} name="selectedBarStyle" onChange={this.handleChange}></Dropdown>
							</div>
						}
					>
						{showingBar(data, selectedBarStyle)}
					</ChartWrap>
				</Segment>
			</Container>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(start);

	while (dt <= end) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}

	return arr;
};

function chartData(data, start_date, end_date, t, i18n) {
	moment.locale(i18n.language);
	/* let dataset = _.each(data, function(it) {
		return { label: it.type, count: it.count };
	}); */
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	//var hourArr = getHourArray();
	_.map(data, function (it, i) {
		return (data[i].dayNumber = moment(it.date, 'YYYY-MM-DD').dayOfYear());
	});
	/* _.map(data, function(it, index) {
		return _.each(dateArr, function(d, i) {
			if (_.findIndex(it, { dayNumber: moment(d).dayOfYear() }) === -1) {
				it.push({ date: moment(d).format(), dayNumber: moment(d).dayOfYear(), total: 0, u_total: 0, c_total: 0 });
			}
		});
	}); */
	_.map(dateArr, function (it, i) {
		if (_.findIndex(data, { dayNumber: moment(it).dayOfYear() }) === -1) {
			return data && data.push({ date: moment(it).format('YYYY-MM-DD'), dayNumber: moment(it).dayOfYear(), total: 0, u_total: 0, c_total: 0 });
		}
	});
	data = _.orderBy(data, ['date'], ['asc']);
	/* if (selectedDate === 'daily') {
		labels = _.map(dateArr, it => moment(it).format('DD-MMM'));
	} else {
		labels = hourArr;
	} */
	labels = _.map(dateArr, (it) => moment(it).format('DD-MMM'));
	let datasets = [
		{
			label: t('totalProduct'),
			data: _.map(data, (item) => item.total),
			borderColor: '#E10840',
			yAxisID: 'A',
			backgroundColor: '#E10840',
			fill: false,
		},
		{
			label: t('uniqueConversation'),
			data: _.map(data, (item) => item.c_total),
			borderColor: '#8B9AE7',
			yAxisID: 'A',
			backgroundColor: '#8B9AE7',
			hidden: false,
			fill: false,
		},
		{
			label: t('uniqueProduct'),
			data: _.map(data, (item) => item.u_total),
			borderColor: '#8B565C',
			yAxisID: 'A',
			backgroundColor: '#8B565C',
			fill: false,
		},
		{
			label: t('productConversation'),
			data: _.map(data, (item) => (item.c_total && (item.total / item.c_total).toFixed(2)) || 0),
			borderColor: '#17BEBB',
			yAxisID: 'B',
			backgroundColor: '#17BEBB',
			fill: false,
		},
	];
	return {
		labels,
		datasets,
	};
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
