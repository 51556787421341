import React, { Component } from 'react';
import { Icon, Segment, Form, Table, Button, Input, Label, Popup } from 'semantic-ui-react';
import Page from '../../components/Page';
import DraggableTableRow from '../../components/DragableTableRow';
import _ from 'lodash';
import { get, post } from '../../util/api';
import RichTextEditor from 'react-rte';
import JSONInput from 'react-json-editor-ajrm';
export default class ActionTable extends Component {
	state = {
		saveButton: 'Kaydet',
		images: [],
		pricing: [],
		limit: 1,
		image: '/icons/layout.svg',
		vote_amount: _.random(42, 50),
		loading: true,
		defaultPage: 'home',
		previewType: 'previewFalse',
		long_description: RichTextEditor.createEmptyValue(),
	};
	baseState = {
		title: '',
		config: '',
		description: '',
		//long_description:"",
		id: '',
		base: '',
		tsoft: '',
		ticimax: '',
		akinon: '',
		faprika: '',
		inveon: '',
		flo: '',
		ideasoft: '',
		woocommerce: '',
		projesoft: '',
		advance: '',
		images: [],
		pricing: [],
		limit: 1,
		previewType: 'previewFalse',
		defaultPage: 'home',
		image: '/icons/layout.svg',
		vote_amount: _.random(42, 50),
		loading: false,
		long_description: RichTextEditor.createEmptyValue(),
		features: [],
		tiers: [],
		required_apps: [],
		altyapi: [],
	};
	componentDidMount() {
		this.getList();
	}
	getList() {
		get('/ext/apps/admin/list').then((it) => {
			this.setState({ list: _.orderBy(it.data, 'order', 'asc'), loading: false });
		});
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleArrayChange = (e, { name, value }) => {
		let images = this.state.images;
		images[name] = value;
		this.setState({ images });
	};
	handleArrayChangeTiers = (name, item, value) => {
		let tiers = this.state.tiers;
		tiers[name][item] = value;
		this.setState({ tiers });
	};
	onChange = (long_description) => {
		this.setState({ long_description });
	};
	toggle = (e, { name, value }) => {
		let { altyapi } = this.state;
		let isTrue = _.includes(altyapi, name);
		if (isTrue) _.pull(altyapi, name);
		else altyapi.push(name);
		this.setState({ altyapi });
	};
	handAddReqApps = (e, { name, value }) => {
		/* let { required_apps } = this.state;
		let isTrue = _.includes(required_apps, value);
		if (isTrue) _.pull(required_apps, value);
		else required_apps.push(value); */
		this.setState({ required_apps: value });
	};
	handleRemove = (index) => {
		let images = this.state.images;
		_.pullAt(images, [index]);
		this.setState({ images });
	};
	handleArrayChangePrice = (e, { name, ex, value }) => {
		let pricing = this.state.pricing;
		pricing[name][ex] = value;
		this.setState({ pricing });
	};
	handleArrayChangePriceExtra = (e, { name, exname, ex, value }) => {
		let pricing = this.state.pricing;
		pricing[name][ex][exname] = value;
		this.setState({ pricing });
	};
	handleRemovePrice = (index) => {
		let pricing = this.state.pricing;
		_.pullAt(pricing, [index]);
		this.setState({ pricing });
	};
	handleRemovePriceExtra = (index, oindex) => {
		let pricing = this.state.pricing;
		_.pullAt(pricing[index].extra, [oindex]);
		this.setState({ pricing });
	};
	handleSave = async () => {
		this.setState({ loadingSaveButton: true });
		let { title, image, description, long_description, id, images, altyapi, required_apps, tiers, features } = this.state;
		let data = {
			info: {
				title,
				description,
				long_description: long_description.toString('html'),
				image,
				images,
			},
			slug: _.kebabCase(title.replace(/\(.*\)|-/, '')),
			altyapi: JSON.stringify(altyapi),
			required_apps: JSON.stringify(required_apps),
			tiers: JSON.stringify(tiers),
			features: JSON.stringify(features),
		};
		if (id) data.id = id;
		await post(`/ext/apps/admin/save`, { new_data: data }).then((data) => {
			let result = data.data;
			let saved = result.success ? 'Kaydedildi' : 'Hata';
			this.setState({ loadingSaveButton: false, saveButton: saved });
		});
		this.getList();
		await setTimeout(() => this.setState({ saveButton: 'Kaydet' }), 2000);
	};
	handleSort = async () => {
		this.setState({ loadingOrderButton: true });
		let { newList, listFrom, listTo, list } = this.state;
		let dragItem = list[listTo];
		let beforeItem = list[listTo - 1];
		let afterItem = list[listTo + 1];
		let mainOrder = dragItem.order;
		await post('/campaign/action/sort', {
			full: newList,
			order: [listFrom, listTo],
			value: [mainOrder, (beforeItem && beforeItem.order) || 0, (afterItem && afterItem.order) || list.length],
		});
		/* console.log({
			full: newList,
			order: [listFrom, listTo],
			value: [mainOrder, (beforeItem && beforeItem.order) || 0, (afterItem && afterItem.order) || list.length],
		}); */
		this.getList();
		await this.setState({ loadingOrderButton: false });
	};
	handleEdit = (temp) => {
		let { info, altyapi, required_apps, tiers, id, features } = temp;
		let { title, description, long_description, images, image } = info;
		if (!_.isArray(images)) {
			images = [];
		}
		this.setState({
			title,
			image,
			description,
			long_description: RichTextEditor.createValueFromString(long_description, 'html'),
			id,
			images,
			altyapi,
			required_apps,
			tiers,
			features,
		});
	};
	fileUpload(file, name) {
		const url = 'https://uploads.yapaytech.com/flow/uploadPermImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				if (name === 'image') {
					this.setState({ [name]: image_url });
				} else {
					let images = this.state.images;
					images[parseFloat(name)] = image_url;
					this.setState({ images });
				}
			});
	}
	handleFileInput = (e) => {
		let file = e.target.files[0];
		let name = e.target.name;
		this.fileUpload(file, name);
	};
	async swap(a, b) {
		let { list } = this.state;
		//list[a] = list.splice(b, 1, list[a])[0];
		list.splice(a, 0, list.splice(b, 1)[0]);
		let newList = _.map(list, (it) => it.id);
		await this.setState({
			...this.state,
			list,
			newList,
			listFrom: b,
			listTo: a,
		});
		await this.handleSort();
	}
	render() {
		let {
			list,
			loading,
			title,
			image,
			description,
			long_description,
			images = [],
			altyapi,
			required_apps = [],
			tiers = [],
			loadingOrderButton,
			features,
		} = this.state;
		if (loading) {
			return (
				<Segment>
					<Page.Loader></Page.Loader>
				</Segment>
			);
		}
		return (
			<Segment style={{ display: 'flex', maxHeight: '93%' }}>
				<div style={{ width: '30%', maxHeight: '92%' }}>
					<div>
						<Table style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 'none' }}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell width={2}>
										<Button color="green" onClick={() => this.setState(this.baseState)}>
											Ekle
										</Button>
									</Table.HeaderCell>
									<Table.HeaderCell width={3}>Id</Table.HeaderCell>
									<Table.HeaderCell width={11}>İsim</Table.HeaderCell>
									<Table.HeaderCell width={2}>
										{loadingOrderButton && <Icon name={loadingOrderButton ? 'spinner' : 'check'} loading={loadingOrderButton}></Icon>}
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
						</Table>
					</div>
					<div className="table" style={{ overflowY: 'auto', maxHeight: '100%' }}>
						<Table style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 'none' }}>
							<Table.Body>
								{_.map(list, (it, i) => (
									<DraggableTableRow key={i} i={i} action={this.swap.bind(this)}>
										<Table.Cell width={2}>
											<Button color="blue" onClick={() => this.handleEdit(it)}>
												Edit
											</Button>
										</Table.Cell>
										<Table.Cell width={3}>{it.id}</Table.Cell>
										<Table.Cell width={11}>{it.info.title}</Table.Cell>
									</DraggableTableRow>
								))}
							</Table.Body>
						</Table>
					</div>
				</div>
				<Segment className="edit" style={{ marginTop: 0, marginLeft: '20px', width: '70%', overflowY: 'auto' }}>
					<Form onSubmit={this.handleSave}>
						<div style={{ display: 'flex' }}>
							<Form.Input required className="wi-90" fluid label="İsim" name="title" value={title} onChange={this.handleChange}></Form.Input>
							<Form.Button
								loading={this.state.loadingSaveButton}
								type="submit"
								color="teal"
								style={{ display: 'flex', marginTop: '24px', marginLeft: '10px' }}
							>
								{this.state.saveButton}
							</Form.Button>
						</div>
						<Form.Input fluid label="İkon" name="image" value={image} onChange={this.handleChange}>
							<Popup
								on="click"
								hideOnScroll
								position="bottom left"
								content={<img width={180} src={image} alt="img"></img>}
								trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{'Icon'}</Label>}
							></Popup>
							<input />
							<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
								<label htmlFor="file-input_image">
									<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
								</label>
								<Input
									id="file-input_image"
									type="file"
									name={'image'}
									accept="image/*"
									style={{ display: 'none' }}
									onChange={(e) => this.handleFileInput(e)}
								/>
							</div>
						</Form.Input>
						<Form.Field>
							<label>Medya</label>
							{_.map(images, (it, i) => (
								<Input key={i} style={{ marginTop: '5px' }} name={i} value={images[i]} onChange={this.handleArrayChange}>
									<Popup
										on="click"
										hideOnScroll
										position="bottom left"
										content={
											/cdnydm/.test(images[i]) ? (
												<img width={720} src={images[i]} alt="img"></img>
											) : (
												<iframe
													id="video"
													title="video"
													width={720}
													height={405}
													src={`https://www.youtube.com/embed/${images[i]}`}
												></iframe>
											)
										}
										trigger={<Label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>{'Resim Link ' + i}</Label>}
									></Popup>

									<input />
									<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
										<label htmlFor={'file-input_' + i}>
											<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
										</label>
										<Input
											id={'file-input_' + i}
											type="file"
											name={i}
											key={i}
											accept="image/*"
											style={{ display: 'none' }}
											onChange={(e) => this.handleFileInput(e)}
										/>
									</div>
									<Form.Button icon type="button" onClick={() => this.handleRemove(i)}>
										<Icon name="trash"></Icon>
									</Form.Button>
								</Input>
							))}
							<Button
								icon
								size="tiny"
								type="button"
								style={{ marginTop: '5px' }}
								onClick={() => {
									images.push('');
									this.setState({ images });
								}}
							>
								<Icon name="plus"></Icon>
							</Button>
						</Form.Field>
						<Form.Input fluid label="Kısa Açıklama" name="description" value={description} onChange={this.handleChange}></Form.Input>
						<Form.Field>
							<label>Açıklama</label> <RichTextEditor className="min-300h" value={long_description} onChange={this.onChange} />
						</Form.Field>
						<Form.Field>
							<label>Required Apps</label>
							<Form.Dropdown
								name="required_apps"
								selection
								multiple
								value={required_apps}
								options={_.map(list, (it) => ({ text: it.info.title, value: it.id }))}
								onChange={this.handAddReqApps}
							></Form.Dropdown>
						</Form.Field>
						<Form.Field>
							<label>Config</label>
							<Form.Group>
								<Form.Checkbox label="Base" name="base" onChange={this.toggle} checked={_.includes(altyapi, 'base')}></Form.Checkbox>
								<Form.Checkbox label="TSoft" name="tsoft" onChange={this.toggle} checked={_.includes(altyapi, 'tsoft')}></Form.Checkbox>
								<Form.Checkbox label="Ticimax" name="ticimax" onChange={this.toggle} checked={_.includes(altyapi, 'ticimax')}></Form.Checkbox>
								<Form.Checkbox label="Akinon" name="akinon" onChange={this.toggle} checked={_.includes(altyapi, 'akinon')}></Form.Checkbox>
								<Form.Checkbox label="Faprika" name="faprika" onChange={this.toggle} checked={_.includes(altyapi, 'faprika')}></Form.Checkbox>
							</Form.Group>
							<Form.Group>
								<Form.Checkbox label="Inveon" name="inveon" onChange={this.toggle} checked={_.includes(altyapi, 'inveon')}></Form.Checkbox>
								<Form.Checkbox label="Flo" name="flo" onChange={this.toggle} checked={_.includes(altyapi, 'flo')}></Form.Checkbox>
								<Form.Checkbox
									label="IdeaSoft"
									name="ideasoft"
									onChange={this.toggle}
									checked={_.includes(altyapi, 'ideasoft')}
								></Form.Checkbox>
								<Form.Checkbox
									label="WooCommerce"
									name="woocommerce"
									onChange={this.toggle}
									checked={_.includes(altyapi, 'woocommerce')}
								></Form.Checkbox>
								<Form.Checkbox
									label="Projesoft"
									name="projesoft"
									onChange={this.toggle}
									checked={_.includes(altyapi, 'projesoft')}
								></Form.Checkbox>
							</Form.Group>
							<Form.Group>
								<Form.Checkbox label="Advance" name="advance" onChange={this.toggle} checked={_.includes(altyapi, 'advance')}></Form.Checkbox>
							</Form.Group>
						</Form.Field>

						<Form.Field>
							<label>Features</label>
							<JSONInput
								id="tiers_json"
								theme="light_mitsuketa_tribute"
								placeholder={features || []}
								height="300px"
								width="100%"
								onChange={(e) => this.setState({ features: e.jsObject })}
							/>
						</Form.Field>
						<Form.Field>
							<label>Tiers</label>
							<JSONInput
								id="tiers_json"
								theme="light_mitsuketa_tribute"
								placeholder={tiers || []}
								height="300px"
								width="100%"
								onChange={(e) => this.setState({ tiers: e.jsObject })}
							/>
						</Form.Field>
					</Form>
				</Segment>
			</Segment>
		);
	}
}
