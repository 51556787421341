import React, { Component } from 'react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import { Grid, Header, Icon, Segment, Table, Checkbox, Dropdown, Container, Radio, Popup } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter2.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import MockAgents from './mock.json';

const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);

const barOptions = {
	scales: {
		xAxes: [{ stacked: false }],
		yAxes: [{ stacked: false, ticks: { min: 0 } }],
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};
const barOptionsStack = {
	scales: {
		xAxes: [{ stacked: true }],
		yAxes: [{ stacked: true, ticks: { min: 0 } }],
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date || moment().subtract(7, 'day').startOf('day'),
		end_date: this.props.end_date || moment(),
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		today: moment(),
		group: ['agent'],
		value: _.filter(TypeList, function (it) {
			return !it.uncheck;
		}),
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* 'c8e75d60-c03f-11ea-8928-974cce4cd258' || */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		column: null,
		botId: '',
		direction: null,
		dataloading: true,
		displayName: this.props.displayName,
		selectedBarStyle: 'line',
		selectedPlatform: 'all',
		selectedWatsonsGroup: 'all',
	};
	handleSort = (clickedColumn) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				direction: 'desc',
			});

			return;
		}
		this.setState({
			direction: direction === 'asc' ? 'desc' : 'asc',
		});
	};
	TableHead = (data, keys, head, column, direction, t) => (
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell width={6}>Agent</Table.HeaderCell>
				{_.map(head, (val, index) => (
					//<Table.HeaderCell style={{ color: TypeList[val].color }}>{_.capitalize(val)}</Table.HeaderCell>
					<Table.HeaderCell key={index} sorted={column === val ? direction : null} onClick={this.handleSort(val)}>
						{t(val)}
					</Table.HeaderCell>
				))}
			</Table.Row>
		</Table.Header>
	);
	TableLine = (head, productAgentList2, selectedAgent, t, othersdata) => (
		<Table.Body>
			{selectedAgent === 'all' ? (
				<>
					{_.map(
						_.orderBy(
							_.filter(productAgentList2, function (it) {
								return it.id !== '5e6f519560b2b4d07559fcf8';
							}),
							[this.state.column],
							[this.state.direction]
						),
						(val, index) => (
							<Table.Row key={index}>
								<Table.Cell>{(val && val[this.props.displayName]) || 'Bot'}</Table.Cell>
								{_.map(head, (d, i) => (
									<Table.Cell key={i}>{(val && val[d]) || 0}</Table.Cell>
								))}
							</Table.Row>
						)
					)}
					{othersdata.closed || othersdata.taken || othersdata.forwarded || othersdata.contacted > 0 ? (
						<Table.Row key={'others'}>
							<Table.Cell>{t('oldAgents')}</Table.Cell>
							{_.map(head, (d, i) => (
								<Table.Cell key={i}>{(othersdata && othersdata[d]) || 0}</Table.Cell>
							))}
						</Table.Row>
					) : (
						''
					)}
				</>
			) : /bot/.test(selectedAgent) ? (
				<Table.Row key="1">
					<Table.Cell>Bot</Table.Cell>
					{_.map(head, (d, i) => (
						<Table.Cell>{_.find(productAgentList2, { key: selectedAgent }) && _.find(productAgentList2, { key: selectedAgent })[d]}</Table.Cell>
					))}
				</Table.Row>
			) : (
				<Table.Row key="1">
					<Table.Cell>{_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent }).developer}</Table.Cell>
					{_.map(head, (d, i) => (
						<Table.Cell>{_.find(productAgentList2, { id: selectedAgent }) && _.find(productAgentList2, { id: selectedAgent })[d]}</Table.Cell>
					))}
				</Table.Row>
			)}
			{selectedAgent === 'all' ? (
				<Table.Row key={'all'}>
					<Table.Cell>{t('all')}</Table.Cell>
					{_.map(head, (d, i) => (
						<Table.Cell key={i}>
							{_.sumBy(
								_.filter(productAgentList2, function (it) {
									return it.id !== '5e6f519560b2b4d07559fcf8';
								}),
								d
							) + othersdata[d] || 0}
						</Table.Cell>
					))}
				</Table.Row>
			) : (
				''
			)}
		</Table.Body>
	);
	async componentDidMount() {
		let typeQuery = [],
			head = [];
		let pairs = {};
		let group = [];
		let select = [];
		let { start_date, end_date } = this.props;
		let { pid, selectedPlatform } = this.state;
		let valueArr = this.state.value.length ? _.orderBy(this.state.value, ['order'], ['asc']) : _.orderBy(TypeList, ['order'], ['asc']);
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.type !== 'activateAgent') head = _.concat(head, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
			if (it.pairs) pairs = _.assignIn(it.pairs);
		});

		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		typeQuery = _.uniq(typeQuery);
		head = _.uniq(head);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		let filter = {};
		if (this.state.agent_role === 'agent') {
			filter = { type: typeQuery, pid: this.state.pid, agent: this.props.access.user.loggedUserObj.extra.user_id };
			this.setState({ selectedAgent: this.props.access.user.loggedUserObj.extra.user_id });
		} else {
			filter = { type: typeQuery, pid: this.state.pid };
		}
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');

		pairs.platform = 'platform';
		fetch('https://log.yapaytech.com/live/test/getInfo?_1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: group,
				pairs: { agent: 'select1', topic: 'select2', platform: 'platform' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent, id: it.agent };
				});
				this.setState({ head });
				agentList = _.uniqBy(agentList, 'key');
				this.setState({ gettingData: _.sortBy(data.result, ['agent', 'asc']), agentList: agentList });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'day', 'agent'],
				pairs,
				options: {
					sum: 0,
				},
				whereRaw: `select1 not like '${this.state.pid}%' `,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				this.setState({
					gettingDataDate: _.orderBy(
						_.filter(data.result, function (it) {
							return !/bot/.test(it.agent);
						}),
						['day', 'asc']
					),
				});
			});
		fetch('https://log.yapaytech.com/live/getWaitingInfo3', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).add('-3', 'hour').valueOf(),
				end_date: moment(end_date).add('-3', 'hour').valueOf(),
				include_hour: true,
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data && data.result && data.result.result;

				this.setState({
					waitingTotalData: result,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?closeddata', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'day', 'hour'],
				pairs: { platform: 'platform' },
				options: { sum: 0 },
				whereRaw: `select1 not like '${this.state.pid}%' `,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.map(data.result, (it) => (it.dayNumber = moment(it.day).dayOfYear()));
				let closedDataHourly = _.filter(data.result, function (it) {
					return it.agent !== pid && it.type === 'closed';
				});
				let contactedDataHourly = _.filter(data.result, function (it) {
					return it.type === 'contacted';
				});
				let viewedDataHourly = _.filter(data.result, function (it) {
					return it.agent !== pid && it.type === 'viewed';
				});
				let takenDataHourly = _.filter(data.result, function (it) {
					return it.type === 'taken';
				});
				let forwardDataHourly = _.filter(data.result, function (it) {
					return it.type === 'forwarded';
				});

				this.setState({
					loadingGraph: false,
					closedDataHourly,
					contactedDataHourly,
					viewedDataHourly,
					takenDataHourly,
					forwardDataHourly,
				});
			});
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		await fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					productOldAgentList: data.result && data.result.old_agents,
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					p_id: data.result && data.result.id,
					chat_groups: data.result && data.result.staticOptions && data.result.staticOptions.groups,
					loading: false,
				});
			});
	}
	async getData() {
		this.setState({ loading: true, dataloading: true });
		let typeQuery = [],
			head = [];
		let pairs = {};
		let group = [];
		let dayGroup = ['type', 'day', 'agent'];
		let waitGroup = 'day';
		let select = [];
		let { selectedAgent, selectedDate, pid, selectedPlatform, selectedWatsonsGroup, productAgentList } = this.state;
		let { start_date, end_date } = this.props;
		let showingproductAgentList2;
		if (selectedWatsonsGroup !== 'all') {
			showingproductAgentList2 = _.filter(productAgentList, (it) => it.group === selectedWatsonsGroup);
		} else {
			showingproductAgentList2 = productAgentList;
		}
		let group_agents = _.map(showingproductAgentList2, (it) => it.id);
		console.log('group_agents', group_agents);
		_.each(_.orderBy(this.state.value, ['order'], ['asc']), (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.type !== 'activateAgent') head = _.concat(head, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
			if (it.pairs) pairs = _.assignIn(it.pairs);
		});
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		//let start_date_s = moment(start_date).add(-1, 'day').format();
		typeQuery = _.uniq(typeQuery);
		head = _.uniq(head);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		let filter = {};
		if (selectedAgent === 'all') {
			if (selectedWatsonsGroup !== 'all') {
				filter = { type: typeQuery, pid: this.state.pid, agent: group_agents };
			} else {
				filter = { type: typeQuery, pid: this.state.pid };
			}
		} else {
			filter = { type: typeQuery, pid: this.state.pid, agent: selectedAgent };
		}

		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		pairs.platform = 'platform';
		pairs.select1 = 'select1';
		if (!(selectedDate === 'daily')) {
			//filter.day = selectedDate;
			dayGroup = ['type', 'hour'];
			waitGroup = 'hour';
		}
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select,
				group: ['type', 'agent'],
				pairs: { agent: 'select1', select1: 'select1', topic: 'select2', platform: 'platform' },
				options: {
					sum: 0,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent };
				});

				this.setState({ head });
				agentList = _.uniqBy(agentList, 'key');
				this.setState({ gettingData: _.sortBy(data.result, ['agent', 'asc']), agentList: agentList, loading: false });
			});

		if (typeQuery.length) {
			fetch('https://log.yapaytech.com/live/test/getInfo', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					include_hour: true,
					filter,
					select,
					group: dayGroup,
					pairs,
					options: {
						sum: 0,
					},
					whereRaw: `select1 not like '${this.state.pid}%' `,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (!(selectedDate === 'daily')) {
						/* _.each(data.result, function (it) {
							return (it.hour = (it.hour + 2) % 24);
						}); */
					} else {
						_.each(data.result, function (it) {
							return (it.dayNumber = moment(it.day).dayOfYear());
						});
					}
					this.setState({
						gettingDataDate: _.orderBy(
							_.filter(data.result, function (it) {
								return !/bot/.test(it.agent);
							}),
							['day', 'asc']
						),
						loading: false,
					});
				});
			fetch('https://log.yapaytech.com/live/getWaitingInfo3', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: moment(start_date).add('-3', 'hour').valueOf(),
					end_date: moment(end_date).add('-3', 'hour').valueOf(),
					include_hour: true,
					pid: this.state.pid,
					group_type: waitGroup,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					let result = data && data.result && data.result.result;
					let newresult = _.map(result, (it) => ({
						count: it.count,
						hour: it.hour,
						day: it.day,
						dayNumber: moment(it.day).dayOfYear(),
					}));
					this.setState({
						waitingTotalData: newresult,
					});
				});
			fetch('https://log.yapaytech.com/live/test/getInfo?closeddata_', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					start_date: start_date,
					end_date: end_date,
					include_hour: true,
					filter,
					select,
					group: ['type', 'day', 'hour'],
					pairs: { agent: 'select1', select1: 'select1', platform: 'platform' },
					options: {},
					whereRaw: `select1 not like '${this.state.pid}%' `,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					_.map(data.result, (it) => (it.dayNumber = moment(it.day).dayOfYear()));
					let closedDataHourly = _.filter(data.result, function (it) {
						return it.agent !== pid && it.type === 'closed';
					});
					let contactedDataHourly = _.filter(data.result, function (it) {
						return it.type === 'contacted';
					});
					let viewedDataHourly = _.filter(data.result, function (it) {
						return it.type === 'viewed';
					});
					let takenDataHourly = _.filter(data.result, function (it) {
						return it.type === 'taken';
					});
					let forwardDataHourly = _.filter(data.result, function (it) {
						return it.type === 'forwarded';
					});

					this.setState({
						loadingGraph: false,
						closedDataHourly,
						contactedDataHourly,
						viewedDataHourly,
						takenDataHourly,
						forwardDataHourly,
					});
				});
		} else {
			this.setState({ gettingDataDate: [], loading: false });
		}
	}
	componentDidUpdate(oldProps, oldState) {
		const check_list = [
			/* 'start_date', 'end_date', */ 'selectedAgent',
			'value',
			'selectedDate',
			'displayName',
			'selectedPlatform',
			'selectedWatsonsGroup',
			'newDataToggle',
		];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'start_date' || key === 'end_date' || key === 'newDataToggle') {
				if (!_.isEqual(this.props[key], oldProps[key])) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 1000);
					break;
				}
			}
			if (!_.isArray(this.state[key])) {
				if (this.state[key] !== oldState[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			} else if (!_.isEqual(this.state[key], oldState[key])) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	toggle = () => this.setState((prevState) => ({ selectedDate: prevState.selectedDate === 'daily' ? 'hourly' : 'daily' }));
	handleFilterCheck(value) {
		let array = this.state.value || [];
		let exists = _.find(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ value: valueArr });
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleGroupChange = (e, { text, value }) => this.setState({ group: value });
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	showingBar(data, barStyle) {
		switch (barStyle) {
			case 'line':
				return <Line data={data} options={barOptions} />;
			case 'bar':
				return <Bar data={data} options={barOptions} />;
			case 'stackbar':
				return <Bar data={data} options={barOptionsStack} />;
			case 'radar':
				return <Radar data={data} />;
			default:
				return <Line data={data} options={barOptions} />;
		}
	}
	render() {
		let {
			selectedBarStyle,
			pid,
			loadingGraph,
			closedDataHourly,
			contactedDataHourly,
			viewedDataHourly,
			takenDataHourly,
			forwardDataHourly,
			p_id,
			waitingTotalData,
			selectedPlatform,
			productOldAgentList,
			loading,
			selectedWatsonsGroup,
			chat_groups,
		} = this.state;
		const { start_date, end_date, t, i18n, product, platformList } = this.props;
		let watsons_groups = [
			{
				text: t('allGroups'),
				value: 'all',
				id: 'all',
				key: 'all',
			},
		];
		if (chat_groups && chat_groups.length) {
			_.each(chat_groups, (it) =>
				watsons_groups.push({
					text: it.label,
					value: it.id,
					id: it.id,
					key: it.id,
				})
			);
		}
		let isMock = product.live && product.live.settings.mock_analytic;
		let gettingData = isMock ? MockAgents.agents : this.state.gettingData;
		let gGettingData = _.groupBy(gettingData, 'agent');
		let agentList = this.state.agentList;
		let agent_id = this.state.agent_id;
		if (isMock) {
			agentList = _.map(gettingData, function (it) {
				return { key: it.agent, text: it.agent, value: it.agent };
			});
		}
		let productAgentList2 = isMock ? MockAgents.agentList : this.state.productAgentList;
		let head = this.state.head;
		let newList = isMock ? MockAgents.agentList : this.state.newList;
		let dataloading = this.state.dataloading;
		_.map(productAgentList2, function (it) {
			return _.map(head, function (d) {
				return (it[d] = 0);
			});
		});
		_.map(gGettingData, function (it) {
			return _.map(it, function (d, i) {
				var e = _.findIndex(agentList, function (o) {
					return o.key === d.agent;
				});
				return (agentList[e][d.type] = d.count || 0);
			});
		});
		let gettingDataDate = isMock ? MockAgents.agentsByDate : this.state.gettingDataDate;
		let typeList = _.map(
			_.orderBy(
				_.filter(TypeList, function (o) {
					return !o.dontlist;
				}),
				['order'],
				['asc']
			),
			function (it) {
				return { text: t('' + it.title), key: it.type, value: it, order: it.order, uncheck: it.uncheck, desc: t(it.desc) };
			}
		);
		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		let data = chartData(gettingDataDate, start_date, end_date, this.state.selectedDate, t, i18n, waitingTotalData);
		let data_hourly = chartDataHourly(closedDataHourly, contactedDataHourly, viewedDataHourly, takenDataHourly, forwardDataHourly, start_date, end_date, t);
		let productAgentList = isMock ? MockAgents.agentList : this.state.productAgentList;
		productAgentList = _.filter(productAgentList, function (it) {
			return it.id !== '5e6f519560b2b4d07559fcf8';
		});
		if (loading) return <Page.Loader></Page.Loader>;
		let othersdata = {
			closed: 0,
			contacted: 0,
			id: 'others',
			key: 'others',
			taken: 0,
			text: 'others',
			value: 'others',
			viewed: 0,
			forwarded: 0,
		};
		let others_id = [];
		let dropAgentList = _.map(productAgentList, function (it) {
			return { text: it.developer || it.email, key: it.id, value: it.id };
		});
		if (this.state.agent_role === 'agent') {
			dropAgentList = _.filter(dropAgentList, function (it) {
				return it.key === agent_id;
			});
		} else {
			dropAgentList = _.concat({ text: t('allAgent'), key: 'all', value: 'all' }, dropAgentList);
		}
		if (isMock) {
			_.map(agentList, function (it) {
				let n = _.findIndex(productAgentList2, function (a) {
					return a.extra.user_id === it.key;
				});
				if (n >= 0) {
					return _.assign(productAgentList2[n], it);
				} /* else if (/bot/.test(it.key)) {
					return (productAgentList2 = _.concat(productAgentList2, it));
				} */
			});
		} else {
			_.map(agentList, async function (it) {
				let n = _.findIndex(productAgentList2, { id: it.key });
				if (n >= 0) {
					return _.assign(productAgentList2[n], it);
				} else if (/bot/.test(it.key) || it.key === pid || it.key === p_id) {
					console.log('bot');
				} else {
					if (productOldAgentList && productOldAgentList.length) {
						let oldAgent = _.find(productOldAgentList, { id: it.key });
						if (oldAgent) {
							productAgentList2.push({
								developer: 'Eski-' + oldAgent.developer,
								id: oldAgent.id,
								wid: oldAgent.id,
								type: 'standard',
								fullname: oldAgent.fullname,
								viewed: it.viewed,
								taken: it.taken,
								contacted: it.contacted,
								closed: it.closed,
								key: oldAgent.id,
								text: oldAgent.id,
								value: oldAgent.id,
								name: oldAgent.fullname,
							});
						} else {
							others_id.push(it.key);
							othersdata = _.assign({
								closed: othersdata.closed + (it.closed || 0),
								contacted: othersdata.contacted + (it.contacted || 0),
								viewed: othersdata.viewed + (it.viewed || 0),
								forwarded: othersdata.forwarded + (it.forwarded || 0),
								taken: othersdata.taken + (it.taken || 0),
							});
						}
					} else {
						othersdata = _.assign({
							closed: othersdata.closed + (it.closed || 0),
							contacted: othersdata.contacted + (it.contacted || 0),
							viewed: othersdata.viewed + (it.viewed || 0),
							forwarded: othersdata.forwarded + (it.forwarded || 0),
							taken: othersdata.taken + (it.taken || 0),
						});
					}
				}
			});
		}
		othersdata = {
			closed: othersdata.closed,
			id: 'others',
			key: 'others',
			contacted: othersdata.contacted,
			viewed: othersdata.viewed,
			forwarded: othersdata.forwarded,
			taken: othersdata.taken,
			text: 'others',
			value: 'others',
			developer: t('oldAgents'),
		};
		console.log('others_id', others_id);
		if (agentList) {
			dataloading = false;
		}
		_.map(productAgentList2, function (it) {
			return _.map(head, function (d) {
				if (it && !it[d]) {
					return (it[d] = 0);
				}
				if (it && !it.developer) {
					if (isMock) {
						return (it.developer = it.email);
					}
					return (it.developer = 'Bot');
				}
			});
		});
		productAgentList2 = _.filter(productAgentList2, function (it) {
			return it.id !== '5e6f519560b2b4d07559fcf8';
		});
		_.map(productAgentList2, function (it, i) {
			let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;
			if (i === 0) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.developer);
		});
		let showingproductAgentList2;
		if (selectedWatsonsGroup !== 'all') {
			showingproductAgentList2 = _.filter(productAgentList2, (it) => it.group === selectedWatsonsGroup);
		} else {
			showingproductAgentList2 = productAgentList2;
		}
		let barStyleDropdown = [
			{
				text: t('Line'),
				value: 'line',
			},
			{
				text: t('Bar'),
				value: 'bar',
			},
			{
				text: t('StackBar'),
				value: 'stackbar',
			},
			{
				text: t('Radar'),
				value: 'radar',
			},
		];
		if (dataloading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);

		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<Segment attached="bottom">
						{
							<div style={{ display: 'flex' }}>
								<Dropdown
									selection
									options={platformList}
									value={selectedPlatform}
									floating
									labeled
									button
									icon="filter"
									className="icon"
									name="selectedPlatform"
									onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
								/>
								{product &&
								product.live &&
								product.live.settings &&
								product.live.settings.analyticView &&
								product.live.settings.analyticView.watsonsgroups ? (
									<Dropdown
										selection
										options={watsons_groups}
										value={selectedWatsonsGroup}
										floating
										labeled
										button
										icon="filter"
										className="icon"
										name="selectedWatsonsGroup"
										onChange={(e, { name, value }) => this.setState({ selectedWatsonsGroup: value })}
									/>
								) : (
									''
								)}
								{this.state.agent_role !== 'agent' ? (
									<div style={{ display: 'flex' }}>
										<Dropdown
											options={dropAgentList}
											selection
											defaultValue={dropAgentList[0].value}
											icon="filter"
											floating
											labeled
											button
											className="icon"
											onChange={this.handleFilterChange}
										></Dropdown>
									</div>
								) : (
									''
								)}
							</div>
						}

						<div style={{ display: 'flex' }}>
							{_.map(typeList, (it, i) => (
								<div>
									<Checkbox
										style={{ margin: '10px' }}
										label={it.text}
										key={i}
										onChange={() => this.handleFilterCheck(it.value)}
										defaultChecked={!it.uncheck}
									></Checkbox>
									<Popup trigger={<Icon name="question circle outline" />} content={t(it.desc)} size="mini" />
								</div>
							))}
						</div>
					</Segment>
					<Table sortable fixed width={16} style={{ padding: '0px' }}>
						{this.TableHead(gGettingData, [], this.state.head, this.state.column, this.state.direction, t)}
						{this.TableLine(this.state.head, showingproductAgentList2, this.state.selectedAgent, t, othersdata)}
					</Table>
					<ChartWrap
						width={8}
						title={t('graph')}
						agentList={this.state.agentList}
						content={
							<div style={{ marginLeft: 'auto' }}>
								<Dropdown
									options={barStyleDropdown}
									value={selectedBarStyle}
									name="selectedBarStyle"
									onChange={this.handleChange}
									style={{ marginRight: '30px' }}
								></Dropdown>
								<Radio style={{ marginLeft: 'auto' }} toggle label={t('24HourDist')} onChange={this.toggle}></Radio>
							</div>
						}
					>
						{this.showingBar(data, selectedBarStyle)}
					</ChartWrap>
					<ChartWrap
						width={16}
						title={t('graphHourly')}
						content={
							<div style={{ marginLeft: 'auto', display: loadingGraph ? 'none' : 'block' }}>
								<Dropdown
									options={barStyleDropdown}
									value={selectedBarStyle}
									name="selectedBarStyle"
									onChange={this.handleChange}
									style={{ marginRight: '30px' }}
								/>
							</div>
						}
					>
						{loadingGraph ? <Page.Loader /> : this.showingBar(data_hourly, selectedBarStyle)}
					</ChartWrap>
				</Segment>
			</Container>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(moment(start).startOf('d'));
	while (dt <= new Date(moment(end).startOf('d'))) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}
	return arr;
};
var getHourArray = function (start, end) {
	var arr = [],
		dt = 0;
	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};
function chartData(data, start_date, end_date, selectedDate, t, i18n, waitingTotalData) {
	moment.locale(i18n.language);
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray(startDate, endDate);
	let gdata = _.groupBy(data, 'type');
	//console.log('ddd', waitingTotalData, dateArr);
	if (selectedDate === 'daily') {
		_.map(gdata, function (it, index) {
			return _.each(dateArr, function (d, i) {
				if (_.findIndex(it, { dayNumber: moment(d).dayOfYear() }) === -1) {
					it.push({ type: it[0].type, day: moment(d).format(), dayNumber: moment(d).dayOfYear(), count: 0 });
				}
			});
		});
		if (waitingTotalData && waitingTotalData.length) {
			_.each(dateArr, function (d, i) {
				if (
					_.findIndex(waitingTotalData, function (it) {
						return moment(it.day).dayOfYear() === moment(d).dayOfYear();
					}) === -1
				) {
					waitingTotalData.push({ day: moment(d).format(), dayNumber: moment(d).dayOfYear(), count: 0 });
				}
			});
		}
	} else {
		_.map(gdata, function (it, index) {
			return _.each(hourArr, function (d, i) {
				if (_.findIndex(it, { hour: d }) === -1) {
					it.push({ type: it[0].type, day: it[0].day, hour: d, dayNumber: it[0].dayNumber, count: 0 });
				}
			});
		});
		if (waitingTotalData && waitingTotalData.length) {
			_.map(hourArr, function (d, i) {
				if (
					_.findIndex(waitingTotalData, function (it) {
						return it.hour === d;
					}) === -1
				) {
					return waitingTotalData.push({ hour: d, count: 0 });
				}
			});
		}
		waitingTotalData = _.sortBy(waitingTotalData, 'hour');
	}
	//let closeddata = gdata.closed;
	/* console.log(
		'gdata',
		gdata,
		_.map(_.groupBy(closeddata, 'dayNumber'), function (it) {
			return { count: _.sumBy(it, 'count'), type: it[0].type, day: it[0].day, dayNumber: it[0].dayNumber };
		})
	); */
	let newdata = _.map(gdata, function (it) {
		return _.map(_.groupBy(it, selectedDate === 'daily' ? 'dayNumber' : 'hour'), function (a) {
			return { count: _.sumBy(a, 'count'), type: a[0].type, day: a[0].day, dayNumber: a[0].dayNumber, hour: a[0].hour };
		});
	});
	//console.log('waitingTotalData', waitingTotalData);
	let waitingTotalDataF = _.map(waitingTotalData, (it) => ({
		count: it.count,
		day: it.day,
		dayNumber: moment(it.day).dayOfYear(),
		type: 'activateAgent',
	}));
	if (waitingTotalDataF && waitingTotalDataF.length) newdata.push(waitingTotalDataF);
	if (selectedDate === 'daily') {
		labels = _.map(dateArr, (it) => moment(it).format('DD-MMM'));
	} else {
		labels = hourArr;
	}
	let datasets = _.map(newdata, function (it) {
		if (selectedDate === 'daily') {
			it = _.sortBy(it, ['day', 'asc']);
		} else {
			it = _.sortBy(it, ['hour', 'asc']);
		}
		return {
			label: t(TypeList[it[0].type].title),
			day: _.map(it, (item) => moment(item.day, 'DD-MM').format()),
			data: _.map(it, (item) => item.count),
			borderColor: TypeList[it[0].type].color,
			backgroundColor: TypeList[it[0].type].color,
			fill: false,
		};
	});

	return {
		labels,
		datasets,
	};
}

function chartDataHourly(closedDataHourly, contactedDataHourly, viewedDataHourly, takenDataHourly, forwardDataHourly, start_date, end_date, t) {
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray(startDate, endDate);
	_.map(dateArr, (it) => {
		return _.map(hourArr, (o) => {
			labels.push(moment(it).format('DD/MM') + ' - ' + moment(o, 'H').format('HH'));
		});
	});
	let closedDataLineData = [],
		contactedDataLineData = [],
		viewedDataLineData = [],
		takenDataLineData = [],
		forwardDataLineData = [],
		closedDataHourlyObj,
		contactedDataHourlyObj,
		viewedDataHourlyObj,
		takenDataHourlyObj,
		forwardDataHourlyObj;
	_.map(dateArr, (item) => {
		return _.map(hourArr, (it) => {
			/* if (it >= 2) {
				closedDataHourlyObj = _.find(closedDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it - 2,
				});
				contactedDataHourlyObj = _.find(contactedDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it - 2,
				});
				viewedDataHourlyObj = _.find(viewedDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it - 2,
				});
				takenDataHourlyObj = _.find(takenDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it - 2,
				});
				forwardDataHourlyObj = _.find(forwardDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it - 2,
				});
			} else if (it === 0) {
				closedDataHourlyObj = _.find(closedDataHourly, {
					dayNumber: moment(item).dayOfYear() - 1,
					hour: it + 22,
				});
				contactedDataHourlyObj = _.find(contactedDataHourly, {
					dayNumber: moment(item).dayOfYear() - 1,
					hour: it + 22,
				});
				viewedDataHourlyObj = _.find(viewedDataHourly, {
					dayNumber: moment(item).dayOfYear() - 1,
					hour: it + 22,
				});
				takenDataHourlyObj = _.find(takenDataHourly, {
					dayNumber: moment(item).dayOfYear() - 1,
					hour: it + 22,
				});
				forwardDataHourlyObj = _.find(forwardDataHourly, {
					dayNumber: moment(item).dayOfYear() - 1,
					hour: it + 22,
				});
			} else {
				closedDataHourlyObj = _.find(closedDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it + 22,
				});
				contactedDataHourlyObj = _.find(contactedDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it + 22,
				});
				viewedDataHourlyObj = _.find(viewedDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it + 22,
				});
				takenDataHourlyObj = _.find(takenDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it + 22,
				});
				forwardDataHourlyObj = _.find(forwardDataHourly, {
					dayNumber: moment(item).dayOfYear(),
					hour: it + 22,
				});
			} */
			closedDataHourlyObj = _.find(closedDataHourly, {
				dayNumber: moment(item).dayOfYear(),
				hour: it,
			});
			contactedDataHourlyObj = _.find(contactedDataHourly, {
				dayNumber: moment(item).dayOfYear(),
				hour: it,
			});
			viewedDataHourlyObj = _.find(viewedDataHourly, {
				dayNumber: moment(item).dayOfYear(),
				hour: it,
			});
			takenDataHourlyObj = _.find(takenDataHourly, {
				dayNumber: moment(item).dayOfYear(),
				hour: it,
			});
			forwardDataHourlyObj = _.find(forwardDataHourly, {
				dayNumber: moment(item).dayOfYear(),
				hour: it,
			});
			closedDataLineData.push((closedDataHourlyObj && closedDataHourlyObj.count) || 0);
			contactedDataLineData.push((contactedDataHourlyObj && contactedDataHourlyObj.count) || 0);
			viewedDataLineData.push((viewedDataHourlyObj && viewedDataHourlyObj.count) || 0);
			takenDataLineData.push((takenDataHourlyObj && takenDataHourlyObj.count) || 0);
			forwardDataLineData.push((forwardDataHourlyObj && forwardDataHourlyObj.count) || 0);
		});
	});
	let closedDataLine = {
			label: t('closedConversations'),
			data: closedDataLineData,
			borderColor: '#8B565C',
			backgroundColor: '#8B565C',
			fill: false,
		},
		contactedDataLine = {
			label: t('contactedConversations'),
			data: contactedDataLineData,
			borderColor: '#0F9E34',
			backgroundColor: '#0F9E34',
			fill: false,
		},
		viewedDataLine = {
			label: t('viewedEvent'),
			data: viewedDataLineData,
			borderColor: '#8B9AE7',
			backgroundColor: '#8B9AE7',
			fill: false,
		},
		takenDataLine = {
			label: t('takenConversations'),
			data: takenDataLineData,
			borderColor: '#E10840',
			backgroundColor: '#E10840',
			fill: false,
		},
		forwardDataLine = {
			label: t('forwardedConversations'),
			data: forwardDataLineData,
			borderColor: '#AD187D',
			backgroundColor: '#AD187D',
			fill: false,
		};
	let datasets = [takenDataLine, viewedDataLine, contactedDataLine, closedDataLine, forwardDataLine];
	return {
		labels,
		datasets,
	};
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
