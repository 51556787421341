import React from 'react';
import Page from '../../components/Page';
import AccountProgress from '../../components/AccountProgress';
import { Icon, Breadcrumb, Table, Tab, Button, Modal, TextArea, Checkbox } from 'semantic-ui-react';
import { Link, Prompt, Route, matchPath, NavLink } from 'react-router-dom';
import useFetch from 'use-http';
import { Formik, FastField, Field, FieldArray } from 'formik';
import _ from 'lodash';
import { BooleanInput, TableLine, FormDropdown, TextInput, DropdownInput, MaterialTextArea } from './inputs';
// eslint-disable-next-line
import styled from 'styled-components/macro';
import MyMenu from './menu';
import Hours from './hours.json';
import Timezone from './timezone.json';
import { useTranslation } from 'react-i18next';

const fetch_config = { responseType: 'json', cachePolicy: 'no-cache' };

const hours = Hours;

function AgentScreenConfigs() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2} />
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<TableLine title={t('instagramComment')} description={t('instagramCommentDesc')}>
						<BooleanInput name="agent.instagram_comments" />
					</TableLine>
					<TableLine title={t('enable_tag_comment')} description={t('enable_tag_commentDesc')}>
						<BooleanInput name="agent.enable_tag_comment" />
					</TableLine>
					<TableLine title={t('instagram_comment_reply')} description={t('instagram_comment_replyDesc')}>
						<TextInput name="agent.instagram_comment_reply" />
					</TableLine>
					<TableLine title={t('open_instagram_comment_reply')} description={t('open_instagram_comment_replyDesc')}>
						<BooleanInput name="agent.open_instagram_comment_reply" />
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}

const ModalWrap = styled.div`
	color: black;
	padding: 0 16px 16px;
`;

const select_colors = {
	amount: '#21ba45',
	code: '#fbbd08',
	quantity: '#2185d0',
};
const Select = styled.div`
	display: inline-block;
	margin: 5px 5px 5px 0;
	padding: 8px 10px;
	border: 1px solid #eee;
	cursor: pointer;
	background: ${(props) => (props.color ? select_colors[props.color] || '#eee' : '#fff')};
	color: ${(props) => (props.color ? '#fff' : '#000')};
	user-select: none;
`;

const ButtonLine = styled.div`
	padding-top: 16px;
	border-top: 1px solid #eee;
	margin-top: 16px;
	display: flex;
`;

function generateList(text) {
	return _.map((text || '').split('\n'), (it) => it.split(/\s+/));
}

function PartSelector(props) {
	const { t } = useTranslation();
	const LABELS = [
		{ key: 'amount', label: t('price'), color: 'green', parse: 'float' },
		{ key: 'code', label: t('code'), color: 'yellow' },
		{ key: 'quantity', label: t('Count'), color: 'blue', parse: 'int' },
	];
	const [selected, setSelected] = React.useState((props.selected && props.selected.selected) || {});
	const [current, setCurrent] = React.useState(LABELS[0].key);
	const list = React.useMemo(() => {
		return generateList(props.value);
	}, [props.value]);

	function change(i, j) {
		let item = selected[current];
		let isCurrent = item && item[0] === i && item[1] === j;
		let key = `${i}-${j}`;
		let isActive = selected[key] && selected[key][2] === current;
		let state = [i, j, current];
		let next = { ...selected, [key]: isActive ? '' : state, [current]: state };
		if (item && !isCurrent) next[`${item[0]}-${item[1]}`] = '';
		if (isCurrent && isActive) next[current] = '';
		//console.log(current, item && !isCurrent, item, selected);

		setSelected(next);
		if (props.onChange) props.onChange({ list, selected: next });
	}

	function button_click(e, data) {
		setCurrent(data.name);
	}

	return (
		<div>
			<Button.Group>
				{_.map(LABELS, (it) => (
					<Button type="button" key={it.key} name={it.key} color={it.color} basic={current !== it.key} onClick={button_click}>
						{it.label}
					</Button>
				))}
			</Button.Group>
			<div
				css={`
					margin-top: 10px;
				`}
			>
				{_.map(list, (line, i) => (
					<div key={i}>
						{_.map(line, (val, j) => {
							let isActive = selected[`${i}-${j}`];
							return (
								<Select key={`${i}-${j}`} color={isActive && isActive[2]} onClick={() => change(i, j)}>
									{val}
								</Select>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
}

//no:\s?(TS.*?)(?:\n|$)Sipari[sş] tutarı:s?(.*?)(?:\n|$)

function generateRegex(found, list, main_text) {
	let last_point = { i: -1, j: -1 };
	let current_reg = '';
	const empty = '(?:\\n|.)*?';

	function compare(x, y) {
		if (x.i === y.i && x.j + 1 === y.j) return true;
		if (x.i === y.i + 1 && y.j === 0 && x.j === list[x.i].length - 1) return true;
	}
	let nfound = _.map(found, (it, x) => {
		let { i, j, text } = it;
		let ci = i,
			cj = j,
			ctext = '';
		let fark = compare(last_point, it);
		function handle() {
			if (cj > 0) cj--;
			else if (ci > 0) {
				ci--;
				let line = list[ci];
				cj = line.length - 1;
			}
			//console.log('handle', i, j, ci, cj);
		}
		handle();
		let trc_count = 1;
		if (fark) {
			last_point = it;
			if (current_reg) current_reg += empty;
			let reg_text = '\\s?(.*?)(?:\\s|\\n|$)';
			current_reg += reg_text;
			return { ...it, reg: reg_text };
		} else
			while (ci > last_point.i || (ci === last_point.i && cj > last_point.j)) {
				let reg = list[ci][cj],
					reg_text;
				if (i === 0 && j === 0) {
					ctext = `^[\\s\\n]*?${ctext}`;
					if (j === 0 && list[i].length > 1) {
						let next = list[i][j + 1];
						reg_text = `${ctext}\\s?(.*?)\\s?${next}`;
					} else reg_text = `${ctext}\\s?(.*?)(?:\\s|\\n|$)`;
				} else {
					if (ctext) ctext = `${reg}[\\s\\n]*?${ctext}`;
					else ctext = reg;
					reg_text = `${ctext}\\s?(.*?)(?:\\s|\\n|$)`;
				}
				let test_reg = new RegExp(current_reg ? current_reg + empty + reg_text : reg_text, 'i');
				let test_result = main_text.match(test_reg);
				test_result = test_result && test_result[x + 1];
				//console.log(test_reg, test_result);
				if (test_result === text) {
					last_point = it;
					if (current_reg) current_reg += empty;
					current_reg += reg_text || '()';
					return { ...it, reg: reg_text };
				}
				if (ci !== 0 && cj !== 0) handle();
				trc_count++;
				if (trc_count > 50) break;
			}
		if (current_reg) current_reg += empty;
		current_reg += '()';
		last_point = it;
	});
	//console.log(nfound);
	let reg = _.map(nfound, (it) => (it && it.reg) || '()').join(empty);
	//console.log('test', reg, main_text.match(new RegExp(reg)));
	return reg;
}

const LABELS = [{ key: 'amount' }, { key: 'code' }, { key: 'quantity' }];

function RegexPreview(props) {
	const { t } = useTranslation();

	const regex = React.useMemo(() => {
		let found = [];
		let { selected, list } = props.selected || {};
		if (!selected || !list) return;
		_.each(LABELS, (label) => {
			let item = selected[label.key];
			if (!item) return;
			let [i, j] = item;
			let text = list[i] && list[i][j];
			if (text) found.push({ i, j, text, key: label.key });
		});
		found = _.sortBy(found, ['i', 'j']);
		let reg = generateRegex(found, list, props.value);
		let res = props.value.match(new RegExp(reg));
		let result = {};
		_.each(found, (it, i) => (result[it.key] = res[i + 1]));
		return { text: props.value, reg, result, found };
	}, [props.selected, props.value]);

	return (
		<ModalWrap>
			{/* <pre>{JSON.stringify(regex, null, 2)}</pre> */}
			{regex ? (
				<div
					css={`
						display: flex;
						b {
							margin-right: 5px;
							text-transform: capitalize;
						}
						> div {
							flex: 1;
						}
						pre {
							margin: 0;
							border: 1px solid #f2711c;
							display: inline-block;
							padding: 16px;
						}
					`}
				>
					<div>
						<h3>{t('exampleMessage')}</h3>
						<pre>{regex.text}</pre>
					</div>
					<div>
						<h3>{t('variablesFound')}</h3>
						<pre>
							{_.map(regex.result, (val, key) => (
								<div key={key}>
									<b>{key}:</b>
									{val}
								</div>
							))}
						</pre>
					</div>
				</div>
			) : (
				t('couldNotAnything')
			)}
			<ButtonLine>
				<Button type="button" onClick={() => props.setStep(1)}>
					{t('return')}
				</Button>
				<Button className="ml-auto" type="button" onClick={() => props.setModal(false)}>
					{t('cancel')}
				</Button>
				<Button type="button" color="green" onClick={() => props.save(regex)}>
					{t('save')}
				</Button>
			</ButtonLine>
		</ModalWrap>
	);
}

function CloseParseModal(props) {
	const { t } = useTranslation();
	//const [init, setInit] = React.useState(props.value && props.value.text);
	const [modal, setModal] = React.useState(false);
	const [step, setStep] = React.useState(props.value && props.value.text ? 1 : 0);
	const [selected, setSelected] = React.useState();
	const [example, setExample] = React.useState((props.value && props.value.text) || '');
	const real_selected = React.useMemo(() => {
		if (selected) return selected;
		if (props.value && props.value.found) {
			let f = props.value && props.value.found;
			let selected = {};
			_.each(f, (it) => {
				selected[it.key] = [it.i, it.j, it.key];
				selected[`${it.i}-${it.j}`] = [it.i, it.j, it.key];
			});
			return { list: generateList(example), selected };
		}
	}, [selected, props.value, example]);
	function ExampleOnchange(e, { value }) {
		setExample(value);
		if (selected) setSelected(null);
	}

	function save(reg) {
		props.form.setFieldValue('agent.__close_parse_config_data', reg);
		setModal(false);
	}

	const CONFIG_PANES = [
		{
			menuItem: {
				content: t('exampleText'),
				key: 'step0',
			},
			render: () => (
				<ModalWrap>
					{t('successfulPaymentMessage')}
					<div className="ui form" style={{ marginTop: 10 }}>
						<TextArea value={example} onChange={ExampleOnchange} />
					</div>
					<ButtonLine>
						<Button type="button" color="teal" onClick={() => setStep(1)}>
							{t('nextStep')}
						</Button>
						<Button className="ml-auto" type="button" onClick={() => setModal(false)}>
							{t('cancel')}
						</Button>
					</ButtonLine>
				</ModalWrap>
			),
		},
		{
			menuItem: {
				content: t('selectLabels'),
				key: 'step1',
			},
			render: () => (
				<ModalWrap>
					<PartSelector value={example} selected={real_selected} onChange={setSelected} />
					<ButtonLine>
						<Button type="button" onClick={() => setStep(0)}>
							{t('return')}
						</Button>
						<Button type="button" color="teal" onClick={() => setStep(2)}>
							{t('nextStep')}
						</Button>
						<Button className="ml-auto" type="button" onClick={() => setModal(false)}>
							{t('cancel')}
						</Button>
					</ButtonLine>
				</ModalWrap>
			),
		},
		{
			menuItem: {
				content: t('preview'),
				key: 'step2',
			},
			render: () => <RegexPreview setStep={setStep} save={save} setModal={setModal} value={example} selected={real_selected} />,
		},
	];
	return (
		<Modal
			open={modal}
			trigger={
				<Button onClick={() => setModal(true)} color="orange" type="button">
					{t('openInteractiveEditor')}
				</Button>
			}
			centered={false}
		>
			<Tab menu={{ secondary: true, pointing: true }} activeIndex={step} panes={CONFIG_PANES} />
		</Modal>
	);
}

function CloseParseEditor(props) {
	return <FastField name={props.name}>{({ field, form }) => <CloseParseModal value={field.value} form={form} />}</FastField>;
}

function SaleDetector() {
	const { t } = useTranslation();
	return (
		<Tab.Pane>
			<Table definition>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2} />
						<Table.HeaderCell>{t('value')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<TableLine title={t('saleDetector')} single>
						<div
							css={`
								display: flex;
								align-items: center;
								button {
									white-space: nowrap;
								}
							`}
						>
							<CloseParseEditor name="agent.__close_parse_config_data" />
							<TextInput title="English" name="agent.__close_parse_config_data.reg" />
						</div>
					</TableLine>
				</Table.Body>
			</Table>
		</Tab.Pane>
	);
}

function Configs(props) {
	const { t } = useTranslation();
	const CONFIG_PANES = [
		{
			menuItem: {
				as: NavLink,
				content: t('agentScreen'),
				to: '/settings/igpreview',
				key: 'agent',
			},
			render: () => <Route path="/settings/igpreview" component={AgentScreenConfigs} />,
		},
	];

	const CONFIG_PANES_NO_SALE = [
		{
			menuItem: {
				as: NavLink,
				content: t('agentScreen'),
				to: '/settings/igpreview',
				key: 'agent',
			},
			render: () => <Route path="/settings/igpreview" component={AgentScreenConfigs} />,
		},
	];
	let host = props.host;
	let Panes = host ? CONFIG_PANES : CONFIG_PANES_NO_SALE;
	const pathname = window.location.pathname;
	const defaultActiveIndex = React.useMemo(
		() =>
			Panes.findIndex((pane) => {
				return !!matchPath(pathname, {
					path: pane.menuItem.to,
					exact: true,
				});
			}),
		[pathname, Panes]
	);
	return (
		<Page.Wrap>
			<Tab menu={{ secondary: true, pointing: true }} activeIndex={defaultActiveIndex} panes={Panes} {...props} />
		</Page.Wrap>
	);
}

export default function ProductSettings() {
	const { response: userData } = useFetch('/api/auth/user', fetch_config, []);
	const { loading, response } = useFetch('/api/dahi/message/getLiveChat', fetch_config, []);
	//console.log('asd', response);
	const { t } = useTranslation();
	const LABELS = [
		{ key: 'amount', label: t('price'), color: 'green', parse: 'float' },
		{ key: 'code', label: t('code'), color: 'yellow' },
		{ key: 'quantity', label: t('Count'), color: 'blue', parse: 'int' },
	];
	let user = userData && userData.data;
	const result = response && response.data && response.data.result;
	const initialValues = React.useMemo(() => {
		if (!result) return;
		const { messageSetting, staticOptions, agentOptions, workingSetting, client, workingHours } = result;
		const combined = _.assignIn(agentOptions, staticOptions);
		const agent = _.merge(
			{ silent_agent: false, selectpopup: true, showSendStart: false, __close_parse_config: '' },
			_.pick(combined, ['instagram_comments', 'enable_tag_comment', 'instagram_comment_reply', 'open_instagram_comment_reply'])
		);
		return { workingSetting, messageSetting, agent, client, workingHours };
	}, [result]);
	if (loading)
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="general settings">
					<Page.Loader />
				</MyMenu>
			</Page>
		);
	let host = user.data.host === null || user.data.host === 'app.dialogtab.com';
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				let clone = { ...values };
				clone.staticOptions = clone.agent;
				delete clone.agent;
				let data = clone.staticOptions && clone.staticOptions.__close_parse_config_data;
				if (data) {
					let list = [];
					_.each(data.found, (it) => {
						let label = _.find(LABELS, { key: it.key });
						list.push({ key: it.key, parse: label && label.parse });
					});
					clone.staticOptions.close_parser_config = data.reg
						? [
								{
									regex: data.reg,
									type: 'order',
									key: 'order',
									display: 'Sipariş Alındı:\nKod: {{code}}\nTutar: {{amount}}',
									version: 2,
									list: list,
								},
						  ]
						: [];
				}
				fetch('/api/dahi/channel/save', {
					method: 'POST',
					body: JSON.stringify({ channel: 'live', data: { set: clone } }),
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then((response) => {
						return response.json();
					})
					.then(() => {
						actions.resetForm(values);
						actions.setSubmitting(false);
					})
					.catch((e) => {
						console.error(e);
						actions.setSubmitting(false);
					});
			}}
		>
			{(props) => {
				return (
					<Page
						header={
							<Breadcrumb size="big">
								<Breadcrumb.Section as={Link} to={`/settings`}>
									<Icon name="setting" />
									{t('settings')}
								</Breadcrumb.Section>
								<Breadcrumb.Divider icon="right chevron" />
								<Breadcrumb.Section>{t('productSettings')}</Breadcrumb.Section>
							</Breadcrumb>
						}
						extra={
							<div className="ml-auto d-flex">
								<AccountProgress></AccountProgress>
								<Button loading={props.isSubmitting} primary type="submit" onClick={props.submitForm} style={{ marginLeft: '10px' }}>
									{t('saveChanges')}
								</Button>
							</div>
						}
						flex
					>
						<MyMenu activeItem="general settings">
							<form onSubmit={props.handleSubmit}>
								<Prompt
									when={props.dirty}
									message={(location) => (location.pathname.startsWith('/settings/igpreview') ? true : t('questionWithoutSaving'))}
								/>
								<Configs {...props} host={host} />
							</form>
						</MyMenu>
					</Page>
				);
			}}
		</Formik>
	);
}
