import React, { Component } from 'react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import { Grid, Header, Icon, Segment, Table, Dropdown, Container, Radio, Checkbox, Loader, Input, Button } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import { post, log } from '../../util/api';

class Analytic extends Component {
	state = {
		loading: false,
	};

	async handlesendMail() {
		let { start_date, end_date, product } = this.props;
		let { sendMail } = this.state;
		let pid = product.live.id;
		setTimeout(() => {
			this.setState({ openSendMailModal: false, loadindsendmail: false, showSendingMessage: false, excelModal: false });
		}, 6000);
		await post('/analytic/createExcel', { start_date, end_date, pid, email: sendMail });
	}
	render() {
		let { t } = this.props;
		return (
			<Container>
				<Segment style={{ marginBottom: '20px' }}>
					<h4>{'Tüm sayfaları içeren excel dosyasını almak istediğiniz maili girin. (Birden fazla maile exceli almak istiyorsanız maillerin arasına virgül koyarak ayırabilirsiniz. example@yt.com,example2@yt.com)'}</h4>
					<Input
						fluid
						placeholder="Mail adresi giriniz"
						required
						value={this.state.sendMail}
						onChange={(e) => this.setState({ sendMail: e.target.value })}
					></Input>
					<Button
						color="green"
						inverted
						disabled={!this.state.sendMail || !this.state.sendMail.includes('@')}
						loading={this.state.loadindsendmail}
						onClick={() => {
							this.handlesendMail();
							this.setState({ showSendingMessage: true, loadindsendmail: true });
						}}
					>
						{t('sendMail')}
					</Button>
					{this.state.showSendingMessage && (
						<p>Talebiniz alınmıştır kısa süre içerisinde raporunuz iletilecektir, epostanızı kontrol edebilirsiniz.</p>
					)}
				</Segment>
			</Container>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
