import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Breadcrumb, Icon } from 'semantic-ui-react';
import Page from '../../../components/Page';
import { ConnectButton } from '360dialog-connect-button';
import MyMenu from '../menu';

function AppButton() {
	const [state, setState] = useState(null);
	const handleCallback = (callbackObject) => {
		/* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */

		console.log('client ID: ' + callbackObject.client);
		console.log('channel IDs: ' + callbackObject.channels);
		setState(callbackObject);
	};

	if (state) {
		return (
			<div>
				Please share following info with us!
				<pre>{JSON.stringify(state, null, 2)}</pre>
			</div>
		);
	}

	return (
		<div>
			<ConnectButton partnerId={'wBRCxXPA'} callback={handleCallback} />
		</div>
	);
}

export default function Dialog360(props) {
	const { t } = useTranslation();
	return (
		<Page
			header={
				<Breadcrumb size="big">
					<Breadcrumb.Section as={Link} to={`/settings`}>
						<Icon name="setting" />
						{t('settings')}
					</Breadcrumb.Section>
					<Breadcrumb.Divider icon="right chevron" />

					<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
						{t('integrations')}
					</Breadcrumb.Section>
					<Breadcrumb.Divider icon="right chevron" />
					<Breadcrumb.Section>360Dialog WhatsApp Integration</Breadcrumb.Section>
				</Breadcrumb>
			}
			extra={<div className="ml-auto d-flex"></div>}
			flex
		>
			<MyMenu activeItem="">
				<Page.Wrap>
					<AppButton />
				</Page.Wrap>
			</MyMenu>
		</Page>
	);
}
