import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Page from '../../components/Page';
import {
	Button,
	Input,
	Label,
	Segment,
	Dimmer,
	Image,
	Header,
	Loader,
	Grid,
	GridRow,
	GridColumn,
	Dropdown,
	Form,
	Modal,
	Icon,
	Message,
} from 'semantic-ui-react';
import { post, get } from '../../util/api';
import _ from 'lodash';
import DraggableDiv from '../../components/DraggableDiv';
class IceBreakerTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			icebreakerFormValues: [{ question: '', payload: '' }],
			loading: true,
			ice_breakers_length: 0,
			saveButton: false,
			deleteAllModal: false,
			crossModal: false,
			alertMessage: false,
			alertMessageText: '',
			accoundId: window.location.pathname.split('/icebreakers/')[1],
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		let { ice_breakers_length, accoundId } = this.state;
		// let getIcebreakersData = await post(`/messenger/register/icebreaker/list/${accoundId}`, {});
		let getIcebreakersData;
		fetch(`/api/messenger/register/icebreaker/list/${accoundId}`, { method: 'POST', body: {} })
			.then((res) => res.json())
			.then((response) => {
				getIcebreakersData = response;
				if (getIcebreakersData.success === false) {
					this.setState({ loading: false, alertMessage: true, alertMessageText: getIcebreakersData.message });
				} else {
					if (getIcebreakersData && getIcebreakersData.data && getIcebreakersData.data.length === 0) {
						let { icebreakerFormValues } = this.state;
						let locale = 'default';
						let call_to_actions = [];
						call_to_actions.push(icebreakerFormValues[0]);
						let newiceBreakerFormValues = { locale: locale, call_to_actions: call_to_actions };
						let newBigData = [];
						newBigData.push(newiceBreakerFormValues);
						let ice_breakers_length = 4;
						this.setState({ icebreakerFormValues: newBigData, ice_breakers_length: ice_breakers_length, loading: false, alertMessage: false });
					} else {
						getIcebreakersData =
							getIcebreakersData && getIcebreakersData.data && getIcebreakersData.data[0] && getIcebreakersData.data[0].ice_breakers;
						let icebreakerData = [];
						for (let i = 0; i < getIcebreakersData && getIcebreakersData.length; i++) {
							icebreakerData.push(getIcebreakersData[i]);
						}
						let iceLength = getIcebreakersData && getIcebreakersData[0] && getIcebreakersData[0].call_to_actions.length;
						ice_breakers_length = 4 - iceLength;
						this.setState({
							icebreakerFormValues: getIcebreakersData,
							ice_breakers_length: ice_breakers_length,
							loading: false,
							alertMessage: false,
						});
					}
				}
			});
	}
	componentDidUpdate(props) {
		if (props !== this.props) {
			this.setState({ loading: true });
			this.checkIceBreakers();
		}
	}
	async swap(a, b) {
		let { list } = this.state;
		//list[a] = list.splice(b, 1, list[a])[0];
		list.splice(a, 0, list.splice(b, 1)[0]);
		await this.setState({
			list,
		});
	}
	checkIceBreakers = () => {
		let { ice_breakers_length, accoundId } = this.state;
		accoundId = window.location.pathname.split('/icebreakers/')[1];
		// let getIcebreakersData = await post(`/messenger/register/icebreaker/list/${accoundId}`, {});
		let getIcebreakersData;
		fetch(`/api/messenger/register/icebreaker/list/${accoundId}`, { method: 'POST', body: {} })
			.then((res) => res.json())
			.then((response) => {
				getIcebreakersData = response;
				if (getIcebreakersData.success === false) {
					this.setState({ loading: false, alertMessage: true, alertMessageText: getIcebreakersData.message });
				} else {
					if (getIcebreakersData && getIcebreakersData.data && getIcebreakersData.data.length === 0) {
						let { icebreakerFormValues } = this.state;
						let locale = 'default';
						let call_to_actions = [];
						call_to_actions.push(icebreakerFormValues[0]);
						let newiceBreakerFormValues = { locale: locale, call_to_actions: call_to_actions };
						let newBigData = [];
						newBigData.push(newiceBreakerFormValues);
						let ice_breakers_length = 4;
						this.setState({ icebreakerFormValues: newBigData, ice_breakers_length: ice_breakers_length, loading: false, alertMessage: false });
					} else {
						getIcebreakersData =
							getIcebreakersData && getIcebreakersData.data && getIcebreakersData.data[0] && getIcebreakersData.data[0].ice_breakers;
						let icebreakerData = [];
						for (let i = 0; i < getIcebreakersData && getIcebreakersData.length; i++) {
							icebreakerData.push(getIcebreakersData[i]);
						}
						let iceLength = getIcebreakersData && getIcebreakersData[0] && getIcebreakersData[0].call_to_actions.length;
						ice_breakers_length = 4 - iceLength;
						this.setState({
							icebreakerFormValues: getIcebreakersData,
							ice_breakers_length: ice_breakers_length,
							loading: false,
							alertMessage: false,
						});
					}
				}
			});
	};
	handleChange(i, e, k) {
		let { icebreakerFormValues } = this.state;
		if (e.target.name === 'question') {
			icebreakerFormValues[i].call_to_actions[k].question = e.target.value;
			// icebreakerFormValues[i].call_to_actions[k].payload = e.target.value;
		} else if (e.target.name === 'payload') {
			icebreakerFormValues[i].call_to_actions[k].payload = e.target.value;
		}
		this.setState({ icebreakerFormValues, saveButton: true });
	}

	addFormFields(index) {
		let { icebreakerFormValues } = this.state;
		if (index !== undefined) {
			for (let i = 0; i < icebreakerFormValues.length; i++) {
				if (i === index) {
					icebreakerFormValues[i].call_to_actions.push({ question: '', payload: '' });
					this.setState({
						icebreakerFormValues: icebreakerFormValues,
					});
				}
			}
		}
		let iceLength =
			icebreakerFormValues && icebreakerFormValues[0] && icebreakerFormValues[0].call_to_actions && icebreakerFormValues[0].call_to_actions.length;
		let ice_breakers_length = 4 - iceLength;
		this.setState({ ice_breakers_length, saveButton: true });
	}

	removeFormFields(i, k) {
		let icebreakerFormValues = this.state.icebreakerFormValues;
		for (let r = 0; r < icebreakerFormValues.length; r++) {
			if (i === r) {
				icebreakerFormValues[i].call_to_actions.splice(k, 1);
			}
		}
		let iceLength =
			icebreakerFormValues && icebreakerFormValues[0] && icebreakerFormValues[0].call_to_actions && icebreakerFormValues[0].call_to_actions.length;
		let ice_breakers_length = 4 - iceLength;
		this.setState({ icebreakerFormValues, ice_breakers_length, saveButton: true });
	}

	async handleSubmit() {
		 this.setState({ loading: true })
		let { icebreakerFormValues, accoundId } = this.state;
		_.map(icebreakerFormValues[0].call_to_actions, (it, i) => {
			if (it.payload === '') {
				it.payload = it.question;
			}
		});
		let iceObje = { call_to_actions: icebreakerFormValues[0].call_to_actions, locale: 'default' };
		let iceBreakers = {
			icebreakers: {
				platform: 'instagram',
				ice_breakers: [iceObje],
			},
		};
		await post(`/messenger/register/icebreaker/set/${accoundId}`, iceBreakers)
			.then((response) => console.log('response', response))
			.catch((err) => {
				console.log('error');
			});
		this.setState({ saveButton: false, loading: false });
	}
	deleteIceBreakerAll = async () => {
		let { accoundId } = this.state;
		await post(`/messenger/register/icebreaker/delete/${accoundId}`)
			.then((res) => {
				console.log('response', res);
			})
			.catch((err) => {
				console.log('error', err);
			});
		this.setState({ deleteAllModal: false });
		this.checkIceBreakers();
		window.location.reload();
	};
	openModal = () => {
		this.setState({ deleteAllModal: true });
	};
	async swap(a, b) {
		let { icebreakerFormValues } = this.state;
		let list = icebreakerFormValues[0].call_to_actions;
		list.splice(a, 0, list.splice(b, 1)[0]);
		icebreakerFormValues[0].call_to_actions = list;
		await this.setState({
			icebreakerFormValues,
			saveButton: true,
		});
	}
	cancelChanges = () => {
		this.setState({ loading: true });
		this.checkIceBreakers();
	};
	render() {
		const { product, t, i18n } = this.props;
		let { alertMessage, alertMessageText, icebreakerFormValues, loading, ice_breakers_length, saveButton, deleteAllModal, crossModal } = this.state;
		return (
			<>
				{loading ? (
					<Dimmer active inverted>
						<Loader inverted></Loader>
					</Dimmer>
				) : alertMessage === true ? (
					<Message error header={alertMessageText} />
				) : (
					<Grid>
						<GridColumn width={12}>
							<Segment>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
									<Header content={'IceBreakers'} />
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<Button icon={'eraser'} onClick={() => this.openModal()} content={t('deleteIcebreakers')} />
										<Button icon={'reply'} content={t('cancelchanges')} onClick={() => this.cancelChanges()} />
									</div>
								</div>
								{ice_breakers_length !== 0 ? (
									<Header as="h5" style={{ color: '#c2c2c2' }} content={t('maxIcebreaker', { limit: ice_breakers_length })} />
								) : (
									<Header as="h5" style={{ color: '#c2c2c2' }} content={t('notmore')} />
								)}

								{_.map(icebreakerFormValues, (it, i) => (
									<Form onSubmit={this.handleSubmit}>
										<Segment style={{ marginBottom: '20px', width: '100%', marginTop: '20px' }} key={i}>
											{_.map(it && it.call_to_actions, (its, k) => (
												<DraggableDiv key={k} i={k} action={this.swap.bind(this)}>
													<div style={{ marginBottom: '20px', width: '100%', display: 'flex', flexDirection: 'row' }}>
														<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
															<div
																style={{
																	display: 'flex',
																	alignItems: 'center',
																	height: '100%',
																	cursor: 'move',
																	marginTop: '10px',
																}}
															>
																<Icon name="arrows alternate vertical" size="large"></Icon>
															</div>
															<div style={{ width: '50%', marginRight: '10px' }}>
																<Form.Input
																	maxLength={80}
																	label={'Text'}
																	required
																	onChange={(e) => this.handleChange(i, e, k)}
																	name="question"
																	placeholder={'Example:How do i find a store near me?'}
																	value={its.question}
																/>
															</div>
															<div style={{ width: '50%' }}>
																<Form.Input
																	maxLength={80}
																	label={'payload'}
																	onChange={(e) => this.handleChange(i, e, k)}
																	name="payload"
																	placeholder={'Example:How do i find a store near me?'}
																	value={its.payload}
																/>
															</div>
														</div>
														{k !== 0 ? (
															<Button
																className="instaCrossButton"
																color={'red'}
																onClick={() => this.removeFormFields(i, k)}
																icon={'remove'}
																style={{ marginLeft: '10px' }}
															/>
														) : (
															<Button
																className="instaCrossButton"
																color={'red'}
																disabled
																icon={'remove'}
																style={{ marginLeft: '10px' }}
															/>
														)}
													</div>
												</DraggableDiv>
											))}
										</Segment>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											{icebreakerFormValues &&
												icebreakerFormValues[0] &&
												icebreakerFormValues[0].call_to_actions &&
												icebreakerFormValues[0].call_to_actions.length < 4 && (
													<Button
														onClick={() => this.addFormFields(i)}
														size={'mini'}
														color={'green'}
														content={t('addIcebreaker')}
														icon={'add'}
													/>
												)}
											{saveButton ? (
												<Form.Button type={'submit'} size={'mini'} color={'blue'} content={t('save')} icon={'save'} />
											) : (
												<Form.Button disabled type={'submit'} size={'mini'} color={'blue'} content={t('save')} icon={'save'} />
											)}
										</div>
									</Form>
								))}
							</Segment>
						</GridColumn>
						<GridColumn width={4}>
							<Segment style={{ display: 'flex', justifyContent: 'center' }}>
								<Image
									src={'https://cdnydm.com/permedia/hQGNJFIabxf4_vfck6btNQ.png?size=750x1624'}
									size="medium"
									rounded
									style={{ border: '1px solid black' }}
								/>
							</Segment>
						</GridColumn>
					</Grid>
				)}

				<Modal onClose={() => this.setState({ crossModal })} open={crossModal}>
					<Modal.Header>
						<Header icon="eraser" content={'deleteIceBreakerAll'} />
					</Modal.Header>
					<Modal.Content>
						<span>Seçili Icebreaker'ı silmek istediğinizden eminmisiniz?</span>
					</Modal.Content>
					<Modal.Actions>
						<Button color="red" onClick={() => this.setState({ modal: false })} icon={'remove'} content={'no'} />
						<Button color="green" onClick={() => this.setState({ modal: false })} icon={'check'} content={'yes'} />
					</Modal.Actions>
				</Modal>
				<Modal onClose={() => this.setState({ deleteAllModal: false })} open={deleteAllModal}>
					<Modal.Header>
						<Header icon="eraser" content={t('deleteIcebreakers')} />
					</Modal.Header>
					<Modal.Content>
						<span>{t('areusuredeleteIcebreakers')}</span>
					</Modal.Content>
					<Modal.Actions>
						<Button color="red" onClick={() => this.setState({ deleteAllModal: false })} icon={'remove'} content={t('no')} />
						<Button color="green" onClick={() => this.deleteIceBreakerAll()} icon={'check'} content={t('yes')} />
					</Modal.Actions>
				</Modal>
			</>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(withTranslation()(IceBreakerTab));
