import React, { Component } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Table, Button, Segment, Step, Message, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import MyMenu from '../menu';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { post } from '../../../util/api';
import AccountProgress from '../../../components/AccountProgress';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

const FACE_APP_ID = process.env.REACT_APP_FACE_APP_ID || '296064065127789';
const FACE_APP_REDIRECT_URL = process.env.REACT_APP_FACE_APP_REDIRECT_URL || 'https://app.dialogtab.com/settings/integrations/instagram-messenger/';
//const redirect_url = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${FACE_APP_ID}&display=page&extras={%22setup%22:{%22channel%22:%22IG_API_ONBOARDING%22}}&redirect_uri=${FACE_APP_REDIRECT_URL}&response_type=token&scope=pages_messaging,instagram_basic,instagram_manage_messages,pages_manage_metadata,instagram_manage_comments,pages_read_engagement,pages_read_user_content,pages_show_list`;
const redirect_url = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${FACE_APP_ID}&display=page&extras={%22setup%22:{%22channel%22:%22IG_API_ONBOARDING%22}}&redirect_uri=${FACE_APP_REDIRECT_URL}&response_type=token&scope=pages_messaging,instagram_basic,instagram_manage_messages,pages_manage_metadata,instagram_manage_comments,pages_read_engagement,pages_show_list,business_management`;

class PageSeceltor extends Component {
	state = { loading: true, buttonLoading: -1, list: [], error: false, status: null, accounts: [], connectedPages: null, error_message: null };

	componentDidMount() {
		let accounts = this.props.accounts;
		let connectedPages = this.props.connectedpages;
		let noconnected_pages = [];
		if (accounts) {
			for (let i = 0; i < accounts.length; i++) {
				const element = accounts[i];
				if (
					element.id &&
					!_.find(connectedPages, (e, j) => {
						return e.shop === element.id;
					})
				) {
					noconnected_pages.push(element);
				}
			}
		}
		if (this.props && this.props.error_description) {
			this.setState({ error_description: this.props.error_description });
		}
		this.setState({ accounts: noconnected_pages, connectedPages, loading: false });
	}

	connect = (props) => async () => {
		this.setState({ buttonLoading: props.id });

		await post('/messenger/register/insta/connect', { page_id: props.id, page_access_token: props.token }).then((response) => {
			fetch('/api/dahi/channel/save', {
				method: 'POST',
				body: JSON.stringify({ channel: 'live', data: { set: { staticOptions: { filter_instagram: true } } } }),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => {
					return response.json();
				})
				.then(() => {
					this.setState({ buttonLoading: -1 });
					window.location.reload();
				})
				.catch((e) => {
					console.error(e);
					this.setState({ error: true });
				});
		});
	};
	disconnect = (props) => async () => {
		this.setState({ buttonLoading: props.id });
		await post('/messenger/register/insta/disconnect', { page_id: props.id, page_access_token: props.token }).then((response) => {
			this.setState({ buttonLoading: -1 });
			window.location.reload();
		});
	};
	render() {
		/* console.log('sss', this.state.connectedPages, this.state.list); */
		const { t } = this.props;
		let { status, accounts, connectedPages, error_description } = this.state;
		//let { error } = this.state;
		if (this.state.loading)
			return (
				<div style={{ textAlign: 'center' }}>
					<Icon name="asterisk" loading></Icon>
					<p>{t('loading')}</p>
				</div>
			);
		return (
			<div>
				<div style={{ textAlign: 'end', marginBottom: '10px' }}>
					<Button color="blue" target="_blank" as={'a'} href={redirect_url}>
						{'Connect Page With Instagram'}
					</Button>
				</div>
				<div style={{ textAlign: 'end' }}>
					{error_description && (
						<Message error style={{ textAlign: 'center' }}>
							{error_description}
						</Message>
					)}
					{accounts && accounts.length > 0 && (
						<Table>
							{_.map(
								accounts,
								(it, i) =>
									!_.find(this.state.connectedPages, { shop: it.id }) && (
										<Table.Row key={'insta-' + i}>
											<Table.Cell key={'insta-cell-' + i} width={12}>
												<a href={'https://m.me/' + it.id} target="_blank" rel="noopener noreferrer">
													{it.name}
												</a>
											</Table.Cell>
											<Table.Cell key={'insta-cell2-' + i} style={{ textAlign: 'end' }}>
												<Button
													color={connectedPages ? (!_.find(this.state.connectedPages, { shop: it.id }) ? 'green' : 'red') : 'green'}
													loading={this.state.buttonLoading === it.id}
													onClick={this.connect({ id: it.id, token: it.access_token })}
												>
													{!_.find(this.state.connectedPages, { shop: it.id }) ? t('connect') : t('disconnect')}
												</Button>
											</Table.Cell>
										</Table.Row>
									)
							)}
						</Table>
					)}
				</div>
			</div>
		);
	}
}

class FacebookMessenger extends Component {
	state = {
		loading: false,
		activeStep: 0,
		result_data: {},
		error_description: null,
		check: false,
	};
	componentDidMount() {
		this.getList();
		post('/messenger/register/integrationCount/instagram').then((data) => this.setState({ connectedCount: data.data.result }));
	}

	connect = (props) => async () => {
		this.setState({ buttonLoading: props.id });
		await post('/messenger/register/insta/connect', { page_id: props.id, page_access_token: props.token }).then((response) => {
			fetch('/api/dahi/channel/save', {
				method: 'POST',
				body: JSON.stringify({ channel: 'live', data: { set: { staticOptions: { filter_instagram: true } } } }),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => {
					return response.json();
				})
				.then(() => {
					this.setState({ buttonLoading: -1 });
					window.location.reload();
				})
				.catch((e) => {
					console.error(e);
					this.setState({ error: true });
				});
		});
	};

	lookAtQueries = async () => {
		let { connectedpages } = this.state;
		let { location } = this.props;
		let { hash } = location;
		const params = new Proxy(new URLSearchParams(hash), {
			get: (searchParams, prop) => searchParams.get(prop),
		});

		if (params['#access_token']) {
			let access_token = params['#access_token'];
			let data_access_expiration_time = params['#data_access_expiration_time'];
			let expires_in = params['#expires_in'];

			this.setState({ loading: true });
			let rest = await fetch(
				`https://graph.facebook.com/v19.0/me/accounts?access_token=${access_token}&fields=id%2Cname%2Caccess_token%2Cinstagram_business_account`,
				{
					method: 'GET',
				}
			).then((data) => data.json());
			if (rest && rest.error) {
				this.setState({ status: 'denied', error_description: rest.error.message });
			}
			let result_data = { access_token, data_access_expiration_time, expires_in };
			if ((connectedpages && connectedpages.length == 0) || !connectedpages || connectedpages == undefined) {
				this.setState({ check: true });
			}
			this.setState({ status: 'success', accounts: rest.data, loading: false, result_data });
		} else if (params['error'] == 'access_denied') {
			this.setState({ status: 'denied', error_description: params['error_description'] });
		} else {
			this.setState({ loading: false });
		}
	};

	getList = () => {
		post('/messenger/register/insta/list', {}).then((data) => {
			let filtered = _.filter(data.data.result, function (it) {
				return it.accessToken;
			});

			if (filtered && filtered.length > 0) {
				this.setState({ connectedpages: filtered, activeStep: 1 });
			} else {
				this.setState({ connectedpages: null, activeStep: 0 });
			}
			this.lookAtQueries();
		});

		// if (apiresponse) this.setState({ connectedPages, loading: false, list: apiresponse.data });
		// else this.setState({ connectedPages, loading: false, error: 'etc...' });
	};
	disconnect = (props) => async () => {
		this.setState({ buttonLoading: props.id });
		await post('/messenger/register/insta/disconnect', { page_id: props.id, page_access_token: props.token }).then((response) => {
			this.setState({ buttonLoading: -1 });
			window.location.reload();
		});
	};
	render() {
		const { t } = this.props;

		let { connectedCount, connectedpages, activeStep, accounts, error_description, check } = this.state;
		/* console.log(this.props); */
		if (this.state.loading)
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="instagram">
						<Page.Loader />
					</MyMenu>
				</Page>
			);

		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/settings`}>
							<Icon name="setting" />
							{t('settings')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />

						<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
							{t('integrations')}
						</Breadcrumb.Section>
						<Breadcrumb.Divider icon="right chevron" />
						<Breadcrumb.Section>Instagram Messenger</Breadcrumb.Section>
					</Breadcrumb>
				}
				extra={
					<div className="ml-auto">
						<AccountProgress></AccountProgress>
					</div>
				}
				flex
			>
				<Prompt
					when={check}
					message={(location, action) => {
						return t('noFoundAccountDetail');
					}}
				/>

				<MyMenu activeItem="instagram">
					<Page.Wrap>
						<Segment>
							<div>
								{connectedCount ? (
									<>
										<Message info style={{ textAlign: 'center' }}>
											{t('connectedCount', { count: connectedCount })}
										</Message>

										{connectedpages && connectedpages.length > 0 ? (
											<>
												<Divider horizontal>{t('connectedPage')}</Divider>
												<Table>
													{_.map(connectedpages, (it) => (
														<Table.Row key={it.shop}>
															<Table.Cell key={it.id} width={12}>
																<a href={'https://m.me/' + it.shop} target="_blank" rel="noopener noreferrer">
																	{(it.data && it.data.connected_instagram_account_name) ||
																		(it.data && it.data.connected_instagram_account_id)}
																</a>
															</Table.Cell>
															<Table.Cell key={it.shop} style={{ textAlign: 'end' }}>
																{/* {console.log('s', _.find(this.state.connectedPages, { accessToken: it.access_token }))} */}

																<Button
																	color={'blue'}
																	icon={'cog'}
																	content={'Extra'}
																	onClick={() =>
																		this.props.history.push(`/settings/instagramsettings/icebreakers/${it.shop}`)
																	}
																/>

																<Button
																	color={'red'}
																	loading={this.state.buttonLoading === it.shop}
																	onClick={this.disconnect({ id: it.shop, token: it.accessToken })}
																>
																	{t('disconnect')}
																</Button>
															</Table.Cell>
														</Table.Row>
													))}
												</Table>
											</>
										) : (
											<Message warning style={{ textAlign: 'center' }}>
												{t('notFoundAccount')}
											</Message>
										)}
									</>
								) : (
									<Message warning style={{ textAlign: 'center' }}>
										{t('notFoundAccount')}
									</Message>
								)}
								<Message info style={{ textAlign: 'center' }}>
									{t('instagramDesc', { title: window.CONFIG.title })}
								</Message>
								<Step.Group widths={4}>
									<Step active={activeStep === 0} completed={activeStep === 1}>
										<Icon name="instagram" />
										<Step.Content>
											<Step.Title>{t('loginInstagram')}</Step.Title>
										</Step.Content>
									</Step>
									<Step active={activeStep === 1}>
										<Icon name="linkify" />
										<Step.Content>
											<Step.Title>{t('connectToInstagramPage')}</Step.Title>
										</Step.Content>
									</Step>
								</Step.Group>
								{!activeStep && (
									<Segment attached className="login">
										<Button color="blue" target="_blank" as={'a'} href={redirect_url}>
											{t('loginWithInstagram')}
										</Button>
									</Segment>
								)}
								{activeStep ? (
									<PageSeceltor
										t={t}
										accounts={accounts}
										result_data={this.state.result_data}
										connectedpages={connectedpages}
										error_description={error_description}
									/>
								) : (
									<>
										{accounts && accounts.length > 0 ? (
											<Table>
												{_.map(accounts, (it, i) => (
													<Table.Row key={'insta-' + i}>
														<Table.Cell key={'insta-cell-' + i} width={12}>
															<a href={'https://m.me/' + it.id} target="_blank" rel="noopener noreferrer">
																{it.name}
															</a>
														</Table.Cell>
														<Table.Cell key={'insta-cell2-' + i} style={{ textAlign: 'end' }}>
															<Button
																color={'green'}
																loading={this.state.buttonLoading === it.id}
																onClick={this.connect({ id: it.id, token: it.access_token })}
															>
																{!_.find(this.state.connectedPages, { shop: it.id }) ? t('connect') : t('disconnect')}
															</Button>
														</Table.Cell>
													</Table.Row>
												))}
											</Table>
										) : null}
									</>
								)}
							</div>
							{/* <div>
								<img
									src="/images/insta_instruction.png"
									alt="/images/insta_instruction.png"
									width="900"
									style={{ display: 'flex', margin: 'auto' }}
								></img>
							</div> */}
						</Segment>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, product: state.product, location: state.router.location });

export default connect(stateToProps)(withTranslation()(FacebookMessenger));
