import React, { Component } from 'react';
import { Grid, Label, Divider, Segment, Container, Header, Icon, Table, Popup, Dropdown, Button } from 'semantic-ui-react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';

const barOptions = {
	scales: {
		xAxes: [{ stacked: false }],
		yAxes: [{ stacked: false, ticks: { min: 0 } }],
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};
const barOptionsStack = {
	scales: {
		xAxes: [{ stacked: true }],
		yAxes: [
			{
				stacked: true,
				ticks: { min: 0 },
				scaleLabel: {
					display: true,
				},
			},
		],
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		filter: (a) => a.yLabel,
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
	legend: {
		display: true,
		labels: { fill: false },
	},
};
const ChartWrap = (props) => (
	<Grid.Column width={props.width}>
		<Header as="h4" attached="top" style={{ display: 'flex' }}>
			<Icon name={props.icon || 'chart bar'} />
			<Header.Content style={{ width: '600px' }}>{props.title}</Header.Content>
			{props.content}
		</Header>
		<Segment attached>{props.children}</Segment>
	</Grid.Column>
);
const CardWrap = (props) => (
	<Grid.Column width={props.width}>
		<Segment textAlign="center" style={{ paddingTop: 60, minHeight: 230, paddingLeft: 8, paddingRight: 8 }} size="massive">
			<Label size="large" color={props.color} attached="top">
				{props.title}
			</Label>
			<div>
				{props.data}
				{props.unit ? <span style={{ fontSize: '10px' }}>{props.unit}</span> : ''}
			</div>
			{props.note ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note}</p> : ''}

			{props.note2 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note2}</p> : ''}
			{props.note3 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note3}</p> : ''}
			{props.note4 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note4}</p> : ''}
			<Divider />
			<div style={{ fontSize: '12px' }}>{props.description}</div>
		</Segment>
	</Grid.Column>
);
const MyDivider = (props) => (
	<Divider horizontal className="my-divider">
		<Header as="h4">
			<Icon name={props.icon} />
			{props.name}
		</Header>
	</Divider>
);
function formatTime(value) {
	const totalSeconds = Math.floor(value / 1000); // Milisaniyeyi saniyeye çevirme
	const hours = Math.floor(totalSeconds / 3600); // Saat hesaplama
	const remainingSeconds = totalSeconds % 3600; // Saat hesabından kalan saniyeler
	const minutes = Math.floor(remainingSeconds / 60); // Dakika hesaplama
	const seconds = remainingSeconds % 60; // Dakika hesabından kalan saniyeler

	return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	let dur = moment.duration(value);
	return dur._data.days * 24 + dur._data.hours + ':' + moment(dur._data.minutes, 'm').format('mm') + ':' + moment(dur._data.seconds, 's').format('ss');
}
class Dashboard extends Component {
	state = {
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		loading: true,
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* '02cc63330cd0808f87c54ad9dd94b74a' || */ this.props.product.live.settings.live.token,
		ecommerce: false,
		direction: null,
		selectedAgent: 'all',
		column: null,
		loadingaverageTotalAfterActivate: true,
		loadingaverageTotalChatTime: true,
		loadingaverageTotalFirstResponse: true,
		loadingratedData: true,
		loadingsharedProductCount: false,
		loadingtemplateTotalData: true,
		loadingtotalClosedData: true,
		loadingtotalConversation: false,
		loadingtotalMessage: true,
		loadingtotalOrderData: true,
		loadingtotalOrderPrice: true,
		loadingtotalTakenByAgent: true,
		loadingtotalUnanswerByAgent: true,
		loadingtotalWaiting: true,
		loadinguniqueUsers: true,
		loadingcontactedData: true,
		loadingwaitingTotalData: true,
		loadingTotalWaitingConv: true,
		loadingGraph: true,
		selectedBarStyle: 'line',
		loadingAgentLastStatus: true,
		loadingforgottenBasketsGeneral: true,
		loadingMomAgentV2: true,
		totalOpenedLoading: true,
		selectedWatsonsGroup: 'all',
		selectedPlatform: 'all',
	};
	async componentDidMount() {
		let select = [];
		let orderFilter,
			timeFilter,
			/* waitingFilter, */
			closedFilter = {};
		let { selectedAgent, pid, selectedPlatform, productAgentList, selectedWatsonsGroup } = this.state;
		let { start_date, end_date } = this.props;
		this.setState({ loadingGraph: true, loading: true });
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		//let start_date_s = moment(start_date).add(-1, 'day').format();
		/* 	if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform'); */
		let showingproductAgentList2;
		if (selectedWatsonsGroup !== 'all') {
			showingproductAgentList2 = _.filter(productAgentList, (it) => it.group === selectedWatsonsGroup);
		} else {
			showingproductAgentList2 = productAgentList;
		}
		let group_agents = _.map(showingproductAgentList2, (it) => it.id);
		if (selectedAgent === 'all') {
			orderFilter = { type: ['orderCreated', 'orderCanceled', 'orderRefunded'], pid: this.state.pid };
			closedFilter = { type: ['closed'], pid: this.state.pid };
			timeFilter = {
				type: ['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate', 'agentFirstResponseTime'],
				pid: this.state.pid,
			};
			if (selectedPlatform !== 'all') {
				_.set(orderFilter, 'platform', selectedPlatform);
				_.set(closedFilter, 'platform', selectedPlatform);
				_.set(timeFilter, 'platform', selectedPlatform);
			} else {
				_.unset(orderFilter, 'platform');
				_.unset(closedFilter, 'platform');
				_.unset(timeFilter, 'platform');
			}
			if (selectedWatsonsGroup !== 'all') {
				_.set(orderFilter, 'agent', group_agents);
				_.set(closedFilter, 'agent', group_agents);
				_.set(timeFilter, 'agent', group_agents);
			} else {
				_.unset(orderFilter, 'agent');
				_.unset(closedFilter, 'agent');
				_.unset(timeFilter, 'agent');
			}
			//waitingFilter = { type: ['activateAgent'], pid: this.state.pid };
		} else {
			orderFilter = { type: ['orderCreated', 'orderCanceled', 'orderRefunded'], pid: this.state.pid, agent: selectedAgent };
			timeFilter = {
				type: ['agentChatTime', 'chatTimeAfterActivate', 'agentFirstResponseTimeAfterActivate', 'agentFirstResponseTime'],
				pid: this.state.pid,
				agent: selectedAgent,
			};
			closedFilter = { type: ['closed'], pid: this.state.pid, agent: selectedAgent };
			if (selectedPlatform !== 'all') {
				_.set(orderFilter, 'platform', selectedPlatform);
				_.set(closedFilter, 'platform', selectedPlatform);
				_.set(timeFilter, 'platform', selectedPlatform);
			} else {
				_.unset(orderFilter, 'platform');
				_.unset(closedFilter, 'platform');
				_.unset(timeFilter, 'platform');
			}
			//waitingFilter = { type: ['activateAgent'], pid: this.state.pid, agent: selectedAgent };
		}
		fetch('https://log.yapaytech.com/live/test/getInfo?mmm', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: orderFilter,
				select,
				group: ['type', 'cid', 'agent', 'day'],
				pairs: {
					agent: 'select5',
					select5: 'select5',
					cid: 'select4',
					platform: 'platform',
				},
				options: {
					sum: 1,
				},
				agg: {
					rows: 'jsonb_agg(lr.*)',
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform from (select *  from live_refined lr where type in ('orderCreated', 'orderCanceled', 'orderRefunded', 'orderSuccessed') and ((day >= '${moment(
					start_date
				).format('YYYY-MM-DD')}' and hour >= ${moment(start_date).format('H')}) and (day <= '${moment(end_date).format(
					'YYYY-MM-DD'
				)}' and hour <=  ${moment(end_date).format('H')} )) and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.type='orderCreated' and lr.type <> 'orderCreated' and lr2.select4=lr.select4) as lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let t_data = [];
				_.map(data.result, (it) => {
					_.map(
						_.orderBy(it.rows, (r) => r.select7),
						(o) => {
							if (o.select7 && o.select2 && !_.find(t_data, { select2: o.select2 }))
								t_data.push({ ...o, cid: it.cid, agent: it.agent, kod: o.select2, id: o.select7, sum: o.total });
							else if (!o.select7 && o.select2 && !(_.find(t_data, { select2: o.select2 }) || _.find(t_data, { select7: o.select2 }))) {
								t_data.push({ ...o, cid: it.cid, agent: it.agent, kod: o.select2, id: o.select7, sum: o.total });
							}
						}
					);
				});
				let orderData = _.filter(t_data, { type: 'orderCreated' });
				let canceledData = _.filter(t_data, { type: 'orderCanceled' });
				let refundedData = _.filter(t_data, { type: 'orderRefunded' });
				this.setState({
					loadingtotalOrderData: false,
					totalOrderLen: orderData && _.sumBy(orderData, 'count'),
					totalOrderData: orderData && orderData.length,
					productperbasket: orderData && orderData.length && (_.sumBy(orderData, 'count') / orderData.length).toFixed(2),
					loadingtotalOrderPrice: false,
					totalOrderPrice: orderData && _.sumBy(orderData, 'sum'),
					totalCancelPrice: canceledData && _.sumBy(canceledData, 'sum'),
					totalRefundPrice: refundedData && _.sumBy(refundedData, 'sum'),
					totalCancelCount: canceledData && _.sumBy(canceledData, 'count'),
					totalRefundCount: refundedData && _.sumBy(refundedData, 'count'),
				});
			});
		fetch('https://micro.yapaytech.com/api/dialogtab/forgottenBaskets/general', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
				start_date,
				end_date,
				page: 0,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let forgottenBasketsGeneral = data.result;
				this.setState({ forgottenBasketsGeneral, loadingforgottenBasketsGeneral: false });
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?closeddata', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: closedFilter,
				select,
				group: ['type', 'agent'],
				pairs: { agent: 'select1', select1: 'select1', platform: 'platform' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.filter(data.result, function (it) {
					return it.agent !== pid;
				});

				this.setState({
					loadingtotalClosedData: false,
					totalClosedData: _.sumBy(filter, 'count') || 0,
					totalAgentClosedData: _.sumBy(onlyAgentCounts, 'count'),
				});
			});
		let filter_1 = {
			type: ['closed'],
			pid: this.state.pid,
		};
		if (selectedPlatform !== 'all') {
			_.set(filter_1, 'platform', selectedPlatform);
		} else {
			_.unset(filter_1, 'platform');
		}
		console.log('selectedWatsonsGroup', selectedWatsonsGroup);
		if (selectedWatsonsGroup !== 'all') {
			_.set(filter_1, 'agent', group_agents);
		} else {
			_.unset(filter_1, 'agent');
		}
		await fetch('https://log.yapaytech.com/live/test/getInfo?closed', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter_1,
				select: [],
				group: ['type', 'cid', 'platform', 'topic'],
				pairs: { agent: 'select1', cid: 'select4', platform: 'platform', topic: 'select2', select1: 'select1' },
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					autoClosedData: data && data.result && _.filter(data.result, { topic: 'auto_close' }),
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter_1,
				select,
				group: ['type', 'agent', 'day', 'hour'],
				pairs: { agent: 'select1', platform: 'platform', select1: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.groupBy(
					_.filter(data.result, function (it) {
						return it.agent !== pid && it.type === 'closed';
					}),
					'dayNumber'
				);

				this.setState({
					loadingtotalClosedDataHourly: false,
					totalClosedDataHourly: _.sumBy(filter, 'count') || 0,
					totalAgentClosedDataHourly: onlyAgentCounts,
				});
			});
		let filter_2 = {
			type: ['contacted'],
			pid: this.state.pid,
		};
		if (selectedPlatform !== 'all') {
			_.set(filter_2, 'platform', selectedPlatform);
		} else {
			_.unset(filter_2, 'platform');
		}
		if (selectedWatsonsGroup !== 'all') {
			_.set(filter_2, 'agent', group_agents);
		} else {
			_.unset(filter_2, 'agent');
		}
		fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter_2,
				select,
				group: ['type', 'agent', 'day', 'hour'],
				pairs: { agent: 'select1', platform: 'platform', select1: 'select1' },
				options: {
					cids: 'count(distinct select2)',
				},
				from: `(select lr.* from (select cid from live_event le where pid = '${this.state.pid}' and created_at between '${moment(start_date).format(
					'YYYY-MM-DD'
				)}' and '${moment(end_date).add('1', 'd').format('YYYY-MM-DD')}' and label='contacted' intersect select cid from live_event le2 where pid = '${
					this.state.pid
				}' and created_at between '${moment(start_date).format('YYYY-MM-DD')}' and '${moment(end_date)
					.add('1', 'd')
					.format('YYYY-MM-DD')}' and label='activateAgent') le left join live_refined lr on lr.select2 =le.cid) lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.dayNumber = moment(it.day).dayOfYear());
				});
				_.each(data.result, function (it) {
					return (it.count = parseInt(it.cids));
				});
				let contactedDataHourly = _.groupBy(
					_.filter(data.result, function (it) {
						return it.type === 'contacted';
					}),
					'dayNumber'
				);
				this.setState({
					contactedDataHourly,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?dayHho', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter_1,
				select,
				group: ['type', 'agent', 'hour'],
				pairs: { agent: 'select1', platform: 'platform', select1: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let filter = _.filter(data.result, function (it) {
					return it.agent !== '5e6f519560b2b4d07559fcf8';
				});
				let onlyAgentCounts = _.groupBy(
					_.filter(data.result, function (it) {
						return it.agent !== pid && it.type === 'closed';
					}),
					'hour'
				);
				this.setState({
					loadingtotalClosedDataDayHourly: false,
					totalClosedDataDayHourly: _.sumBy(filter, 'count') || 0,
					totalAgentClosedDataDayHourly: onlyAgentCounts,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?dayCon', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter_2,
				select,
				group: ['type', 'agent', 'hour'],
				pairs: { agent: 'select1', platform: 'platform', select1: 'select1' },
				options: {
					cids: 'count(distinct select2)',
				},
				from: `(select lr.* from (select cid from live_event le where pid = '${this.state.pid}' and created_at between '${moment(start_date).format(
					'YYYY-MM-DD'
				)}' and '${moment(end_date).add('1', 'd').format('YYYY-MM-DD')}' and label='contacted' intersect select cid from live_event le2 where pid = '${
					this.state.pid
				}' and created_at between '${moment(start_date).format('YYYY-MM-DD')}' and '${moment(end_date)
					.add('1', 'd')
					.format('YYYY-MM-DD')}' and label='activateAgent') le left join live_refined lr on lr.select2 =le.cid) lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				_.each(data.result, function (it) {
					return (it.count = parseInt(it.cids));
				});
				let contactedDataDayHourly = _.groupBy(
					_.filter(data.result, function (it) {
						return it.type === 'contacted';
					}),
					'hour'
				);
				this.setState({
					contactedDataDayHourly,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo?sss', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: timeFilter,
				select,
				group: ['type'],
				pairs: { agent: 'select1', platform: 'platform', select1: 'select1' },
				options: { sum: 1, avg: 1 },
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				function filter(value) {
					return (
						(data.result &&
							_.find(data.result, { type: value }) &&
							_.find(data.result, { type: value }).avg &&
							formatTime(_.find(data.result, { type: value }).avg)) ||
						0
					);
				}
				function filterTotal(value) {
					return (
						(data.result &&
							_.find(data.result, { type: value }) &&
							_.find(data.result, { type: value }).sum &&
							formatTime(_.find(data.result, { type: value }).sum)) ||
						0
					);
				}
				this.setState({
					loadingaverageTotalChatTime: false,
					loadingaverageTotalAfterActivate: false,
					loadingaverageTotalFirstResponse: false,
					averageTotalChatTime: filter('agentChatTime'),
					averageTotalAfterActivate: filter('chatTimeAfterActivate'),
					averageTotalFirstResponse: filter('agentFirstResponseTimeAfterActivate'),
					averageTotalFirstAgentResponse: filter('agentFirstResponseTime'),
					totalTotalChatTime: filterTotal('agentChatTime'),
					totalTotalAfterActivate: filterTotal('chatTimeAfterActivate'),
					totalTotalFirstResponse: filterTotal('agentFirstResponseTimeAfterActivate'),
					totalTotalFirstAgentResponse: filterTotal('agentFirstResponseTime'),
				});
			});
		let filter_3 = {
			pid: this.state.pid,
		};
		if (selectedPlatform !== 'all') {
			_.set(filter_3, 'platform', selectedPlatform);
		} else {
			_.unset(filter_3, 'platform');
		}
		fetch('https://log.yapaytech.com/live/getEventInfoGeneral?filtertest', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: filter_3,
				labels: ['opened', 'activateAgent', 'firstUserMessage'],
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let actived_list = _.filter(data.result.result, { label: 'activateAgent' });
				let opened_list = _.filter(data.result.result, { label: 'opened' });
				let firstuser_list = _.filter(data.result.result, { label: 'firstUserMessage' });
				this.setState({
					totalWaitingConv: data.result.total,
					totalWaitingConvHourly: actived_list,
					totalOpenedConv: data.result.o_total,
					totalOpenedConvHourly: opened_list,
					totalFirstMessageConv: data.result.f_total,
					totalFirstMessageConvHourly: firstuser_list,
					totalOpenedLoading: false,
				});
			});
		let filter_4 = {
			start_date: moment(start_date).valueOf(),
			end_date: moment(end_date).valueOf(),
			pid: this.state.pid,
		};
		if (selectedPlatform !== 'all') {
			_.set(filter_4, 'platform', selectedPlatform);
		} else {
			_.unset(filter_4, 'platform');
		}
		/* if (selectedWatsonsGroup !== 'all') {
			_.set(filter_4, 'cv_group', selectedWatsonsGroup);
		} else {
			_.unset(filter_4, 'cv_group');
		} */
		fetch('https://log.yapaytech.com/live/getBotForwardInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(filter_4),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				let total = _.sumBy(result, (item) => Number(item.total));
				this.setState({
					totalWaitingcConv: total,
					loadingTotalWaitingConv: false,
				});
			});
		fetch('https://log.yapaytech.com/live/getEventInfoGeneralByHour', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: filter_3,
				labels: ['opened', 'activateAgent', 'firstUserMessage'],
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let actived_list = _.filter(data.result.result, { label: 'activateAgent' });
				let opened_list = _.filter(data.result.result, { label: 'opened' });
				let firstuser_list = _.filter(data.result.result, { label: 'firstUserMessage' });
				this.setState({
					totalWaitingConvDayHourly: actived_list,
					totalOpenedConvDayHourly: opened_list,
					totalFirstMessageConvDayHourly: firstuser_list,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter_1,
				select: [],
				group: ['type', 'rate'],
				pairs: { rate: 'select5', platform: 'platform', agent: 'select1', select1: 'select1' },
				options: {},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingratedData: false,
					ratedData:
						data.result &&
						_.filter(data.result, function (o) {
							return o.rate;
						}),
				});
			});

		fetch('https://log.yapaytech.com/live/test/getInfo?contacted', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter: filter_2,
				select: [],
				group: ['type', 'cid'],
				pairs: { agent: 'select1', select1: 'select1', cid: 'select2' },
				options: {
					cids: 'count(distinct select2)',
				},
				from: `(select lr.* from (select cid from live_event le where pid = '${this.state.pid}' and created_at between '${moment(start_date).format(
					'YYYY-MM-DD'
				)}' and '${moment(end_date).add('1', 'd').format('YYYY-MM-DD')}' and label='contacted' intersect select cid from live_event le2 where pid = '${
					this.state.pid
				}' and created_at between '${moment(start_date).format('YYYY-MM-DD')}' and '${moment(end_date)
					.add('1', 'd')
					.format('YYYY-MM-DD')}' and label='activateAgent') le left join live_refined lr on lr.select2 =le.cid) lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingcontactedData: false,
					contactedDataList: data && data.result && data.result,
					contactedData: (data && data.result && data.result.length) || 0,
				});
			});
		/* fetch('https://live.dahi.ai/analytics/dialogtab/messageCounts', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingtotalMessage: false,
					totalMessage: (data.data && data.data[0] && data.data[0].is_human) || 0,
					totalSendMessage: (data.data && data.data[0] && data.data[0].is_agent) || 0,
					totalComeMessage: (data.data && data.data[0] && data.data[0].is_user) || 0,
				});
			}); */
		/* 		fetch('https://live.dahi.ai/analytics/dialogtab/uniqueUserCountsByMessage', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadinguniqueUsers: false,
					uniqueUsers: data && data.data,
				});
			}); */

		fetch('https://live.dahi.ai/analytics/dialogtab/waitingCounts', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingtotalWaiting: false,
					totalWaiting: data.data,
				});
			});
		/* fetch('https://live.dahi.ai/analytics/statusInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					loadingtotalConversation: false,
					totalConversation:
						(data.data &&
							data.data.general &&
							_.find(data.data.general, { title: 'Toplam' }) &&
							_.find(data.data.general, { title: 'Toplam' }).count) ||
						0,
				});
			}); */
		fetch('https://live.dahi.ai/message/getStateCounts/' + this.state.pid, {
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					totalTakenByAgent: _.sumBy(data.result.agents, 'count') || 0,
					loadingtotalTakenByAgent: false,
					totalUnanswerByAgent: _.sumBy(data.result.agents, 'unanswer') || 0,
					momAgentData: data.result.agents,
					loadingtotalUnanswerByAgent: false,
					loadingGraph: false,
					loadinguniqueUsersHourly: false,
				});
			});
		fetch('https://live.dahi.ai/analytics/dialogtab/unansweredResult', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					momAgentData_v2: data.data,
					loadingMomAgentV2: false,
				});
			});
		/* fetch('https://live.dahi.ai/analytics/sharedProductCount', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					sharedProductCount: data.data && data.data.byAgent,
					loadingsharedProductCount: false,
				});
			}); */
		await fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					ecommerce: data.result && data.result.ecommerce,
					sendProduct: data.result && data.result.sendProduct,
					ratePoint: data.result && data.result.ratePoint,
					showTemplate: data.result && data.result.showTemplate,
					phone_list: data.result && data.result.phone_list,
					chat_groups: data.result && data.result.staticOptions && data.result.staticOptions.groups,
				});
			});
		fetch('https://log.yapaytech.com/live/getEventInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				filter: {
					label: 'template',
					pid: this.state.pid,
				},
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ templateTotalData: data && data.result && data.result.total, loadingtemplateTotalData: false });
			});
		fetch('https://log.yapaytech.com/live/getWaitingInfo1', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
				select: '*',
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data && data.result && data.result.result;
				let ne_result = _.map(result, (it) => {
					let selected = _.find(it, { label: 'activateAgent' }) || it[0];
					return {
						cid: selected.cid,
						count: null,
						created_at: selected.created_at,
						label: 'activateAgent',
						platform: selected.platform,
					};
				});
				//console.log('ne_result.length', ne_result, ne_result.length);
				let f_result = _.flatten(result);
				let group_by_cv_phone = _.groupBy(f_result, (it) => it && it.extra && it.extra.cv_phone_id);
				this.setState({
					waitingTotalDataList: ne_result,
					waitingTotalData: ne_result.length,
					loadingwaitingTotalData: false,
					group_by_cv_phone,
					loading: false,
				});
			});
		/* fetch('https://live.dahi.ai/analytics/dialogtab/uniqueUserCountsByMessage', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: moment(start_date).valueOf(),
				end_date: moment(end_date).valueOf(),
				pid: this.state.pid,
				hourly: true,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ loadingGraph: false, loadinguniqueUsersHourly: false, uniqueUsersHourly: data && data.data });
			}); */
		fetch('https://log.yapaytech.com/live/test/getLastAgentStatuses', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ loadingAgentLastStatus: false, agentLastStatus: data.result });
			});
	}
	async getMomentData() {
		this.setState({ btnMomentData: true, loadingAgentLastStatus: true });
		fetch('https://live.dahi.ai/message/getStateCounts/' + this.state.pid, {
			method: 'get',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					momAgentData: data.result.agents,
					btnMomentData: false,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getLastAgentStatuses', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ loadingAgentLastStatus: false, agentLastStatus: data.result });
			});
	}
	async getMomentDataV2() {
		this.setState({ loadingMomAgentV2: true, loadingAgentLastStatus: true });
		fetch('https://live.dahi.ai/analytics/dialogtab/unansweredResult', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				//console.log('momAgentData_v2', data.data);
				this.setState({
					momAgentData_v2: data.data,
					loadingMomAgentV2: false,
				});
			});
		fetch('https://log.yapaytech.com/live/test/getLastAgentStatuses', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ loadingAgentLastStatus: false, agentLastStatus: data.result });
			});
	}
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = [/* 'start_date', 'end_date', */ 'value', 'selectedPlatform', 'newDataToggle', 'selectedWatsonsGroup'];

		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (/* key === 'start_date' || key === 'end_date' */ key === 'newDataToggle') {
				if (this.props[key] !== oldProps[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.componentDidMount(), 3000);
					break;
				}
			}
			if (!_.isArray(this.state[key])) {
				if (this.state[key] !== oldState[key]) {
					window.clearTimeout(timer);
					timer = setTimeout(() => this.componentDidMount(), 3000);
					break;
				}
			} else if (!_.isEqual(this.state[key], oldState[key])) {
				window.clearTimeout(timer);
				timer = setTimeout(() => this.componentDidMount(), 3000);
				break;
			}
		}
	}
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	loadingText(text, load, title, popup) {
		if (load) {
			return title ? (
				<p>
					{title + ': '}
					<Icon name="spinner" loading></Icon>
				</p>
			) : (
				<Icon name="spinner" loading></Icon>
			);
		}

		return title ? (
			<p>
				{popup && <Popup trigger={<Icon name="question circle outline" />} content={popup} size="mini" position={'bottom center'} />}{' '}
				{title + ': ' + text || 0}
			</p>
		) : (
			text || 0
		);
	}

	render() {
		const { start_date, end_date, platformList } = this.props;
		let {
			totalOrderData,
			//totalOrderLen,
			//totalMessage,
			totalConversation,
			totalTakenByAgent,
			totalUnanswerByAgent,
			totalWaiting,
			totalOrderPrice,
			totalCancelPrice,
			totalRefundPrice,
			totalCancelCount,
			totalRefundCount,
			sharedProductCount,
			ratedData,
			averageTotalChatTime,
			averageTotalFirstResponse,
			averageTotalAfterActivate,
			averageTotalFirstAgentResponse,
			totalTotalFirstAgentResponse,
			//totalTotalChatTime,
			//totalTotalFirstResponse,
			totalTotalAfterActivate,
			totalClosedData,
			templateTotalData,
			//uniqueUsers,
			uniqueUsersHourly,
			loadingaverageTotalAfterActivate,
			loadingaverageTotalChatTime,
			loadingaverageTotalFirstResponse,
			loadingratedData,
			loadingsharedProductCount,
			loadingtemplateTotalData,
			loadingtotalClosedData,
			loadingtotalConversation,
			//loadingtotalMessage,
			loadingtotalOrderData,
			//loadingtotalOrderPrice,
			loadingtotalTakenByAgent,
			loadingtotalUnanswerByAgent,
			loadingtotalWaiting,
			//loadinguniqueUsers,
			//totalSendMessage,
			//totalComeMessage,
			//totalOrderLen,
			productperbasket,
			waitingTotalData,
			waitingTotalDataList,
			loadingwaitingTotalData,
			loadingcontactedData,
			contactedData,
			contactedDataList,
			contactedDataHourly,
			loadingTotalWaitingConv,
			//totalWaitingConv,
			totalAgentClosedData,
			totalAgentClosedDataHourly,
			loadingGraph,
			selectedBarStyle,
			totalWaitingcConv,
			totalWaitingConvHourly,
			totalOpenedConvHourly,
			totalFirstMessageConvHourly,
			totalAgentClosedDataDayHourly,
			contactedDataDayHourly,
			totalWaitingConvDayHourly,
			totalOpenedConvDayHourly,
			totalFirstMessageConvDayHourly,
			momAgentData,
			momAgentData_v2,
			productAgentList,
			agentLastStatus,
			loadingAgentLastStatus,
			forgottenBasketsGeneral,
			loadingforgottenBasketsGeneral,
			loadingMomAgentV2,
			totalOpenedLoading,
			totalOpenedConv,
			totalFirstMessageConv,
			autoClosedData,
			phone_list,
			group_by_cv_phone,
			chat_groups,
			selectedWatsonsGroup,
			selectedPlatform,
		} = this.state;
		const { t, product } = this.props;
		let ecommerce, ratePoint, sendProduct, showTemplate, generalTable, generalTable_v2;
		let currency = (this.props.product && this.props.product.live && this.props.product.live.settings.currency) || 'try';
		if (this.props.product.live.settings.analyticView) {
			ecommerce = this.props.product.live.settings.analyticView.ecommerce;
			sendProduct = this.props.product.live.settings.analyticView.sendProduct;
			//conversationGroup = this.props.product.live.settings.analyticView.conversationGroup;
			showTemplate = this.props.product.live.settings.analyticView.showTemplate;
			ratePoint = this.props.product.live.settings.analyticView.ratePoint;
			generalTable = this.props.product.live.settings.analyticView.generalTable;
			generalTable_v2 = this.props.product.live.settings.analyticView.generalTable_v2;
			//userlist = this.props.product.live.settings.analyticView.userlist;
		}
		let watsons_groups = [
			{
				text: t('allGroups'),
				value: 'all',
				id: 'all',
				key: 'all',
			},
		];
		if (chat_groups && chat_groups.length) {
			_.each(chat_groups, (it) =>
				watsons_groups.push({
					text: it.label,
					value: it.id,
					id: it.id,
					key: it.id,
				})
			);
		}
		function showingBar(data, barStyle) {
			switch (barStyle) {
				case 'line':
					return <Line data={data} options={barOptions} />;
				case 'bar':
					return <Bar data={data} options={barOptions} />;
				case 'stackbar':
					return <Bar data={data} options={barOptionsStack} />;
				case 'radar':
					return <Radar data={data} />;
				default:
					return <Line data={data} options={barOptions} />;
			}
		}
		let barStyleDropdown = [
			{
				text: t('Line'),
				value: 'line',
			},
			{
				text: t('Bar'),
				value: 'bar',
			},
			{
				text: t('StackBar'),
				value: 'stackbar',
			},
		];
		let showingproductAgentList2;
		if (selectedWatsonsGroup !== 'all') {
			showingproductAgentList2 = _.filter(productAgentList, (it) => it.group === selectedWatsonsGroup);
		} else {
			showingproductAgentList2 = productAgentList;
		}
		if (this.state.loading)
			return (
				<Container>
					<Segment>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		let sellingClosed = totalOrderData && totalClosedData ? ((100 * totalOrderData) / totalClosedData).toFixed(2) + ' %' : '0';
		let sellingConversation = totalOrderData && totalConversation ? ((100 * totalOrderData) / totalConversation).toFixed(2) + ' %' : '0';
		let totalSharedProduct = (sharedProductCount && _.sumBy(sharedProductCount, 'total')) || 0;
		let priceperbasket = (totalOrderData && totalOrderPrice / totalOrderData) || 0;
		//console.log('totalOrderPrice', totalOrderPrice, totalOrderData);
		let ratedTotalData,
			averageRateData = [];

		//let dontAnswer = waitingTotalData - contactedData < 0 ? 0 : waitingTotalData - contactedData;
		let missing_list = _.differenceBy(waitingTotalDataList, contactedDataList, 'cid');
		missing_list = _.filter(missing_list, (it) => {
			if (_.find(autoClosedData, { cid: it.cid })) {
				return true;
			}
		});
		let dontAnswer = missing_list.length || 0;
		//console.log('waitingTotalData', missing_list, missing_list.length, waitingTotalDataList, contactedDataList);
		let data = [],
			dataDayHourly = [];
		if (generalTable) {
			data = chartData(
				[], //uniqueUsersHourly,
				contactedDataHourly,
				totalAgentClosedDataHourly,
				totalWaitingConvHourly,
				totalOpenedConvHourly,
				totalFirstMessageConvHourly,
				start_date,
				end_date,
				t,
				missing_list
			);
			dataDayHourly = chartDataDayHourly(
				totalWaitingConvDayHourly,
				contactedDataDayHourly,
				totalAgentClosedDataDayHourly,
				totalOpenedConvDayHourly,
				totalFirstMessageConvDayHourly,
				start_date,
				end_date,
				t,
				missing_list
			);
		}
		let ciro = totalOrderPrice - (totalCancelPrice || 0) - (totalRefundPrice || 0);
		let total_order_with_forgotten = ((forgottenBasketsGeneral && forgottenBasketsGeneral.userTotal) || 0) + totalOrderData;
		let rate_of_order = total_order_with_forgotten ? ((totalOrderData / total_order_with_forgotten) * 100).toFixed(2) + ' %' : 0;
		if (ratedData) {
			ratedTotalData = _.map(ratedData, function (it) {
				return {
					count: it.count,
					rate: it.rate,
					totalRate: it.count * it.rate,
				};
			});
			averageRateData = {
				count: _.sumBy(ratedTotalData, 'count'),
				totalRage: _.sumBy(ratedTotalData, 'totalRate'),
				average: (_.sumBy(ratedTotalData, 'count') && (_.sumBy(ratedTotalData, 'totalRate') / _.sumBy(ratedTotalData, 'count')).toFixed(2)) || 0,
			};
		}
		return (
			<Container>
				<Segment>
					<Grid stackable columns={2} style={{ width: '100%', margin: 0 }}>
						<Dropdown
							selection
							options={platformList}
							value={selectedPlatform}
							floating
							labeled
							button
							icon="filter"
							className="icon"
							name="selectedPlatform"
							onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
						/>
						{product &&
						product.live &&
						product.live.settings &&
						product.live.settings.analyticView &&
						product.live.settings.analyticView.watsonsgroups ? (
							<Dropdown
								selection
								options={watsons_groups}
								value={selectedWatsonsGroup}
								floating
								labeled
								button
								icon="filter"
								className="icon"
								name="selectedWatsonsGroup"
								onChange={(e, { name, value }) => this.setState({ selectedWatsonsGroup: value })}
							/>
						) : (
							''
						)}
						<MyDivider name={t('conversation')} icon="conversation"></MyDivider>
						<CardWrap
							width={4}
							title={t('totalUser')}
							color="red"
							data={this.loadingText(totalWaitingcConv, loadingwaitingTotalData)}
							description={t('totalWaitingAllPopupC')}
						/>
						{/* note={this.loadingText(uniqueUsers, loadinguniqueUsers, t('totalUserTitle'), t('totalUserDesc'))} */}
						{/* <CardWrap
							width={4}
							title={t('totalMessage')}
							color="orange"
							data={this.loadingText(totalMessage, loadingtotalMessage)}
							note={this.loadingText(totalSendMessage, loadingtotalMessage, t('totalSendingMessage'), t('totalSendingMessagePopup'))}
							note2={this.loadingText(totalComeMessage, loadingtotalMessage, t('totalComingMessage'), t('totalComingMessagePopup'))}
							description={t('totalMessageDesc')}
						/> */}
						<CardWrap
							width={4}
							title={t('totalWaiting')}
							color="green"
							data={this.loadingText(totalWaiting, loadingtotalWaiting)}
							description={t('totalWaitingDesc')}
						/>
						<CardWrap
							width={4}
							title={t('totalTakingAgent')}
							color="blue"
							data={this.loadingText(totalTakenByAgent, loadingtotalTakenByAgent)}
							description={t('totalTakingAgentDesc')}
							note={this.loadingText(
								totalUnanswerByAgent,
								loadingtotalUnanswerByAgent,
								t('unanswerConversation'),
								t('unanswerConversationPopup')
							)}
						/>
						{ratePoint ? (
							<>
								<CardWrap
									width={4}
									title={t('averageRatePoint')}
									color="red"
									data={this.loadingText(averageRateData.average, loadingratedData)}
									note={this.loadingText(averageRateData.count, loadingratedData, t('averageRatePointNote'), t('averageRatePointNotePopup'))}
									description={t('averageRatePointDesc')}
								/>
							</>
						) : (
							''
						)}
						<CardWrap
							width={4}
							title={t('averageChatTime')}
							color="orange"
							data={this.loadingText(averageTotalChatTime, loadingaverageTotalChatTime)}
							note={this.loadingText(
								averageTotalAfterActivate,
								loadingaverageTotalAfterActivate,
								t('averageChatTimeNote1'),
								t('averageChatTimeNote1Popup')
							)}
							note2={this.loadingText(
								averageTotalFirstResponse,
								loadingaverageTotalFirstResponse,
								t('averageChatTimeNote2'),
								t('averageChatTimeNote2Popup')
							)}
							note3={this.loadingText(
								averageTotalFirstAgentResponse,
								loadingaverageTotalFirstResponse,
								t('firstResponse'),
								t('firstResponseDesc')
							)}
							description={t('averageChatTimeDesc')}
						/>
						<CardWrap
							width={4}
							title={t('totalAgentClosed')}
							color="yellow"
							note2={this.loadingText(totalClosedData, loadingtotalClosedData, t('totalClosed'), t('totalClosedBetween'))}
							/* note={this.loadingText(totalConversation, loadingtotalConversation, t('totalConversation'), t('totalConversationPopup'))} */
							data={this.loadingText(totalAgentClosedData, loadingtotalClosedData)}
							description={t('totalClosedPopup')}
						/>
						{showTemplate ? (
							<>
								<CardWrap
									width={4}
									title={t('templateMessage')}
									color="green"
									data={this.loadingText(templateTotalData, loadingtemplateTotalData)}
									description={t('templateMessageDesc')}
								/>
							</>
						) : (
							''
						)}
						{phone_list && <MyDivider name={'Bekleyen Tablosu'} icon="whatsapp"></MyDivider>}
						{phone_list && (
							<Table className="dashboard-table">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{'Telefon'}</Table.HeaderCell>
										<Table.HeaderCell>{'Toplam Konuşma'}</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(group_by_cv_phone, (it, i) => {
										let _phone = _.find(phone_list, { id: i });
										return (
											<Table.Row key={i}>
												<Table.Cell>{_phone.name || i}</Table.Cell>
												<Table.Cell>{it.length || 0}</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						)}
						{generalTable_v2 && <MyDivider name={t('momentaryAgentStatus')} icon="users"></MyDivider>}
						{generalTable_v2 && (
							<Table className="dashboard-table">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{t('agent')}</Table.HeaderCell>
										<Table.HeaderCell>{t('onAgent')}</Table.HeaderCell>
										<Table.HeaderCell>{t('unanswer')}</Table.HeaderCell>
										<Table.HeaderCell>{t('status')}</Table.HeaderCell>
										<Table.HeaderCell width={1}>
											<Button icon basic onClick={() => this.getMomentDataV2()} loading={loadingMomAgentV2} disabled={loadingMomAgentV2}>
												<Icon name="redo"></Icon>
											</Button>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(showingproductAgentList2, (it, i) => {
										let data = _.find(momAgentData_v2, { _id: it.id });
										let displayName_2 = this.props.displayName === 'name' ? 'fullname' : 'developer';
										let last_status = _.find(agentLastStatus, { uid: it.id }) || { label: 'offline' };
										let colors = {
											online: '#4ed34e',
											offline: '#d34e4e',
											unknown: '#d4d4d5',
											idle_agent: '#F4B400',
										};
										if (loadingMomAgentV2)
											return (
												<Table.Row>
													<Table.Cell>{it[displayName_2] || it.developer}</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading></Icon>
													</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading></Icon>
													</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading={loadingAgentLastStatus}></Icon>
													</Table.Cell>
													<Table.Cell></Table.Cell>
												</Table.Row>
											);
										return (
											<Table.Row key={i}>
												<Table.Cell>{it[displayName_2] || it.developer}</Table.Cell>
												<Table.Cell>{(data && data.total_conversation) || 0}</Table.Cell>
												<Table.Cell>{(data && data.unanswered_conversation) || 0}</Table.Cell>
												<Table.Cell style={{ color: (last_status && colors[last_status.label]) || 'black' }}>
													{last_status && t(last_status.label)}
												</Table.Cell>
												<Table.Cell></Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						)}
						{generalTable && <MyDivider name={t('momentaryAgentStatus')} icon="users"></MyDivider>}
						{generalTable && (
							<Table className="dashboard-table">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{t('agent')}</Table.HeaderCell>
										<Table.HeaderCell>{t('onAgent')}</Table.HeaderCell>
										<Table.HeaderCell>{t('unanswer')}</Table.HeaderCell>
										<Table.HeaderCell>{t('status')}</Table.HeaderCell>
										<Table.HeaderCell width={1}>
											<Button
												icon
												basic
												onClick={() => this.getMomentData()}
												loading={this.state.btnMomentData}
												disabled={this.state.btnMomentData}
											>
												<Icon name="redo"></Icon>
											</Button>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(showingproductAgentList2, (it) => {
										console.log(it, selectedWatsonsGroup);
										let data = _.find(momAgentData, { _id: it.id });
										let displayName_2 = this.props.displayName === 'name' ? 'fullname' : 'developer';
										let last_status = _.find(agentLastStatus, { uid: it.id }) || { label: 'offline' };
										let colors = {
											online: '#4ed34e',
											offline: '#d34e4e',
											unknown: '#d4d4d5',
											idle_agent: '#F4B400',
										};
										if (this.state.btnMomentData)
											return (
												<Table.Row>
													<Table.Cell>{it[displayName_2] || it.developer}</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading></Icon>
													</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading></Icon>
													</Table.Cell>
													<Table.Cell>
														<Icon name="spinner" loading={loadingAgentLastStatus}></Icon>
													</Table.Cell>
													<Table.Cell></Table.Cell>
												</Table.Row>
											);
										return (
											<Table.Row>
												<Table.Cell>{it[displayName_2] || it.developer}</Table.Cell>
												<Table.Cell>{(data && data.count) || 0}</Table.Cell>
												<Table.Cell>{(data && data.unanswer) || 0}</Table.Cell>
												<Table.Cell style={{ color: (last_status && colors[last_status.label]) || 'black' }}>
													{last_status && t(last_status.label)}
												</Table.Cell>
												<Table.Cell></Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						)}
						{generalTable && <MyDivider name={t('generalAgent')} icon="table"></MyDivider>}

						{generalTable ? (
							<Table className="dashboard-table">
								<Table.Body>
									{/* <Table.Row>
										<Table.Cell width={12}>
											{
												<p>
													<Popup
														trigger={<Icon name="question circle outline" />}
														content={t('totalUserPopup')}
														size="mini"
														position={'bottom'}
													/>
													{t('totalUser')}
												</p>
											}
										</Table.Cell>
										<Table.Cell>{this.loadingText(uniqueUsers, loadinguniqueUsers)}</Table.Cell>
									</Table.Row> */}
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalWaitingAllPopupC')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalWaitingAllC')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalWaitingcConv, loadingTotalWaitingConv)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalOpenedAllPopupC')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalOpenedAllC')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalOpenedConv, totalOpenedLoading)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalFirstMessageAllPopupC')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalFirstMessageAllC')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalFirstMessageConv, totalOpenedLoading)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalContactedPopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalContacted')}
											</p>
										</Table.Cell>
										<Table.Cell>
											{this.loadingText(totalWaitingcConv - dontAnswer, loadingwaitingTotalData)}
											{'      '}
											{' (' +
												this.loadingText(
													(((totalWaitingcConv - dontAnswer) / totalWaitingcConv) * 100).toFixed(1) + '%)',
													loadingwaitingTotalData
												)}{' '}
											<Popup
												trigger={<Icon name="question circle outline" />}
												content={t('rateContact')}
												size="mini"
												position={'bottom'}
											/>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('dontConnecttedDescriptionPopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('dontConnecttedDescription')}
											</p>
										</Table.Cell>
										<Table.Cell>
											{this.loadingText(dontAnswer, loadingwaitingTotalData)}{' '}
											{' (' + this.loadingText(((dontAnswer / totalWaitingcConv) * 100).toFixed(1) + '%)', loadingwaitingTotalData)}{' '}
											<Popup
												trigger={<Icon name="question circle outline" />}
												content={t('rateMissing')}
												size="mini"
												position={'bottom'}
											/>
										</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalClosedPopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalClosed')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalAgentClosedData, loadingtotalClosedData)}</Table.Cell>
									</Table.Row>
									{/* <Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('averageTakenTimePopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('averageTakenTime')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalAgentClosedData, loadingaverageTotalChatTime)}</Table.Cell>
									</Table.Row> */}
									{/* <Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('firstMessageTimePopup')}
													size="mini"
													position={'bottom'}
												/>
												{t('firstMessageTime')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalTotalFirstResponse, loadingaverageTotalChatTime)}</Table.Cell>
									</Table.Row> */}
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('averageChatTimeNote2Popup')}
													size="mini"
													position={'bottom'}
												/>
												{t('averageChatTimeNote2')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(averageTotalFirstResponse, loadingaverageTotalChatTime)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('firstResponseDesc')}
													size="mini"
													position={'bottom'}
												/>
												{t('firstResponse')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(averageTotalFirstAgentResponse, loadingaverageTotalChatTime)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('totalChatTimeNote1Popup')}
													size="mini"
													position={'bottom'}
												/>
												{t('totalChatTimeNote1')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(totalTotalAfterActivate, loadingaverageTotalAfterActivate)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell width={12}>
											<p>
												<Popup
													trigger={<Icon name="question circle outline" />}
													content={t('averageChatTimeNote1Popup')}
													size="mini"
													position={'bottom'}
												/>
												{t('averageChatTimeNote1')}
											</p>
										</Table.Cell>
										<Table.Cell>{this.loadingText(averageTotalAfterActivate, loadingaverageTotalAfterActivate)}</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						) : (
							''
						)}
						{/* {generalTable && (
							<ChartWrap
								width={16}
								title={t('graph')}
								content={
									<div style={{ marginLeft: 'auto', display: loadingGraph ? 'none' : 'block' }}>
										<Dropdown
											options={barStyleDropdown}
											value={selectedBarStyle}
											name="selectedBarStyle"
											onChange={this.handleChange}
											style={{ marginRight: '30px' }}
										/>
									</div>
								}
							>
								{loadingGraph ? <Page.Loader /> : showingBar(dataDayHourly, selectedBarStyle)}
							</ChartWrap>
						)} */}
						{/* {generalTable && (
							<ChartWrap
								width={16}
								title={t('graphHourly')}
								content={
									<div style={{ marginLeft: 'auto', display: loadingGraph ? 'none' : 'block' }}>
										<Dropdown
											options={barStyleDropdown}
											value={selectedBarStyle}
											name="selectedBarStyle"
											onChange={this.handleChange}
											style={{ marginRight: '30px' }}
										/>
									</div>
								}
							>
								{loadingGraph ? <Page.Loader /> : showingBar(data, selectedBarStyle)}
							</ChartWrap>
						)} */}

						{ecommerce || sendProduct ? <MyDivider name={t('eCommerce')} icon="shopping cart"></MyDivider> : ''}
						{ecommerce ? (
							<>
								{totalCancelCount || totalRefundCount ? (
									<CardWrap
										width={4}
										title={t('totalSellingNumber')}
										color="green"
										data={this.loadingText(totalOrderData, loadingtotalOrderData)}
										note3={this.loadingText(productperbasket || 0, loadingtotalOrderData, t('productPerBasketCount'))}
										note={this.loadingText(totalCancelCount || 0, loadingtotalOrderData, t('productCanceled'))}
										note2={this.loadingText(totalRefundCount || 0, loadingtotalOrderData, t('productRefunded'))}
										description={t('totalSellingNumberDesc')}
									/>
								) : (
									<CardWrap
										width={4}
										title={t('totalSellingNumber')}
										color="green"
										data={this.loadingText(totalOrderData, loadingtotalOrderData)}
										note3={this.loadingText(productperbasket || 0, loadingtotalOrderData, t('productPerBasketCount'))}
										description={t('totalSellingNumberDesc')}
									/>
								)}

								{totalCancelPrice || totalRefundPrice ? (
									<CardWrap
										width={4}
										title={t('generalSum')}
										color="blue"
										data={this.loadingText(
											(ciro && ciro.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData
										)}
										note={this.loadingText(
											(totalOrderPrice && totalOrderPrice.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData,
											t('totalSellingPrice')
										)}
										note2={this.loadingText(
											(totalCancelPrice && totalCancelPrice.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData,
											t('orderCanceledSum')
										)}
										note3={this.loadingText(
											(totalRefundPrice && totalRefundPrice.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData,
											t('orderRefundedSum')
										)}
										note4={this.loadingText(
											priceperbasket.toLocaleString('tr-TR', { style: 'currency', currency }),
											loadingtotalOrderData,
											t('priceperbasketnote')
										)}
										description={t('totalSellingPriceDesc')}
									/>
								) : (
									<CardWrap
										width={4}
										title={t('generalSum')}
										color="blue"
										data={this.loadingText(
											(ciro && ciro.toLocaleString('tr-TR', { style: 'currency', currency })) || 0,
											loadingtotalOrderData
										)}
										note4={this.loadingText(
											priceperbasket.toLocaleString('tr-TR', { style: 'currency', currency }),
											loadingtotalOrderData,
											t('priceperbasketnote')
										)}
										description={t('totalSellingPriceDesc')}
									/>
								)}

								<CardWrap
									width={4}
									title={t('rateSellingConv')}
									color="yellow"
									data={this.loadingText(sellingClosed, loadingtotalConversation)}
									note={this.loadingText(sellingConversation, loadingtotalConversation, t('totalSellingRate'))}
									description={t('rateSellingConvDesc')}
								/>
								<CardWrap
									width={4}
									title={t('totalOrderRate')}
									color="brown"
									data={this.loadingText(rate_of_order, loadingforgottenBasketsGeneral)}
									description={t('totalOrderRateDesc')}
								/>
								<CardWrap
									width={4}
									title={t('totalPriceForgotten')}
									color="red"
									data={this.loadingText(
										(forgottenBasketsGeneral &&
											forgottenBasketsGeneral.basketTotal &&
											forgottenBasketsGeneral.basketTotal.toLocaleString('tr-TR', { style: 'currency', currency })) ||
											0,
										loadingforgottenBasketsGeneral
									)}
									note={this.loadingText(
										(forgottenBasketsGeneral && forgottenBasketsGeneral.userTotal) || 0,
										loadingforgottenBasketsGeneral,
										t('totalUserForgotten')
									)}
									note2={this.loadingText(
										(forgottenBasketsGeneral && forgottenBasketsGeneral.itemTotal) || 0,
										loadingforgottenBasketsGeneral,
										t('totalItemForgotten')
									)}
									description={t('totalUserForgottenDesc')}
								/>
							</>
						) : (
							''
						)}
						{/* {sendProduct ? (
							<>
								<CardWrap
									width={4}
									title={t('totalSendingProduct')}
									color="purple"
									data={this.loadingText(totalSharedProduct, loadingsharedProductCount)}
									description={t('totalSendingProductDesc')}
								/>
							</>
						) : (
							''
						)} */}
					</Grid>
				</Segment>
			</Container>
		);
	}
}
// date array
var getDateArray = function (start, end) {
	var arr = [],
		dt = new Date(start);

	while (dt <= end) {
		arr.push(new Date(dt));
		dt.setDate(dt.getDate() + 1);
	}

	return arr;
};
var getHourArray = function (start, end) {
	var arr = [],
		dt = 0;

	while (dt < 24) {
		arr.push(dt);
		dt++;
	}
	return arr;
};

function chartData(
	data,
	contactedDataHourly,
	totalAgentClosedDataHourly,
	totalWaitingConvHourly,
	totalOpenedConvHourly,
	totalFirstMessageConvHourly,
	start_date,
	end_date,
	t,
	missing_list
) {
	let startDate = new Date(start_date);
	let endDate = new Date(end_date);
	let labels = [];
	var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	_.map(dateArr, (it) => {
		return _.map(hourArr, (o) => {
			labels.push(moment(it).format('DD/MM') + ' - ' + moment(o, 'H').format('HH'));
		});
	});
	let uniqueUserCountsLineData = [],
		contactedDataLineData = [],
		closedDataLineData = [],
		totalWaitingConvLineData = [],
		totalOpenedConvLineData = [],
		totalFirstMessageConvLineData = [],
		missingDataLineData = [],
		obj,
		contactedDataHourlyObj,
		closedDataHourlyObj,
		totalWaitingConvHourlyObj,
		totalOpenedConvHourlyObj,
		totalFirstMessageConvHourlyObj,
		newMisslingObj;
	_.map(dateArr, (item, i) => {
		return _.map(hourArr, (it) => {
			obj = _.find(data, {
				date: moment(item).format('YYYY-MM-DD'),
				hour: moment(it, 'H').format('HH'),
			});
			newMisslingObj = _.filter(missing_list, (o) => moment(o.created_at).dayOfYear() === moment(item).dayOfYear() && moment(o.created_at).hour() === it);
			totalWaitingConvHourlyObj = _.find(totalWaitingConvHourly, function (o) {
				return moment(o.date).dayOfYear() === moment(item).dayOfYear() && moment(o.date).hour() === it;
			});
			totalOpenedConvHourlyObj = _.find(totalOpenedConvHourly, function (o) {
				return moment(o.date).dayOfYear() === moment(item).dayOfYear() && moment(o.date).hour() === it;
			});
			totalFirstMessageConvHourlyObj = _.find(totalFirstMessageConvHourly, function (o) {
				return moment(o.date).dayOfYear() === moment(item).dayOfYear() && moment(o.date).hour() === it;
			});
			/* if (it >= 0) {
				contactedDataHourlyObj = _.filter(contactedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
				closedDataHourlyObj = _.filter(totalAgentClosedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			} else {
				contactedDataHourlyObj = _.filter(contactedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it + 23,
				});
				closedDataHourlyObj = _.filter(totalAgentClosedDataHourly[moment(item).format('YYYY-MM-DD') + 'T00:00:00.000Z'], {
					hour: it + 23,
				});
			} */
			contactedDataHourlyObj =
				contactedDataHourly &&
				contactedDataHourly[moment(item).dayOfYear()] &&
				_.filter(contactedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			closedDataHourlyObj =
				totalAgentClosedDataHourly &&
				totalAgentClosedDataHourly[moment(item).dayOfYear()] &&
				_.filter(totalAgentClosedDataHourly[moment(item).dayOfYear()], {
					hour: it,
				});
			/* 	console.log('contactedDataHourlyObj', contactedDataHourlyObj); */
			//uniqueUserCountsLineData.push((obj && obj.total_user) || 0);
			contactedDataLineData.push((contactedDataHourlyObj && _.sumBy(contactedDataHourlyObj, 'count')) || 0);
			closedDataLineData.push((closedDataHourlyObj && _.sumBy(closedDataHourlyObj, 'count')) || 0);
			totalWaitingConvLineData.push((totalWaitingConvHourlyObj && totalWaitingConvHourlyObj.cid_count) || 0);
			totalOpenedConvLineData.push((totalOpenedConvHourlyObj && totalOpenedConvHourlyObj.cid_count) || 0);
			totalFirstMessageConvLineData.push((totalFirstMessageConvHourlyObj && totalFirstMessageConvHourlyObj.cid_count) || 0);
			missingDataLineData.push((newMisslingObj && newMisslingObj.length) || 0);
		});
	});
	//let missingDataLineData = _.map(totalWaitingConvLineData, (it, i) => Math.max(it - contactedDataLineData[i], 0));
	let totalWaitingConvLine = {
			label: t('totalWaitingAllC'),
			data: totalWaitingConvLineData,
			borderColor: 'orange',
			backgroundColor: 'orange',
			fill: false,
		},
		totalOpenedConvLine = {
			label: t('totalOpenedAllC'),
			data: totalOpenedConvLineData,
			borderColor: 'pink',
			backgroundColor: 'pink',
			fill: false,
		},
		totalFirstMessageConvLine = {
			label: t('totalFirstMessageAllC'),
			data: totalFirstMessageConvLineData,
			borderColor: 'purple',
			backgroundColor: 'purple',
			fill: false,
		},
		contactedDataLine = {
			label: t('totalContacted'),
			data: contactedDataLineData,
			borderColor: 'blue',
			backgroundColor: 'blue',
			fill: false,
		},
		missingDataLine = {
			label: t('dontConnecttedDescription'),
			data: missingDataLineData,
			borderColor: 'red',
			backgroundColor: 'red',
			fill: false,
		},
		closedDataLine = {
			label: t('totalClosed'),
			data: closedDataLineData,
			borderColor: 'green',
			backgroundColor: 'green',
			fill: false,
		};
	//let datasets = [uniqueUserCountsLine, totalWaitingConvLine, contactedDataLine, closedDataLine];
	let datasets = [totalWaitingConvLine, totalOpenedConvLine, totalFirstMessageConvLine, contactedDataLine, missingDataLine, closedDataLine];
	//let datasets = [totalWaitingConvLine, contactedDataLine, missingDataLine, closedDataLine];
	return {
		labels,
		datasets,
	};
}
function chartDataDayHourly(
	totalWaitingConvDayHourly,
	contactedDataDayHourly,
	totalAgentClosedDataDayHourly,
	totalOpenedConvDayHourly,
	totalFirstMessageConvDayHourly,
	start_date,
	end_date,
	t,
	missing_list
) {
	//let startDate = new Date(start_date);
	//let endDate = new Date(end_date);
	let labels = [];
	//var dateArr = getDateArray(startDate, endDate);
	var hourArr = getHourArray();
	labels = hourArr;
	let totalWaitingConvDayHourlyData = [],
		totalOpenedConvDayHourlyData = [],
		totalFirstMessageConvDayHourlyData = [],
		contactedDataDayHourlyLineData = [],
		totalAgentClosedDataDayHourlyLineData = [],
		missingDataLineData = [],
		twObj,
		oObj,
		fmObj,
		cObj,
		newMisslingObj,
		tcObj;
	//console.log('contactedDataDayHourly', totalWaitingConvDayHourly);
	_.map(hourArr, (it, i) => {
		newMisslingObj = _.filter(missing_list, (o) => parseInt(moment(o.created_at).format('H')) === it);
		twObj = _.find(totalWaitingConvDayHourly, (o) => o.hour === it);
		oObj = _.find(totalOpenedConvDayHourly, (o) => o.hour === it);
		fmObj = _.find(totalFirstMessageConvDayHourly, (o) => o.hour === it);
		cObj = (contactedDataDayHourly && contactedDataDayHourly[i] && _.sumBy(contactedDataDayHourly[i], 'count')) || 0;
		tcObj = (totalAgentClosedDataDayHourly && totalAgentClosedDataDayHourly[i] && _.sumBy(totalAgentClosedDataDayHourly[i], 'count')) || 0;
		totalWaitingConvDayHourlyData.push((twObj && twObj.count) || 0);
		totalOpenedConvDayHourlyData.push((oObj && oObj.count) || 0);
		totalFirstMessageConvDayHourlyData.push((fmObj && fmObj.count) || 0);
		contactedDataDayHourlyLineData.push(cObj || 0);
		totalAgentClosedDataDayHourlyLineData.push(tcObj || 0);
		missingDataLineData.push((newMisslingObj && newMisslingObj.length) || 0);
	});

	//let missingDataLineData = _.map(totalWaitingConvDayHourlyData, (it, i) => Math.max(it - contactedDataDayHourlyLineData[i], 0));
	//console.log('missingDataLineData', totalWaitingConvDayHourlyData, contactedDataDayHourlyLineData, missingDataLineData);
	let totalWaitingConvLine = {
			label: t('totalWaitingAllC'),
			data: totalWaitingConvDayHourlyData,
			borderColor: 'orange',
			backgroundColor: 'orange',
			fill: false,
		},
		totalOpenedConvLine = {
			label: t('totalOpenedAllC'),
			data: totalOpenedConvDayHourlyData,
			borderColor: 'pink',
			backgroundColor: 'pink',
			fill: false,
		},
		totalFirstMessageConvLine = {
			label: t('totalFirstMessageAllC'),
			data: totalFirstMessageConvDayHourlyData,
			borderColor: 'purple',
			backgroundColor: 'purple',
			fill: false,
		},
		contactedDataLine = {
			label: t('totalContacted'),
			data: contactedDataDayHourlyLineData,
			borderColor: 'blue',
			backgroundColor: 'blue',
			fill: false,
		},
		missingDataLine = {
			label: t('dontConnecttedDescription'),
			data: missingDataLineData,
			borderColor: 'red',
			backgroundColor: 'red',
			fill: false,
		},
		closedDataLine = {
			label: t('totalClosed'),
			data: totalAgentClosedDataDayHourlyLineData,
			borderColor: 'green',
			backgroundColor: 'green',
			fill: false,
		};
	//let datasets = [uniqueUserCountsLine, totalWaitingConvLine, contactedDataLine, closedDataLine];
	let datasets = [totalWaitingConvLine, totalOpenedConvLine, totalFirstMessageConvLine, contactedDataLine, missingDataLine, closedDataLine];
	//let datasets = [totalWaitingConvLine, contactedDataLine, missingDataLine, closedDataLine];
	return {
		labels,
		datasets,
	};
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Dashboard));
