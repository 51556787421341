import React, { PureComponent } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import Page from '../../components/Page';
import SendMessage from './sendmessage';
import History from './historytable';
import Telegram from './telegramsend';
import Contacts from './contacts';
import ContactsNew from './contacts_new';
import Unsub from './unsubs';
import ScheduleTable from './scheduletable';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
class WGROUP extends PureComponent {
	render() {
		let { t, product } = this.props;
		let isTelegramTemplate = product && product.settings && product.settings.isTelegramTemplate;
		return (
			<Page title="WhatsApp Broadcast" icon="whatsapp">
				<Page.Wrap>
					<Menu className="settings" pointing secondary stackable>
						<Menu.Item as={NavLink} exact to="/wsendmessage">
							<Icon name="group" />
							{t('sendMessage')}
						</Menu.Item>
						<Menu.Item as={NavLink} exact to="/wsendmessage/schedules">
							<Icon name="calendar" />
							{t('schedule')}
						</Menu.Item>
						{isTelegramTemplate && (
							<Menu.Item as={NavLink} exact to="/wsendmessage/telegram">
								<Icon name="telegram" />
								{t('Telegram')}
							</Menu.Item>
						)}

						<Menu.Item as={NavLink} exact to="/wsendmessage/history">
							<Icon name="list" />
							{t('Geçmiş')}
						</Menu.Item>
						{product.settings.merged_broadcast_list ? (
							<Menu.Item as={NavLink} exact to="/wsendmessage/contacts_new">
								<Icon name="table" />
								{t('contacts')}
							</Menu.Item>
						) : (
							<Menu.Item as={NavLink} exact to="/wsendmessage/contacts">
								<Icon name="list" />
								{t('contacts')}
							</Menu.Item>
						)}
						{product.settings.merged_broadcast_list ? (
							<Menu.Item as={NavLink} exact to="/wsendmessage/unsublist">
								<Icon name="table" />
								{t('unsublist')}
							</Menu.Item>
						) : null}
					</Menu>

					<Switch>
						{isTelegramTemplate && <Route path="/wsendmessage/telegram" exact component={Telegram}></Route>}
						<Route path="/wsendmessage/history" exact component={History}></Route>
						<Route path="/wsendmessage/schedules" exact component={ScheduleTable}></Route>
						<Route path="/wsendmessage/contacts" component={Contacts}></Route>
						<Route path="/wsendmessage" exact component={SendMessage}></Route>
						<Route path="/wsendmessage/contacts_new" component={ContactsNew}></Route>
						<Route path="/wsendmessage/unsublist" component={Unsub}></Route>
						<Redirect to="/wsendmessage" />
					</Switch>
				</Page.Wrap>
			</Page>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });
export default connect(stateToProps)(withTranslation()(WGROUP));
