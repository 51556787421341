import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { Button, Icon, Modal, Table, Header, Confirm, Segment } from 'semantic-ui-react';
import { log } from '../../util/api';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

class HistoryTable extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.getLogs();
	}

	async getLogs() {
		let { product } = this.props;
		let pid = product.id;
		await log('/api/listSchedules', { pid, limit: 20 }).then((data) => {
			let response = data.data && data.data.result;
			this.setState({ logData: response, loading: false });
		});
	}
	closeModal = () => {
		this.setState({ openModal: false });
	};
	exportExcel = async () => {
		this.setState({ buttonLoading: true });
		let { product } = this.props;
		let pid = product.id;
		let { details, selected, selectedDate } = this.state;

		let sent_list = _.filter(details, { status: 'sent' });
		let delivered_list = _.filter(details, { status: 'delivered' });
		let read_list = _.filter(details, { status: 'read' });
		let excel_data = _.map(selected, (o) => {
			let phone_number = /@/.test(o.id) ? o.id.replace(/@.*/, '') : o.id;
			let rgx = new RegExp(phone_number);

			let isRead = _.find(read_list, (it) => rgx.test(it.phone_id));
			let isDelivered = isRead || _.find(delivered_list, (it) => rgx.test(it.phone_id));
			let isSent = isDelivered || _.find(sent_list, (it) => rgx.test(it.phone_id));

			return {
				İsim: o.name,
				Telefon: phone_number,
				'Gönderen Telefon': o.phone,
				Gönderildi: '' + !!isSent,
				Ulaştı: '' + !!isDelivered,
				Okundu: '' + !!isRead,
			};
		});
		//console.log(excel_data, selectedDate);
		let sheets = [
			{
				name: 'message_analytic',
				x: ['İsim', 'Telefon', 'Gönderen Telefon', 'Gönderildi', 'Ulaştı', 'Okundu'],
				datasets: excel_data,
			},
		];
		await fetch('https://log.yapaytech.com/analytic/download', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				filename: moment(selectedDate).format('DD_MM_YYYY_HH_mm_ss') + '_broadcast_message_' + pid,
				sheets,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let dataurl = data.url;
				var a = document.createElement('a');
				a.href = dataurl;
				a.setAttribute('download', dataurl);
				a.click();
				this.setState({ buttonLoading: false });
			});
	};
	async getDetail(log, groups, date) {
		this.setState({ btnRefresh: true });
		let { product } = this.props;
		//let pid = product.id;
		let live_pid = product.settings.live.token;
		await fetch('https://live.dahi.ai/analytics/dialogtab/broadcastResult ', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				template_name: log.template.name,
				list: 'uid',
				pid: live_pid,
				start_date: moment(date).add('-3', 'h'),
				end_date: moment(date).add('5', 'h'),
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let list = [];
				_.map(data.data, (it) =>
					_.map(it.list, (o) => {
						o.status = it._id;
						list.push(o);
					})
				);
				this.setState({
					details: list,
					detailList: data.data,
				});
			});

		this.setState({ openModal: true, selected: groups, selectedLog: log, selectedDate: date, btnRefresh: false });
	}
	async cancelSchedule() {
		let job_id = this.state.selectedJobId;
		let { product } = this.props;
		let pid = product.id;

		await log('/api/addSchedule', {
			category: 'schedule',
			label: 'broadcast_cancel_event',
			extra: { job_id },
			pid,
		}).then(() => {
			this.setState({ openCancel: false });
			this.getLogs();
		});
	}

	render() {
		let { logData, loading, openModal, selected, details, selectedLog } = this.state;
		let { t } = this.props;
		if (loading) return <Page.Loader></Page.Loader>;
		let messageTypes = [
			{
				value: 'text',
				text: t('Yazı'),
			},
			{
				value: 'media',
				text: t('Medya'),
			},
			{
				value: 'link',
				text: t('Link'),
			},
			{
				value: 'document',
				text: t('document'),
			},
			{
				value: 'template',
				text: 'Template',
			},
			{
				value: 'audio',
				text: t('audio'),
			},
			{
				value: 'image',
				text: t('Medya'),
			},
			{
				value: 'video',
				text: t('Medya'),
			},
		];
		let sent_list = _.uniqBy(
			_.filter(details, (it) => it.status === 'delivered' || it.status === 'read' || it.status === 'sent'),
			'cid'
		);
		let delivered_list = _.uniqBy(
			_.filter(details, (it) => it.status === 'delivered' || it.status === 'read'),
			'cid'
		);
		let read_list = _.filter(details, { status: 'read' });
		return (
			<Table>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>{t('message')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('type')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('date')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('numbers')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('status')}</Table.HeaderCell>
						<Table.HeaderCell width={2}>{t('cancel')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{_.map(logData, (obj, i) => {
						if (obj.data && obj.data.event && obj.data.event.extra) {
							let groups = [];
							_.map(obj.data && obj.data.event && obj.data.event.extra.numbers, (it) => groups.push(it));
							return (
								<Table.Row key={i}>
									<Table.Cell>
										{obj.data.event.extra.template && obj.data.event.extra.template.value
											? obj.data.event.extra.template.value
											: obj.data.event.extra.content.imageUrl
											? obj.data.event.extra.content.imageUrl +
											  (obj.data.event.extra.content.text ? ' - ' + obj.data.event.extra.content.text : '')
											: obj.data.event.extra.content.text}
									</Table.Cell>
									<Table.Cell>
										{obj.data.event.extra.template && obj.data.event.extra.template.value
											? 'Template'
											: _.find(messageTypes, { value: obj.data.event.extra.content.type }).text}
									</Table.Cell>
									<Table.Cell>{moment(obj.startafter).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
									<Table.Cell>
										{groups.length}
										<Button
											icon
											size="mini"
											style={{ marginLeft: '20px' }}
											onClick={() => this.getDetail(obj.data.event.extra.content, groups, obj.startafter)}
										>
											<Icon name="eye"></Icon>
										</Button>
									</Table.Cell>
									<Table.Cell>{t(obj.state)}</Table.Cell>
									<Table.Cell>
										{obj.state === 'created' ? (
											<Button icon color="red" size="mini" onClick={() => this.setState({ openCancel: true, selectedJobId: obj.job_id })}>
												<Icon name="x"></Icon>
											</Button>
										) : (
											''
										)}
									</Table.Cell>
								</Table.Row>
							);
						}
					})}
				</Table.Body>
				{selectedLog ? (
					<Modal open={openModal} onClose={this.closeModal} style={{ width: '900px' }}>
						<Header icon="whatsapp" content={t('numbers')} />
						<Modal.Content>
							<Segment className="message">
								{
									<div>
										<p>
											{selectedLog.imageUrl
												? selectedLog.imageUrl + (selectedLog.text ? ' - ' + selectedLog.text : '')
												: selectedLog.text}
										</p>
										<p>
											{t('type')}: {_.find(messageTypes, { value: selectedLog.type }).text}
										</p>
									</div>
								}
							</Segment>
							{/* <div style={{ display: 'flex', marginBottom: '10px' }}>
								<Button icon positive onClick={() => this.exportExcel()} loading={this.state.buttonLoading} disabled={this.state.buttonLoading}>
									{t('DownloadExcel')}
								</Button>
								<div style={{ textAlign: 'end', marginLeft: 'auto' }}>
									<Button
										loading={btnRefresh}
										icon
										basic
										color="green"
										size="mini"
										onClick={() => this.getDetail(selectedLog, selected, selectedDate)}
									>
										<Icon name="redo"></Icon>
									</Button>
									<Label color="orange" image>
										{t('sent')}
										<Label.Detail>{sent_list.length}</Label.Detail>
									</Label>
									<Label color="yellow" image>
										{t('delivered')}
										<Label.Detail>{delivered_list.length}</Label.Detail>
									</Label>
									<Label color="green" image>
										{t('read_mee')}
										<Label.Detail>{read_list.length}</Label.Detail>
									</Label>
								</div>
							</div> */}
							<div style={{ display: 'flex', maxHeight: '500px', overflowY: 'scroll' }}>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('name')}</Table.HeaderCell>
											<Table.HeaderCell>{t('phone')}</Table.HeaderCell>
											{/* <Table.HeaderCell>{t('sentPhone')}</Table.HeaderCell> */}
											{/* <Table.HeaderCell width={5} textAlign="center">
												{t('status')}
											</Table.HeaderCell> */}
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(selected, (it, i) => {
											let phone_number = /@/.test(it.id) ? it.id.replace(/@.*/, '') : it.id;
											let rgx = new RegExp(phone_number);

											let isRead = _.find(read_list, (o) => rgx.test(o.phone_id));
											let isDelivered = isRead || _.find(delivered_list, (o) => rgx.test(o.phone_id));
											let isSent = isDelivered || _.find(sent_list, (o) => rgx.test(o.phone_id));

											return (
												<Table.Row key={i}>
													<Table.Cell key={isSent}>{it.name || it.id}</Table.Cell>
													<Table.Cell>{phone_number}</Table.Cell>
													{/* <Table.Cell>{it.phone}</Table.Cell> */}
													{/* <Table.Cell style={{ textAlign: 'end' }}>
														{isSent ? <Label color="orange">{t('sent')}</Label> : null}
														{isDelivered ? <Label color="yellow">{t('delivered')}</Label> : null}
														{isRead ? <Label color="green">{t('read_mee')}</Label> : null}
													</Table.Cell> */}
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</div>
						</Modal.Content>
					</Modal>
				) : (
					''
				)}
				<Confirm
					open={this.state.openCancel}
					content="Planlanmış gönderimi iptal etmek istediğinize emin misiniz?"
					cancelButton={t('no')}
					confirmButton={t('yes')}
					onCancel={() => this.setState({ openCancel: false })}
					onConfirm={() => this.cancelSchedule()}
				/>
			</Table>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(HistoryTable));
