import React from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import TimePicker from 'react-time-picker';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import pt from 'date-fns/locale/pt';
import ru from 'date-fns/locale/ru';
import de from 'date-fns/locale/de';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, endOfDay, startOfDay, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek } from 'date-fns';
import moment from 'moment';
import { Button } from 'semantic-ui-react';

const dateLocales = { tr, en, es, pt, ru, fr, de };
const defineds = {
	startOfWeek: addDays(startOfWeek(new Date()), 1),
	endOfWeek: addDays(endOfWeek(new Date()), 1),
	startOfLastWeek: addDays(startOfWeek(addDays(new Date(), -7)), 1),
	endOfLastWeek: addDays(endOfWeek(addDays(new Date(), -7)), 1),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};
let defaultStaticRangesEn = createStaticRanges([
	{
		label: 'Today',
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Yesterday',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},

	{
		label: 'This Week',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'Last Week',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'This Month',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'Last Month',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: 'Last 7 Days',
		range: () => ({
			startDate: addDays(new Date(), -7),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Last 14 Days',
		range: () => ({
			startDate: addDays(new Date(), -14),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Last 30 Days',
		range: () => ({
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -1),
		}),
	},
]);
let defaultStaticRangesTr = createStaticRanges([
	{
		label: 'Bugün',
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Dün',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},

	{
		label: 'Bu Hafta',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'Geçen Hafta',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'Bu Ay',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'Geçen Ay',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: 'Son 7 Gün',
		range: () => ({
			startDate: addDays(new Date(), -7),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Son 14 Gün',
		range: () => ({
			startDate: addDays(new Date(), -14),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Son 30 Gün',
		range: () => ({
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -1),
		}),
	},
]);
let defaultStaticRangesFr = createStaticRanges([
	{
		label: "Aujourd'hui",
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Hier',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},

	{
		label: 'Cette semaine',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'La semaine dernière',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'Ce mois-ci',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'Le mois dernier',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: '7 derniers jours',
		range: () => ({
			startDate: addDays(new Date(), -7),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: '14 derniers jours',
		range: () => ({
			startDate: addDays(new Date(), -14),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: '30 derniers jours',
		range: () => ({
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -1),
		}),
	},
]);
let defaultStaticRangesDe = createStaticRanges([
	{
		label: 'Heute',
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Gestern',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},

	{
		label: 'In dieser Woche',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'Letzte Woche',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'Diesen Monat',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'Letzten Monat',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: 'Letzten 7 Tage',
		range: () => ({
			startDate: addDays(new Date(), -7),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Letzte 14 Tage',
		range: () => ({
			startDate: addDays(new Date(), -14),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Letzte 30 Tage',
		range: () => ({
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -1),
		}),
	},
]);
let defaultStaticRangesEs = createStaticRanges([
	{
		label: 'Hoy',
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Ayer',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},

	{
		label: 'Esta semana',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'La semana pasada',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'Este mes',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'El mes pasado',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: 'Los últimos 7 días',
		range: () => ({
			startDate: addDays(new Date(), -7),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Los últimos 14 días',
		range: () => ({
			startDate: addDays(new Date(), -14),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Los últimos 30 días',
		range: () => ({
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -1),
		}),
	},
]);
let defaultStaticRangesPt = createStaticRanges([
	{
		label: 'Hoje',
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Ontem',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},

	{
		label: 'Esta semana',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'Semana Anterior',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'Este mês',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'Mês passado',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: 'Últimos 7 dias',
		range: () => ({
			startDate: addDays(new Date(), -7),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Últimos 14 dias',
		range: () => ({
			startDate: addDays(new Date(), -14),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Últimos 30 dias',
		range: () => ({
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -1),
		}),
	},
]);
let defaultStaticRangesRu = createStaticRanges([
	{
		label: 'Сегодня',
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
	},
	{
		label: 'Вчера',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},

	{
		label: 'На этой неделе',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
	},
	{
		label: 'Прошлая неделя',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'Этот месяц',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
	},
	{
		label: 'Прошлый месяц',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: 'Последние 7 дней',
		range: () => ({
			startDate: addDays(new Date(), -7),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Последние 14 дней',
		range: () => ({
			startDate: addDays(new Date(), -14),
			endDate: addDays(new Date(), -1),
		}),
	},
	{
		label: 'Последние 30 дней',
		range: () => ({
			startDate: addDays(new Date(), -30),
			endDate: addDays(new Date(), -1),
		}),
	},
]);
let langRange = {
	en: defaultStaticRangesEn,
	tr: defaultStaticRangesTr,
	de: defaultStaticRangesDe,
	es: defaultStaticRangesEs,
	pt: defaultStaticRangesPt,
	ru: defaultStaticRangesRu,
	fr: defaultStaticRangesFr,
};

const Picker = (props) => (
	// eslint-disable-next-line react/react-in-jsx-scope
	<div>
		{props.isTimeSelectPage && props.timeSelect && (
			<div style={{ display: 'flex', borderBottom: '1px solid #EFF2F7', paddingBottom: '10px' }}>
				<div style={{ display: 'flex', margin: 'auto' }}>
					<p style={{ margin: 'auto', marginRight: '10px' }}>Başlangıç Saati: {moment(props.startDate).format('DD-MMM')}</p>
					<TimePicker
						value={props.startDate}
						format={'HH:mm'}
						disableClock={true}
						clearIcon={null}
						onChange={(e) => {
							props.timeChangeStart(e);
						}}
					/>
					<span style={{ marginTop: '1px' }}>:00:00</span>
				</div>
				<div style={{ display: 'flex', margin: 'auto' }}>
					<p style={{ margin: 'auto', marginRight: '10px' }}>Bitiş Saati: {moment(props.endDate).format('DD-MMM')}</p>
					<TimePicker
						value={props.endDate}
						format={'HH:mm'}
						disableClock={true}
						clearIcon={null}
						onChange={(e) => {
							props.timeChangeEnd(e);
						}}
					/>
					<span style={{ marginTop: '1px' }}>:59:59</span>
				</div>
			</div>
		)}
		{props.noMinDate ? (
			<>
				<DateRangePicker
					locale={dateLocales[props.lang]}
					ranges={[
						{
							startDate: props.startDate,
							endDate: props.endDate,
							key: 'selection',
						},
					]}
					weekStartsOn={1}
					/* maxDate={
					new Date(props.startDate).valueOf() + 62 * 86400000 > Date.now()
						? new Date()
						: new Date(new Date(props.startDate).valueOf() + 62 * 86400000)
				} */
					//maxDate={new Date()}
					onChange={props.dateChange}
					staticRanges={langRange[props.lang]}
					inputRanges={[]}
					months={2}
				></DateRangePicker>
				<Button basic color="blue" className="analytic-button" onClick={props.getNewData}>
					Getir
				</Button>
			</>
		) : (
			<>
				<DateRangePicker
					locale={dateLocales[props.lang]}
					ranges={[
						{
							startDate: props.startDate,
							endDate: props.endDate,
							key: 'selection',
						},
					]}
					weekStartsOn={1}
					maxDate={
						new Date(props.startDate).valueOf() + 62 * 86400000 > Date.now()
							? new Date()
							: new Date(new Date(props.startDate).valueOf() + 62 * 86400000)
					}
					//maxDate={new Date()}
					onChange={props.dateChange}
					staticRanges={langRange[props.lang]}
					inputRanges={[]}
					months={2}
					minDate={new Date(new Date(props.endDate).valueOf() - 366 * 86400000)}
				></DateRangePicker>
				<Button
					className={props.isTimeSelectPage && props.timeSelect ? 'analytic-button with-time-select' : 'analytic-button'}
					basic
					color="blue"
					onClick={props.getNewData}
				>
					Getir
				</Button>
			</>
		)}
	</div>
);

export default Picker;
