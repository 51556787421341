import React, { Component } from 'react';
import Page from '../../../components/Page';
import { Icon, Breadcrumb, Segment, Form, Table, Modal, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MyMenu from '../menu';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { postEx } from '../../../util/api';
class Phones extends Component {
	phoneToDelete = null;
	state = {
		loading: true,
		saveButton: 'add',
		ex_file: '',
	};
	componentDidMount() {
		this.getList();
	}
	getList() {
		const { product } = this.props;
		let pid = product.id;
		fetch(`https://micro.yapaytech.com/api/dump/readAll?pid=${pid}&key=contactList&unionBy=no_union`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				let excelData = result && result.data;
				this.setState({ list: excelData, loading: false });
			});
	}

	fileUpload(file) {
		const { product } = this.props;
		this.setState({ loadingBtn: true });
		let pid = product.id;
		const url = 'https://micro.yapaytech.com/api/dump/write/excel';
		const formData = new FormData();
		formData.append('file', file);
		formData.append('pid', pid);
		formData.append('key', 'contactList');
		let file_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				file_url = data && data.files && data.files[0] && data.files[0].url;
				this.setState({ ex_file: file_url, loadingBtn: false, message: true });
				this.getList();
			});
	}
	handleFileInput = (e) => {
		let file = e.target.files[0];
		this.fileUpload(file);
	};
	removeExcel(pid, id) {
		this.setState({ loadDeletedBtn: true });
		postEx('https://micro.yapaytech.com/api/dump/remove', { pid, id }).then(() => {
			this.setState({ openConfirm: false, loadDeletedBtn: false });
			this.getList();
		});
	}
	downloadExample = async () => {
		var a = document.createElement('a');
		a.href = '/contacts_example.xlsx';
		a.target = '_blank';
		a.download = 'contacts_example.xlsx';
		a.click();
	};
	render() {
		const { t, product } = this.props;
		let pid = product.id;
		let { loadingBtn, message, list, openModal, selectedExcel, openConfirm, selectedExcelId, loadDeletedBtn } = this.state;
		if (this.state.loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon={{ name: 'asterisk', loading: true }} />
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="label">
						<Page.Loader />
					</MyMenu>
				</Page>
			);
		} else {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/settings`}>
								<Icon name="setting" />
								{t('settings')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />

							<Breadcrumb.Section as={Link} to={`/settings/integrations`}>
								{t('integrations')}
							</Breadcrumb.Section>
							<Breadcrumb.Divider icon="right chevron" />
							<Breadcrumb.Section>{t('contacts')}</Breadcrumb.Section>
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="contacts">
						<Page.Wrap>
							<Segment>
								<div style={{ padding: '10px', width: '100%' }}>
									<h3>
										<Icon name="whatsapp" />
										{t('contacts')}
									</h3>
									<Form>
										<Form.Field style={{ display: 'flex', marginBottom: '10px' }}>
											<div className="image-upload" style={{ margin: 'auto' }}>
												<label htmlFor="file-input" className={`ui blue icon left labeled button ${loadingBtn ? 'loading' : null}`}>
													{t('upload')}
													<Icon name="upload" style={{ cursor: 'pointer' }}></Icon>
												</label>
												<Button
													onClick={() => this.downloadExample()}
													content={t('example download')}
													icon="download"
													labelPosition="left"
													color="teal"
												/>
											</div>

											<input
												id="file-input"
												type="file"
												accept=".xlsx, .xls"
												style={{ display: 'none' }}
												onChange={(e) => {
													this.handleFileInput(e);
												}}
											/>
										</Form.Field>
										{message ? <p style={{ textAlign: 'center' }}>{t('messageExcelSuccess')}</p> : null}
									</Form>
								</div>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>{t('name')}</Table.HeaderCell>
											<Table.HeaderCell>{t('date')}</Table.HeaderCell>
											<Table.HeaderCell>{t('phoneCount')}</Table.HeaderCell>
											<Table.HeaderCell>{t('Phones')}</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{_.map(list, (it, i) => (
											<Table.Row key={i}>
												<Table.Cell>{it.filename || '---'}</Table.Cell>
												<Table.Cell>{moment(it.date).format('DD-MM-YYYY')}</Table.Cell>
												<Table.Cell>{it.data.length}</Table.Cell>
												<Table.Cell>
													<Button color="teal" icon onClick={() => this.setState({ openModal: true, selectedExcel: it.data })}>
														<Icon name="eye"></Icon>
													</Button>
													<Button color="red" icon onClick={() => this.setState({ openConfirm: true, selectedExcelId: it._id })}>
														<Icon name="trash"></Icon>
													</Button>
												</Table.Cell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
								<Modal open={openModal} onClose={() => this.setState({ openModal: false })} closeIcon>
									<Modal.Content scrolling>
										<Table>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell>{'Id'}</Table.HeaderCell>
													<Table.HeaderCell>{t('name')}</Table.HeaderCell>
													<Table.HeaderCell>{t('phone')}</Table.HeaderCell>
													<Table.HeaderCell>{t('label')}</Table.HeaderCell>
													<Table.HeaderCell>{'Website'}</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												{_.map(selectedExcel, (it, i) => (
													<Table.Row key={i}>
														<Table.Cell>{it['Users Id']}</Table.Cell>
														<Table.Cell>{it.Name}</Table.Cell>
														<Table.Cell>{it.Phone}</Table.Cell>
														<Table.Cell>{it.Label}</Table.Cell>
														<Table.Cell>{it.Website}</Table.Cell>
													</Table.Row>
												))}
											</Table.Body>
										</Table>
									</Modal.Content>
								</Modal>
								<Modal open={openConfirm} onClose={() => this.setState({ openConfirm: false })} closeIcon>
									<Modal.Content>{t('areyousureDeleteExcel')}</Modal.Content>
									<Modal.Actions>
										<Button onClick={() => this.setState({ openConfirm: false })}>{t('no')}</Button>
										<Button color="red" loading={loadDeletedBtn} onClick={() => this.removeExcel(pid, selectedExcelId)}>
											{t('yes')}
										</Button>
									</Modal.Actions>
								</Modal>
							</Segment>
						</Page.Wrap>
					</MyMenu>
				</Page>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Phones));
