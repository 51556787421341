import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Page from '../../components/Page';
import {
	Button,
	Input,
	Label,
	Message,
	Segment,
	Dimmer,
	Icon,
	Header,
	Loader,
	Grid,
	GridRow,
	GridColumn,
	Dropdown,
	Form,
	Modal,
	Image,
} from 'semantic-ui-react';
import { post, get } from '../../util/api';
import _ from 'lodash';
import DraggableDiv from '../../components/DraggableDiv';
class PersisantMenuTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			persistantFormValues: [{ type: '', title: '', payload: '' }],
			loading: true,
			persistent_menu_length: 0,
			saveButton: false,
			deleteAllModal: false,
			alertMessage: false,
			alertMessageText: '',
			accoundId: window.location.pathname.split('/persistentMenu/')[1],
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		let { accoundId } = this.state;
		let getPersistentMenuData;
		fetch(`/api/messenger/register/persistent/list/${accoundId}`, { method: 'POST', body: {} })
			.then((res) => res.json())
			.then((response) => {
				getPersistentMenuData = response;
				if (getPersistentMenuData && getPersistentMenuData.success === false) {
					this.setState({ loading: false, alertMessage: true, alertMessageText: getPersistentMenuData.message });
				} else {
					if (getPersistentMenuData && getPersistentMenuData.data && getPersistentMenuData.data.length === 0) {
						let { persistantFormValues } = this.state;
						let locale = 'default';
						let call_to_actions = [];
						call_to_actions.push(persistantFormValues[0]);
						let newPersistantFormValues = { locale: locale, call_to_actions: call_to_actions };
						let newBigData = [];
						newBigData.push(newPersistantFormValues);
						let persistent_menu_length = 5;
						this.setState({
							persistantFormValues: newBigData,
							persistent_menu_length: persistent_menu_length,
							loading: false,
							alertMessage: false,
						});
					} else {
						getPersistentMenuData = getPersistentMenuData && getPersistentMenuData.data[0] && getPersistentMenuData.data[0].persistent_menu;
						let persistentMenuData = [];
						for (let i = 0; i < getPersistentMenuData.length; i++) {
							persistentMenuData.push(getPersistentMenuData[i]);
						}
						let persistentLenght =
							persistentMenuData &&
							persistentMenuData[0] &&
							persistentMenuData[0].call_to_actions &&
							persistentMenuData[0].call_to_actions.length;
						persistentLenght = 5 - persistentLenght;
						this.setState({
							loading: false,
							persistantFormValues: persistentMenuData,
							persistent_menu_length: persistentLenght,
							alertMessage: false,
						});
					}
				}
			});
	}
	checkPersistantMenu = () => {
		let { accoundId } = this.state;
		accoundId = window.location.pathname.split('/persistentMenu/')[1];
		let getPersistentMenuData;
		fetch(`/api/messenger/register/persistent/list/${accoundId}`, { method: 'POST', body: {} })
			.then((res) => res.json())
			.then((response) => {
				getPersistentMenuData = response;
				if (getPersistentMenuData && getPersistentMenuData.success === false) {
					this.setState({ loading: false, alertMessage: true, alertMessageText: getPersistentMenuData.message });
				} else {
					if (getPersistentMenuData && getPersistentMenuData.data && getPersistentMenuData.data.length === 0) {
						let { persistantFormValues } = this.state;
						let locale = 'default';
						let call_to_actions = [];
						call_to_actions.push(persistantFormValues[0]);
						let newPersistantFormValues = { locale: locale, call_to_actions: call_to_actions };
						let newBigData = [];
						newBigData.push(newPersistantFormValues);
						let persistent_menu_length = 5;
						this.setState({
							persistantFormValues: newBigData,
							persistent_menu_length: persistent_menu_length,
							loading: false,
							alertMessage: false,
						});
					} else {
						getPersistentMenuData = getPersistentMenuData && getPersistentMenuData.data[0] && getPersistentMenuData.data[0].persistent_menu;
						let persistentMenuData = [];
						for (let i = 0; i < getPersistentMenuData.length; i++) {
							persistentMenuData.push(getPersistentMenuData[i]);
						}
						let persistentLenght =
							persistentMenuData &&
							persistentMenuData[0] &&
							persistentMenuData[0].call_to_actions &&
							persistentMenuData[0].call_to_actions.length;
						persistentLenght = 5 - persistentLenght;
						this.setState({
							loading: false,
							persistantFormValues: persistentMenuData,
							persistent_menu_length: persistentLenght,
							alertMessage: false,
						});
					}
				}
			});
	};
	removeFormFields(i, k) {
		let { persistantFormValues } = this.state;
		for (let r = 0; r < persistantFormValues.length; r++) {
			if (i == r) {
				persistantFormValues[i].call_to_actions.splice(k, 1);
			}
		}
		let persistentLenght =
			persistantFormValues && persistantFormValues[0] && persistantFormValues[0].call_to_actions && persistantFormValues[0].call_to_actions.length;
		let persistent_lenght = 5 - persistentLenght;
		this.setState({ persistantFormValues, persistent_menu_length: persistent_lenght, saveButton: true });
	}
	loadingDiv = () => {
		return (
			<Segment>
				<Dimmer active inverted>
					<Loader inverted></Loader>
				</Dimmer>
			</Segment>
		);
	};
	addFormFields(index) {
		let { persistantFormValues } = this.state;
		if (index !== undefined) {
			for (let i = 0; i < persistantFormValues.length; i++) {
				if (i === index) {
					persistantFormValues[i].call_to_actions.push({ type: '', title: '', payload: '' });
					this.setState({ persistantFormValues: persistantFormValues });
				}
			}
		}
		let persistentLenght =
			persistantFormValues && persistantFormValues[0] && persistantFormValues[0].call_to_actions && persistantFormValues[0].call_to_actions.length;
		let persistent_lenght = 5 - persistentLenght;
		this.setState({ persistent_menu_length: persistent_lenght, saveButton: true });
	}
	openModal = () => {
		this.setState({ deleteAllModal: true });
	};
	async handleSubmit() {
		 this.setState({loading:true})
		let { persistantFormValues, accoundId } = this.state;
		_.map(persistantFormValues[0].call_to_actions, (it, i) => {
			if (it.payload === '') {
				it.payload = it.title;
			}
		});
		let persitentObje = { call_to_actions: persistantFormValues[0].call_to_actions, locale: 'default' };

		let persistentMenu = {
			persistent_menu: [persitentObje],
		};
		await post(`/messenger/register/persistent/set/${accoundId}`, persistentMenu)
			.then((response) => console.log('response', response))
			.catch((err) => console.log('err', err));
		this.setState({ saveButton: false,loading:false });
	}
	handleChange = (i, e, k) => {
		let { persistantFormValues } = this.state;
		if (persistantFormValues[i].call_to_actions[k].type === 'web_url') {
			if (e.target.name === 'url') {
				persistantFormValues[i].call_to_actions[k].url = e.target.value;
			} else if (e.target.name === 'title') {
				persistantFormValues[i].call_to_actions[k].title = e.target.value;
			}
		} else if (persistantFormValues[i].call_to_actions[k].type === 'postback') {
			if (e.target.name === 'payload') {
				persistantFormValues[i].call_to_actions[k].payload = e.target.value;
			} else if (e.target.name === 'title') {
				persistantFormValues[i].call_to_actions[k].title = e.target.value;
			}
		}

		this.setState({ persistantFormValues, saveButton: true });
	};
	componentDidUpdate(props) {
		if (props !== this.props) {
			this.setState({ loading: true });
			this.checkPersistantMenu();
		}
	}
	handleDropDownChange = (e, { name, value }) => {
		let { persistantFormValues, i, k } = this.state;
		if (value === 'web_url') {
			let newPersistaneFormValues = { type: value, title: '', url: '' };
			persistantFormValues[i].call_to_actions[k] = newPersistaneFormValues;
		} else {
			persistantFormValues[i].call_to_actions[k].type = value;
			// persistantFormValues[i].call_to_actions[k].payload = '<PAYLOAD>'
		}

		this.setState({ persistantFormValues, saveButton: true });
	};
	onClick(i, e, k) {
		this.setState({ i, k });
	}
	deletePersisteneMenu = async () => {
		let { accoundId } = this.state;
		await post(`/messenger/register/persistent/delete/${accoundId}`)
			.then((res) => console.log('res', res))
			.then((err) => console.log('err', err));
		this.setState({ deleteAllModal: false });
		window.location.reload();
	};
	async swap(a, b) {
		let { persistantFormValues } = this.state;
		let list = persistantFormValues[0].call_to_actions;
		list.splice(a, 0, list.splice(b, 1)[0]);
		persistantFormValues[0].call_to_actions = list;
		await this.setState({
			persistantFormValues,
			saveButton: true,
		});
	}
	cancelChanges = () => {
		this.setState({ loading: true });
		this.checkPersistantMenu();
	};
	render() {
		let { loading, persistent_menu_length, deleteAllModal, saveButton, persistantFormValues, alertMessage, alertMessageText } = this.state;
		const persistentMenuItemTypeOptions = [
			{ key: 'postback', text: 'Button', value: 'postback' },
			{ key: 'web_url', text: 'Url', value: 'web_url' },
		];
		const { product, t, i18n } = this.props;
		return (
			<>
				{loading ? (
					<Dimmer active inverted>
						<Loader inverted></Loader>
					</Dimmer>
				) : alertMessage === true ? (
					<Message error header={alertMessageText} />
				) : (
					<Grid>
						<GridColumn width={12}>
							<Segment>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
									<Header content={'PersistentMenu'} />
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<Button icon={'eraser'} onClick={() => this.openModal()} content={t('deletePersistentMenu')} />
										<Button icon={'reply'} content={t('cancelchanges')} onClick={() => this.cancelChanges()} />
									</div>
								</div>
								{persistent_menu_length !== 0 ? (
									<Header as="h5" style={{ color: '#c2c2c2' }} content={t('maxPersistentMenuItem', { limit: persistent_menu_length })} />
								) : (
									<Header as="h5" style={{ color: '#c2c2c2' }} content={t('notmore')} />
								)}

								{_.map(persistantFormValues, (it, i) => (
									<Form onSubmit={this.handleSubmit}>
										<Segment style={{ marginBottom: '20px', marginTop: '20px', width: '100%' }} key={i}>
											{_.map(it && it.call_to_actions, (its, k) => (
												<DraggableDiv key={k} i={k} action={this.swap.bind(this)}>
													<div style={{ marginBottom: '20px' }}>
														<div id="instagramPersisantDiv" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
															<div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
																<Icon name="arrows alternate vertical" size="large"></Icon>
															</div>
															<div style={{ width: '15%' }}>
																<Form.Dropdown
																	label={'Item Type'}
																	required
																	autoComplete="off"
																	value={its.type}
																	options={persistentMenuItemTypeOptions}
																	selection
																	name={'itemType'}
																	id={'itemType'}
																	placeholder={'Button || Url'}
																	onClick={(e) => this.onClick(i, e, k)}
																	onChange={this.handleDropDownChange}
																/>
															</div>
															<div style={{ width: '40%', marginLeft: '56px' }}>
																<Form.Input
																	label={'Title'}
																	maxLength={30}
																	required
																	onChange={(e) => this.handleChange(i, e, k)}
																	name={'title'}
																	value={its.title}
																	placeholder={'Show Products'}
																/>
															</div>
															{its.type !== 'web_url' ? (
																<div style={{ width: '40%' }}>
																	{' '}
																	<Form.Input
																		maxLength={80}
																		label={'payload'}
																		onChange={(e) => this.handleChange(i, e, k)}
																		name="payload"
																		placeholder={'Example:How do i find a store near me?'}
																		value={its.payload}
																	/>
																</div>
															) : (
																''
															)}
															{its.type === 'web_url' ? (
																<div style={{ width: '40%' }}>
																	{' '}
																	<Form.Input
																		label={'url'}
																		required
																		onChange={(e) => this.handleChange(i, e, k)}
																		name={'url'}
																		value={its && its.url}
																		placeholder={'https://'}
																	/>{' '}
																</div>
															) : (
																''
															)}
															{k!==0 ? (<Button
																className="instaCrossButton"
																id="instaSecondIcon"
																color={'red'}
																onClick={() => this.removeFormFields(i, k)}
																icon={'remove'}
																size={'mini'}
															/>):(<Button
																className="instaCrossButton"
																id="instaSecondIcon"
																color={'red'}
																disabled
																icon={'remove'}
																size={'mini'}
															/>)}
															
														</div>
													</div>
												</DraggableDiv>
											))}
										</Segment>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											{persistantFormValues &&
												persistantFormValues[0] &&
												persistantFormValues[0].call_to_actions &&
												persistantFormValues[0].call_to_actions.length < 5 && (
													<Button
														onClick={() => this.addFormFields(i)}
														size={'mini'}
														color={'green'}
														content={t('addpersisteneMenuItem')}
														icon={'add'}
													/>
												)}
											{saveButton ? (
												<Form.Button type={'submit'} size={'mini'} color={'blue'} content={t('save')} icon={'save'} />
											) : (
												<Form.Button type={'submit'} disabled size={'mini'} color={'blue'} content={t('save')} icon={'save'} />
											)}
										</div>
									</Form>
								))}
							</Segment>
						</GridColumn>
						<GridColumn width={4}>
							<Segment style={{ display: 'flex', justifyContent: 'center' }}>
								<Image
									src={'https://cdnydm.com/permedia/cnvIK91NgWRJ1CmxTgrzEw.png?size=306x600'}
									size="medium"
									rounded
									style={{ border: '1px solid black' }}
								/>
							</Segment>
						</GridColumn>
					</Grid>
				)}
				<Modal onClose={() => this.setState({ deleteAllModal: false })} open={deleteAllModal}>
					<Modal.Header>
						<Header icon="eraser" content={t('deletePersistentMenu')} />
					</Modal.Header>
					<Modal.Content>
						<span>{t('areusuredeletePersistent')}</span>
					</Modal.Content>
					<Modal.Actions>
						<Button color="red" onClick={() => this.setState({ deleteAllModal: false })} icon={'remove'} content={'no'} />
						<Button color="green" onClick={() => this.deletePersisteneMenu()} icon={'check'} content={'yes'} />
					</Modal.Actions>
				</Modal>
			</>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(withTranslation()(PersisantMenuTab));
