import React, { Component } from 'react';
import { Line, Bar, Radar } from 'react-chartjs-2';
import { Grid, Header, Icon, Segment, Table, Dropdown, Container, Radio, Checkbox, Loader } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import { post, log } from '../../util/api';

class Analytic extends Component {
	state = {
		loading: true,
	};
	async componentDidMount() {
		let { product } = this.props;
		let pid = product.live.settings.live.token;
		let results = await log('/analytic/sheets', { pid })
			.then(function (response) {
				return response;
			})
			.catch(function (error) {
				console.log(error.message);
			});
		console.log('results', results, pid);
		let sheets = results.data && results.data.groups && _.reverse(results.data.groups);
		if (sheets && sheets.length > 0) this.setState({ selectedDate: sheets[0].date });
		this.setState({ sheets, loading: false });
	}
	render() {
		let { t } = this.props;
		let { sheets, loading, selectedDate } = this.state;
		if (loading)
			return (
				<Segment style={{ marginBottom: '20px' }}>
					<Loader active />
				</Segment>
			);

		let options = _.map(sheets, (o) => ({ key: o.date, text: `${o.date} (${o.sheets.length})`, value: o.date }));
		let filtered = _.find(sheets, (o) => o.date === selectedDate);
		return (
			<Segment style={{ marginBottom: '20px' }}>
				<div>
					<Dropdown selection options={options} value={selectedDate} onChange={(e, { value }) => this.setState({ selectedDate: value })}></Dropdown>
				</div>
				<Table celled structured>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>{t('date')}</Table.HeaderCell>
							<Table.HeaderCell>Sheets</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{filtered &&
							filtered.sheets.map((sheet, index) => (
								<Table.Row key={index}>
									<Table.Cell>{`${sheet.first} - ${sheet.last}`}</Table.Cell>
									<Table.Cell>
										<a href={sheet.url}>{sheet.name}</a>
									</Table.Cell>
								</Table.Row>
							))}
					</Table.Body>
				</Table>
			</Segment>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default connect(stateToProps)(withTranslation()(Analytic));
