import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { toWapi } from '../../util/api';
import { Button, Segment, Table, Grid, GridColumn, Header, Modal, Icon } from 'semantic-ui-react';

class Phone extends Component {
	state = {
		phones: [],
		loading: false,
		targets: [],
		message_type: 'template',
		sw_groups: true,
		sw_contacts: false,
		start_date: new Date(),
	};
	async sendMessage() {
		this.setState({ btnLoading: true });
		let { product } = this.props;
		let pid = product.id;
		let body = {
			to_number: '@WhiteHouseOnline',
			type: 'text',
			message:
				"Уважаемые клиенты👋 !Специально  для Вас мы подготовили список фирм по отдельности всех наших каналов.Теперь вы с лёгкостью сможете просмотреть любую фирму ,индивидуально нажимая на табличку с названием интересующего вас бренда 📲\n\nDear customers,\nWe have started TELEGRAM CHANNELS for each of our brands now you can browse and see brands of your choice individually by clicking on the brand name attached to the digital board.\n\nChers clients,\nVoila la liste de tous nos TELEGRAM CANAUX des marques . Maintenant, vous pouvez facilement voir n'importe quelle canals de marques en cliquant individuellement sur le nom de la marque qui vous intéresse.\n\nزبائننا الكرام\nلقد بدأنا قنوات TELEGRAM لكل شركة من شركاتنا ، الآن يمكنك تصفح ورؤية شركاتك المفضلة بشكل",
			buttons: [
				[
					{
						text: 'AL MARWAH',
						url: 'https://t.me/joinchat/KJF9ZHeGOUM3ZTA0',
						type: 'url',
					},
					{
						text: 'ANNAVERO',
						url: 'https://t.me/joinchat/9a5nisVHL3hkMGE0',
						type: 'url',
					},
					{
						text: 'AVERSIA',
						url: 'https://t.me/joinchat/XJKlsj61y7c5MzNk',
						type: 'url',
					},
				],
				[
					{
						text: 'BASICO',
						url: 'https://t.me/joinchat/aANQuji0oGMwMWFk',
						type: 'url',
					},

					{
						text: 'BIZE',
						url: 'https://t.me/joinchat/WgwedrCyr7s4Njlk',
						type: 'url',
					},

					{
						text: 'BOEEM',
						url: 'https://t.me/joinchat/mKEhKc_gi6RlYThk',
						type: 'url',
					},
				],
				[
					{
						text: 'CARLA CARMELITA',
						url: 'https://t.me/+TwJAWX6nSdZiZjJk',
						type: 'url',
					},

					{
						text: 'CEREMONY',
						url: 'https://t.me/joinchat/IAT_PqZ0I9dhYjg8',
						type: 'url',
					},
					{
						text: 'CHEEYA',
						url: 'https://t.me/+EIA66kpHvZQyMmY0',
						type: 'url',
					},
				],
				[
					{
						text: 'DE WHITE’S',
						url: 'https://t.me/joinchat/6LvjMtTeCsM1MmE8',
						type: 'url',
					},

					{
						text: 'DIMARE',
						url: 'https://t.me/joinchat/AM1ojnQwB1MyMTI0',
						type: 'url',
					},
					{
						text: 'FEVER',
						url: 'https://t.me/joinchat/22TgbWce5RliY2Q8',
						type: 'url',
					},
				],
				[
					{
						text: 'GARMONIYA',
						url: 'https://t.me/joinchat/MRP_wgdoXjFhYTI8',
						type: 'url',
					},

					{
						text: 'GEMKO',
						url: 'https://t.me/joinchat/QYvEiQwLcwIzZDg0',
						type: 'url',
					},

					{
						text: 'HUKKA',
						url: 'https://t.me/joinchat/lynoJ4TlqD44NDU0',
						type: 'url',
					},
				],
				[
					{
						text: 'KAMEYA',
						url: 'https://t.me/joinchat/JvYrCryuNP80Mzk0',
						type: 'url',
					},

					{
						text: 'KAPRIS',
						url: 'https://t.me/joinchat/8VGs8h9cjzA0YWE0',
						type: 'url',
					},

					{
						text: 'KASHA',
						url: 'https://t.me/joinchat/g3fab08tx8kxOGRk',
						type: 'url',
					},
				],
				[
					{
						text: 'KSAS',
						url: 'https://t.me/joinchat/wTgZwSQeo5gwNTBk',
						type: 'url',
					},

					{
						text: 'KUPSS',
						url: 'https://t.me/joinchat/AV2vCI5RYlEzNDlk',
						type: 'url',
					},

					{
						text: 'LA LUCE&QM',
						url: 'https://t.me/joinchat/m1M-adaHcgs3MmM0',
						type: 'url',
					},
				],
				[
					{
						text: 'LA SIBELLA',
						url: 'https://t.me/joinchat/YrUKEbVbgs1lNjFk',
						type: 'url',
					},

					{
						text: 'LADYFORM&TOPWOMAN',
						url: 'https://t.me/joinchat/44CPlPSYVItlYWU0',
						type: 'url',
					},

					{
						text: 'LUISA WANG',
						url: 'https://t.me/joinchat/nNznr3lBTU1lMzJk',
						type: 'url',
					},
				],
				[
					{
						text: 'MARE SOLE',
						url: 'https://t.me/joinchat/7QNd11bCABgxZmE0',
						type: 'url',
					},

					{
						text: 'MIANOTTE',
						url: 'https://t.me/joinchat/c8qdiTxD37A0YmI0',
						type: 'url',
					},

					{
						text: 'MISS LILIUM',
						url: 'https://t.me/joinchat/bkRdC3o42OY4NDM8',
						type: 'url',
					},
				],
				[
					{
						text: 'MYRAANG',
						url: 'https://t.me/joinchat/3oLG6JEaj_YyYWU0',
						type: 'url',
					},

					{
						text: 'NERI',
						url: 'https://t.me/joinchat/uDmGSG7T2e1lN2I0',
						type: 'url',
					},

					{
						text: 'NEVA',
						url: 'https://t.me/joinchat/m8zlGtWkNrU1NTJk',
						type: 'url',
					},
				],
				[
					{
						text: 'NOMENS',
						url: 'https://t.me/joinchat/9w-11CcivEsyNjA0',
						type: 'url',
					},
					{
						text: 'NORM',
						url: 'https://t.me/joinchat/DWZGgWv3cFliODE0',
						type: 'url',
					},

					{
						text: 'PIENA',
						url: 'https://t.me/joinchat/gizk5pC4o29mMWE8',
						type: 'url',
					},
				],
				[
					{
						text: 'POLIN',
						url: 'https://t.me/joinchat/aipDkBkUYQ02OTY0',
						type: 'url',
					},
					{
						text: 'CARRA&PUNKY KLAN',
						url: 'https://t.me/joinchat/n3dwtZ98ZNYwOGM0',
						type: 'url',
					},
					{
						text: 'QMEK',
						url: 'https://t.me/joinchat/JnxiArLj7U01OWU0',
						type: 'url',
					},
				],
				[
					{
						text: 'QUIZAS',
						url: 'https://t.me/joinchat/BCkTI-vPWvQ1YzM0',
						type: 'url',
					},
					{
						text: 'REVARO',
						url: 'https://t.me/joinchat/u5F1SSVtl2llNmZk',
						type: 'url',
					},
					{
						text: 'SALKIM',
						url: 'https://t.me/+Kq1qR2BGYbw1YTU0',
						type: 'url',
					},
				],
				[
					{
						text: 'SEMPRE',
						url: 'https://t.me/joinchat/F1vmXl_ggeY3NzVk',
						type: 'url',
					},
					{
						text: 'SETRE',
						url: 'https://t.me/joinchat/MFlMJvI7Los4ZmY0',
						type: 'url',
					},

					{
						text: 'SI DESIGN',
						url: 'https://t.me/joinchat/KGVqRvBZhMZmYTZk',
						type: 'url',
					},
				],
				[
					{
						text: 'TONSURTON',
						url: 'https://t.me/joinchat/Mf8K5sc8S8U3M2Zk',
						type: 'url',
					},
					{
						text: 'VEDI',
						url: 'https://t.me/joinchat/tENuYBpWX080M2U0',
						type: 'url',
					},

					{
						text: 'VIA VIA',
						url: 'https://t.me/joinchat/pFnzZEdvx9I5Mjhk',
						type: 'url',
					},
				],
				[
					{
						text: 'YAQA',
						url: 'https://t.me/+J7iOyNW3ZqJkNTg0',
						type: 'url',
					},
				],
				[
					{
						text: 'Whatsapp Contact',
						url: 'https://wa.me/905415197153',
						type: 'url',
					},
				],
			],
		};
		await toWapi(`/api/${pid}/15377/sendMessage`, product.settings.wapi_token, body)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				return data;
			})
			.catch((err) => {
				console.error(err);
			});
		this.setState({ btnLoading: false, openModal: false });
	}
	closeModal = () => {
		this.setState({ openModal: false });
	};
	render() {
		let { loading } = this.state;
		let { t } = this.props;

		let groups = [
			{
				name: 'WhiteHouseOnline',
				id: '@WhiteHouseOnline',
			},
		];

		if (loading) return <Page.Loader></Page.Loader>;
		return (
			<div>
				<Grid>
					<GridColumn width={16}>
						<Segment className="scroll">
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Header>{t('Telegram Grup')}</Header>
								</div>
							</div>
							<Table>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{t('name')}</Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(groups, (it) => (
										<Table.Row>
											<Table.Cell>{it.name}</Table.Cell>
											<Table.Cell textAlign={'right'}>
												<Button positive onClick={() => this.setState({ openModal: true })}>
													{t('sendTemplate')}
												</Button>
											</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</Segment>
					</GridColumn>
				</Grid>
				<Modal open={this.state.openModal} onClose={this.closeModal} style={{ width: '900px', maxHeight: '600px' }}>
					<Header icon="telegram" content={t('sendMessage')} />
					<Modal.Content>
						<p style={{ display: 'flex', alignItems: 'center' }}>{t('sendTelegramsSure')}</p>
					</Modal.Content>
					<Modal.Actions>
						<div style={{ display: 'flex' }}>
							<div style={{ marginLeft: 'auto' }}>
								<Button color="red" onClick={this.closeModal}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" onClick={() => this.sendMessage()}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</div>
						</div>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}
const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});
export default connect(stateToProps)(withTranslation()(Phone));