import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AgentsSettings from './agents';
import AgentsConfig from './agentConfig';
import Integrations from './integrations';
import Subscription from './subscription';
import ShopifySubscription from './shopifysubs';
import ProductSettings from './product';
import InstagramSettings from './instagramsettings'
import SCA from './sca';
import IGPreview from './igpreview';
import Progress from './progress';
import { connect } from 'react-redux';

class Channel extends PureComponent {
	render() {
		let { user, product } = this.props;
		let isShopify = product.type === 'shopify';
		let host = user.host === null || user.host === 'app.dialogtab.com' || user.host === 'test.dialogtab.com';
		let auto_take_global = product && product.settings && product.settings.auto_take_global;

		return (
			<Switch>
				<Route path="/settings" exact component={Progress}></Route>
				<Route path="/settings/product" component={ProductSettings} />
				<Route path="/settings/sca" component={SCA} />
				<Route path="/settings/igpreview" component={IGPreview} />
				<Route path="/settings/agents" component={AgentsSettings} />
				<Route path="/settings/instagramsettings" component={InstagramSettings}/>
				{auto_take_global ? <Route path="/settings/agentsConfig" component={AgentsConfig} /> : ''}
				{/* 	{isShopify && <Route path="/settings/shopifysubscription" component={ShopifySubscription} />} */}
				{host ? <Route path="/settings/subscription" exact component={isShopify ? ShopifySubscription : Subscription} /> : null}
				<Route path="/settings/integrations" component={Integrations} />

				<Redirect to="/settings" />
			</Switch>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj });

export default connect(stateToProps)(Channel);
