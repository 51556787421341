import React, { Component } from 'react';
import { Table, Breadcrumb, Dimmer, Loader, Grid, Segment, Label, Divider, Header, Icon, Tab, Card, Button, Modal, Dropdown, Form } from 'semantic-ui-react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { post } from '../../util/api';
import MyMenu from './menu';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Countries from '../settings/countries.json';
const options = [
	{ key: '1', text: '1', value: 1 },
	{ key: '2', text: '2', value: 2 },
	{ key: '3', text: '3', value: 3 },
	{ key: '4', text: '4', value: 4 },
	{ key: '5', text: '5', value: 5 },
	{ key: '6', text: '6', value: 6 },
	{ key: '7', text: '7', value: 7 },
	{ key: '8', text: '8', value: 8 },
	{ key: '9', text: '9', value: 9 },
	{ key: '10', text: '10', value: 10 },
	{ key: '11', text: '11', value: 11 },
	{ key: '12', text: '12', value: 12 },
	{ key: '13', text: '13', value: 13 },
	{ key: '14', text: '14', value: 14 },
	{ key: '15', text: '15', value: 15 },
	{ key: '16', text: '16', value: 16 },
	{ key: '17', text: '17', value: 17 },
	{ key: '18', text: '18', value: 18 },
	{ key: '19', text: '19', value: 19 },
	{ key: '20', text: '20', value: 20 },
];
const startPackageOptions = [
	{ key: '1', text: '1', value: 1 },
	{ key: '2', text: '2', value: 2 },
	{ key: '3', text: '3', value: 3 },
	{ key: '4', text: '4', value: 4 },
	{ key: '5', text: '5', value: 5 },
];

const Plan_Package = [
	{
		text: 'Start',
		name: 'start_monthly',
		price: 24,
		value: '595965',
	},
	{
		text: 'Grow',
		name: 'grow_monthly',
		price: 36,
		value: '595967',
	},
	{
		text: 'Scale',
		name: 'scale_monthly',
		price: 48,
		value: '595969',
	},
];
const TableLine = (data) =>
	_.map(data, (val, index) => (
		<Table.Row key={val.owner_id}>
			<Table.Cell>{index + 1}</Table.Cell>
			<Table.Cell>{val && val.owner_id}</Table.Cell>
			<Table.Cell>{val && val.owner_email}</Table.Cell>
			<Table.Cell>
				{val.package === 'trial-reseller'
					? 'Trial'
					: val && _.find(Plan_Package, { value: val.real_package }) && _.find(Plan_Package, { value: val.real_package }).text}
			</Table.Cell>
			<Table.Cell>{val && val.agent_limit}</Table.Cell>
			<Table.Cell>{val && val.phone_limit}</Table.Cell>
			<Table.Cell>
				{_.map(val && val.agents, (it) => (
					<p>{it.agent_email}</p>
				))}
			</Table.Cell>
		</Table.Row>
	));
const TableLinePhones = (data) =>
	_.map(data, (val, index) => (
		<Table.Row key={val && val.owner_id}>
			<Table.Cell>{index + 1}</Table.Cell>
			<Table.Cell>{val && val.owner_id}</Table.Cell>
			<Table.Cell>{val && val.owner_email}</Table.Cell>
			<Table.Cell>
				{val.package === 'trial-reseller'
					? 'Trial'
					: val && _.find(Plan_Package, { value: val.real_package }) && _.find(Plan_Package, { value: val.real_package }).text}
			</Table.Cell>
			<Table.Cell>{val && val.phone_limit}</Table.Cell>
			<Table.Cell>
				{val && val.phones && val.phones.length ? (
					<Table>
						{_.map(val && val.phones, (it) => (
							<Table.Row>
								<Table.Cell>{it.id}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>{it.number || '-----'}</Table.Cell>
								<Table.Cell>{it.status}</Table.Cell>
								<Table.Cell style={{ textAlign: 'center' }}>
									<a
										href={'https://wapi.yapaytech.com/api/' + (val.wapi_pid || val.pid) + '/' + it.id + '/screen?token=' + val.wapi_token}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'screen '}
										<Icon name="external alternate" />
									</a>
									<a
										href={
											'https://wapi.yapaytech.com/api/' +
											(val.wapi_pid || val.pid) +
											'/' +
											it.id +
											'/factoryreset?token=' +
											val.wapi_token
										}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'factory reset'}
										<Icon name="redo" />
									</a>
									<a
										href={'https://wapi.yapaytech.com/api/' + (val.wapi_pid || val.pid) + '/' + it.id + '/redeploy?token=' + val.wapi_token}
										target="_blank"
										style={{ margin: 'auto' }}
										rel="noopener noreferrer"
									>
										{'redeploy '}
										<Icon name="redo" />
									</a>
								</Table.Cell>
							</Table.Row>
						))}
					</Table>
				) : null}
			</Table.Cell>
		</Table.Row>
	));
const CardWrap = (props) => (
	<Grid.Column width={props.width}>
		<Segment textAlign="center" style={{ paddingTop: 50, height: 200 }} size="massive">
			<Label size="large" color={props.color} attached="top">
				{props.title}
			</Label>
			<div>
				{props.data}
				{props.unit ? <span style={{ fontSize: '10px' }}>{props.unit}</span> : ''}
			</div>
			{props.note ? <p style={{ fontSize: '10px', padding: '0', margin: '0' }}>{props.note}</p> : ''}
			{props.note2 ? <p style={{ fontSize: '10px', padding: '0', margin: '0' }}>{props.note2}</p> : ''}
			{props.note3 ? <p style={{ fontSize: '10px', padding: '0', margin: '0' }}>{props.note3}</p> : ''}
			<Divider />
			<div style={{ fontSize: '12px' }}>{props.description}</div>
		</Segment>
	</Grid.Column>
);
const MyDivider = (props) => (
	<Divider horizontal className="my-divider">
		<Header as="h4">
			<Icon name={props.icon} />
			{props.name}
		</Header>
	</Divider>
);

class Dashboard extends Component {
	state = {
		column: null,
		direction: null,
		loading: true,
		companyName: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.name) || '',
		vatNumber: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.vatNumber) || '',
		companyCountry: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.country) || '',
		companyCity: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.city) || '',
		companyState: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.state) || '',
		companyPostCode: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.postcode) || '',
		companyStreet: (this.props.user.extra && this.props.user.extra.company && this.props.user.extra.company.street) || '',
		quantityUpdate: 1,
	};
	componentDidMount() {
		this.getData();
	}
	async getData() {
		await post('/reseller/product/dashboard').then((data) => this.setState({ data: data.data.result, loading: false }));
	}
	handleSort = (clickedColumn, data) => () => {
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	openPurchaseModal = (value, price) => {
		this.setState({ purchaseModal: true, selectedPlanId: value, purchasePrice: price });
	};
	postData(values) {
		let extra = _.merge(this.props.user.extra, {
			company: {
				name: values.companyName,
				vatNumber: values.vatNumber,
				country: values.country,
				state: values.state,
				city: values.city,
				street: values.street,
				postcode: values.postcode,
			},
		});
		post('/auth/updateInfo', {
			id: this.props.user.id,
			company: values.companyName,
			extra,
		});
	}
	closeModal = () => {
		this.setState({
			open: false,
			unsubscribe: false,
			openCreate: false,
			openNew: false,
			unsubscribeTrial: false,
			trialSuccess: false,
			previewModal: false,
			planChangeModal: false,
			purchaseModal: false,
			planChangeModalPreview: false,
			historyModal: false,
		});
	};
	checkoutHandler = (quantity, plan, isVat) => async () => {
		this.postData({
			companyName: this.state.companyName,
			vatNumber: this.state.vatNumber,
			country: this.state.companyCountry,
			city: this.state.companyCity,
			state: this.state.companyState,
			street: this.state.companyStreet,
			postcode: this.state.companyPostCode,
		});
		let link = await post('/live/payment/custompaddlelink', {
			isVat,
			plan_id: plan,
			vat_number: this.state.vatNumber,
			vat_company_name: this.state.companyName,
			vat_street: this.state.companyStreet,
			vat_city: this.state.companyCity,
			vat_country: this.state.companyCountry,
			country: this.state.companyCountry || 'BR',
			postcode: this.state.companyPostCode,
			vat_state: this.state.companyState,
			vat_postcode: this.state.companyPostCode,
			email: this.props.user.email,
			pid: this.props.product.id,
		});
		try {
			let { product = {} } = this.props;
			let email = this.props.user.email;
			//let country = 'BR';
			if (product && product.id) {
				window.Paddle.Checkout.open({
					override: link.data.response.url,
					email,
					country: this.state.companyCountry || 'BR',
					quantity,
					postcode: this.state.companyPostCode,
					successCallback: (data) => {
						post('/live/payment/checkSubscription', {
							checkout_id: data.checkout.id,
							plan_id: data.product.id,
							user_id: data.user.id,
							pid: this.props.product.id,
							quantity: data.product.quantity,
							email: data.user.email,
							extra: data,
						})
							.then((resp) => {
								if (resp.data.success) {
									window.location.reload();
									this.setState({ messageOnScreen: 'Process is successful!', messageColor: 'green' });
								} else {
									this.setState({ messageOnScreen: 'Process cannot be applied!', messageColor: 'red' });
								}
							})
							.catch((err) => {
								console.error(err);
							});
					},
					closeCallback: () => {
						this.setState({ messageOnScreen: 'Your process has been cancelled, see you again soon!', messageColor: 'red' });
					},
				});
			}
		} catch (err) {
			console.error(err);
		}
	};
	handleFormChange = (e, { name, value }) => this.setState({ [name]: value });
	render() {
		let { loading, data, column, direction } = this.state;
		let { product, t } = this.props;
		let { vatNumber, companyCity, companyCountry, companyName, companyPostCode, companyState, companyStreet, quantityUpdate } = this.state;
		let dg = _.map(data, function (it) {
			it = {
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				owner_id: it.owner_id,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones,
				package: it.package,
				real_package: it.real_package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				wapi_token: it.wapi_token,
			};
			return it;
		});

		if (loading) {
			return (
				<Page
					header={
						<Breadcrumb size="big">
							<Breadcrumb.Section as={Link} to={`/reseller`}>
								{t('Partnership')}
							</Breadcrumb.Section>
						</Breadcrumb>
					}
					flex
				>
					<MyMenu activeItem="dashboard">
						<Dimmer active inverted>
							<Loader inverted></Loader>
						</Dimmer>
					</MyMenu>
				</Page>
			);
		}
		let agentLimit = product && product.settings && product.settings.agent_limit;
		let phoneLimit = product && product.settings && product.settings.phone_limit;
		let enterprise = product && product.settings && product.settings.enterprise;
		/* let totalAgents = _.sumBy(
			_.filter(data, function (it) {
				return it.package !== 'trial-start';
			}),
			'agent_limit'
		);
		let totalPhones = _.sumBy(
			_.filter(data, function (it) {
				return it.package !== 'trial-start';
			}),
			'phone_limit'
		); */
		let totalAgents = _.sumBy(data, 'agent_limit');
		let totalPhones = _.sumBy(data, 'phone_limit');
		let trialSubs = _.filter(data, { package: 'trial-reseller' });
		let startSubs = _.filter(data, { real_package: '595965' });
		let growSubs = _.filter(data, { real_package: '595967' });
		let scaleSubs = _.filter(data, { real_package: '595969' });
		let trialSubsCount = _.sumBy(trialSubs, 'agent_limit');
		let startSubsCount = _.sumBy(startSubs, 'agent_limit');
		let growSubsCount = _.sumBy(growSubs, 'agent_limit');
		let scaleSubsCount = _.sumBy(scaleSubs, 'agent_limit');
		let trialPhoneCount = _.sumBy(trialSubs, 'phone_limit');
		let startPhoneCount = _.sumBy(startSubs, 'phone_limit');
		let growPhoneCount = _.sumBy(growSubs, 'phone_limit');
		let scalePhoneCount = _.sumBy(scaleSubs, 'phone_limit');
		let totalPrice = startSubsCount * 24 + growSubsCount * 36 + scaleSubsCount * 48 + (startPhoneCount + growPhoneCount + scalePhoneCount) * 49;
		let totalPriceAgents = startSubsCount * 24 + growSubsCount * 36 + scaleSubsCount * 48;
		let totalPricePhones = (startPhoneCount + growPhoneCount + scalePhoneCount) * 49;
		return (
			<Page
				header={
					<Breadcrumb size="big">
						<Breadcrumb.Section as={Link} to={`/reseller`}>
							{t('Partnership')}
						</Breadcrumb.Section>
					</Breadcrumb>
				}
				flex
			>
				<MyMenu activeItem="dashboard">
					<Page.Wrap>
						<Grid stackable columns={2} style={{ width: '100%', margin: 0 }}>
							<Modal open={this.state.purchaseModal} onClose={this.closeModal} style={{ maxWidth: '500px' }}>
								<Header icon="exchange" content={t('purchase')} />
								<Modal.Content>
									<div>
										<div style={{ margin: 'auto', marginBottom: '20px' }}>
											<h3>{t('packagePrice', { price: this.state.purchasePrice * this.state.quantityUpdate })}</h3>
											<div>
												<Dropdown
													options={
														this.state.selectedPlanId === '595965' || this.state.selectedPlanId === '595966'
															? startPackageOptions
															: options
													}
													placeholder={t('chooseQuantity')}
													selection
													value={quantityUpdate}
													defaultValue={1}
													onAddItem={this.handleAddition}
													onChange={this.handleChangeUpdate}
												/>
											</div>
											<p>{t('ifDontVat')}</p>
											<Button primary onClick={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, false)}>
												{t('iDontCompany')}
											</Button>
										</div>
										<Form onSubmit={this.checkoutHandler(this.state.quantityUpdate, this.state.selectedPlanId, true)}>
											<Form.Input
												label={t('vatNumber')}
												placeholder={t('companyPagePlaceholder', { query: t('vatNumber') })}
												required
												name="vatNumber"
												value={vatNumber}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('companyName')}
												required
												name="companyName"
												placeholder={t('companyPagePlaceholder', { query: t('companyName') })}
												value={companyName}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('street')}
												required
												name="companyStreet"
												value={companyStreet}
												placeholder={t('companyPagePlaceholder', { query: t('street') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('city')}
												required
												value={companyCity}
												name="companyCity"
												placeholder={t('companyPagePlaceholder', { query: t('city') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Input
												label={t('state')}
												value={companyState}
												name="companyState"
												placeholder={t('companyPagePlaceholder', { query: t('state') })}
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Dropdown
												label={t('country')}
												placeholder={t('companyPagePlaceholder', { query: t('country') })}
												search
												required
												autoComplete="off"
												fluid
												name="companyCountry"
												value={companyCountry}
												options={Countries}
												selection
												onChange={this.handleFormChange}
											></Form.Dropdown>
											<Form.Input
												label={t('postcode') + ' (**)'}
												placeholder={t('companyPagePlaceholder', { query: t('postcode') })}
												value={companyPostCode}
												required={_.includes(['AU', 'CA', 'DE', 'ES', 'FR', 'GB', 'IN', 'IT', 'NL', 'US'], this.state.country)}
												name="companyPostCode"
												onChange={this.handleFormChange}
											></Form.Input>
											<Form.Group>
												<Form.Button style={{ marginLeft: 'auto' }} positive type="submit">
													<Icon name="checkmark" /> {t('next')}
												</Form.Button>
												<Form.Button color="red" onClick={this.closeModal}>
													<Icon name="x" /> {t('close')}
												</Form.Button>
											</Form.Group>
										</Form>
										<span>{t('companyPageLabel')}</span>
									</div>
								</Modal.Content>
							</Modal>
							<MyDivider name={t('Limits')} icon="exclamation"></MyDivider>
							<CardWrap width={3} title={t('AgentLimit')} color="blue" data={agentLimit || 0} description={t('AgentLimitDesc')} />
							<CardWrap width={3} title={t('PhoneLimit')} color="yellow" data={phoneLimit || 0} description={t('PhoneLimitDesc')} />
							<MyDivider name={t('TotalData')} icon="align justify"></MyDivider>
							{enterprise ? null : (
								<CardWrap width={3} title={t('TotalPrice')} color="orange" data={'$' + totalPrice || 0} description={t('TotalPriceDesc')} />
							)}
							<CardWrap
								width={3}
								title={t('TotalAgentNumbers')}
								color="blue"
								data={totalAgents || 0}
								description={t('TotalAgentNumbersDesc')}
								note={t('TrialAgents') + ': ' + trialSubsCount}
							/>
							<CardWrap
								width={3}
								title={t('TotalPhoneNumbers')}
								color="red"
								data={totalPhones || 0}
								description={t('TotalPhoneNumbersDesc')}
								note={t('TrialPhones') + ': ' + trialPhoneCount}
							/>
							{enterprise ? null : (
								<CardWrap
									width={3}
									title={t('TotalAgentPrice')}
									color="green"
									data={'$' + totalPriceAgents || 0}
									description={t('TotalAgentPriceDesc')}
									note={startSubsCount ? startSubsCount + ' Start Agent x $24' : ''}
									note2={growSubsCount ? growSubsCount + ' Grow Agent x $36' : ''}
									note3={scaleSubsCount ? scaleSubsCount + ' Scale Agent x $48' : ''}
								/>
							)}
							{enterprise ? null : (
								<CardWrap
									width={3}
									title={t('TotalPhonePrices')}
									color="yellow"
									data={'$' + totalPricePhones || 0}
									description={t('TotalPhonePricesDesc')}
									note={totalPhones + ' ' + t('phones') + ' x $49'}
								/>
							)}
							<MyDivider name={t('subscriptions')} icon="cart"></MyDivider>
							<CardWrap
								width={3}
								title={t('XPackage', { query: t('Trial') })}
								color="yellow"
								data={trialSubs.length || 0}
								description={t('XPackageDesc', { query: t('trial') })}
								note={t('AgentNumbers') + ': ' + trialSubsCount}
								note2={t('PhoneNumbers') + ': ' + trialPhoneCount}
							/>
							<CardWrap
								width={3}
								title={t('XPackage', { query: 'Start' })}
								color="green"
								data={startSubs.length || 0}
								description={t('XPackageDesc', { query: 'start' })}
								note={t('AgentNumbers') + ': ' + startSubsCount}
								note2={t('PhoneNumbers') + ': ' + startPhoneCount}
							/>
							<CardWrap
								width={3}
								title={t('XPackage', { query: 'Grow' })}
								color="blue"
								data={growSubs.length || 0}
								description={t('XPackageDesc', { query: 'grow' })}
								note={t('AgentNumbers') + ': ' + growSubsCount}
								note2={t('PhoneNumbers') + ': ' + growPhoneCount}
							/>
							<CardWrap
								width={3}
								title={t('XPackage', { query: 'Scale' })}
								color="red"
								data={scaleSubs.length || 0}
								description={t('XPackageDesc', { query: 'scale' })}
								note={t('AgentNumbers') + ': ' + scaleSubsCount}
								note2={t('PhoneNumbers') + ': ' + scalePhoneCount}
							/>
							<MyDivider name={t('Package')} icon="dollar"></MyDivider>
							{product ? (
								<Card style={{ margin: 'auto' }}>
									<Card.Content style={{ textAlign: 'center', maxHeight: '60px', padding: '23px' }} header="Custom" />
									<Card.Content
										description={
											<div style={{ maxHeight: '200px' }}>
												<div style={{ textAlign: 'center' }}>
													<span style={{ fontSize: '72px', lineHeight: '72px' }}>
														$185
														<span style={{ fontSize: '16px', color: '#A9A9A9' }}>{t('/mo')}</span>
													</span>
												</div>
											</div>
										}
									/>
									<Card.Content style={{ maxHeight: '65px' }}>
										<Button onClick={() => this.openPurchaseModal(800955, 185)} positive fluid loading={this.state.trialButtonLoadingLive}>
											{t('purchase')}
										</Button>
									</Card.Content>
								</Card>
							) : (
								''
							)}
						</Grid>

						<Tab
							menu={{ secondary: true, pointing: true }}
							panes={[
								{
									menuItem: t('general'),
									render: () => (
										<Tab.Pane attached={false}>
											<Table celled sortable>
												<Table.Header>
													<Table.Row>
														<Table.HeaderCell>#</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_id' ? direction : null}
															onClick={this.handleSort('owner_id', dg)}
														>
															Id
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_email' ? direction : null}
															onClick={this.handleSort('owner_email', dg)}
														>
															Email
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'package' ? direction : null}
															onClick={this.handleSort('package', dg)}
														>
															{t('Package')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'agent_limit' ? direction : null}
															onClick={this.handleSort('agent_limit', dg)}
														>
															Agent Limit
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'phone_limit' ? direction : null}
															onClick={this.handleSort('phone_limit', dg)}
														>
															{t('PhoneLimit')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'agents' ? direction : null}
															onClick={this.handleSort('agents', dg)}
														>
															{t('agents')}
														</Table.HeaderCell>
													</Table.Row>
												</Table.Header>
												<Table.Body>{TableLine(dg)} </Table.Body>
											</Table>
										</Tab.Pane>
									),
								},
								{
									menuItem: t('Phones'),
									render: () => (
										<Tab.Pane attached={false}>
											<Table celled sortable>
												<Table.Header>
													<Table.Row>
														<Table.HeaderCell>#</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_id' ? direction : null}
															onClick={this.handleSort('owner_id', dg)}
														>
															Id
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'owner_email' ? direction : null}
															onClick={this.handleSort('owner_email', dg)}
														>
															Email
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'package' ? direction : null}
															onClick={this.handleSort('package', dg)}
														>
															{t('Package')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'phone_limit' ? direction : null}
															onClick={this.handleSort('phone_limit', dg)}
														>
															{t('PhoneLimit')}
														</Table.HeaderCell>
														<Table.HeaderCell
															sorted={column === 'phones' ? direction : null}
															onClick={this.handleSort('phones', dg)}
														>
															{t('Phones')}
														</Table.HeaderCell>
													</Table.Row>
												</Table.Header>
												<Table.Body>{TableLinePhones(dg)} </Table.Body>
											</Table>
										</Tab.Pane>
									),
								},
							]}
						/>
					</Page.Wrap>
				</MyMenu>
			</Page>
		);
	}
}

const stateToProps = (state) => ({
	product: state.product.reseller,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Dashboard));
