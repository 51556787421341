import React, { Component } from 'react';
import Page from '../../components/Page';
import { Icon, Segment, Form, Table, Button, Modal, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

class Phones extends Component {
	phoneToDelete = null;
	state = {
		loading: true,
		saveButton: 'add',
		ex_file: '',
	};
	componentDidMount() {
		this.getList();
	}
	getList() {
		const { product } = this.props;
		let pid = product.id;
		fetch(`https://micro.yapaytech.com/api/dump/readMulti?pid=${pid}&key=contactList`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				let excelData = result;
				this.setState({ list: excelData, loading: false });
			});
	}
	deleteList() {
		const { product } = this.props;
		let pid = product.id;
		let selectedExcel = this.state.selectedExcel;
		this.setState({ deleteButtonLoading: true });
		fetch(`https://micro.yapaytech.com/api/dump/remove`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ pid, id: selectedExcel._id }),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ deleteButtonLoading: false, deleteModal: false });
				this.getList();
			});
	}

	fileUpload(file) {
		const { product } = this.props;
		this.setState({ loadingBtn: true });
		let pid = product.id;
		const url = 'https://micro.yapaytech.com/api/dump/write/excel';
		const formData = new FormData();
		formData.append('file', file);
		formData.append('pid', pid);
		formData.append('key', 'contactList');
		let file_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				file_url = data && data.files && data.files[0] && data.files[0].url;
				this.setState({ ex_file: file_url, loadingBtn: false, message: true });
				this.getList();
			});
	}
	handleFileInput = (e) => {
		let file = e.target.files[0];
		this.fileUpload(file);
	};
	downloadExample = async () => {
		var a = document.createElement('a');
		a.href = '/contacts_example.xlsx';
		a.target = '_blank';
		a.download = 'contacts_example.xlsx';
		a.click();
	};
	render() {
		const { t } = this.props;
		let { loadingBtn, message, list } = this.state;
		if (this.state.loading) {
			return <Page.Loader />;
		} else {
			return (
				<Segment>
					<div style={{ padding: '10px', width: '100%' }}>
						<h3>
							<Icon name="whatsapp" />
							{t('contacts')}
						</h3>
						<Form>
							<Form.Field style={{ display: 'flex', marginBottom: '10px' }}>
								<div className="image-upload" style={{ margin: 'auto' }}>
									<label htmlFor="file-input" className={`ui blue icon left labeled button ${loadingBtn ? 'loading' : null}`}>
										{t('upload')}
										<Icon name="upload" style={{ cursor: 'pointer' }}></Icon>
									</label>
									<Button
										onClick={() => this.downloadExample()}
										content={t('example download')}
										icon="download"
										labelPosition="left"
										color="teal"
									/>
								</div>

								<input
									id="file-input"
									type="file"
									accept=".xlsx, .xls"
									style={{ display: 'none' }}
									onChange={(e) => {
										this.handleFileInput(e);
									}}
								/>
							</Form.Field>
							{message ? <p style={{ textAlign: 'center' }}>{t('messageExcelSuccess')}</p> : null}
						</Form>
					</div>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>{'Name'}</Table.HeaderCell>
								<Table.HeaderCell>{'Date'}</Table.HeaderCell>
								<Table.HeaderCell>{'Count'}</Table.HeaderCell>
								<Table.HeaderCell>{}</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(list, (it) => (
								<Table.Row>
									<Table.Cell>{it.filename || moment(it.date).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
									<Table.Cell>{moment(it.date).format('DD-MM-YYYY')}</Table.Cell>
									<Table.Cell>{it.data.length}</Table.Cell>
									<Table.Cell>
										<Button
											icon
											size="mini"
											style={{ textAlign: 'right', marginLeft: '20px' }}
											onClick={() => this.setState({ openModal: true, numberList: it.data, selectedExcel: it })}
										>
											<Icon name="eye"></Icon>
										</Button>
										<Button
											icon
											size="mini"
											style={{ marginLeft: '20px' }}
											onClick={() => this.setState({ deleteModal: true, numberList: it.data, selectedExcel: it })}
										>
											<Icon name="trash"></Icon>
										</Button>
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
					<Modal open={this.state.openModal} onClose={() => this.setState({ openModal: false })} closeIcon>
						<Modal.Header>{this.state.selectedExcel && this.state.selectedExcel.filename}</Modal.Header>
						<Modal.Content>
							<Table>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>{'Id'}</Table.HeaderCell>
										<Table.HeaderCell>{'Name'}</Table.HeaderCell>
										<Table.HeaderCell>{'Phone'}</Table.HeaderCell>
										<Table.HeaderCell>{'Website'}</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{_.map(this.state.numberList, (it) => (
										<Table.Row>
											<Table.Cell>{it['Users Id']}</Table.Cell>
											<Table.Cell>{it.Name}</Table.Cell>
											<Table.Cell>{it.Phone}</Table.Cell>
											<Table.Cell>{it.Website}</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</Modal.Content>
					</Modal>
					<Modal
						open={this.state.deleteModal}
						onClose={() => {
							this.setState({ deleteModal: false });
						}}
					>
						<Header icon="dont" content={t('delete')} />
						<Modal.Content>
							<p>{t('areYouSureDeleteExcel')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button
								color="red"
								onClick={() => {
									this.setState({ deleteModal: false });
								}}
							>
								<Icon name="remove" /> {t('no')}
							</Button>
							<Button color="green" onClick={() => this.deleteList()} loading={this.state.deleteButtonLoading}>
								<Icon name="checkmark" /> {t('yes')}
							</Button>
						</Modal.Actions>
					</Modal>
				</Segment>
			);
		}
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Phones));
