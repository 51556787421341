import React, { Component } from 'react';
import Page from '../../components/Page';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { toWapi, log, post } from '../../util/api';
import { Button, Segment, Table, Grid, GridColumn, Form, Header, Checkbox, Dropdown, Modal, Icon, Popup, Input } from 'semantic-ui-react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import tr from 'date-fns/locale/tr';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import pt from 'date-fns/locale/pt';
import ru from 'date-fns/locale/ru';
import de from 'date-fns/locale/de';
const dateLocales = { tr, en, es, pt, ru, fr, de };
const filterPassedTime = (time) => {
	const currentDate = new Date();
	const selectedDate = new Date(time);

	return currentDate.getTime() < selectedDate.getTime();
};
const time_options = [];
function formatTime(value) {
	let dur = moment.duration(value);
	return moment(dur._data.minutes, 'm').format('mm') + ':' + moment(dur._data.seconds, 's').format('ss');
}
function formatTimeText(value) {
	let dur = moment.duration(value);
	return (
		dur._data.days * 24 +
		' gün ' +
		dur._data.hours +
		' saat ' +
		moment(dur._data.minutes, 'm').format('m') +
		' dakika ' +
		moment(dur._data.seconds, 's').format('s') +
		' saniye'
	);
}
for (var i = 1; i <= 40; i++) {
	time_options.push({ key: '' + i * 15, text: formatTime(i * 15000), value: i * 15000 });
}
const tagged = (tag, str) => `<${tag}>${str}</${tag}\>`;
const tags = {
	'~': 's',
	'*': 'b',
	_: 'em',
};
var __escaped = {
	'<': '&lt;',
	'>': '&gt;',
	'&': '&amp;',
	'"': '&quot;',
	"'": '&#x27;',
	'`': '&#x60;',
	'\n': '<br>',
	'\\n': '<br>',
};

const escapeHTML = function (text) {
	if (!text) return text;
	return String(text).replace(/\\n|[<>&"`'\n]/g, function (character) {
		return __escaped[character] || '';
	});
};

function Markdown(str = '') {
	let ret = str.replace(/([*~_]{1,5})([^\s_*~].*?)\1/g, (a, b, c) => {
		if (tags[b] && c) return tagged(tags[b], Markdown(c));
		return a;
	});

	return ret;
}
function checkForImage(url, file_format) {
	let regexMedia = /^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/gim;
	let regexVideo = /^https?:\/\/.*\/.*\.(mp4)\??.*$/gim;
	let regexVoice = /^https?:\/\/.*\/.*\.(ogg|mp3)\??.*$/gim;

	let result;
	if (url.match(regexMedia)) {
		result = 'image';
	} else if (url.match(regexVideo)) {
		result = 'video';
	} else if (url.match(regexVoice)) {
		result = 'audio';
	} else {
		result = 'unvalid';
	}
	if (file_format) result = file_format;
	//console.log('result', result);
	return result;
}
/* function timeChangeStart(value) {
	let { lasttime } = this.state;
	lasttime = moment(lasttime).set({ hour: moment(value, 'HH:mm').format('HH'), minute: moment('00', 'mm').format('mm') });
	this.setState({ lasttime });
} */
class Phone extends Component {
	state = {
		startDate: new Date(),
		phones: [],
		loading: true,
		targets: [],
		message_type: 'text',
		sw_groups: true,
		sw_contacts: false,
		start_date: new Date(),
		lasttime: new Date(),
		selectedTag: 'all',
		message: '',
		selectedSource: 'whatsappweb',
		phoneSource: 'contacts',
		sending_date: 'now',
		selectedAralik: 20 * 15000,
	};
	async componentDidMount() {
		let { product } = this.props;
		this.getSchedulesList();
		await this.getContactList();
		await this.getLogTargets();
		await this.getListPhone();
		await this.getBusinessPhoneList();
		await this.getListExcel();
		await this.getListTable();
		let { phones } = this.state;
		for (let index = 0; index < phones.length; index++) {
			const element = phones[index];
			/* if (index === 0) */ this.getGroups(element.id);
		}
		if (product.settings.sendMessageContacts) this.setState({ sw_groups: false, sw_contacts: true });
		this.setState({ loading: false, tableloading: false });
	}
	async getSchedulesList() {
		const { product } = this.props;
		let pid = product.id;
		log('/api/listSchedules', {
			pid,
			limit: 1,
		}).then((data) => this.setState({ job_list: data.data.result }));
	}
	async getListExcel() {
		const { product, t } = this.props;
		let pid = product.id;
		fetch(`https://micro.yapaytech.com/api/dump/readMulti?pid=${pid}&key=contactList`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = data.result;
				let excelData = result;
				_.map(excelData, (it) => {
					_.map(it.data, (o) => {
						if (o.Etiket) o.tags = o.Etiket.split(',');
					});
				});
				let phoneSourceList = [
					{
						value: 'contacts',
						text: t('contacts'),
					},
				];
				if (excelData && excelData.length) {
					_.map(excelData, (it) => {
						phoneSourceList.push({
							value: 'excel_' + it._id,
							text: it.filename || moment(it.date).format('DD-MM-YYYY HH:mm:ss'),
						});
					});
				}
				this.setState({ excelData });
				if (excelData && excelData.length) this.setState({ phoneSourceList, phoneSource: 'excel_' + excelData[0]._id });
			});
	}

	async getListTable() {
		const { product } = this.props;
		let pid = product.id;
		if (product.settings.merged_broadcast_list)
			await post('/broadcast/listBroadcastUser', { pid }).then((data) => {
				let list = data.data.result;
				let merged_excel_list =
					(list &&
						list.length &&
						_.map(list, (it) => ({
							Name: it.name,
							Phone: it.phone,
							tags: it.data.tag,
						}))) ||
					[];
				this.setState({
					merged_excel_list,
				});
			});
	}
	async getLogTargets() {
		let { product } = this.props;
		let pid = product.id;
		await log('/live/groupMessageLog/last', { pid }).then((data) => {
			let response = data.data && data.data.message;
			let targetsData = (response && response[0] && response[0].log && response[0].log.groups) || [];
			let targets = [];
			_.map(targetsData, (it) => targets.push(it));
			if (!product.settings.merged_broadcast_list && _.filter(targets, { number: 'waba' }).length) this.setState({ selectedSource: 'waba', targets: [] });
			else {
				this.setState({ targets });
			}
		});
	}
	async getListPhone() {
		let { product } = this.props;
		let pid = product.id;
		await toWapi(`/api/${pid}/listPhones`, product.settings.wapi_token)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				data = _.orderBy(
					_.filter(data.data, function (it) {
						return it.type === 'whatsapp';
					}),
					'id'
				);
				_.map(data, (it) => (it.loading = true));
				/* _.map(data, async (it) => {
					let stats = await this.getQueue(it.id);
					let queue = stats.sending;
					it.stats = queue;
				}); */
				let dropdownList = _.map(
					_.filter(data, (o) => o.number),
					(it) => ({
						value: it.id,
						text: it.name ? `${it.name}` : it.number,
						content: <Header size="small" content={it.name || it.number} subheader={it.name ? it.number : ''} />,
					})
				);
				dropdownList = _.concat({ text: 'Hepsi', value: 'all', content: <Header size="small" content={'Hepsi'} subheader={''} /> }, dropdownList);
				this.setState({
					dropdownList,
					phones: _.filter(data, (o) => o.number),
					phone_ids: _.map(data, (o) => o.id),
					btnLoading: false,
					selectedphone: dropdownList.length === 2 ? dropdownList[1] && dropdownList[1].value : dropdownList[0] && dropdownList[0].value,
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}
	async getContactList() {
		let { product } = this.props;
		//console.log('product', product);
		let pid = product.settings.live.token;
		//let pid = product.id;
		await fetch('https://live.dahi.ai/blacklist/list', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					blackList: data.result,
				});
			});
		await fetch('https://live.dahi.ai/analytics/dialogtab/userLabelList', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					contactList: data.data,
				});
			});
		await post('/dahi/tag/list', {}).then((data) => {
			//console.log(data);
			this.setState({ tagList: data.data.result.result });
		});
	}
	async getBusinessPhoneList() {
		let { product } = this.props;
		let pid = product.id;
		await post('/broadcast/phoneList', { pid }).then((data) => {
			let phones = data.data.phones;
			let businessPhoneList = [];
			if (phones && phones.length) {
				businessPhoneList = _.map(phones, (it) => ({
					value: it,
					text: it,
				}));
			}
			this.setState({ businessPhoneList, selectedBusinessphone: businessPhoneList && businessPhoneList[0] && businessPhoneList[0].value });
		});
	}
	async getBusinessTemplates(refresh = false) {
		let { selectedBusinessphone } = this.state;
		let { product } = this.props;
		let pid = product.id;
		await post('/broadcast/templateList ', { pid, shop: selectedBusinessphone, refresh }).then((data) => {
			//console.log('da', data);
			let templates = _.filter(data.data.templates, { status: 'approved' });
			let template_config = data.data.config;
			let template_list = _.map(templates, (it) => ({
				text: it.name,
				value: it.name,
				includeVariable: _.findIndex(it.components, (o) => /{{\d+}}/.test(o.text)),
			}));
			template_list = _.filter(template_list, (it) => it.includeVariable < 0);
			this.setState({ templates, template_list, template_config, templateLoading: false });
		});
	}
	componentDidUpdate(oldProps, oldState) {
		const check_list = ['selectedSource', 'selectedBusinessphone', 'phoneSource'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			if (key === 'selectedBusinessphone') {
				if (this.state[key] !== oldState[key]) {
					this.getBusinessTemplates();
				}
			} else {
				if (this.state[key] !== oldState[key]) {
					if (this.state[key] === 'waba' || oldState[key] === 'waba') {
						this.setState({
							targets: [],
							message_type: this.state[key] === 'waba' ? 'template' : 'link',
						});
					} else if (key === 'phoneSource') {
						this.setState({ targets: [] });
					}
					break;
				}
			}
		}
	}
	async getGroups(phone_id) {
		let { phones, contactList } = this.state;
		let { product } = this.props;
		let pid = product.id;
		let sendMessageContacts = product.settings.sendMessageContacts;
		let type = sendMessageContacts ? 'type=contact' : '';
		let data = await toWapi(`/api/${pid}/${phone_id}/contacts?${type}`, product.settings.wapi_token)
			.then((data) => {
				if (data.status !== 200) {
					return;
				}
				return data.data && data.data.data;
			})
			.catch((err) => {
				_.find(phones, { id: phone_id }).isError = true;
				console.error(err);
			});
		let fi = _.find(phones, { id: phone_id });
		_.map(contactList, (it) => {
			let sep = _.find(data, { id: it.phone + '@c.us' });
			if (sep) sep.tags = it.tags;
		});
		fi.groups = data;
		fi.loading = false;
		if (data && data.type === 'error') {
			_.find(phones, { id: phone_id }).isError = true;
		}
		//_.find(phones, { id: phone_id }).contacts = contacts_data;
		this.setState({ phones });
	}
	async postLog(data) {
		await log('/live/groupMessageLog/add', data);
	}
	fixPhone(number) {
		number = '' + number;
		number = number.replace('+', '').replace(/ /gm, '');
		if (/^05/.test(number)) {
			number = '9' + number;
		} else if (/^5/.test(number)) {
			number = '90' + number;
		} else {
			number = number;
		}
		return number;
	}
	async sendMessage(isTest = false) {
		let {
			targets,
			message,
			message_type,
			text_message,
			selectedSource,
			selectedBusinessphone,
			template_id,
			selectedphone,
			startDate,
			sending_date,
			testNumber,
			file_format,
			file_name,
			job_list,
			businessPhoneList,
			dropdownList,
			lasttime,
			selectedTag,
			message_name,
			phone_ids,
		} = this.state;

		this.setState({ btnLoading: true });
		let { product } = this.props;
		let pid = product.id;
		let uid = product.uid;
		let logData = {},
			start_time;
		let diff_minute = moment(startDate).diff(moment(), 's');
		let isWapiLiveapi = product && product.settings && product.settings.wapiliveapi;
		let fixPhoneSending = product && product.settings && product.settings.fixPhoneSending;
		let randomPhoneId = product && product.settings && product.settings.randomPhoneId;
		//if (randomPhoneId) selectedphone = _.sample(phone_ids);
		if (sending_date === 'now') {
			start_time = 1;
		} else {
			start_time = Math.max(diff_minute, 1);
		}
		let hash = '' + new Date().getTime();
		if (message_type === 'link') file_format = 'link';
		if (!file_format) file_format = message_type;
		let content = { type: file_format, message: message };
		if (text_message) content.text = text_message;
		let extra = {};
		if (isTest) {
			let filtered_phones = _.filter(dropdownList, (it) => it.value !== 'all');
			let first_phone =
				selectedSource === 'waba'
					? (businessPhoneList[0] && businessPhoneList[0].value) || 'all'
					: (filtered_phones[0] && filtered_phones[0].value) || 'all';
			targets = [{ id: testNumber, name: testNumber, number: selectedphone === 'all' ? first_phone : selectedphone, phone: '' }];
		}
		if (product.settings.wsendwithdelay) {
			let max_phone_length = _.round(moment(lasttime).diff(moment(), 'seconds') / 60);
			targets = _.filter(targets, (it, i) => i < max_phone_length);
		}
		if (selectedSource === 'waba') {
			if (fixPhoneSending) {
				let _new_targets = _.map(targets, (it) => ({
					Name: it.Name,
					Phone: this.fixPhone(it.Phone),
					'Users Id': this.fixPhone(it.Phone),
					Label: it.Label,
					number: it.number,
					phone: it.phone,
					id: this.fixPhone(it.Phone),
					name: it.name,
					type: it.type,
				}));
				targets = _new_targets;
			}
			let new_id_ob = _.maxBy(job_list, (it) => parseInt(it.data.event.extra.id.replace(/.*_/, '')));

			let new_id =
				(new_id_ob &&
					new_id_ob.data &&
					new_id_ob.data.event &&
					/_/.test(new_id_ob.data.event.extra.id) &&
					parseInt(new_id_ob.data.event.extra.id.replace(/.*_/, ''))) ||
				0;
			//console.log('new_id', new_id_ob, new_id);
			extra = {
				source: 'liveapi',
				shop: selectedBusinessphone,
				numbers: _.map(targets, (it) => ({
					id: it.id,
					name: it.name || it.id,
				})),
				start_time,
				id: selectedBusinessphone + '_' + (new_id + 1),
				template: {
					value: template_id,
					params: {},
				},
				singletonMinutes: 1,
				hash,
			};
			if (product.settings.wsendwithdelay) {
				extra.seconds = 60;
			}
			logData = {
				message: template_id,
				message_type,
				text_message,
				extra,
				template_name: template_id,
				groups: _.map(targets, ({ id, name, phone, number }) => ({
					id,
					name,
					phone,
					number,
				})),
			};
		} else {
			let new_id_ob = _.maxBy(job_list, (it) => it.data.event && it.data.event.extra && parseInt(it.data.event.extra.id.replace(/.*_/, '')));
			let new_id = (new_id_ob && new_id_ob.data && new_id_ob.data.event && parseInt(new_id_ob.data.event.extra.id.replace(/.*_/, ''))) || 0;
			if (fixPhoneSending) {
				let _new_targets = _.map(targets, (it) => ({
					Name: it.Name,
					Phone: this.fixPhone(it.Phone),
					'Users Id': this.fixPhone(it.Phone),
					Label: it.Label,
					number: it.number,
					phone: it.phone,
					id: this.fixPhone(it.Phone),
					name: it.name,
					type: it.type,
				}));
				targets = _new_targets;
			}
			if (isWapiLiveapi) {
				//console.log('isWapi-live', file_format);
				if (file_format === 'media' && message_type === 'media') {
					file_format = checkForImage(message);
				}
				if (file_format === 'image' || file_format === 'video' || file_format === 'document') {
					content = {
						type: file_format,
						imageUrl: message,
						targetlist: selectedTag,
						message_name,
						hash,
						template: { name: selectedphone + '_' + (new_id + 1) },
					};
					if (text_message) content.text = text_message;
					if (file_format === 'document') content.filename = file_name;
				} else if (file_format === 'link') {
					content = {
						type: 'text',
						text: message,
						targetlist: selectedTag,
						message_name,
						hash,
						template: { name: selectedphone + '_' + (new_id + 1) },
					};
					if (text_message) content.text = message + '\n' + text_message;
				} else if (file_format === 'audio') {
					content = {
						type: 'audio',
						imageUrl: message,
						targetlist: selectedTag,
						message_name,
						hash,
						template: { name: selectedphone + '_' + (new_id + 1) },
					};
					if (text_message) content.text = text_message;
				} else {
					content = {
						type: 'text',
						text: message,
						targetlist: selectedTag,
						message_name,
						hash,
						template: { name: selectedphone + '_' + (new_id + 1) },
					};
				}
			} else {
				if (file_format === 'image' || file_format === 'video' || file_format === 'document' || file_format === 'audio') {
					content = { type: 'media', message, template: { name: selectedphone + '_' + (new_id + 1) } };
					if (text_message) content.text = text_message;
				}
			}
			extra = {
				source: isWapiLiveapi ? 'wapi-liveapi' : 'wapi',
				numbers: _.map(targets, (it) => ({
					id: '' + it.id,
					name: it.name || it.Name || it.id,
					phone_id: it.number,
				})),
				phone_id: selectedphone,
				phone_ids,
				randomPhoneId,
				id: selectedphone + '_' + (new_id + 1),
				content,
				start_time,
				singletonMinutes: 1,
				targetlist: selectedTag,
				message_name,
				hash,
			};
			if (product.settings.wsendwithdelay) {
				extra.seconds = 60;
			}
			if (!extra.seconds) {
				extra.seconds = this.state.selectedAralik / 1000;
			}
			logData = {
				message,
				message_type,
				text_message,
				template_name: selectedphone + '_' + (new_id + 1),
				extra,
				groups: _.map(targets, ({ id, name, phone, number }) => ({
					id,
					name,
					phone,
					number,
				})),
			};
		}
		if (!isTest) this.postLog({ pid, uid, log: logData });
		//return;
		await log('/api/addSchedule', {
			category: 'schedule',
			label: 'broadcast_message_event',
			extra,
			pid,
		});
		this.getSchedulesList();
		this.setState({ btnLoading: false, openModal: false, openTestModal: false });
	}
	onEmojiClick = (emoji, event) => {
		let { message } = this.state;
		let start_index = document.getElementById('message_area').selectionEnd;
		let native = emoji.native;
		message = message && message.substr(0, start_index) + native + message.substr(start_index);
		this.setState({ message });
	};
	textEditor(icon) {
		let { message } = this.state;
		let start_index = document.getElementById('message_area').selectionStart,
			end_index = document.getElementById('message_area').selectionEnd;
		message = message && message.substr(0, start_index) + icon + message.substr(start_index);
		message = message && message.substr(0, end_index + 1) + icon + message.substr(end_index + 1);
		this.setState({ message });
	}
	textEditorText(icon) {
		let { text_message } = this.state;
		let start_index = document.getElementById('message_area_text').selectionStart,
			end_index = document.getElementById('message_area_text').selectionEnd;
		text_message = text_message && text_message.substr(0, start_index) + icon + text_message.substr(start_index);
		text_message = text_message && text_message.substr(0, end_index + 1) + icon + text_message.substr(end_index + 1);
		this.setState({ text_message });
	}
	onEmojiClickText = (emoji, event) => {
		let { text_message } = this.state;
		let start_index = document.getElementById('message_area_text').selectionEnd;
		let native = emoji.native;
		text_message = text_message && text_message.substr(0, start_index) + native + text_message.substr(start_index);
		this.setState({ text_message });
	};
	handleChange = (e, { name, value }) => {
		/* const curr = this.state.selectedphone;
		if ((curr !== 'waba' && value === 'waba') || (curr === 'waba' && value !== 'waba')) {
			this.setState({ targets: [] });
		} */
		if (name === 'message_type') this.setState({ file_format: '' });
		this.setState({ [name]: value });
		if (name === 'selectedphone' && value === 'all') this.setState({ sw_contacts: false });
	};
	fileUpload(file, name) {
		const url = 'https://uploads.yapaytech.com/flow/uploadImage';
		const formData = new FormData();
		formData.append('files[]', file);
		let image_url = '';
		let file_type = file.type;
		let file_format = '';
		if (/image/.test(file_type)) file_format = 'image';
		else if (/video/.test(file_type)) file_format = 'video';
		else if (/application/.test(file_type)) file_format = 'document';
		else if (/audio/.test(file_type)) file_format = 'audio';
		this.setState({ file_format, file_name: file.name });
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => {
				return response.json();
			})
			.then(async (data) => {
				image_url = data && data.files && data.files[0] && data.files[0].url;
				this.setState({ message: image_url });
			});
	}
	handleFileInput = (e) => {
		let file = e.target.files[0];
		let name = e.target.name;
		this.fileUpload(file, name);
	};
	handleChangeArray = (e, { name, value }) => this.setState({ [name]: value });
	toggle = (o, number, phone) => {
		let { targets } = this.state;
		let isCheck = _.find(targets, { id: o.id, number });
		o.number = number;
		o.phone = phone;
		if (!isCheck) {
			targets.push(o);
		} else {
			_.remove(targets, function (it) {
				return it.id === o.id && it.phone === o.phone;
			});
		}
		this.setState({ targets });
	};
	toggleExcel = (o, number, base_phone) => {
		let { targets } = this.state;
		let isCheck = _.find(targets, { id: number });
		//id: '905539834337@c.us', name: '+90 553 983 43 37', phone: '79655865528', number: 13628
		o.number = base_phone;
		o.phone = base_phone;
		o.id = number;
		o.name = o.Name;
		o.type = 'chat';
		if (!isCheck) {
			targets.push(o);
		} else {
			_.remove(targets, function (it) {
				return it.id === number;
			});
		}
		this.setState({ targets });
	};
	toggleSw = (e, { name }) => this.setState((prevState) => ({ [name]: !prevState[name] }));
	toggleAll = (o, number, phone) => {
		let { targets, phoneSource, blackList } = this.state;
		if (phoneSource !== 'contacts') {
			_.map(o, (it) => {
				it.number = number;
				it.phone = it.Phone;
				it.id = it.Phone;
				it.type = 'chat';
				let isCheck = _.find(targets, { id: it.Phone });
				let isBlackList = _.includes(blackList, '' + it.Phone);
				if (!isCheck && !isBlackList) return targets.push(it);
			});
		} else {
			_.map(o, (it) => {
				it.number = number;
				it.phone = phone;
				let isCheck = _.find(targets, { id: it.id });
				let isBlackList = _.includes(blackList, o.id && o.id.replace('@c.us', ''));
				if (!isCheck && !isBlackList) return targets.push(it);
			});
		}
		this.setState({ targets });
	};
	toggleAllExcel = (o) => {
		let { targets, blackList, selectedphone, selectedSource } = this.state;
		_.map(o, (it) => {
			it.number = selectedSource === 'waba' ? 'waba' : selectedphone;
			it.phone = selectedSource === 'waba' ? 'waba' : selectedphone;
			it.id = it.Phone;
			it.name = it.Name;
			let isCheck = _.find(targets, { id: it.Phone });
			let isBlackList = _.includes(blackList, '' + it.Phone);
			if (!isCheck && !isBlackList) return targets.push(it);
		});
		this.setState({ targets });
	};
	closeModal = () => {
		this.setState({ openModal: false, openListModal: false, btnLoading: false, openTestModal: false });
	};
	setStartDate = (date) => {
		this.setState({ start_date: new Date(date) });
	};

	render() {
		let {
			phones,
			loading,
			message,
			message_name,
			targets,
			btnLoading,
			message_type,
			text_message,
			dropdownList,
			selectedphone,
			//isError,
			sw_groups,
			sw_contacts,
			//sw_excel,
			//start_date,
			//contactList,
			tagList,
			selectedTag,
			//emojiObject,
			excelData,
			template_id,
			selectedSource,
			businessPhoneList,
			selectedBusinessphone,
			template_list,
			phoneSource,
			startDate,
			sending_date,
			testNumber,
			template_config,
			templateLoading,
			file_name,
			blackList,
			lasttime,
			phoneSourceList,
			merged_excel_list,
		} = this.state;
		let { t, i18n, product } = this.props;
		let tag_filter = _.concat(
			[{ value: 'all', text: 'Hepsi' }],
			_.map(tagList, (it) => ({
				value: it._id,
				text: it.title,
			}))
		);
		let fixPhoneSending = product && product.settings && product.settings.fixPhoneSending;
		if (loading) return <Page.Loader></Page.Loader>;
		let max_phone_length = _.round(moment(lasttime).diff(moment(), 'seconds') / 60);
		let selectedphonedata = selectedphone === 'all' || selectedphone === 'waba' ? phones : _.find(phones, { id: selectedphone });
		let messageTypes = [
			{
				value: 'text',
				text: t('Yazı'),
			},
			{
				value: 'media',
				text: t('Medya'),
			},
			{
				value: 'link',
				text: 'Link',
			},
			{
				value: 'document',
				text: t('document'),
			},
			{
				value: 'audio',
				text: t('audio'),
			},
		];
		let sourceList = [
			{
				value: 'whatsappweb',
				text: 'WhatsApp Web',
			},
			{
				value: 'waba',
				text: 'WhatsApp Business',
			},
		];
		if (this.state.selectedSource === 'waba') {
			messageTypes = [
				{
					value: 'template',
					text: t('Template'),
				},
			];
		} else {
			messageTypes = [
				{
					value: 'text',
					text: t('Yazı'),
				},
				{
					value: 'media',
					text: t('Medya'),
				},
				{
					value: 'link',
					text: t('Link'),
				},
				{
					value: 'document',
					text: t('document'),
				},
				{
					value: 'audio',
					text: t('audio'),
				},
			];
		}
		/* let c_types = [
			{ text: t('Kişiler'), value: 'chat' },
			{ text: t('Gruplar'), value: 'group' },
		]; */
		//console.log('blackList', blackList);
		let showList = [],
			showExcelList = [];
		if (/excel/.test(phoneSource)) {
			let _tempExcel = _.find(excelData, (it) => it._id === phoneSource.replace('excel_', ''));
			/* console.log('_tempExcel', excelData, phoneSource.replace('excel_', ''), _tempExcel); */
			showExcelList = _tempExcel && _tempExcel.data;
		} else {
			showExcelList = excelData && excelData[0] && excelData[0].data;
		}

		if (selectedSource !== 'waba') {
			showList = _.orderBy(
				_.filter(selectedphonedata.groups, function (it) {
					return (it.type === 'group' && sw_groups) || (it.type === 'chat' && sw_contacts);
				}),
				'type',
				'desc'
			);
		}
		if (merged_excel_list && merged_excel_list.length) {
			showExcelList = merged_excel_list;
			showList = merged_excel_list;
		}
		if (selectedTag !== 'all' && !merged_excel_list) {
			showList = _.filter(showList, (it) => _.includes(it.tags, selectedTag));
			let selectedTag_Text = _.find(tagList, { _id: selectedTag }).title;
			let _tempExcel = _.find(excelData, (it) => it._id === phoneSource.replace('excel_', ''));
			showExcelList = _.filter(_tempExcel.data, (it) => _.includes(it.tags, selectedTag_Text));
		} else if (selectedTag !== 'all' && merged_excel_list.length) {
			let selectedTag_Text = _.find(tagList, { _id: selectedTag }).title;
			showList = _.filter(showList, (it) => _.includes(it.tags, selectedTag_Text));
			let _tempExcel = merged_excel_list;
			showExcelList = _.filter(_tempExcel, (it) => _.includes(it.tags, selectedTag_Text));
		}

		return (
			<div>
				<Grid>
					<GridColumn width={12}>
						<Segment className="scroll">
							<div style={{ display: 'flex' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Header>{t('Gruplar')}</Header>
								</div>
							</div>
							{selectedSource === 'waba' ? (
								<Table className="phone">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell width={10}>
												<Grid style={{ marginLeft: '10px' }}>
													<Grid.Row>
														{businessPhoneList && businessPhoneList.length ? (
															<Dropdown
																selection
																options={sourceList}
																name="selectedSource"
																value={selectedSource}
																onChange={this.handleChange}
															></Dropdown>
														) : null}
														{merged_excel_list && merged_excel_list.length ? null : excelData && excelData.length ? (
															<Dropdown
																style={{ marginLeft: '10px' }}
																selection
																options={_.filter(phoneSourceList, (it) => it.value !== 'contacts')}
																name="phoneSource"
																value={phoneSource}
																onChange={this.handleChange}
															/>
														) : null}
													</Grid.Row>
													<Grid.Row>
														{tagList && tagList.length ? (
															<Dropdown
																label={t('label')}
																selection
																options={tag_filter}
																name="selectedTag"
																value={selectedTag}
																onChange={this.handleChange}
															/>
														) : (
															''
														)}
														<Dropdown
															label={t('phone')}
															style={{ marginLeft: '10px' }}
															selection
															options={businessPhoneList}
															name="selectedBusinessphone"
															value={selectedBusinessphone}
															onChange={this.handleChange}
														></Dropdown>
													</Grid.Row>
												</Grid>
											</Table.HeaderCell>
											<Table.HeaderCell>
												<Button
													basic
													color="blue"
													style={{ marginLeft: '10px' }}
													onClick={() => this.setState({ openListModal: true })}
												>
													Seçili Numaralar({targets.length})
												</Button>
											</Table.HeaderCell>
											{/* <Table.HeaderCell>
												<div style={{ display: 'flex' }}>
													<Checkbox
														label={t('Kişiler')}
														name="sw_contacts"
														checked={sw_contacts}
														disabled
														style={{ marginRight: '10px' }}
														onChange={this.toggleSw}
													/>
													<Checkbox label={t('Gruplar')} name="sw_groups" checked={sw_groups} onChange={this.toggleSw} />
												</div>
											</Table.HeaderCell> */}

											<Table.HeaderCell>{t('type')}</Table.HeaderCell>
											<Table.HeaderCell textAlign="center">
												<Button.Group>
													<Button basic color="green" onClick={() => this.toggleAllExcel(showExcelList)}>
														{t('selectAll')}
													</Button>
													<Button basic color="red" onClick={() => this.setState({ targets: [] })}>
														{t('deSelectAll')}
													</Button>
												</Button.Group>
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>

									<Table.Body>
										{_.map(showExcelList, (it, i) => {
											let isCheck = !!_.find(targets, { id: it.Phone, number: 'waba' });
											let isBlackList = _.includes(blackList, '' + it.Phone);

											if (it.Label) {
												let _label = it.Label;
												it.tags = _label.split(',');
											}
											return (
												<Table.Row key={i} disabled={isBlackList}>
													<Table.Cell>{it.Name || it.Phone}</Table.Cell>{' '}
													<Table.Cell>
														{isBlackList ? (
															<div className="cl-labels tags-line">
																<label className="label-tags" style={{ color: 'black' }}>
																	{'BlackList'}
																</label>
															</div>
														) : (
															''
														)}
														{it.tags && it.tags.length > 0 ? (
															<div className="cl-labels tags-line">
																{_.map(it.tags, function (ot) {
																	let _tag = _.find(tagList, { title: ot });
																	let tag_color = (_tag && _tag.color) || 'blue';
																	if (_tag)
																		return (
																			<label className="label-tags" style={{ color: tag_color }}>
																				{_tag.title}
																			</label>
																		);
																})}
															</div>
														) : (
															''
														)}
													</Table.Cell>
													<Table.Cell>{t('Kişi')}</Table.Cell>
													<Table.Cell textAlign="center">
														<Checkbox checked={isCheck} onChange={() => this.toggleExcel(it, it.Phone, 'waba')}></Checkbox>
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							) : (
								<div style={{ marginTop: '10px' }}>
									{selectedphone === 'all' ? (
										<Table className="phone">
											<Table.Header>
												{merged_excel_list && merged_excel_list.length ? (
													<Table.Row>
														<Table.HeaderCell width={10}>
															<Grid style={{ marginLeft: '10px' }}>
																<Grid.Row>
																	{businessPhoneList && businessPhoneList.length ? (
																		<Dropdown
																			selection
																			options={sourceList}
																			name="selectedSource"
																			value={selectedSource}
																			onChange={this.handleChange}
																		></Dropdown>
																	) : null}
																	{merged_excel_list && merged_excel_list.length ? null : excelData && excelData.length ? (
																		<Dropdown
																			style={{ marginLeft: '10px' }}
																			selection
																			options={_.filter(phoneSourceList, (it) => it.value !== 'contacts')}
																			name="phoneSource"
																			value={phoneSource}
																			onChange={this.handleChange}
																		/>
																	) : null}
																</Grid.Row>
																<Grid.Row>
																	{tagList && tagList.length ? (
																		<Dropdown
																			label={t('label')}
																			selection
																			options={tag_filter}
																			name="selectedTag"
																			value={selectedTag}
																			onChange={this.handleChange}
																		/>
																	) : (
																		''
																	)}
																	<Dropdown
																		label={t('phone')}
																		style={{ marginLeft: '20px' }}
																		selection
																		options={dropdownList}
																		name="selectedphone"
																		value={selectedphone}
																		onChange={this.handleChange}
																	></Dropdown>
																</Grid.Row>
															</Grid>
														</Table.HeaderCell>
														<Table.HeaderCell>
															<Button
																basic
																color="blue"
																style={{ marginLeft: '10px' }}
																onClick={() => this.setState({ openListModal: true })}
															>
																Seçili Numaralar({targets.length})
															</Button>
														</Table.HeaderCell>

														<Table.HeaderCell>{t('type')}</Table.HeaderCell>
														<Table.HeaderCell textAlign="center">
															<Button.Group>
																<Button basic color="green" onClick={() => this.toggleAllExcel(showExcelList)}>
																	{t('selectAll')}
																</Button>
																<Button basic color="red" onClick={() => this.setState({ targets: [] })}>
																	{t('deSelectAll')}
																</Button>
															</Button.Group>
														</Table.HeaderCell>
													</Table.Row>
												) : (
													<Table.Row>
														<Table.HeaderCell width={11}>
															{businessPhoneList && businessPhoneList.length ? (
																<Dropdown
																	selection
																	options={sourceList}
																	name="selectedSource"
																	value={selectedSource}
																	onChange={this.handleChange}
																></Dropdown>
															) : null}
															<Dropdown
																label={t('phone')}
																style={{ marginLeft: '20px' }}
																selection
																options={dropdownList}
																name="selectedphone"
																value={selectedphone}
																onChange={this.handleChange}
															></Dropdown>
														</Table.HeaderCell>
														<Table.HeaderCell>
															<div>
																<Checkbox
																	label={t('Kişiler')}
																	name="sw_contacts"
																	checked={sw_contacts}
																	disabled
																	style={{ marginRight: '10px' }}
																	onChange={this.toggleSw}
																/>
																<Checkbox label={t('Gruplar')} name="sw_groups" checked={sw_groups} onChange={this.toggleSw} />
															</div>
														</Table.HeaderCell>
														<Table.HeaderCell>{t('type')}</Table.HeaderCell>
														<Table.HeaderCell textAlign="center">
															<Button onClick={() => this.setState({ targets: [] })}>{t('cleanAll')}</Button>
														</Table.HeaderCell>
													</Table.Row>
												)}
											</Table.Header>

											<Table.Body>
												{merged_excel_list && merged_excel_list.length
													? _.map(showExcelList, (it, i) => {
															let isCheck = !!_.find(targets, { id: it.Phone });
															let isBlackList = _.includes(blackList, '' + it.Phone);
															return (
																<Table.Row key={i} disabled={isBlackList}>
																	<Table.Cell>{it.Name || it.Phone}</Table.Cell>{' '}
																	<Table.Cell>
																		{isBlackList ? (
																			<div className="cl-labels tags-line">
																				<label className="label-tags" style={{ color: 'black' }}>
																					{'BlackList'}
																				</label>
																			</div>
																		) : (
																			''
																		)}
																		{it.tags && it.tags.length > 0 ? (
																			<div className="cl-labels tags-line">
																				{_.map(it.tags, function (ot) {
																					let _tag = _.find(tagList, { title: ot });
																					let tag_color = (_tag && _tag.color) || 'blue';
																					if (_tag)
																						return (
																							<label className="label-tags" style={{ color: tag_color }}>
																								{_tag.title}
																							</label>
																						);
																				})}
																			</div>
																		) : (
																			''
																		)}
																	</Table.Cell>
																	<Table.Cell>{t('Kişi')}</Table.Cell>
																	<Table.Cell textAlign="center">
																		<Checkbox
																			checked={isCheck}
																			onChange={() => this.toggleExcel(it, it.Phone, 'waba')}
																		></Checkbox>
																	</Table.Cell>
																</Table.Row>
															);
													  })
													: _.map(phones, (it) => (
															<>
																<Table.Row style={{ background: 'lightgray' }}>
																	<Table.Cell>{it.number}</Table.Cell>
																	<Table.Cell></Table.Cell>
																	<Table.Cell></Table.Cell> <Table.Cell></Table.Cell>
																</Table.Row>

																{it.loading ? (
																	<Table.Row>
																		<Table.Cell width={16}>
																			<Page.Loader></Page.Loader>
																		</Table.Cell>
																	</Table.Row>
																) : it.isError ? (
																	<Table.Row>
																		<Table.Cell>{t('error')}</Table.Cell>
																	</Table.Row>
																) : (
																	_.map(
																		_.orderBy(
																			_.filter(it.groups, function (o) {
																				return o.type === 'group' && sw_groups;
																			}),
																			'type',
																			'desc'
																		),
																		(o) => {
																			let isCheck = !!_.find(targets, { id: o.id, number: it.id });

																			let isBlackList = _.includes(blackList, o.id && o.id.replace('@c.us', ''));
																			return (
																				<Table.Row disabled={isBlackList}>
																					<Table.Cell>{o.name}</Table.Cell>
																					<Table.Cell>
																						{isBlackList ? (
																							<div className="cl-labels tags-line">
																								<label className="label-tags" style={{ color: 'black' }}>
																									{'BlackList'}
																								</label>
																							</div>
																						) : (
																							''
																						)}
																					</Table.Cell>
																					<Table.Cell>{o.type === 'chat' ? t('Kişi') : t('Grup')}</Table.Cell>
																					<Table.Cell textAlign="center">
																						<Checkbox
																							checked={isCheck}
																							onChange={() => this.toggle(o, it.id, it.number)}
																						></Checkbox>
																					</Table.Cell>
																				</Table.Row>
																			);
																		}
																	)
																)}
															</>
													  ))}
											</Table.Body>
										</Table>
									) : (
										<Table className="phone">
											<Table.Header>
												{merged_excel_list && merged_excel_list.length ? (
													<Table.Row>
														<Table.HeaderCell width={10}>
															<Grid style={{ marginLeft: '10px' }}>
																<Grid.Row>
																	{businessPhoneList && businessPhoneList.length ? (
																		<Dropdown
																			selection
																			options={sourceList}
																			name="selectedSource"
																			value={selectedSource}
																			onChange={this.handleChange}
																		></Dropdown>
																	) : null}
																	{merged_excel_list && merged_excel_list.length ? null : excelData && excelData.length ? (
																		<Dropdown
																			style={{ marginLeft: '10px' }}
																			selection
																			options={_.filter(phoneSourceList, (it) => it.value !== 'contacts')}
																			name="phoneSource"
																			value={phoneSource}
																			onChange={this.handleChange}
																		/>
																	) : null}
																</Grid.Row>
																<Grid.Row>
																	{tagList && tagList.length ? (
																		<Dropdown
																			label={t('label')}
																			selection
																			options={tag_filter}
																			name="selectedTag"
																			value={selectedTag}
																			onChange={this.handleChange}
																		/>
																	) : (
																		''
																	)}
																	<Dropdown
																		label={t('phone')}
																		style={{ marginLeft: '20px' }}
																		selection
																		options={dropdownList}
																		name="selectedphone"
																		value={selectedphone}
																		onChange={this.handleChange}
																	></Dropdown>
																</Grid.Row>
															</Grid>
														</Table.HeaderCell>
														<Table.HeaderCell>
															<Button
																basic
																color="blue"
																style={{ marginLeft: '10px' }}
																onClick={() => this.setState({ openListModal: true })}
															>
																Seçili Numaralar({targets.length})
															</Button>
														</Table.HeaderCell>

														<Table.HeaderCell>{t('type')}</Table.HeaderCell>
														<Table.HeaderCell textAlign="center">
															<Button.Group>
																<Button basic color="green" onClick={() => this.toggleAllExcel(showExcelList)}>
																	{t('selectAll')}
																</Button>
																<Button basic color="red" onClick={() => this.setState({ targets: [] })}>
																	{t('deSelectAll')}
																</Button>
															</Button.Group>
														</Table.HeaderCell>
													</Table.Row>
												) : (
													<Table.Row>
														<Table.HeaderCell width={11}>
															<div style={{ display: 'flex' }}>
																{businessPhoneList && businessPhoneList.length ? (
																	<Dropdown
																		style={{ marginRight: '20px' }}
																		selection
																		options={sourceList}
																		name="selectedSource"
																		value={selectedSource}
																		onChange={this.handleChange}
																	></Dropdown>
																) : null}
																<Dropdown
																	label={t('phone')}
																	selection
																	options={dropdownList}
																	name="selectedphone"
																	value={selectedphone}
																	onChange={this.handleChange}
																/>
																{excelData && excelData.length ? (
																	<Dropdown
																		style={{ marginLeft: '10px' }}
																		selection
																		options={phoneSourceList}
																		name="phoneSource"
																		value={phoneSource}
																		onChange={this.handleChange}
																	/>
																) : null}
																{tagList && tagList.length ? (
																	<Dropdown
																		style={{ marginLeft: '10px' }}
																		label={t('label')}
																		selection
																		options={tag_filter}
																		name="selectedTag"
																		value={selectedTag}
																		onChange={this.handleChange}
																	/>
																) : (
																	''
																)}
																<Button
																	basic
																	color="blue"
																	style={{ marginLeft: '10px' }}
																	onClick={() => this.setState({ openListModal: true })}
																>
																	Seçili Numaralar({targets.length})
																</Button>
															</div>
														</Table.HeaderCell>

														<Table.HeaderCell width={2}>
															<div>
																<Checkbox
																	label={t('Kişiler')}
																	name="sw_contacts"
																	checked={sw_contacts}
																	style={{ marginRight: '10px' }}
																	onChange={this.toggleSw}
																/>
																<Checkbox label={t('Gruplar')} name="sw_groups" checked={sw_groups} onChange={this.toggleSw} />
															</div>
														</Table.HeaderCell>
														<Table.HeaderCell>{t('type')}</Table.HeaderCell>
														<Table.HeaderCell textAlign="center" width={4}>
															<Button.Group>
																<Button
																	basic
																	color="green"
																	onClick={() =>
																		this.toggleAll(
																			phoneSource === 'contacts' ? showList : showExcelList,
																			selectedphonedata.id,
																			selectedphonedata.number
																		)
																	}
																>
																	{t('selectAll')}
																</Button>
																<Button basic color="red" onClick={() => this.setState({ targets: [] })}>
																	{t('deSelectAll')}
																</Button>
															</Button.Group>
														</Table.HeaderCell>
													</Table.Row>
												)}
											</Table.Header>
											<Table.Body>
												{selectedphonedata.isError && phoneSource === 'contacts' ? (
													<Table.Row>
														<Table.Cell>{t('error')}</Table.Cell>
													</Table.Row>
												) : phoneSource === 'contacts' && !(merged_excel_list && merged_excel_list.length) ? (
													_.map(showList, (o, i) => {
														let isCheck = !!_.find(targets, { id: o.id, number: selectedphonedata.id });
														let isBlackList = _.includes(blackList, o.id && o.id.replace('@c.us', ''));
														//console.log('phones', isBlackList, o.id.replace('@c.us', ''));
														//console.log('ss', o);
														return (
															<Table.Row key={i} disabled={isBlackList}>
																<Table.Cell>{o.name}</Table.Cell>
																<Table.Cell>
																	{isBlackList ? (
																		<div className="cl-labels tags-line">
																			<label className="label-tags" style={{ color: 'black' }}>
																				{'BlackList'}
																			</label>
																		</div>
																	) : (
																		''
																	)}
																	{o && o.tags && o.tags.length > 0 ? (
																		<div className="cl-labels tags-line">
																			{_.map(o.tags, function (ot) {
																				let _tag = _.find(tagList, { _id: ot });
																				let tag_color = (_tag && _tag.color) || 'blue';
																				if (_tag)
																					return (
																						<label className="label-tags" style={{ color: tag_color }}>
																							{_tag.title}
																						</label>
																					);
																			})}
																		</div>
																	) : (
																		''
																	)}
																</Table.Cell>
																<Table.Cell>{o.type === 'chat' ? t('Kişi') : t('Grup')}</Table.Cell>
																<Table.Cell textAlign="center">
																	<Checkbox
																		checked={isCheck}
																		onChange={() => this.toggle(o, selectedphonedata.id, selectedphonedata.number)}
																	></Checkbox>
																</Table.Cell>
															</Table.Row>
														);
													})
												) : (
													_.map(showExcelList, (it) => {
														let isCheck = !!_.find(targets, { id: it.Phone });
														let isBlackList = _.includes(blackList, '' + it.Phone);
														return (
															<Table.Row disabled={isBlackList}>
																<Table.Cell>{it.Name || it.Phone}</Table.Cell>{' '}
																<Table.Cell>
																	{isBlackList ? (
																		<div className="cl-labels tags-line">
																			<label className="label-tags" style={{ color: 'black' }}>
																				{'BlackList'}
																			</label>
																		</div>
																	) : (
																		''
																	)}
																	{it.tags && it.tags.length > 0 ? (
																		<div className="cl-labels tags-line">
																			{_.map(it.tags, function (ot) {
																				let _tag = _.find(tagList, { title: ot });
																				let tag_color = (_tag && _tag.color) || 'blue';
																				if (_tag)
																					return (
																						<label className="label-tags" style={{ color: tag_color }}>
																							{_tag.title}
																						</label>
																					);
																			})}
																		</div>
																	) : (
																		''
																	)}
																</Table.Cell>
																<Table.Cell>{t('Kişi')}</Table.Cell>
																<Table.Cell textAlign="center">
																	<Checkbox
																		checked={isCheck}
																		onChange={() => this.toggleExcel(it, it.Phone, selectedphonedata.id)}
																	></Checkbox>
																</Table.Cell>
															</Table.Row>
														);
													})
												)}
											</Table.Body>
										</Table>
									)}
								</div>
							)}
						</Segment>
					</GridColumn>
					<GridColumn width={4}>
						<Segment>
							<Form onSubmit={() => this.setState({ openModal: true })}>
								<Form.Dropdown
									label={t('messageType')}
									selection
									options={messageTypes}
									name="message_type"
									value={message_type}
									onChange={this.handleChange}
								></Form.Dropdown>
								<Form.Input
									placeholder="Mesaj İsmi"
									label={t('name')}
									name="message_name"
									value={message_name}
									onChange={this.handleChange}
								></Form.Input>
								{message_type === 'text' ? (
									<Form.Field required>
										<div style={{ display: 'flex', marginBottom: '10px' }}>
											<Form.Field required>
												<label>{t('message')}</label>
											</Form.Field>
											<Button.Group className="editor" style={{ marginLeft: 'auto', display: 'flex' }} size="mini">
												<Button type="button" icon onClick={() => this.textEditor('*')}>
													<Icon name="bold" />
												</Button>
												<Button type="button" size="mini" icon onClick={() => this.textEditor('_')}>
													<Icon name="italic" />
												</Button>
												<Button type="button" size="mini" icon onClick={() => this.textEditor('~')}>
													<Icon name="underline" />
												</Button>
											</Button.Group>
											<Popup
												on="click"
												pinned
												trigger={
													<Button type="button" size="mini" icon style={{ marginLeft: '10px' }}>
														<Icon name="smile outline"></Icon>
													</Button>
												}
												content={<Picker onClick={this.onEmojiClick} set="google" />}
											></Popup>
										</div>
										<Form.TextArea
											id="message_area"
											placeholder={t('MesajGirin')}
											name="message"
											required
											value={message}
											onChange={this.handleChange}
										></Form.TextArea>
									</Form.Field>
								) : message_type === 'media' ? (
									<Form.Input
										fluid
										placeholder={t('LinkGirin')}
										label="Url"
										required
										name="message"
										value={message}
										onChange={this.handleChange}
									>
										<input />
										<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
											<label htmlFor="file-input_image">
												<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
											</label>
											<Input
												id="file-input_image"
												type="file"
												name={'message'}
												accept="*"
												style={{ display: 'none' }}
												onChange={(e) => this.handleFileInput(e)}
											/>
										</div>
									</Form.Input>
								) : message_type === 'template' ? (
									<Form.Group>
										<Form.Dropdown
											label={t('Template')}
											selection
											required
											placeholder={t('select')}
											options={template_list}
											name="template_id"
											value={template_id}
											onChange={this.handleChange}
											loading={templateLoading}
										></Form.Dropdown>
										{template_config && template_config.partner && (
											<Button
												basic
												icon
												color="green"
												type="button"
												style={{ height: '37px', display: 'flex', marginTop: 'auto', marginBottom: '1px' }}
												onClick={() => {
													this.setState({ templateLoading: true });
													this.getBusinessTemplates(true);
												}}
											>
												<Icon name="redo" loading={templateLoading}></Icon>
											</Button>
										)}
									</Form.Group>
								) : message_type === 'document' ? (
									<Form.Input
										fluid
										placeholder={t('LinkGirin')}
										label="Url"
										required
										name="message"
										value={message}
										onChange={this.handleChange}
									>
										<input />
										<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
											<label htmlFor="file-input_image">
												<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
											</label>
											<Input
												id="file-input_image"
												type="file"
												name={'message'}
												accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
												style={{ display: 'none' }}
												onChange={(e) => this.handleFileInput(e)}
											/>
										</div>
									</Form.Input>
								) : message_type === 'audio' ? (
									<Form.Input
										fluid
										placeholder={t('LinkGirin')}
										label="Url"
										required
										name="message"
										value={message}
										onChange={this.handleChange}
									>
										<input />
										<div className="image-upload" style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
											<label htmlFor="file-input_image">
												<Icon name="upload" color="grey" size="large" style={{ cursor: 'pointer' }}></Icon>
											</label>
											<Input
												id="file-input_image"
												type="file"
												name={'message'}
												accept="audio/*"
												style={{ display: 'none' }}
												onChange={(e) => this.handleFileInput(e)}
											/>
										</div>
									</Form.Input>
								) : (
									<Form.Input
										placeholder={t('LinkGirin')}
										label="Url"
										required
										name="message"
										value={message}
										onChange={this.handleChange}
									></Form.Input>
								)}
								{message_type === 'text' || message_type === 'template' || message_type === 'document' || message_type === 'audio' ? (
									''
								) : (
									<Form.Field>
										<div style={{ display: 'flex', marginBottom: '10px' }}>
											<Form.Field>
												<label>{t('Yazı')}</label>
											</Form.Field>
											<Button.Group className="editor" style={{ marginLeft: 'auto', display: 'flex' }} size="mini">
												<Button type="button" icon onClick={() => this.textEditorText('*')}>
													<Icon name="bold" />
												</Button>
												<Button type="button" size="mini" icon onClick={() => this.textEditorText('_')}>
													<Icon name="italic" />
												</Button>
												<Button type="button" size="mini" icon onClick={() => this.textEditorText('~')}>
													<Icon name="underline" />
												</Button>
											</Button.Group>
											<Popup
												on="click"
												pinned
												trigger={
													<Button type="button" size="mini" icon style={{ marginLeft: '10px' }}>
														<Icon name="smile outline"></Icon>
													</Button>
												}
												content={<Picker onClick={this.onEmojiClickText} set="google" />}
											></Popup>
										</div>
										<Form.TextArea
											id="message_area_text"
											placeholder={t('Mesajops')}
											name="text_message"
											value={text_message}
											onChange={this.handleChange}
										></Form.TextArea>
									</Form.Field>
								)}
								<Form.Field>
									<Form.Group>
										{this.state.message ? (
											<div id="preview" className="menuModal">
												<div id="messageBar" className="barOther">
													{(() => {
														if (this.state.message_type === 'text')
															return (
																<>
																	<div
																		className="message sent"
																		style={{ color: 'black' }}
																		dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.message)) }}
																	></div>
																</>
															);
														if (message_type === 'media' || message_type === 'audio')
															return (
																<span>
																	<div className="message sent" style={{ color: 'black' }}>
																		{(() => {
																			let controller = checkForImage(this.state.message, this.state.file_format);
																			if (controller === 'image')
																				return <img className="modalImg" alt="img" src={this.state.message}></img>;
																			if (controller === 'video')
																				return (
																					<video className="modalVideo" width="100%" height="200" controls autoPlay>
																						<source src={this.state.message} type="video/mp4" />
																					</video>
																				);
																			if (controller === 'audio')
																				return (
																					<audio
																						controls
																						style={{ width: '88%', height: '80px', marginLeft: '10px' }}
																					>
																						<source src={this.state.message} type="audio/ogg" />
																						<source src={this.state.message} type="audio/mpeg" />
																					</audio>
																				);
																			else return <div>Invalid Format</div>;
																		})()}
																		<div
																			className="message sent"
																			style={{ color: 'black' }}
																			dangerouslySetInnerHTML={{
																				__html: Markdown(escapeHTML(this.state.text_message)),
																			}}
																		></div>
																	</div>
																</span>
															);
														if (this.state.message_type === 'link')
															return (
																<div className="message sent">
																	<a
																		target="_blank"
																		rel="noopener noreferrer"
																		href={this.state.message}
																		style={{ color: '#44ABCD', textDecoration: 'underline', cursor: 'pointer' }}
																	>
																		{this.state.message}
																	</a>
																	<a
																		style={{
																			display: 'block',
																			color: 'black',
																			cursor: 'default',
																			textDecoration: 'none',
																		}}
																		dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.text_message)) }}
																	></a>
																</div>
															);
														if (this.state.message_type === 'document')
															return (
																<span>
																	<div className="message sent" style={{ color: 'black' }}>
																		{
																			<img
																				alt="img"
																				className="documentImage"
																				src="https://cdnydm.com/permedia/NhYp4A0y36gINHcyZO2SnQ.png?size=512x512"
																			/>
																		}
																		<div className="message sent" style={{ color: 'black', display: 'inline' }}>
																			{file_name || 'file://'}
																		</div>
																		<div
																			className="message sent"
																			style={{ color: 'black', display: 'block' }}
																			dangerouslySetInnerHTML={{
																				__html: Markdown(escapeHTML(this.state.text_message)),
																			}}
																		></div>
																	</div>
																</span>
															);
													})()}
													<span className="metadata">
														<span className="tick">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="16"
																height="15"
																id="msg-dblcheck-ack"
																x="2063"
																y="2076"
															>
																<path
																	d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
																	fill="#4fc3f7"
																></path>
															</svg>
														</span>
													</span>
												</div>
											</div>
										) : (
											<div></div>
										)}
									</Form.Group>
								</Form.Field>
								<Form.Field>
									<Form.Input
										label={t('testNumber')}
										value={testNumber}
										name="testNumber"
										onChange={this.handleChange}
										placeholder="905XXXXXXXXX"
									></Form.Input>
									<Form.Button
										type="button"
										className="test_send"
										style={{ marginTop: 'auto!important', marginBottom: '1px' }}
										primary
										disabled={!testNumber}
										loading={btnLoading}
										onClick={() => this.setState({ openTestModal: true })}
									>
										{t('testSend')}
									</Form.Button>
								</Form.Field>
								<Form.Field>
									<label>{t('date')}</label>
									{!this.props.product.settings.wsendwithdelay ? (
										<Button.Group style={{ marginBottom: '10px' }}>
											<Button type="button" basic={sending_date !== 'now'} primary onClick={() => this.setState({ sending_date: 'now' })}>
												{t('now')}
											</Button>
											<Button
												type="button"
												basic={sending_date !== 'next'}
												primary
												onClick={() => this.setState({ sending_date: 'next' })}
											>
												{t('nextDate')}
											</Button>
										</Button.Group>
									) : (
										''
									)}
									{selectedSource !== 'waba' && !this.props.product.settings.wsendwithdelay ? (
										<>
											<label>{'Aralık'}</label>
											<Dropdown
												options={time_options}
												scrolling
												value={this.state.selectedAralik}
												onChange={(e, { value }) => {
													this.setState({ selectedAralik: value });
												}}
											></Dropdown>
											<p style={{ fontFamily: 'monospace', fontSize: '10px' }}>
												Spamı engellemek için iki mesaj arasında geçecek süre minimum 15 saniye
											</p>
										</>
									) : (
										''
									)}
									{sending_date === 'next' ? (
										<DatePicker
											selected={startDate}
											onChange={(date) => this.setState({ startDate: date })}
											minDate={new Date()}
											filterTime={filterPassedTime}
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={5}
											dateFormat="MM/dd/yyyy HH:mm"
											timeCaption={t('time')}
											locale={dateLocales[i18n.language]}
										/>
									) : null}
									{this.props.product.settings.wsendwithdelay ? (
										<DatePicker
											selected={lasttime}
											onChange={(date) => this.setState({ lasttime: date })}
											minDate={new Date()}
											maxDate={new Date()}
											filterTime={filterPassedTime}
											showTimeSelect
											timeFormat="HH:mm"
											timeIntervals={5}
											dateFormat="HH:mm"
											timeCaption={t('time')}
											locale={dateLocales[i18n.language]}
										/>
									) : (
										''
									)}
								</Form.Field>
								<Form.Button type="submit" positive disabled={btnLoading} loading={btnLoading}>
									{t('send')}
								</Form.Button>
							</Form>
						</Segment>
					</GridColumn>
				</Grid>
				<Modal open={this.state.openModal} onClose={this.closeModal} style={{ width: '900px', maxHeight: '600px' }}>
					<Header icon="whatsapp" content={`${t('sendMessage')} (${targets.length})`} />
					<Modal.Content>
						<div style={{ maxHeight: '330px', overflowY: 'auto' }}>
							<Table>
								<Table.Body>
									<div id="preview" className="preModal">
										<div id="messageBar" className="barPix">
											{(() => {
												if (this.state.message_type === 'text')
													return (
														<div
															className="message sent"
															style={{ color: 'black' }}
															dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.message)) }}
														></div>
													);
												if (message_type === 'media' || message_type === 'audio')
													return (
														<span>
															<div className="message sent" style={{ color: 'black' }}>
																{(() => {
																	let controller = checkForImage(this.state.message, this.state.file_format);
																	if (controller === 'image')
																		return <img className="modalImg" alt="img" src={this.state.message}></img>;
																	if (controller === 'video')
																		return (
																			<video className="modalVideo" width="100%" height="200" controls autoPlay>
																				<source src={this.state.message} type="video/mp4" />
																			</video>
																		);
																	if (controller === 'audio')
																		return (
																			<audio style={{ width: '88%', height: '80px', marginLeft: '10px' }} controls>
																				<source src={this.state.message} type="audio/ogg" />
																				<source src={this.state.message} type="audio/mpeg" />
																			</audio>
																		);
																	else return <div>Invalid Format</div>;
																})()}
																<div
																	className="message sent"
																	style={{ color: 'black' }}
																	dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.text_message)) }}
																></div>
															</div>
														</span>
													);
												if (this.state.message_type === 'link')
													return (
														<div className="message sent">
															<a
																style={{ color: '#44ABCD', textDecoration: 'underline', cursor: 'pointer' }}
																target="_blank"
																rel="noopener noreferrer"
																href={this.state.message}
															>
																{this.state.message}
															</a>
															<a
																style={{ display: 'block', color: 'black', cursor: 'default', textDecoration: 'none' }}
																dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.text_message)) }}
															></a>
														</div>
													);
												if (this.state.message_type === 'document')
													return (
														<span>
															<div className="message sent" style={{ color: 'black' }}>
																{
																	<img
																		alt="img"
																		className="documentImage"
																		src="https://cdnydm.com/permedia/NhYp4A0y36gINHcyZO2SnQ.png?size=512x512"
																	/>
																}
																<div className="message sent" style={{ color: 'black', display: 'inline' }}>
																	{file_name || 'file://'}
																</div>
																<div
																	className="message sent"
																	style={{ color: 'black', display: 'block' }}
																	dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.text_message)) }}
																></div>
															</div>
														</span>
													);
											})()}
											<span className="metadata">
												<span className="tick">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
														<path
															d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
															fill="#4fc3f7"
														></path>
													</svg>
												</span>
											</span>
										</div>
									</div>
								</Table.Body>
							</Table>
						</div>
					</Modal.Content>
					{this.props.product.settings.wsendwithdelay ? (
						<Modal.Content>
							<strong>
								{moment(lasttime).format('HH:mm') +
									' saatine kadar her dakika bir numaraya mesaj gönderilecektir. Bu sürede en fazla ' +
									max_phone_length +
									' numaraya mesaj atabilirsiniz. Bu sayıdan fazla seçilmişse listenin geri kalanına gönderim yapılmayacaktır.'}
							</strong>
						</Modal.Content>
					) : (
						''
					)}
					{/* {!this.props.product.settings.wsendwithdelay ? (
						<Modal.Content>
							<strong>
								{`Mesaj gönderme aralığı ${
									this.state.selectedAralik / 1000
								} saniyedir. Numara banlanmasını engellemek için iki mesaj arasında ${
									this.state.selectedAralik / 1000
								} saniye olacaktır. Tüm mesajların atılması en az ${formatTimeText(
									this.state.selectedAralik * ((targets && targets.length && targets.length - 1) || 0)
								)} sürecektir.`}
							</strong>
						</Modal.Content>
					) : (
						''
					)} */}
					<Modal.Content>
						<div style={{ maxHeight: '129px', overflowY: 'auto' }}>
							<Table>
								<Table.Body>
									{_.map(targets, (it, i) => (
										<Table.Row key={i}>
											<Table.Cell>{it.name || it.id}</Table.Cell>
											<Table.Cell>{it.id}</Table.Cell>
											<Table.Cell>
												{it.tags && (
													<div className="cl-labels tags-line">
														{_.map(it.tags, function (ot) {
															let _tag = _.find(tagList, { _id: ot });
															if (_tag)
																return (
																	<label className="label-tags" style={{ color: _tag.color }}>
																		{_tag.title}
																	</label>
																);
														})}
													</div>
												)}
											</Table.Cell>
											<Table.Cell>
												{it.phone === 'waba' ? 'Kişi' : it.type === 'chat' || /@c\.us/.test(it.id) ? t('Kişi') : t('Grup')}
											</Table.Cell>
											<Table.Cell>{it.phone === 'waba' ? selectedBusinessphone : it.phone}</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</div>
					</Modal.Content>
					<Modal.Actions>
						<div style={{ display: 'flex' }}>
							<div style={{ textAlign: 'left' }}>
								<p style={{ display: 'flex', alignItems: 'center' }}>{t('sendGroupsSure')}</p>
								{sending_date === 'next' ? <p>{t('messageSendingDate', { date: moment(startDate).format('DD/MM/YYYY HH:mm') })}</p> : null}
							</div>

							<div style={{ marginLeft: 'auto' }}>
								<Button color="red" onClick={this.closeModal}>
									<Icon name="remove" /> {t('no')}
								</Button>
								<Button color="green" onClick={() => this.sendMessage(false)}>
									<Icon name="checkmark" /> {t('yes')}
								</Button>
							</div>
						</div>
					</Modal.Actions>
				</Modal>
				<Modal open={this.state.openListModal} onClose={this.closeModal} style={{ width: '900px', maxHeight: '600px' }}>
					<Header icon="whatsapp" content={`Seçilenler (${targets.length})`} />
					<Modal.Content>
						<div style={{ maxHeight: '500px', overflowY: 'auto' }}>
							<Table>
								<Table.Body>
									{_.map(targets, (it, i) => (
										<Table.Row key={i}>
											<Table.Cell>{it.name || it.id}</Table.Cell>
											<Table.Cell>
												{it.tags && (
													<div className="cl-labels tags-line">
														{_.map(it.tags, function (ot) {
															let _tag = _.find(tagList, { _id: ot });
															if (_tag)
																return (
																	<label className="label-tags" style={{ color: _tag.color }}>
																		{_tag.title}
																	</label>
																);
														})}
													</div>
												)}
											</Table.Cell>
											<Table.Cell>{it.phone === 'waba' ? 'Kişi' : it.type === 'chat' ? t('Kişi') : t('Grup')}</Table.Cell>
											<Table.Cell>{it.phone === 'waba' ? selectedBusinessphone : it.phone}</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</div>
					</Modal.Content>
				</Modal>
				<Modal open={this.state.openTestModal} onClose={this.closeModal} style={{ width: '900px', maxHeight: '600px' }}>
					<Header content={`Mesaj Yollanacak Numara (${testNumber})`} />
					<Modal.Content>
						<div style={{ maxHeight: '330px', overflowY: 'auto' }}>
							<Table>
								<Table.Body>
									<div id="preview" className="preModal">
										<div id="messageBar" className="barPix">
											{(() => {
												if (this.state.message_type === 'text')
													return (
														<div
															className="message sent"
															style={{ color: 'black' }}
															dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.message)) }}
														></div>
													);
												if (message_type === 'media' || message_type === 'audio')
													return (
														<span>
															<div className="message sent" style={{ color: 'black' }}>
																{(() => {
																	let controller = checkForImage(this.state.message, this.state.file_format);
																	if (controller === 'image')
																		return <img className="modalImg" alt="img" src={this.state.message}></img>;
																	if (controller === 'video')
																		return (
																			<video className="modalVideo" width="100%" height="200" controls autoPlay>
																				<source src={this.state.message} type="video/mp4" />
																			</video>
																		);
																	if (controller === 'audio')
																		return (
																			<audio controls style={{ width: '88%', height: '80px', marginLeft: '10px' }}>
																				<source src={this.state.message} type="audio/ogg" />
																				<source src={this.state.message} type="audio/mpeg" />
																			</audio>
																		);
																	else return <div>Invalid Format</div>;
																})()}
																<div
																	className="message sent"
																	style={{ color: 'black' }}
																	dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.text_message)) }}
																></div>
															</div>
														</span>
													);
												if (this.state.message_type === 'link')
													return (
														<div className="message sent">
															<a
																style={{ color: '#44ABCD', textDecoration: 'underline', cursor: 'pointer' }}
																target="_blank"
																rel="noopener noreferrer"
																href={this.state.message}
															>
																{this.state.message}
															</a>
															<a
																style={{ display: 'block', color: 'black', cursor: 'default', textDecoration: 'none' }}
																dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.text_message)) }}
															></a>
														</div>
													);
												if (this.state.message_type === 'document')
													return (
														<span>
															<div className="message sent" style={{ color: 'black' }}>
																{
																	<img
																		className="documentImage"
																		alt="img"
																		src="https://cdnydm.com/permedia/NhYp4A0y36gINHcyZO2SnQ.png?size=512x512"
																	/>
																}
																<div className="message sent" style={{ color: 'black', display: 'inline' }}>
																	{file_name || 'file://'}
																</div>
																<div
																	className="message sent"
																	style={{ color: 'black', display: 'block' }}
																	dangerouslySetInnerHTML={{ __html: Markdown(escapeHTML(this.state.text_message)) }}
																></div>
															</div>
														</span>
													);
											})()}
											<span className="metadata">
												<span className="tick">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
														<path
															d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
															fill="#4fc3f7"
														></path>
													</svg>
												</span>
											</span>
										</div>
									</div>
								</Table.Body>
							</Table>
						</div>
					</Modal.Content>
					<Modal.Content>
						<div style={{ maxHeight: '500px', overflowY: 'auto' }}>
							<Table>
								<Table.Body style={{ textAlign: 'center' }}>
									<Button style={{ margin: '15px' }} color="red" onClick={this.closeModal}>
										<Icon name="remove" /> {t('no')}
									</Button>
									<Button style={{ margin: '15px' }} color="green" onClick={() => this.sendMessage(true)}>
										<Icon name="checkmark" /> {t('yes')}
									</Button>
								</Table.Body>
							</Table>
						</div>
					</Modal.Content>
				</Modal>
			</div>
		);
	}
}

const stateToProps = (state) => {
	return { access: state.access, location: state.router.location, product: state.product.live, user: state.access.user.loggedUserObj };
};
export default connect(stateToProps)(withTranslation()(Phone));
