import React, { Component } from 'react';
import Page from '../../components/Page';
import {
	Icon,
	Segment,
	Form,
	Table,
	Button,
	Input,
	TableRow,
	TableHeaderCell,
	TableHeader,
	TableFooter,
	TableCell,
	TableBody,
	MenuItem,
	Menu,
	Modal,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { get, post } from '../../util/api';

class Tables extends Component {
	constructor(props) {
		super(props);
		this.currentPage = 1;
		this.cellsPerPage = 200;
	}

	state = {
		loading: true,
		saveButton: 'add',
		ex_file: '',
		isAddUserModalOpen: false,
		list: [],
		numberList: [],
		selectedExcel: null,
		deleteButtonLoading: false,
		deleteModal: false,
		loadingBtn: false,
		message: false,
		openDeleteModal: false,
		currentPage: 1,
	};

	openAddUserModal = () => {
		this.setState({ isAddUserModalOpen: true });
	};

	closeAddUserModal = () => {
		this.setState({ isAddUserModalOpen: false, openDeleteModal: false, selectedUserId: '', openEditModal: false });
	};

	componentDidMount() {
		this.getTagList();
		this.getList();
	}
	handlePageChange = (newPage, list) => {
		const totalPages = Math.ceil(list.length / this.cellsPerPage);
		if (newPage >= 1 && newPage <= totalPages) {
			//this.currentPage = newPage;
			this.setState({ currentPage: newPage });
			//this.getList();
		}
	};

	async getList() {
		const { product } = this.props;
		let pid = product.id;
		await post('/broadcast/listBroadcastUser', { pid, state: 'unsubcribe' }).then((data) => {
			let list = data.data.result;
			console.log('loading', list);
			this.setState({
				list,
				filtered_list: list,
				selectedUserId: '',
				loading: false,
				isAddUserModalOpen: false,
				openDeleteModal: false,
				loadingBtn: false,
				openEditModal: false,
				name: '',
				phone: '',
				selectedUser: '',
				selectedUserId: '',
			});
		});
	}
	async getTagList() {
		await post('/dahi/tag/list').then((data) => {
			let result = data.data && data.data.result && data.data.result.result;
			this.setState({ tagList: _.filter(result, { active: true }) });
		});
	}
	async addUser() {
		const { product } = this.props;
		let pid = product.id;
		let { name, phone, tag } = this.state;
		let body = {
			pid,
			name,
			phone,
			data: { tag },
		};
		await post('/broadcast/createBroadcastUserList', body);
		this.getList();
	}

	async addUserExcel(data) {
		const { product } = this.props;
		let pid = product.id;
		let users = _.map(data, (it) => ({
			name: it.Name,
			phone: it.Phone,
			data: { tag: it.Label },
		}));
		let body = {
			pid,
			users,
		};
		await post('/broadcast/createBroadcastUserListExcel', body);
		this.getList();
	}
	async deleteUser() {
		const { product } = this.props;
		let pid = product.id;
		let { selectedUserId } = this.state;
		let body = { pid, id: selectedUserId };
		await post('/broadcast/deleteBroadcastUser', body);
		this.getList();
	}
	async updateUser() {
		const { product } = this.props;
		let pid = product.id;
		let { selectedUser } = this.state;
		let body = {
			pid,
			id: selectedUser.id,
			user: selectedUser,
		};
		await post('/broadcast/updateBroadcastUser', body);
		this.getList();
	}

	fileUpload(file) {
		const { product } = this.props;
		this.setState({ loadingBtn: true });
		let pid = product.id;
		const url = 'https://micro.yapaytech.com/api/dump/write/excel';
		const formData = new FormData();
		formData.append('file', file);
		formData.append('pid', pid);
		formData.append('key', 'contactList');
		let file_url = '';
		fetch(url, {
			method: 'post',
			body: formData,
		})
			.then((response) => response.json())
			.then(async (data) => {
				this.addUserExcel(data.result && data.result.data);
			});
	}

	handleFileInput = (e) => {
		let file = e.target.files[0];
		this.fileUpload(file);
	};

	downloadExample = async () => {
		var a = document.createElement('a');
		a.href = '/contacts_example.xlsx';
		a.target = '_blank';
		a.download = 'contacts_example.xlsx';
		a.click();
	};
	handleSearch = async (e, { value }) => {
		let regex = new RegExp(value);
		let list = this.state.list;
		console.log('regex', regex, list);
		let filtered_list = value ? _.filter(list, (it) => regex.test(it.name.toLocaleLowerCase('tr')) || regex.test(it.phone)) : list;
		this.setState({ filtered_list });
	};
	render() {
		const { t } = this.props;
		let { loadingBtn, message, loading, filtered_list } = this.state;

		if (loading) return <Page.Loader />;

		const startIndex = (this.state.currentPage - 1) * this.cellsPerPage;
		const endIndex = startIndex + this.cellsPerPage;
		const displayedNumberList = filtered_list.slice(startIndex, endIndex);
		return (
			<Segment>
				<div style={{ padding: '10px', width: '100%' }}>
					<h3>
						<Icon name="whatsapp" />
						{t('unsublist')}
					</h3>
				</div>
				<div>
					<Input icon="search" placeholder={t('Search') + '...'} onChange={this.handleSearch} />
				</div>
				<Table celled fixed>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>{'#'}</TableHeaderCell>
							<TableHeaderCell>{t('name')}</TableHeaderCell>
							<TableHeaderCell>{t('phone')}</TableHeaderCell>
							<TableHeaderCell>{t('label')}</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{_.map(displayedNumberList, (it, index) => (
							<TableRow key={index}>
								<TableCell>{index + 1}</TableCell>
								<TableCell>{it.name}</TableCell>
								<TableCell>{it.phone}</TableCell>
								<TableCell>{it.data && it.data.tag && it.data.tag.join(',')}</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableHeaderCell colSpan="4">
								<Menu floated="right" pagination>
									<MenuItem
										as="a"
										icon
										onClick={() => this.handlePageChange(this.state.currentPage - 1, filtered_list)}
										disabled={this.state.currentPage === 1}
									>
										<Icon name="chevron left" />
									</MenuItem>
									{[...Array(Math.ceil(this.state.filtered_list.length / this.cellsPerPage))].map((_, i) => (
										<MenuItem
											key={i}
											as="a"
											active={i + 1 === this.state.currentPage}
											onClick={() => this.handlePageChange(i + 1, filtered_list)}
										>
											{i + 1}
										</MenuItem>
									))}
									<MenuItem
										as="a"
										icon
										onClick={() => this.handlePageChange(this.state.currentPage + 1, filtered_list)}
										disabled={this.state.currentPage === Math.ceil(this.state.filtered_list.length / this.cellsPerPage)}
									>
										<Icon name="chevron right" />
									</MenuItem>
								</Menu>
							</TableHeaderCell>
						</TableRow>
					</TableFooter>
				</Table>
			</Segment>
		);
	}
}

const stateToProps = (state) => ({
	access: state.access,
	location: state.router.location,
	product: state.product.live,
	user: state.access.user.loggedUserObj,
});

export default connect(stateToProps)(withTranslation()(Tables));
