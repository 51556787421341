import React, { Component } from 'react';
import { Segment, Table, Dropdown, Container, Tab, Button, Icon, Modal, Header, Popup, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import PropTypes from 'prop-types';

/* const CardWrap = (props) => (
	<Grid.Column width={props.width}>
		<Segment textAlign="center" style={{ paddingTop: 60, minHeight: 230, paddingLeft: 8, paddingRight: 8 }} size="massive">
			<Label size="large" color={props.color} attached="top">
				{props.title}
			</Label>
			<div>
				{props.data}
				{props.unit ? <span style={{ fontSize: '10px' }}>{props.unit}</span> : ''}
			</div>
			{props.note ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note}</p> : ''}

			{props.note2 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note2}</p> : ''}
			{props.note3 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note3}</p> : ''}
			{props.note4 ? <p style={{ fontSize: '10px', padding: '0', margin: '0', marginTop: '5px' }}>{props.note4}</p> : ''}
			<Divider />
			<div style={{ fontSize: '12px' }}>{props.description}</div>
		</Segment>
	</Grid.Column>
); */

function fixPrice(val, currency) {
	if (val) return val.toLocaleString('tr-TR', { style: 'currency', currency });
}
const TableHead = (t, isPrice, isId) => (
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell width={1}>{'#'}</Table.HeaderCell>
			<Table.HeaderCell width={3}>{t('orderCode')}</Table.HeaderCell>
			{isId ? <Table.HeaderCell width={2}>{t('orderId')}</Table.HeaderCell> : null}
			<Table.HeaderCell width={1}>{t('count')}</Table.HeaderCell>
			{isPrice ? <Table.HeaderCell width={2}>{t('price')}</Table.HeaderCell> : null}
			<Table.HeaderCell width={2}>{t('day')}</Table.HeaderCell>
			<Table.HeaderCell width={3}>{t('agent')}</Table.HeaderCell>
			<Table.HeaderCell width={2}>{t('status')}</Table.HeaderCell>
			<Table.HeaderCell width={1}>{t('details')}</Table.HeaderCell>{' '}
		</Table.Row>
	</Table.Header>
);

const ForgettenTableHead = (t) => (
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell width={1}>{'#'}</Table.HeaderCell>
			<Table.HeaderCell>{t('productCount')}</Table.HeaderCell>
			<Table.HeaderCell>{t('price')}</Table.HeaderCell>
			<Table.HeaderCell>{t('date')}</Table.HeaderCell>
			<Table.HeaderCell>{t('user')}</Table.HeaderCell>
			<Table.HeaderCell width={2}>{t('details')}</Table.HeaderCell>
		</Table.Row>
	</Table.Header>
);

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* '5f8dbdc560e4f7d3e00876b8ab5f2ecf' || */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		botId: '',
		tableStart: 0,
		tableEnd: 20,
		dataloading: true,
		orderDetails: {},
		selectedPlatform: 'all',
		activePage: 1,
		hideId: true,
	};
	_i = 0;
	static contextTypes = {
		router: PropTypes.object,
	};
	componentDidMount() {
		let typeQuery = [];
		let group = [];
		let select = [];
		let { start_date, end_date, agent_id, agent_role, selectedPlatform } = this.state;
		let valueArr = this.state.value.length ? this.state.value : TypeList;
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		this.setState({ head: typeQuery });
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let filter = {};
		if (agent_role === 'agent') {
			filter = { type: typeQuery, pid: this.state.pid, agent: agent_id };
			this.setState({ selectedAgent: agent_id });
		} else {
			filter = { type: typeQuery, pid: this.state.pid };
		}
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					staticOptions: data.result && data.result.staticOptions,
				});
			});
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		this.getForgettenBaskets();
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select: [],
				group: ['type', 'cid', 'agent', 'day'],
				pairs: {
					agent: 'select5',
					cid: 'select4',
					platform: 'platform',
				},
				options: {
					sum: 1,
				},
				agg: {
					rows: 'jsonb_agg(lr.*)',
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform from (select *  from live_refined lr where type in ('orderCreated', 'orderCanceled', 'orderRefunded', 'orderSuccessed') and ((day >= '${moment(
					start_date
				).format('YYYY-MM-DD')}' and hour >= ${moment(start_date).format('H')}) and (day <= '${moment(end_date).format(
					'YYYY-MM-DD'
				)}' and (day < '${moment(end_date).format('YYYY-MM-DD')}' or (day = '${moment(end_date).format('YYYY-MM-DD')}' and hour <=  ${moment(
					end_date
				).format('H')}) )  )) and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.type='orderCreated' and lr.type <> 'orderCreated' and lr2.select4=lr.select4) as lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent, id: it.agent };
				});
				let t_data = [];
				_.map(data.result, (it) => {
					_.map(
						_.orderBy(it.rows, (r) => r.select7),
						(o) => {
							if (o.select7 && o.select2 && !_.find(t_data, { select2: o.select2 }))
								t_data.push({ ...o, cid: it.cid, agent: it.agent, kod: o.select2, id: o.select7, sum: o.total });
							else if (!o.select7 && o.select2 && !(_.find(t_data, { select2: o.select2 }) || _.find(t_data, { select7: o.select2 }))) {
								t_data.push({ ...o, cid: it.cid, agent: it.agent, kod: o.select2, id: o.select7, sum: o.total });
							}
						}
					);
				});
				console.log('t_data', t_data);
				agentList = _.uniqBy(agentList, 'key');
				let ecommerce_type =
					_.filter(t_data, function (it) {
						return it.id;
					}).length > 0;
				this.setState({ gettingData: _.sortBy(t_data, ['date', 'asc']), agentList: agentList, loading: false, ecommerce_type });
			});
	}
	getForgettenBaskets() {
		let { start_date, end_date, activePage } = this.props;
		start_date = new Date(start_date);
		end_date = new Date(end_date);
		fetch('https://micro.yapaytech.com/api/dialogtab/forgottenBaskets/general', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
				start_date,
				end_date,
				page: activePage - 1,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let forgottenBasketsGeneral = data.result;
				this.setState({ forgottenBasketsGeneral });
			});
		fetch('https://micro.yapaytech.com/api/dialogtab/forgottenBaskets', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				pid: this.state.pid,
				start_date,
				end_date,
				page: activePage - 1,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let forgottenBaskets = data.result;
				this.setState({ forgottenBaskets, pageLoading: false });
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	handleFilterCheck(value) {
		let array = this.state.value || [];
		let exists = _.find(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ value: valueArr });
	}
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = [/* 'start_date', 'end_date', */ 'selectedAgent', 'value', 'selectedPlatform', 'activePage', 'newDataToggle'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;
			if (key === 'activePage') {
				if (this.state[key] !== oldState[key]) {
					this.getForgettenBaskets();
					break;
				}
			} else {
				if (key === 'start_date' || key === 'end_date' || key === 'newDataToggle') {
					if (this.props[key] !== oldProps[key]) {
						this.setState({ activePage: 1 });
						window.clearTimeout(timer);
						timer = setTimeout(() => this.getData(), 3000);
						break;
					}
				}
				if (this.state[key] !== oldState[key]) {
					this.setState({ activePage: 1 });
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
		}
	}

	async getData() {
		this.setState({ loading: true });
		let typeQuery = [];
		let group = [];
		let select = [];
		let current = ++this._i;
		let { selectedAgent, selectedPlatform } = this.state;
		let { start_date, end_date } = this.props;

		_.each(this.state.value, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		let filter = {};
		if (selectedAgent === 'all') {
			filter = { type: typeQuery, pid: this.state.pid };
		} else {
			filter = { type: typeQuery, pid: this.state.pid, agent: selectedAgent };
		}
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		this.getForgettenBaskets();
		fetch('https://log.yapaytech.com/live/test/getInfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				include_hour: true,
				filter,
				select: [],
				group: ['type', 'cid', 'agent', 'day'],
				pairs: {
					agent: 'select5',
					cid: 'select4',
					platform: 'platform',
				},
				options: {
					sum: 1,
				},
				agg: {
					rows: 'jsonb_agg(lr.*)',
				},
				from: `(select  COALESCE(lr.total, lr2.total) as total, lr.type, lr.pid, lr.select1, lr.select2, lr.select3, lr.select4, lr.select5, lr.select6, lr.select7, lr.count, lr.date, lr.day, lr.hour, lr.platform from (select *  from live_refined lr where type in ('orderCreated', 'orderCanceled', 'orderRefunded', 'orderSuccessed') and ((day >= '${moment(
					start_date
				).format('YYYY-MM-DD')}' and hour >= ${moment(start_date).format('H')}) and (day <= '${moment(end_date).format(
					'YYYY-MM-DD'
				)}' and (day < '${moment(end_date).format('YYYY-MM-DD')}' or (day = '${moment(end_date).format('YYYY-MM-DD')}' and hour <=  ${moment(end_date)
					.endOf('D')
					.format('H')}) )  )) and pid = '${
					this.state.pid
				}') lr left join live_refined lr2 on lr2.type='orderCreated' and lr.type <> 'orderCreated' and lr2.select4=lr.select4) as lr`,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (current !== this._i) return;
				this.setState({ head: typeQuery });
				let agentList = _.map(data.result, function (it) {
					return { key: it.agent, text: it.agent, value: it.agent };
				});
				let t_data = [];
				_.map(data.result, (it) => {
					_.map(
						_.orderBy(it.rows, (r) => r.select7),
						(o) => {
							if (o.select7 && o.select2 && !_.find(t_data, { select2: o.select2 }))
								t_data.push({ ...o, cid: it.cid, agent: it.agent, kod: o.select2, id: o.select7, sum: o.total });
							else if (!o.select7 && o.select2 && !(_.find(t_data, { select2: o.select2 }) || _.find(t_data, { select7: o.select2 }))) {
								t_data.push({ ...o, cid: it.cid, agent: it.agent, kod: o.select2, id: o.select7, sum: o.total });
							}
						}
					);
				});
				console.log('t_data', t_data);
				agentList = _.uniqBy(agentList, 'key');
				this.setState({ gettingData: _.sortBy(t_data, ['date', 'asc']), agentList: agentList, loading: false });
			});
	}
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	getOrderDetails = async (code, isSale, cid) => {
		let pid = this.props.product.live.settings.live.token;
		this.setState({ selectedConversation: cid });
		if (isSale) {
			fetch(`https://micro.yapaytech.com/api/ecommerce/${pid}/order/${code}?token=bRzxSV7xGkHNp44xsn6yAjjFVSeLgJn7nwtd`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.success) {
						this.setState({ selectedOrderCode: code, orderDetails: data.data, productDetailsModal: true, productDetailsError: false });
					} else {
						this.setState({ selectedOrderCode: code, productDetailsModal: true, productDetailsError: true });
					}
				});
		} else {
			if (cid) {
				const win = window.open('/#' + cid, '_blank');
				win.focus();
			}
		}
	};

	getConversationFromUid(uid) {
		fetch('https://live.dahi.ai/analytics/findCidByUid', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid,
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let cid = data.data;
				if (cid) {
					const win = window.open('/#' + cid, '_blank');
					win.focus();
				}
			});
	}
	closeModal = () => {
		this.setState({ productDetailsModal: false, productDetailsError: false });
	};
	TableLine = (data, productAgentList, displayName, otherStatus, t, isPrice, isId, isSale, currency, hideId) => (
		<Table.Body>
			{_.map(
				_.orderBy(
					_.filter(data, (it) => {
						if (isId && hideId) return !!it.id;
						else return it;
					}),
					['day'],
					['desc']
				),
				(val, index) => {
					return (
						<Table.Row key={index}>
							<Table.Cell>{index + 1}</Table.Cell>
							<Table.Cell>{(val && val.kod) || 0}</Table.Cell>
							{isId ? <Table.Cell>{(val && val.id) || '---'}</Table.Cell> : null}
							<Table.Cell>{(val && val.count) || 0}</Table.Cell>
							{isPrice ? <Table.Cell>{(val && val.sum && fixPrice(val.sum, currency)) || 0}</Table.Cell> : null}
							<Table.Cell>{(val && val.day && moment(val.day).format('DD-MM-YYYY')) || 0}</Table.Cell>
							<Table.Cell>
								{(_.find(productAgentList, { id: val.agent }) && _.find(productAgentList, { id: val.agent })[displayName]) || 'Bot'}
							</Table.Cell>
							<Table.Cell>{(_.find(otherStatus, { kod: val.kod }) && t(_.find(otherStatus, { kod: val.kod }).type)) || t('created')}</Table.Cell>
							<Table.Cell>
								{isSale ? (
									<Button icon primary onClick={() => this.getOrderDetails(val.kod, isSale, val.cid)}>
										<Icon name="eye"></Icon>
									</Button>
								) : (
									<Button icon primary onClick={() => this.getOrderDetails(val.kod, isSale, val.cid)}>
										<Icon name="chat"></Icon>
									</Button>
								)}
							</Table.Cell>
						</Table.Row>
					);
				}
			)}
		</Table.Body>
	);

	ForgettenTableLine = (data, t, currency) => (
		<Table.Body>
			{_.map(data, (val, index) => (
				<Table.Row key={index}>
					<Table.Cell>{index + 1}</Table.Cell>
					<Table.Cell>{val.urun && val.urun.length ? val.urun.length : '-'}</Table.Cell>
					<Table.Cell>
						{val.urun && val.urun.length
							? fixPrice(
									_.sumBy(val.urun, (it) => parseFloat(it.price)),
									currency
							  )
							: '-'}
					</Table.Cell>
					<Table.Cell>{val.updateDate ? moment(val.updateDate).format('DD-MM-YYYY') : ''}</Table.Cell>
					<Table.Cell>{/[0-9]{12}-/.test(val.uid) ? val.uid.replace(/-.*/, '') : ''} </Table.Cell>
					<Table.Cell>
						<Button primary icon onClick={() => this.getConversationFromUid(val.uid)}>
							<Icon name="conversation"></Icon>
						</Button>
					</Table.Cell>
				</Table.Row>
			))}
		</Table.Body>
	);
	handlePaginationChange = (e, { activePage }) => this.setState({ activePage, pageLoading: true });
	loadingText(text, load, title, popup) {
		if (load) {
			return title ? (
				<p>
					{title + ': '}
					<Icon name="spinner" loading></Icon>
				</p>
			) : (
				<Icon name="spinner" loading></Icon>
			);
		}

		return title ? (
			<p>
				{popup && <Popup trigger={<Icon name="question circle outline" />} content={popup} size="mini" position={'bottom'} />}{' '}
				{title + ': ' + text || 0}
			</p>
		) : (
			text || 0
		);
	}
	render() {
		const { t, platformList, product } = this.props;
		let selectedPlatform = this.state.selectedPlatform;
		let ecommerce_type = this.state.ecommerce_type;
		let gettingData = this.state.gettingData;
		let productAgentList = this.state.productAgentList;
		let newList = this.state.newList;
		let hideId = this.state.hideId;
		let agent_role = this.state.agent_role;
		let { forgottenBaskets } = this.state;
		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		let dropAgentList = _.map(productAgentList, function (it) {
			return { text: it.developer, key: it.id, value: it.id };
		});
		let staticOptions = this.state.staticOptions;
		let currency = (product && product.live && product.live.settings.currency) || 'try';
		_.map(gettingData, function (it, i) {
			return (it.dayNumber = moment(it.day).dayOfYear());
		});
		let orderCreatedData = _.filter(gettingData, function (it) {
			return it.kod || it.id;
		});
		/* let orderCreatedDataFilter = _.map(_.groupBy(orderCreatedData, 'kod'), function (it) {
			return it[0];
		}); */
		let orderCanceledData = _.filter(gettingData, function (it) {
			return it.type === 'orderCanceled' && (it.kod || it.id);
		});
		let orderRefundedData = _.filter(gettingData, function (it) {
			return it.type === 'orderRefunded' && (it.kod || it.id);
		});
		let otherData = _.filter(gettingData, function (it) {
			return it.type !== 'orderCreated';
		});
		if (this.state.botId) {
			dropAgentList = _.concat(
				{ text: t('allAgent'), key: 'all', value: 'all' },
				{ text: 'Bot', key: 'bot', value: this.state.bot_id, id: this.state.bot_id },
				dropAgentList
			);
		} else {
			dropAgentList = _.concat({ text: t('allAgent'), key: 'all', value: 'all' }, dropAgentList);
		}
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		let isSale = _.filter(staticOptions, (it) => {
			return it && it._insale;
		}).length;
		_.map(productAgentList, function (it, i) {
			let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;
			if (i === 0) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.developer);
		});
		//let totalPages = forgottenBasketsGeneral && forgottenBasketsGeneral.userTotal ? Math.round(forgottenBasketsGeneral.userTotal / 20) : 20;
		const panes = [
			{
				menuItem: t('allOrder'),
				render: () => (
					<Tab.Pane attached={false} className="nosegment">
						<Table sortable width={16} fixed style={{ padding: '0px' }}>
							{TableHead(t, true, ecommerce_type)}
							{this.TableLine(
								orderCreatedData,
								productAgentList,
								this.props.displayName,
								otherData,
								t,
								true,
								ecommerce_type,
								!!isSale,
								currency,
								hideId
							)}
						</Table>
					</Tab.Pane>
				),
			},
			{
				menuItem: t('orderCanceled'),
				render: () => (
					<Tab.Pane attached={false} className="nosegment">
						<Table sortable width={16} fixed style={{ padding: '0px' }}>
							{TableHead(t, true, ecommerce_type)}
							{this.TableLine(
								orderCanceledData,
								productAgentList,
								this.props.displayName,
								otherData,
								t,
								true,
								ecommerce_type,
								!!isSale,
								currency,
								hideId
							)}
						</Table>
					</Tab.Pane>
				),
			},
			{
				menuItem: t('orderRefunded'),
				render: () => (
					<Tab.Pane attached={false} className="nosegment">
						<Table sortable width={16} fixed style={{ padding: '0px' }}>
							{TableHead(t, true, ecommerce_type)}
							{this.TableLine(
								orderRefundedData,
								productAgentList,
								this.props.displayName,
								otherData,
								t,
								true,
								ecommerce_type,
								!!isSale,
								currency,
								hideId
							)}
						</Table>
					</Tab.Pane>
				),
			},
			{
				menuItem: t('forgottenBasket'),
				render: () => (
					<Tab.Pane attached={false} className="nosegment">
						<Table sortable width={16} fixed style={{ padding: '0px' }}>
							{ForgettenTableHead(t)}
							{this.ForgettenTableLine(forgottenBaskets, t, currency)}
						</Table>
						{/* 	<div style={{ display: 'flex' }}>
							<Pagination
								disabled={this.state.pageLoading}
								style={{ margin: 'auto' }}
								activePage={activePage}
								onPageChange={this.handlePaginationChange}
								totalPages={totalPages}
							/>
						</div> */}
					</Tab.Pane>
				),
			},
		];

		return (
			<Page.Wrap>
				<Segment style={{ marginBottom: '20px' }}>
					{
						<div style={{ display: 'flex' }}>
							<Dropdown
								selection
								options={platformList}
								value={selectedPlatform}
								floating
								labeled
								button
								icon="filter"
								className="icon"
								name="selectedPlatform"
								onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
							/>
							{agent_role !== 'agent' ? (
								<div style={{ display: 'flex' }}>
									<Dropdown
										options={dropAgentList}
										selection
										defaultValue={dropAgentList[0].value}
										icon="filter"
										floating
										labeled
										button
										className="icon"
										onChange={this.handleFilterChange}
									></Dropdown>
								</div>
							) : (
								''
							)}
							{ecommerce_type && (
								<div style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto', marginLeft: '10px' }}>
									<Checkbox
										onChange={() => this.setState({ hideId: !hideId })}
										checked={hideId}
										toggle
										label="Sipariş Idsiz Gizle"
									></Checkbox>
								</div>
							)}
						</div>
					}
					{/* <Grid stackable columns={2} style={{ width: '100%', margin: 0 }}>
						<CardWrap
							width={3}
							title={t('totalUserForgotten')}
							color="red"
							data={forgottenBasketsGeneral.userTotal}
							note={this.loadingText(forgottenBasketsGeneral.itemTotal, false, t('totalItemForgotten'))}
							description={t('totalUserForgottenDesc')}
						/>
						<CardWrap
							width={3}
							title={t('totalPriceForgotten')}
							color="green"
							data={fixPrice(forgottenBasketsGeneral.basketTotal, currency)}
							description={t('totalPriceForgottenDesc')}
						/>
					</Grid> */}

					<Tab menu={{ secondary: true, pointing: true }} panes={panes} />
				</Segment>
				{/*product detail modal*/}
				<Modal open={this.state.productDetailsModal} onClose={this.closeModal} style={{ width: 500 }} closeIcon>
					<Header icon="eye" content={t('productDetails')} />
					{this.state.productDetailsError ? (
						<Modal.Content>
							<p>{t('productDetailsError')}</p>
							<p>
								{t('orderCode')}: {this.state.selectedOrderCode}
							</p>
						</Modal.Content>
					) : (
						<Modal.Content>
							<p>
								{t('fullName')}: {this.state.orderDetails.DeliveryName}
							</p>
							<p>
								{t('phone')}: {this.state.orderDetails.DeliveryMobile}
							</p>
							<p>
								{t('address')}: {this.state.orderDetails.InvoiceAddress} {this.state.orderDetails.InvoiceTown}-
								{this.state.orderDetails.InvoiceCity}
							</p>
							<p>
								{t('date')}: {moment(this.state.orderDetails.OrderDate).format('DD-MM-YYYY')}
							</p>
							<p>
								{t('status')}: {this.state.orderDetails.OrderStatus}
							</p>
							<p>
								{t('orderCode')}: {this.state.orderDetails.OrderCode}
							</p>
							{ecommerce_type ? (
								<p>
									{t('orderId')}: {this.state.orderDetails.ID}
								</p>
							) : (
								''
							)}
							<p>
								{t('quantity')}: {this.state.orderDetails.adet}
							</p>
							<p>
								{t('price')}: {parseFloat(this.state.orderDetails.OrderTotalPrice).toFixed(2)}
							</p>
							<p>
								{t('cargoFirm')}: {this.state.orderDetails.CargoFirm}
							</p>
							<p>
								{t('cargoCode')}: {this.state.orderDetails.CargoNo || '---'}
							</p>
						</Modal.Content>
					)}
					{this.state.selectedConversation ? (
						<Modal.Actions>
							<Button positive as="a" target="_blank" href={'/#' + this.state.selectedConversation}>
								{t('goToConversation')}
							</Button>
						</Modal.Actions>
					) : (
						''
					)}
				</Modal>
			</Page.Wrap>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default withRouter(connect(stateToProps)(withTranslation()(Analytic)));
