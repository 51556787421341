import React, { Component } from 'react';
import { Message, Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'react-intl-tel-input/dist/main.css';
import { withTranslation } from 'react-i18next';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
class PlatformModal extends Component {
	state = {
		selectedPlatform: '',
		activePage: 1,
		btnLoading: false,
	};

	render() {
		let { t, user } = this.props;
		const roleAgent = user && user.role === 'agent';
		return (
			<Page.Wrap>
				<Segment>
					{roleAgent ? (
						<Message warning>
							{t('loginAgentAccount')},{' '}
							<a href="https://web.whatsapp.com" target="_blank" rel="noopener noreferrer">
								https://web.whatsapp.com
							</a>{' '}
							{t('loginAgentAccountExp1')}
						</Message>
					) : null}
					<Header>{t('howtoInstall')}</Header>
					{roleAgent ? (
						<div className="howtoinstall">
							<div className="main-step">
								<div className="title">1.{t('installChromeExtension')}</div>
								<div className="description">
									<p>
										{t('installChromeExtensionExp1')}{' '}
										<a
											href="https://chrome.google.com/webstore/detail/dialogtab-whatsapp/jjpficnagafofjbfigphjegdnpoibkgp?hl=tr"
											target="_blank"
											rel="noopener noreferrer"
										>
											{t('installChromeExtensionExp2')}
										</a>{' '}
										{t('installChromeExtensionExp3')}
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">2. WhatsApp Business</div>
								<div className="description">
									<p>
										{t('whatsappbusinessExp1')}{' '}
										<a href="https://web.whatsapp.com" target="_blank" rel="noopener noreferrer">
											https://web.whatsapp.com
										</a>{' '}
										{t('whatsappbusinessExp1')} <Link to="/appstore/help/whatsapp">Yardım</Link> {t('loginWhatsappbsnExtentionExp3')}
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">3. {t('definelabelExtention')}</div>
								<div className="description">
									<p>
										{t('definelabelExtentionExp1')} <Link to="/appstore/help/label">Yardım</Link> {t('definelabelExtentionExp2')}
									</p>
								</div>
							</div>
						</div>
					) : (
						<div className="howtoinstall">
							<div className="main-step">
								<div className="title">1. {t('installChromeExtension')}</div>
								<div className="description">
									<p>
										{t('installChromeExtensionExp1')}{' '}
										<a
											href="https://chrome.google.com/webstore/detail/dialogtab-whatsapp/jjpficnagafofjbfigphjegdnpoibkgp?hl=tr"
											target="_blank"
											rel="noopener noreferrer"
										>
											{t('installChromeExtensionExp2')}
										</a>{' '}
										{t('installChromeExtensionExp3')}
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">2. {t('addAgentChromeExtention')}</div>
								<div className="description">
									<p>
										{t('addAgentChromeExtentionExp1')} <Link to="/appstore/agents">{t('agents')}</Link> {t('addAgentChromeExtentionExp2')}
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">3. {t('loginWhatsappbsnExtention')}</div>
								<div className="description">
									<p>
										{t('loginWhatsappbsnExtentionExp1')}{' '}
										<a href="https://web.whatsapp.com" target="_blank" rel="noopener noreferrer">
											https://web.whatsapp.com
										</a>{' '}
										{t('loginWhatsappbsnExtentionExp2')} <Link to="/appstore/help/whatsapp">{t('help')}</Link>{' '}
										{t('loginWhatsappbsnExtentionExp3')}
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">4. {t('definelabelExtention')}</div>
								<div className="description">
									<p>
										{t('definelabelExtentionExp1')} <Link to="/appstore/help/label">{t('help')}</Link> {t('definelabelExtentionExp2')}
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">5. {t('ecommerceIntegExtention')}</div>
								<div className="description">
									<p>
										{t('ecommerceIntegExtentionExp1')} <Link to="/ecommerce">{t('eCommerce')}</Link> {t('ecommerceIntegExtentionExp2')}
									</p>
								</div>
							</div>
							<div className="main-step">
								<div className="title">6. {t('selectSubsExtention')}</div>
								<div className="description">
									<p>
										{t('selectSubsExtentionExp1')} <Link to="/appstore">Abonelik</Link> {t('selectSubsExtentionExp1')}
									</p>
								</div>
							</div>
						</div>
					)}
				</Segment>
			</Page.Wrap>
		);
	}
}
const stateToProps = (state) => ({ user: state.access.user.loggedUserObj, product: state.product.live });

export default connect(stateToProps)(withTranslation()(PlatformModal));
