import React, { Component } from 'react';
import { Segment, Table, Dropdown, Container, Tab, Button, Icon, Modal, Header, Popup, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import TypeList from '../filter3.json';
import { connect } from 'react-redux';
import Page from '../../components/Page';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import 'moment/locale/tr';
import PropTypes from 'prop-types';

function fixPrice(val, currency) {
	if (val) return val.toLocaleString('tr-TR', { style: 'currency', currency });
}
const TableHead = (t, isPrice, isId) => (
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell width={1}>{'#'}</Table.HeaderCell>
			<Table.HeaderCell width={3}>{t('orderCode')}</Table.HeaderCell>
			{isId ? <Table.HeaderCell width={2}>{t('orderId')}</Table.HeaderCell> : null}
			<Table.HeaderCell width={1}>{t('count')}</Table.HeaderCell>
			{isPrice ? <Table.HeaderCell width={2}>{t('price')}</Table.HeaderCell> : null}
			<Table.HeaderCell width={2}>{t('day')}</Table.HeaderCell>
			<Table.HeaderCell width={3}>{t('agent')}</Table.HeaderCell>
			<Table.HeaderCell width={2}>{t('status')}</Table.HeaderCell>
			<Table.HeaderCell width={1}>{t('details')}</Table.HeaderCell>{' '}
		</Table.Row>
	</Table.Header>
);

const ForgettenTableHead = (t) => (
	<Table.Header>
		<Table.Row>
			<Table.HeaderCell width={1}>{'#'}</Table.HeaderCell>
			<Table.HeaderCell>{t('productCount')}</Table.HeaderCell>
			<Table.HeaderCell>{t('price')}</Table.HeaderCell>
			<Table.HeaderCell>{t('date')}</Table.HeaderCell>
			<Table.HeaderCell>{t('user')}</Table.HeaderCell>
			<Table.HeaderCell width={2}>{t('details')}</Table.HeaderCell>
		</Table.Row>
	</Table.Header>
);

class Analytic extends Component {
	state = {
		filterType: false,
		filterAgent: false,
		loading: true,
		start_date: this.props.start_date,
		end_date: this.props.end_date,
		today: moment(),
		group: ['agent'],
		value: _.map(TypeList, (it) => {
			return it;
		}),
		agent_role: this.props.access.user.loggedUserObj.role,
		agent_id: this.props.access.user.loggedUserObj.extra.user_id,
		pid: /* '1e00a47c670273a623c13c54dba44006' || */ /* '5f8dbdc560e4f7d3e00876b8ab5f2ecf' || */ this.props.product.live.settings.live.token,
		selectedAgent: 'all',
		selectedDate: 'daily',
		botId: '',
		tableStart: 0,
		tableEnd: 20,
		dataloading: true,
		orderDetails: {},
		selectedPlatform: 'all',
		activePage: 1,
		hideId: true,
	};
	_i = 0;
	static contextTypes = {
		router: PropTypes.object,
	};
	componentDidMount() {
		let typeQuery = [];
		let group = [];
		let select = [];
		let { start_date, end_date, agent_id, agent_role, selectedPlatform } = this.state;
		let valueArr = this.state.value.length ? this.state.value : TypeList;
		_.each(valueArr, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		this.setState({ head: typeQuery });
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		let filter = {};
		if (agent_role === 'agent') {
			filter = { type: typeQuery, pid: this.state.pid, agent: agent_id };
			this.setState({ selectedAgent: agent_id });
		} else {
			filter = { type: typeQuery, pid: this.state.pid };
		}
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		fetch('/api/dahi/message/getLiveChat', {})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({
					productAgentList:
						data.result &&
						data.result.agents &&
						_.filter(data.result.agents, function (it) {
							return it.type !== 'viewer';
						}),
					botId: data.result && data.result.bot.bid,
					bot_id: data.result && data.result.bot.id,
					staticOptions: data.result && data.result.staticOptions,
				});
			});
		fetch('/api/live/agent/list')
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				this.setState({ newList: data.agents });
			});
		fetch('https://log.collectaction.com/api/getUserOrders', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				pid: 'b59ce1f4-d16c-464c-90ee-5c297d8ab9fb',
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = _.filter(data.data, (it) => it.extra.agent_id && /livechat/.test(it.extra.agent_id));
				console.log('result', result);
				result = _.uniqBy(result, 'code');
				this.setState({ gettingData: _.sortBy(result, ['created_at', 'asc']), loading: false });
			});
	}
	handleFilterChange = (e, { text, value }) => this.setState({ selectedAgent: value, selectedAgentName: text });
	handleFilterCheck(value) {
		let array = this.state.value || [];
		let exists = _.find(array, value);
		let valueArr = exists ? _.filter(array, (it) => it !== value) : _.concat([], array, value);
		this.setState({ value: valueArr });
	}
	filterSelectType = () => {
		this.setState({ filterType: !this.state.filterType });
	};
	componentDidUpdate(oldProps, oldState) {
		const check_list = [/* 'start_date', 'end_date',  */ 'selectedAgent', 'value', 'selectedPlatform', 'activePage', 'newDataToggle'];
		for (let i = 0; i < check_list.length; i++) {
			const key = check_list[i];
			let timer;

			if (key === 'start_date' || key === 'end_date' || key === 'newDataToggle') {
				if (this.props[key] !== oldProps[key]) {
					this.setState({ activePage: 1 });
					window.clearTimeout(timer);
					timer = setTimeout(() => this.getData(), 3000);
					break;
				}
			}
			if (this.state[key] !== oldState[key]) {
				this.setState({ activePage: 1 });
				window.clearTimeout(timer);
				timer = setTimeout(() => this.getData(), 3000);
				break;
			}
		}
	}

	async getData() {
		this.setState({ loading: true });
		let typeQuery = [];
		let group = [];
		let select = [];
		let current = ++this._i;
		let { selectedAgent, selectedPlatform } = this.state;
		let { start_date, end_date } = this.props;

		_.each(this.state.value, (it) => {
			typeQuery = _.concat(typeQuery, it.type);
			if (it.group) {
				if (group.length) group = _.intersection(group, it.group);
				else group = it.group;
			}
			if (it.select) {
				if (select.length) select = _.intersection(select, it.select);
				else select = it.select;
			}
		});
		typeQuery = _.uniq(typeQuery);
		if (group.length) group = _.union(group, ['type']);
		if (select.length) select = _.union(select, ['type', 'day']);
		let filter = {};
		if (selectedAgent === 'all') {
			filter = { type: typeQuery, pid: this.state.pid };
		} else {
			filter = { type: typeQuery, pid: this.state.pid, agent: selectedAgent };
		}
		start_date = moment(start_date).format();
		end_date = moment(end_date).format();
		if (selectedPlatform !== 'all') _.set(filter, 'platform', selectedPlatform);
		else _.unset(filter, 'platform');
		fetch('https://log.collectaction.com/api/getUserOrders', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				start_date: start_date,
				end_date: end_date,
				pid: 'b59ce1f4-d16c-464c-90ee-5c297d8ab9fb',
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let result = _.filter(data.data, (it) => it.extra.agent_id && /livechat/.test(it.extra.agent_id));
				console.log('result', result);
				result = _.uniqBy(result, 'code');
				this.setState({ gettingData: _.sortBy(result, ['created_at', 'asc']), loading: false });
			});
	}
	dateChange = ({ selection }) => {
		let start_date = moment(selection.startDate).startOf('day');
		let end_date = moment(selection.endDate);
		if (end_date.format('YYYY-MM-DD') !== this.state.today.format('YYYY-MM-DD')) {
			end_date = end_date.endOf('day');
		} else {
			end_date = moment();
		}
		this.setState({ start_date, end_date });
	};
	getOrderDetails = async (val) => {
		this.setState({ selectedOrderCode: val.code, orderDetails: val, productDetailsModal: true, productDetailsError: false });
	};

	getConversationFromUid(uid) {
		fetch('https://live.dahi.ai/analytics/findCidByUid', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				uid,
				pid: this.state.pid,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let cid = data.data;
				if (cid) {
					const win = window.open('/#' + cid, '_blank');
					win.focus();
				}
			});
	}
	closeModal = () => {
		this.setState({ productDetailsModal: false, productDetailsError: false });
	};
	TableLine = (data, productAgentList, displayName, otherStatus, t, isPrice, isId, isSale, currency, hideId) => (
		<Table.Body>
			<Table.Row key="total">
				<Table.Cell>#</Table.Cell>
				<Table.Cell>---</Table.Cell>
				{isId ? <Table.Cell>{'---'}</Table.Cell> : null}
				<Table.Cell>{_.sumBy(data, (it) => parseInt(it.count)) || 0}</Table.Cell>
				{isPrice ? <Table.Cell>{fixPrice(_.sumBy(data, (it) => parseFloat(it.total)) || 0, currency) || 0}</Table.Cell> : null}
				<Table.Cell>{'---'}</Table.Cell>
				<Table.Cell>{'Toplam'}</Table.Cell>
				<Table.Cell>{'---'}</Table.Cell>
				<Table.Cell></Table.Cell>
			</Table.Row>
			{_.map(
				_.orderBy(
					_.filter(data, (it) => {
						if (isId && hideId) return !!it.id;
						else return it;
					}),
					['day'],
					['desc']
				),
				(val, index) => {
					let agent = _.find(productAgentList, (it) => it.fullname === val.extra.agent_id.replace('livechat_', ''));
					return (
						<Table.Row key={index}>
							<Table.Cell>{index + 1}</Table.Cell>
							<Table.Cell>{(val && val.code) || 0}</Table.Cell>
							{isId ? <Table.Cell>{(val && val.id) || '---'}</Table.Cell> : null}
							<Table.Cell>{(val && val.count) || 0}</Table.Cell>
							{isPrice ? <Table.Cell>{(val && val.total && fixPrice(val.total, currency)) || 0}</Table.Cell> : null}
							<Table.Cell>{(val && val.created_at && moment(val.created_at).format('DD-MM-YYYY')) || 0}</Table.Cell>
							<Table.Cell>{agent?.developer || val.extra.agent_id.replace('livechat_', '')}</Table.Cell>
							<Table.Cell>{(_.find(otherStatus, { kod: val.kod }) && t(_.find(otherStatus, { kod: val.kod }).type)) || t('created')}</Table.Cell>
							<Table.Cell>
								<Button icon primary onClick={() => this.getOrderDetails(val)}>
									<Icon name="eye"></Icon>
								</Button>
							</Table.Cell>
						</Table.Row>
					);
				}
			)}
		</Table.Body>
	);

	ForgettenTableLine = (data, t, currency) => (
		<Table.Body>
			{_.map(data, (val, index) => (
				<Table.Row key={index}>
					<Table.Cell>{index + 1}</Table.Cell>
					<Table.Cell>{val.urun && val.urun.length ? val.urun.length : '-'}</Table.Cell>
					<Table.Cell>
						{val.urun && val.urun.length
							? fixPrice(
									_.sumBy(val.urun, (it) => parseFloat(it.price)),
									currency
							  )
							: '-'}
					</Table.Cell>
					<Table.Cell>{val.updateDate ? moment(val.updateDate).format('DD-MM-YYYY') : ''}</Table.Cell>
					<Table.Cell>{/[0-9]{12}-/.test(val.uid) ? val.uid.replace(/-.*/, '') : ''} </Table.Cell>
					<Table.Cell>
						<Button primary icon onClick={() => this.getConversationFromUid(val.uid)}>
							<Icon name="conversation"></Icon>
						</Button>
					</Table.Cell>
				</Table.Row>
			))}
		</Table.Body>
	);
	handlePaginationChange = (e, { activePage }) => this.setState({ activePage, pageLoading: true });
	loadingText(text, load, title, popup) {
		if (load) {
			return title ? (
				<p>
					{title + ': '}
					<Icon name="spinner" loading></Icon>
				</p>
			) : (
				<Icon name="spinner" loading></Icon>
			);
		}

		return title ? (
			<p>
				{popup && <Popup trigger={<Icon name="question circle outline" />} content={popup} size="mini" position={'bottom'} />}{' '}
				{title + ': ' + text || 0}
			</p>
		) : (
			text || 0
		);
	}
	render() {
		const { t, platformList, product } = this.props;
		let selectedPlatform = this.state.selectedPlatform;
		let ecommerce_type = this.state.ecommerce_type;
		let gettingData = this.state.gettingData;
		let productAgentList = this.state.productAgentList;
		let newList = this.state.newList;
		let hideId = this.state.hideId;
		let agent_role = this.state.agent_role;
		let { forgottenBaskets } = this.state;
		let ownerName =
			this.props && this.props.access && this.props.access.user && this.props.access.user.loggedUserObj && this.props.access.user.loggedUserObj.name;
		let dropAgentList = _.map(productAgentList, function (it) {
			return { text: it.developer, key: it.id, value: it.id };
		});
		let staticOptions = this.state.staticOptions;
		let currency = (product && product.live && product.live.settings.currency) || 'try';
		_.map(gettingData, function (it, i) {
			return (it.dayNumber = moment(it.day).dayOfYear());
		});
		let orderCreatedData = _.filter(gettingData, function (it) {
			return it.kod || it.id;
		});
		/* let orderCreatedDataFilter = _.map(_.groupBy(orderCreatedData, 'kod'), function (it) {
			return it[0];
		}); */
		let orderCanceledData = _.filter(gettingData, function (it) {
			return it.type === 'orderCanceled' && (it.kod || it.id);
		});
		let orderRefundedData = _.filter(gettingData, function (it) {
			return it.type === 'orderRefunded' && (it.kod || it.id);
		});
		let otherData = _.filter(gettingData, function (it) {
			return it.type !== 'orderCreated';
		});
		if (this.state.botId) {
			dropAgentList = _.concat(
				{ text: t('allAgent'), key: 'all', value: 'all' },
				{ text: 'Bot', key: 'bot', value: this.state.bot_id, id: this.state.bot_id },
				dropAgentList
			);
		} else {
			dropAgentList = _.concat({ text: t('allAgent'), key: 'all', value: 'all' }, dropAgentList);
		}
		console.log('ppp', gettingData);
		if (this.state.loading)
			return (
				<Container>
					<Segment style={{ marginBottom: '20px' }}>
						<Page.Loader></Page.Loader>
					</Segment>
				</Container>
			);
		let isSale = _.filter(staticOptions, (it) => {
			return it && it._insale;
		}).length;
		_.map(productAgentList, function (it, i) {
			let name = _.find(newList, { email: it.developer }) && _.find(newList, { email: it.developer }).name;
			if (i === 0) {
				return (it.name = ownerName || it.developer);
			}
			return (it.name = name ? name : it.developer);
		});
		//let totalPages = forgottenBasketsGeneral && forgottenBasketsGeneral.userTotal ? Math.round(forgottenBasketsGeneral.userTotal / 20) : 20;
		const panes = [
			{
				menuItem: t('allOrder'),
				render: () => (
					<Tab.Pane attached={false} className="nosegment">
						<Table sortable width={16} fixed style={{ padding: '0px' }}>
							{TableHead(t, true, ecommerce_type)}
							{this.TableLine(
								orderCreatedData,
								productAgentList,
								this.props.displayName,
								otherData,
								t,
								true,
								ecommerce_type,
								!!isSale,
								currency,
								hideId
							)}
						</Table>
					</Tab.Pane>
				),
			},
		];

		return (
			<Page.Wrap>
				<Segment style={{ marginBottom: '20px' }}>
					{
						<div style={{ display: 'flex' }}>
							{/* <Dropdown
								selection
								options={platformList}
								value={selectedPlatform}
								floating
								labeled
								button
								icon="filter"
								className="icon"
								name="selectedPlatform"
								onChange={(e, { name, value }) => this.setState({ selectedPlatform: value })}
							/>
							{agent_role !== 'agent' ? (
								<div style={{ display: 'flex' }}>
									<Dropdown
										options={dropAgentList}
										selection
										defaultValue={dropAgentList[0].value}
										icon="filter"
										floating
										labeled
										button
										className="icon"
										onChange={this.handleFilterChange}
									></Dropdown>
								</div>
							) : (
								''
							)} */}
							{ecommerce_type && (
								<div style={{ display: 'flex', marginTop: 'auto', marginBottom: 'auto', marginLeft: '10px' }}>
									<Checkbox
										onChange={() => this.setState({ hideId: !hideId })}
										checked={hideId}
										toggle
										label="Sipariş Idsiz Gizle"
									></Checkbox>
								</div>
							)}
						</div>
					}
					{/* <Grid stackable columns={2} style={{ width: '100%', margin: 0 }}>
						<CardWrap
							width={3}
							title={t('totalUserForgotten')}
							color="red"
							data={forgottenBasketsGeneral.userTotal}
							note={this.loadingText(forgottenBasketsGeneral.itemTotal, false, t('totalItemForgotten'))}
							description={t('totalUserForgottenDesc')}
						/>
						<CardWrap
							width={3}
							title={t('totalPriceForgotten')}
							color="green"
							data={fixPrice(forgottenBasketsGeneral.basketTotal, currency)}
							description={t('totalPriceForgottenDesc')}
						/>
					</Grid> */}

					<Tab menu={{ secondary: true, pointing: true }} panes={panes} />
				</Segment>
				{/*product detail modal*/}
				{this.state.orderDetails ? (
					<Modal open={this.state.productDetailsModal} onClose={this.closeModal} style={{ width: 500 }} closeIcon>
						<Header icon="eye" content={t('productDetails')} />
						{this.state.productDetailsError ? (
							<Modal.Content>
								<p>{t('productDetailsError')}</p>
								<p>
									{t('orderCode')}: {this.state.selectedOrderCode}
								</p>
							</Modal.Content>
						) : (
							<Modal.Content>
								<p>
									{t('fullName')}: {this.state.orderDetails?.extra?.user?.name + ' ' + this.state.orderDetails?.extra?.user?.lastname}
								</p>
								<p>
									{t('phone')}: {this.state.orderDetails?.extra?.user?.number}
								</p>
								<p>
									{t('email')}: {this.state.orderDetails?.extra?.user?.email}
								</p>
								<p>
									{t('address')}: {this.state.orderDetails?.extra?.user?.address} {this.state.orderDetails?.extra?.user?.district}-
									{this.state.orderDetails?.extra?.user?.town}
								</p>
								<p>
									{t('date')}: {moment(this.state.orderDetails?.created_at).format('DD-MM-YYYY')}
								</p>
								<p>{t('status')}: Oluşturuldu</p>
								<p>
									{t('orderCode')}: {this.state.orderDetails?.code}
								</p>

								<p>
									{t('quantity')}: {this.state.orderDetails?.count}
								</p>
								<p>
									{t('price')}: {parseFloat(this.state.orderDetails?.total).toFixed(2)}
								</p>
								{/* <p>
								{t('cargoFirm')}: {this.state.orderDetails.CargoFirm}
							</p> */}
								{/* <p>
								{t('cargoCode')}: {this.state.orderDetails.CargoNo || '---'}
							</p> */}
							</Modal.Content>
						)}
						{this.state.selectedConversation ? (
							<Modal.Actions>
								<Button positive as="a" target="_blank" href={'/#' + this.state.selectedConversation}>
									{t('goToConversation')}
								</Button>
							</Modal.Actions>
						) : (
							''
						)}
					</Modal>
				) : (
					''
				)}
			</Page.Wrap>
		);
	}
}
const stateToProps = (state) => ({ access: state.access, location: state.router.location, product: state.product });

export default withRouter(connect(stateToProps)(withTranslation()(Analytic)));
