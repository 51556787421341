import React, { Component } from 'react';
import { get, post } from '../../util/api';
import { Segment, Table, Tab, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';

export default class Extension extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		this.postData();
	}
	async postData() {
		await get('/ext/apps/admin/log').then((data) => this.setState({ data: data.data, loading: false }));
	}
	render() {
		let { loading, data } = this.state;
		console.log("data ",data)
		if (loading) return <Segment attached="bottom">loading...</Segment>;
		return (
			<div style={{ display: 'flex', maxHeight: '95%' }}>
				<Tab.Pane attached={true} style={{ overflow: 'auto', padding: 0 }}>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Product Mail</Table.HeaderCell>
								<Table.HeaderCell>Agent Email</Table.HeaderCell>
								<Table.HeaderCell>Son Giriş Saati</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_.map(data, (o, i) => (
								<Table.Row key={i}>
									<Table.Cell>{o.product_email}</Table.Cell>
									<Table.Cell>{o.email}</Table.Cell>

									<Table.Cell>{moment(o.subs_date).format('YYYY-MM-DD HH:m:s')}</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Tab.Pane>
			</div>
		);
	}
}
