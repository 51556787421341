import React, { Component } from 'react';
import { post } from '../../util/api';
import { Segment, Table, Tab, Button, Header, Form, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

export default class ActiveAccounts extends Component {
	TableLineCustom = (data) =>
		_.map(data, (val, index) => (
			<Table.Row key={index}>
				{/* <Table.Cell>
					<Button
						color="blue"
						disabled={val.is_reseller || val.is_enterprise}
						onClick={() =>
							this.selectProduct({
								email: val.owner_email,
								pid: val.pid,
								agentQuantity: val.agent_limit,
								phoneQuantity: val.phone_limit,
								instagramQuantity: val.instagram_limit,
								telegramQuantity: val.telegram_limit,
								insta: val.insta,
								plan_id: val.package,
							})
						}
					>
						Edit
					</Button>
				</Table.Cell> */}
				<Table.Cell>{val && val.owner_id}</Table.Cell>
				<Table.Cell>{val && val.owner_email}</Table.Cell>
				<Table.Cell>{val && val.phones && val.phones.length}</Table.Cell>
				<Table.Cell>{val && val.instagram ? 'true' : 'false'}</Table.Cell>

				<Table.Cell>{val && val.Telegram ? 'true' : 'false'}</Table.Cell>
				<Table.Cell>{val && val.facebook ? 'true' : 'false'}</Table.Cell>

				<Table.Cell>{val && val['whatsapp-business'] ? 'true' : 'false'}</Table.Cell>

				<Table.Cell>{val && val.is_eticaret ? 'true' : 'false'}</Table.Cell>
			</Table.Row>
		));
	selectProduct(value) {
		this.setState({
			pid: value.pid,
			agentQuantity: value.agentQuantity || 0,
			phoneQuantity: value.phoneQuantity || 0,
			instagramQuantity: value.instagramQuantity || 0,
			telegramQuantity: value.telegramQuantity || 0,
			subscriptionId: value.plan_id,
			plan_id: value.plan_id,
			insta: value.insta,
			email: value.email,
		});
	}
	addProduct = () => {
		this.setState({ btnloading: true });
		let { pid, agentQuantity, phoneQuantity, instagramQuantity, telegramQuantity, subscriptionId, trialDay, insta } = this.state;
		post('/live/admin/setPackage', {
			pid: pid,
			new_limit: parseInt(agentQuantity),
			new_phone_limit: parseInt(phoneQuantity),
			new_instagram_limit: parseInt(instagramQuantity),
			new_telegram_limit: parseInt(telegramQuantity),
			subscription_plan_id: subscriptionId,
			insta,
			trial: subscriptionId === 'custom' ? false : true,
			trial_day: trialDay,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnloading: false });
				this.refresh();
			} else {
				this.setState({ btnloading: false, errMessage: data.data.error });
			}
		});
	};
	resetSub = () => {
		this.setState({ btnResloading: true });
		let { pid } = this.state;
		post('/live/admin/setPackage', {
			pid: pid,
			reset: true,
		}).then((data) => {
			if (data.data.success) {
				this.setState({ btnResloading: false });
				this.refresh();
			} else {
				this.setState({ btnResloading: false, errMessage: data.data.error });
			}
		});
	};
	handleChange = (e, { name, value }) => this.setState({ [name]: value });
	toggle = (e, { name, value }) => this.setState((prevState) => ({ [name]: !prevState[name] }));
	state = {
		loading: true,
		activePage: 1,
		limit: 50,
		email: '',
		buttonloading: true,
		subscriptionId: 'custom',
		trialDay: 14,
	};
	componentDidMount() {
		this.postData();
	}
	async postData() {
		await post('/live/admin/active').then((data) => this.setState({ data: _.orderBy(data.data.result, 'owner_id', 'desc'), loading: false }));
		let { data } = this.state;
		let dg = _.map(data, function (it) {
			it = {
				tip: it.tip ? it.tip : it.telegram_limit ? 'Telegram' : '',
				agent_limit: it.agent_limit || 0,
				phone_limit: it.phone_limit || 0,
				instagram_limit: it.instagram_limit || 0,
				telegram_limit: it.telegram_limit || 0,
				owner_id: it.owner_id,
				owner_name: it.owner_name,
				is_reseller: it.is_reseller,
				is_enterprise: it.is_enterprise,
				is_eticaret: it.is_eticaret,
				owner_email: it.owner_email,
				pid: it.pid,
				token: it.token,
				agents: it.agents,
				phones: it.phones || [],
				instagrams: it.instagrams,
				telegrams: it.telegrams,
				package: it.package,
				verified: it.verified,
				total_agent: it.total_agent,
				wapi_pid: it.wapi_pid,
				insta: it.insta,
				wapi_token: it.wapi_token,
				analyticview: it.analyticview,
				userlist: (it.analyticview && it.analyticview.userlist) || false,
				ecommerce: (it.analyticview && it.analyticview.ecommerce) || false,
				ratePoint: (it.analyticview && it.analyticview.ratePoint) || false,
				sendProduct: (it.analyticview && it.analyticview.sendProduct) || false,
				showTemplate: (it.analyticview && it.analyticview.showTemplate) || false,
				conversationGroup: (it.analyticview && it.analyticview.conversationGroup) || false,
				orderRefunded_count: 0,
				orderRefunded_len: 0,
				orderCanceled_count: 0,
				orderCanceled_len: 0,
				test_account: it.test_account || false,
				instagram: '',
				facebook: '',
				'whatsapp-business': '',
				Telegram: '',
			};
			return it;
		});

		var unique = [];
		for (let i = 0; i < dg.length; i++) {
			if (
				!unique.find((mail, j) => {
					return mail.owner_email === dg[i].owner_email;
				})
			) {
				unique.push(dg[i]);
			} else {
				let { tip } = dg[i];
				let tip2 = [tip];
				let tip3 = tip2[0];
				let res = _.find(unique, (c, t) => {
					if (c.owner_email === dg[i].owner_email) {
						if (tip3) {
							unique[t][tip3] = true;

							return c;
						}
					}
				});
			}
		}

		dg = unique;
		let counts = { instagram: 0, facebook: 0, Telegram: 0, waba: 0 };
		dg = _.filter(dg, (e, i) => {
			let { tip } = dg[i];
			let tip2 = [tip];
			let tip3 = tip2[0];
			dg[i][tip3] = true;
			let control = 0;

			if (e.facebook) {
				control++;
				counts.facebook++;
			}
			if (e.instagram) {
				control++;
				counts.instagram++;
			}
			if (e.Telegram) {
				control++;
				counts.Telegram++;
			}
			if (e['whatsapp-business']) {
				control++;
				counts.waba++;
			}
			if (control > 0) return true;
		});

		this.setState({ dg, counts });
	}
	handleSort = (clickedColumn, data) => () => {
		console.log('data ', data.length);
		const { column, direction } = this.state;
		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [clickedColumn]),
				dg: _.sortBy(data, [clickedColumn]),

				direction: 'ascending',
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending',
		});
	};
	refresh() {
		setTimeout(() => {
			window.location.reload(false);
		}, 1000);
	}
	render() {
		const { column, direction, loading, data, dg, counts } = this.state;
		let packageOptions = [
			{
				text: 'Custom',
				value: 'custom',
				key: 'custom',
			},
			{
				text: 'Trial',
				value: 'trial-start',
				key: 'trial-start',
			},
		];
		if (loading) return <Segment attached="bottom">loading...</Segment>;

		return (
			<div style={{ display: 'flex', maxHeight: '100%' }}>
				<Tab.Pane attached={false} style={{ width: '100%', overflow: 'auto', padding: 0 }}>
					<Segment>
						{counts &&
							_.map(Object.entries(counts), (e, i) => {
								return (
									<div
										key={'detail ' + i}
										style={{
											marginLeft: 15,
											display: 'inline',
											background: '#e4e4e4',
											borderRadius: '4px',
											padding: 10,
											border: '1px solid #c8c8c8',
										}}
									>
										{e[0] + '  |  ' + e[1]}
									</div>
								);
							})}
					</Segment>
					<Table celled sortable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell sorted={column === 'owner_id' ? direction : null} onClick={this.handleSort('owner_id', dg)}>
									Id
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'owner_email' ? direction : null} onClick={this.handleSort('owner_email', dg)}>
									Email
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'phones' ? direction : null} onClick={this.handleSort('phones', dg)}>
									Phone
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'instagram' ? direction : null} onClick={this.handleSort('instagram', dg)}>
									Instagrams
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'Telegram' ? direction : null} onClick={this.handleSort('Telegram', dg)}>
									Telegram
								</Table.HeaderCell>

								<Table.HeaderCell sorted={column === 'facebook' ? direction : null} onClick={this.handleSort('facebook', dg)}>
									Facebook
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'whatsapp-business' ? direction : null} onClick={this.handleSort('whatsapp-business', dg)}>
									WABA
								</Table.HeaderCell>
								<Table.HeaderCell sorted={column === 'is_eticaret' ? direction : null} onClick={this.handleSort('is_eticaret', dg)}>
									E-commerce
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{this.TableLineCustom(dg)}</Table.Body>
					</Table>
				</Tab.Pane>
			</div>
		);
	}
}
