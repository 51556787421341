import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginPage, RegisterPage, ForgetPasswordPage, ResetPasswordPage, VerifyPage } from './pages/auth';
import ErrorMessage from './components/ErrorMessage';
import Page from './components/Page';
import Header from './components/Header';
import CheckMail from './pages/checkMail';
import { load_product } from './actions/product.actions';
import ChatPage from './pages/chat';
import Analytic from './pages/analytic';
import Settings from './pages/settings';
import Account from './pages/account/router';
import PlatformModal from './components/PlatformModal';
import CreateTrialReseller from './components/CreateTrialReseller';
import Admin from './pages/admin';
import OrderBot from './pages/orderbot';
import Reseller from './pages/reseller';
import WGroup from './pages/wgroup';
import WSendmessage from './pages/wsendmessage';
import WCart from './pages/wcart';
import ShoppingList from './pages/shoppinglist';
import AppStore from './pages/appstore';
import Ecommerce from './pages/settings/modules/ecommerce';
import RoofMap from './pages/roofMap';
import OldAnalytic from './pages/old_analytic';
import WValidation from './pages/wvalid';
import TicimaxLogs from './pages/settings/modules/ticimax_logs';

function NoMatch() {
	return <ErrorMessage title="We are sorry, Page not found!" list={['Please check that the connection is correct.']} code="404" />;
}
function AgentWarning() {
	return <ErrorMessage title="Only admin can view this page." list={['Please contact to your admin.']} code="404" />;
}

const LayoutRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(matchProps) => (
			<>
				<Header />
				<Component {...matchProps} />{' '}
			</>
		)}
	/>
);

function LoadProduct({ dispatch }) {
	React.useEffect(() => {
		dispatch(load_product());
	}, [dispatch]);
	return <Page.Loader />;
}
function LoadReseller({ dispatch }) {
	React.useEffect(() => {
		dispatch(load_product('reseller'));
	}, [dispatch]);
	return <Page.Loader />;
}
class App extends React.PureComponent {
	render() {
		if (this.props.access.loading === 'profile' || this.props.product.loading) return <Page.Loader />;
		const user = this.props.access.user;
		if (this.props.product.error) throw new Error(this.props.product.error);
		let product = this.props.product.live;
		let reseller = user && user.loggedUserObj && user.loggedUserObj.extra && user.loggedUserObj.extra.reseller;
		if (reseller) {
			product = this.props.product.reseller;
		}
		/* let host = user && user.loggedUserObj && user.loggedUserObj.host;
		let pass_sandbox = (product && product.settings && product.settings.sandbox) || process.env.REACT_APP_RESELLER;
		let pass_subscription = (product && product.settings && product.settings.usedTrial !== false) || process.env.REACT_APP_RESELLER; */
		let usedTrial = product && product.settings && product.settings.usedTrial;
		let admin = user && user.loggedUserObj && user.loggedUserObj.extra && user.loggedUserObj.extra.admin;
		let wgroup = user && user.loggedUserObj && user.loggedUserObj.extra && user.loggedUserObj.extra.wgroup;
		let isAgent = user && user.loggedUserObj && user.loggedUserObj.extra && user.loggedUserObj.extra.__isAgent;
		let roleAgent = user && user.loggedUserObj && user.loggedUserObj.role && user.loggedUserObj.role === 'agent';
		let roleDev = user && user.loggedUserObj && user.loggedUserObj.role && user.loggedUserObj.role === 'dev';

		let platform = product && product.settings && product.settings.panel;
		let wsendmessage = product && product.settings && product.settings.wsendmessage;
		let wvalidation = product && product.settings && product.settings.wvalidation;
		let showWABroadcast = wsendmessage && !roleAgent;
		if (platform === 'roof') {
			return (
				<Switch>
					<Route path="/login" component={LoginPage} />
					<Route path="/register" component={RegisterPage} />
					<Route exact path="/forgot" component={ForgetPasswordPage} />
					<Route path="/forgot/checkyourmail" component={CheckMail} />
					<Route path="/verify/resetPassword/:token" component={ResetPasswordPage} />
					<Route path="/verify/account/:token" component={VerifyPage} />
					<LayoutRoute path="/" component={RoofMap} />
				</Switch>
			);
		} else if (platform === 'ext') {
			return (
				<Switch>
					<Route path="/login" component={LoginPage} />
					<Route path="/register" component={RegisterPage} />
					<Route exact path="/forgot" component={ForgetPasswordPage} />
					<Route path="/forgot/checkyourmail" component={CheckMail} />
					<Route path="/verify/resetPassword/:token" component={ResetPasswordPage} />
					<Route path="/verify/account/:token" component={VerifyPage} />
					<Route path="/shoppinglist/:pid" component={ShoppingList} />
					{(!user || !user.isAuthenticated) && <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />}
					{reseller && !this.props.product.list && <Route component={() => <LoadReseller {...this.props} />} />}
					{!reseller && !this.props.product.list && <Route component={() => <LoadProduct {...this.props} />} />}
					{!platform ? <LayoutRoute component={PlatformModal} /> : null}
					{/* {(pass_sandbox && pass_subscription) || reseller ? null : <LayoutRoute component={StartModal} />} */}
					{/* {!reseller && !usedTrial && host !== 'app.dialogtab.com' ? host !== null ? <LayoutRoute component={CreateTrialReseller} /> : null : null} */}
					{!reseller && !usedTrial && !isAgent ? <LayoutRoute component={CreateTrialReseller} /> : null}
					{admin ? <LayoutRoute path="/yta" component={() => <Admin {...this.props} />} /> : null}
					{reseller ? <LayoutRoute path="/reseller" component={Reseller}></LayoutRoute> : null}
					{reseller ? <LayoutRoute path="/" exact component={Reseller} /> : null}
					{(admin || wgroup) && !roleAgent ? <LayoutRoute path="/wgroup" component={WGroup} /> : ''}
					{admin || showWABroadcast ? <LayoutRoute path="/wsendmessage" component={WSendmessage} /> : ''}
					{admin || showWABroadcast ? <LayoutRoute path="/wcart" component={WCart} /> : ''}
					{platform === 'dialogtab' ? (
						<LayoutRoute path="/" exact component={ChatPage} />
					) : (
						<LayoutRoute path="/" exact component={() => <AppStore {...this.props} />} />
					)}

					<LayoutRoute path="/account" component={Account} />
					<LayoutRoute path="/appstore" component={() => <AppStore {...this.props} />} />
					{roleAgent ? null : <LayoutRoute path="/ecommerce" component={() => <Ecommerce {...this.props} />} />}
					<LayoutRoute component={NoMatch} />
				</Switch>
			);
		}
		return (
			<Switch>
				<Route path="/login" component={LoginPage} />
				<Route path="/register" component={RegisterPage} />
				<Route exact path="/forgot" component={ForgetPasswordPage} />
				<Route path="/forgot/checkyourmail" component={CheckMail} />
				<Route path="/verify/resetPassword/:token" component={ResetPasswordPage} />
				<Route path="/verify/account/:token" component={VerifyPage} />
				<Route path="/shoppinglist/:pid" component={ShoppingList} />
				{(!user || !user.isAuthenticated) && <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />}
				{reseller && !this.props.product.list && <Route component={() => <LoadReseller {...this.props} />} />}
				{!reseller && !this.props.product.list && <Route component={() => <LoadProduct {...this.props} />} />}
				{!platform ? <LayoutRoute component={PlatformModal} /> : null}
				{/* {(pass_sandbox && pass_subscription) || reseller ? null : <LayoutRoute component={StartModal} />} */}
				{/* {!reseller && !usedTrial && host !== 'app.dialogtab.com' ? host !== null ? <LayoutRoute component={CreateTrialReseller} /> : null : null} */}
				{!reseller && !usedTrial && !isAgent ? <LayoutRoute component={CreateTrialReseller} /> : null}
				{admin ? <LayoutRoute path="/yta" component={() => <Admin {...this.props} />} /> : null}
				{<LayoutRoute path="/orderbot" component={() => <OrderBot {...this.props} />} />}
				{reseller ? <LayoutRoute path="/reseller" component={Reseller}></LayoutRoute> : null}
				{reseller ? <LayoutRoute path="/" exact component={Reseller} /> : null}
				{(admin || wgroup) && !roleAgent ? <LayoutRoute path="/wgroup" component={WGroup} /> : ''}
				{admin || showWABroadcast ? <LayoutRoute path="/wsendmessage" component={WSendmessage} /> : ''}
				{admin || showWABroadcast ? <LayoutRoute path="/wcart" component={WCart} /> : ''}
				{platform === 'dialogtab' ? (
					<LayoutRoute path="/" exact component={ChatPage} />
				) : (
					<LayoutRoute path="/" exact component={() => <AppStore {...this.props} />} />
				)}
				{admin || roleDev ? <LayoutRoute path="/ticimax_logs" component={TicimaxLogs} /> : ''}
				{admin || wvalidation ? <LayoutRoute path="/wvalidation" component={WValidation} /> : ''}
				<LayoutRoute path="/account" component={Account} />
				{!isAgent && !reseller ? <LayoutRoute path="/settings" component={Settings} /> : null}
				{isAgent ? <LayoutRoute path="/settings" component={AgentWarning} /> : null}
				{!reseller ? <LayoutRoute path="/analytic" component={Analytic} /> : null}
				<LayoutRoute path="/oldanalytic" component={OldAnalytic} />
				{roleAgent ? null : <LayoutRoute path="/ecommerce" component={() => <Ecommerce {...this.props} />} />}
				<LayoutRoute component={NoMatch} />
			</Switch>
		);
	}
}

const stateToProps = (state) => ({ access: state.access, product: state.product, location: state.router.location, sub: state });

export default connect(stateToProps)(App);
